import React from 'react';
import './styles.css';
import { Button, TextField } from 'papaya-ui/dist';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ROUTES } from '../../../../config/ClaimPortal/pathSetting';
import utils from '../../../../config/utils';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import setUser from '../../../../store/ClaimPortal/commonActions/user';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
}

interface IState {
  data: { email?: string; password?: string };
  error?: string;
  generalError?: string;
  isLoading: boolean;
}

const mapStateToProp = (state) => ({
  auth: state.auth,
  loading: state.loading,
});

class LoginScreen extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        password: '',
      },
      error: '',
      generalError: '',
      isLoading: false,
    };
  }

  async componentDidMount() {
    window.addEventListener('keyup', this.enterToLogin);
    const response = await this.checkValid();
    if (response) {
      this.props.history.push(ROUTES.dashboard);
    }
  }

  checkValid = async () => {
    return ClaimPortalService.me();
  };

  handleChange = (name, value) => {
    const { t } = this.props;
    const { data }: Readonly<IState> = this.state;
    const newData = data;
    newData[name] = value;
    if (name === 'email') {
      let emailErr = '';
      if (!utils.validate('email', value)) {
        emailErr = t('errors.EMAIL_INCORRECT');
      }
      this.setState({
        error: emailErr,
      });
    }
    this.setState({ data: newData, generalError: '' });
  };

  enterToLogin = (e) => {
    if (e.keyCode === 13) {
      this.doLogin();
    }
  };

  doLogin = async () => {
    const { t } = this.props;
    const { data, error }: Readonly<IState> = this.state;
    this.setState({
      generalError: '',
      isLoading: true,
    });
    if (data && data.email && data.password && !error) {
      const isAuth = await ClaimPortalService.login(data.email, data.password);
      if (isAuth) {
        const user = await ClaimPortalService.me();
        this.props.dispatch(setUser(user.fullName));
        this.props.history.push(ROUTES.dashboard);
        window.removeEventListener('keyup', this.enterToLogin);
      }
      this.setState({
        isLoading: false,
        generalError: t('errors.INFO_INCORRECT'),
      });
    } else {
      this.setState({
        generalError: t('errors.BLANK_FIELD'),
      });
    }
  };

  render() {
    const {
      data,
      error,
      generalError,
      isLoading,
    }: Readonly<IState> = this.state;
    return (
      <div className="loginContainer">
        <div className="loginPart">
          <div className="logo" />
          <div className="loginForm">
            <h1>Đăng nhập</h1>
            <TextField
              className="email fieldStyle"
              name="email"
              label="ĐỊA CHỈ EMAIL"
              type="text"
              errorPosition="bottom"
              errorMsg={generalError || error}
              onChange={(name, value) => this.handleChange(name, value)}
              value={data.email}
              placeHolder="Nhập email ?"
            />
            <TextField
              className="password fieldStyle"
              name="password"
              label="MẬT KHẨU"
              type="password"
              errorPosition="bottom"
              onChange={(name, value) => this.handleChange(name, value)}
              value={data.password}
              errorMsg={generalError}
              placeHolder="Nhập password ?"
            />
            <Button
              styles={{ marginTop: '2rem' }}
              primary
              disabled={isLoading}
              onClick={() => this.doLogin()}
            >
              Đăng nhập
            </Button>
          </div>
        </div>
        <div className="testimal">
          <div />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProp)(withTranslation('common')(LoginScreen));
