import i18next from '../translation';

const KEYS = {
  ANNOUNCEMENT_MESSAGE: 'message',
  ANNOUNCEMENT_CONTENT: 'content',
  SHOW_SIGNOFF_INFO: 'showSignOffInfo',
  DAYS_IN_HOSPITAL: '0',
  DAYS_IN_ICU: '1',
  TIMES_FOR_SURGERY: '2',
  TIMES_FOR_MAJOR_SURGERY: '3',
  DAYS_IN_HOSPITAL_NAME: 0,
  DAYS_IN_ICU_NAME: 1,
  TIMES_FOR_SURGERY_NAME: 2,
  TIMES_FOR_MAJOR_SURGERY_NAME: 3,
  SUBMISSION_DOCS: '1',
  SUBMISSION_DOCS_PATCH: 'patchOne',
  PORTAL_DOCS_PATCH: 'patchTwo',
  TRACKING_DOCS_PATCH: 'patchThree',
  PORTAL_DOCS: '2',
  TRACKING_DOCS: '3',
  JET_STATUS: {
    NONE_JET: 0,
    JET: 1,
    CONFIRM_JET: 2,
    ASSESSMENT_JET: 3,
  },
  STP_STATUS: {
    NONE: 0,
    IN_PROGRESS: 1,
    SUCCESS: 2,
    FAIL: 3,
    ADJUSTED: 4,
  },
  FOLLOWUP_CODE: {
    O: 'Chưa nộp',
    W: 'Được miễn trừ',
    R: 'Đã nhận',
  },
  STATUS: {
    HOSPITAL: {
      RIDER: 'Ngày vào viện - ngày ra viện',
      REQUEST: 'Nằm viện thường',
    },
    ICU: {
      RIDER: 'Ngày nằm viện ICU',
      REQUEST: 'ICU (Khoa Hồi sức Tích cực)',
    },
    SURGERY: {
      RIDER: 'Phẫu thuật',
      REQUEST: 'Phẫu thuật',
    },
    MAJOR_SURGERY: {
      RIDER: 'Đại phẫu',
      REQUEST: 'Đại phẫu (Mở hộp sọ/Mở lồng ngực)',
    },
    INPROGRESS: {
      NAME: 'Đang thẩm định',
      VALUE: 1,
    },
    PENDING: {
      NAME: 'Yêu cầu bổ sung thông tin',
      VALUE: 2,
    },
    RECEIVE_UPLOAD: {
      NAME: 'Tiếp nhận hồ sơ bổ sung',
      VALUE: 3,
    },
    APPROVED: {
      NAME: 'Đã có quyết định chấp thuận',
      VALUE: 6,
    },
    DECLINED: {
      NAME: 'Từ chối yêu cầu',
      VALUE: 8,
    },
    PAYMENT: {
      NAME: 'Đã thực hiện thanh toán',
      VALUE: 9,
    },
  },
  RIDER_OPTION: {
    '0': 'Nằm viện thường',
    '1': 'ICU (Khoa Hồi sức Tích cực)',
    '2': 'Phẫu thuật',
    '3': 'Đại phẫu\n(Mở hộp sọ/Mở lồng ngực)',
  },
  TABLE: {
    ID: 'id',
  },
  CASE_ONLY_HOSPITAL: 1,
  CASE_ONLY_ICU: 2,
  CASE_ONLY_SURGERY: 3,
  CASE_HOSPITAL_ICU: 4,
  CASE_ICU_SURGERY: 5,
  CASE_HOSPITAL_SURGERY: 6,
  CASE_ALL: 7,
  DECLINE_BY_MANAGER: 8,
  DECLINE_BY_ASSESSOR: 7,
  DELIMITER: '2b58921e9217e97dd26221ab3484df19',
  PAYMENT_TYPE: {
    TRANSFER_MONEY: {
      KEY: 'transfer',
      NAME: 'Chuyển khoản qua tài khoản ngân hàng',
      VALUE: 'B',
    },
    BANK_MONEY: {
      KEY: 'cash',
      NAME: 'Nhận tiền mặt tại ngân hàng',
      VALUE: 'C',
    },
    FORWARD_MONEY: {
      KEY: 'forward',
      NAME: 'Chuyển đóng phí hợp đồng khác',
      VALUE: 'M',
    },
  },
  STATUS_OPTIONS_CASE: () => ({
    IN_PROGRESS: {
      name: i18next.t('portal.assessment.status.in_process'),
      value: 1,
    },
    PENDING: {
      name: i18next.t('portal.assessment.status.pending'),
      value: 2,
    },
    UPDATED: {
      name: i18next.t('portal.assessment.status.updated'),
      value: 3,
    },
    WAITING: {
      name: i18next.t('portal.assessment.status.waiting'),
      value: 4,
    },
    APPROVED_BY_ASSESSOR: {
      name: i18next.t('portal.assessment.status.approved_by_assessor'),
      value: 5,
    },
    APPROVED_BY_MANAGER: {
      name: i18next.t('portal.assessment.status.approved_by_manager'),
      value: 6,
    },
    DECLINE_BY_ASSESSOR: {
      name: i18next.t('portal.assessment.status.decline_by_assessor'),
      value: 7,
    },
    DECLINE_BY_MANAGER: {
      name: i18next.t('portal.assessment.status.decline_by_manager'),
      value: 8,
    },
    PAYMENT: {
      name: i18next.t('portal.assessment.status.payment'),
      value: 9,
    },
  }),
  STATUS_SELECTION_CASE: () => ({
    IN_PROGRESS: {
      name: i18next.t('portal.assessment.status.in_process'),
      value: 1,
    },
    WAITING: {
      name: i18next.t('portal.assessment.status.waiting'),
      value: 4,
    },
    DECLINE_BY_MANAGER: {
      name: i18next.t('portal.assessment.status.decline_by_manager'),
      value: 8,
    },
    APPROVED_BY_MANAGER: {
      name: i18next.t('portal.assessment.status.approved_by_manager'),
      value: 6,
    },
  }),
  FIELD: {
    HOSPITAL_DISCHARGE_PAPER: 'HospitalDischargePaper',
    OTHERS_PAPER: 'Others',
    INVESTIGATION_PAPER: 'Investigation',
    ASSESSMENT: 'assessment',
    JET_STATUS: 'jetStatus',
    STP: 'isBot',
    PARTIAL_STATUS: 'isPartial',
    CLAIM_CASE: 'cases',
    CLAIM_CASE_HISTORY: 'caseHistory',
    CLAIM_CASE_POLICY: 'policy',
    DOCUMENTS: 'documents',
    DECLINE_REASON: 'declineReason',
    UPDATE_POLICY_STATUS: 'updatePolicyStatus',
    DECLINE_CODE: 'declineCode',
    DECLINE_DESCRIPTION: 'declineDescription',
    RIDERS: 'riders',
    PROVINCE: 'province',
    RIDER_ENTITY: 'riderEntity',
    DETAILS: 'details',
    PAYMENT: 'payment',
    CHILD: 'child',
    DATA: 'data',
    POLICY_OWNER_ID: 'PolicyOwnerNationalId',
    DOB: 'Dob',
    POLICY_NUMBER: 'PolicyNumber',
    CAPTCHA: 'captcha',
    INSURED: 'lifeAssureds',
    CLAIM_ID: 'claimID',
    IS_ACCIDENT: 'isAccident',
    MEDICAL_PROVIDER: 'medicalProvider',
    TREATMENT_METHOD: 'treatmentMethod',
    DISEASES: 'diseases',
    DISEASES_LIST_PORTAL: 'diseasesList',
    POLICY_NUMBER_CAMEL: 'policyNumber',
    OWNER_ACCOUNT: 'ownerAccount',
    BANKS_CODE: 'bankCode',
    BANK_BRANCH: 'bankBranch',
    RECEIVER_NAME: 'receiverName',
    RECEIVER_ID: 'receiverId',
    RECEIVER_ID_DATE: 'receiverIdDate',
    RECEIVER_ID_LOCATION: 'receiverIdLocation',
    OWNER_NAME: 'ownerName',
    FILE: 'file',
    DOC_TYPE: 'docType',
    SIGNS_OFF: 'signOffNotes',
    SIGN_OFF: 'signOffNote',
    PENDING: 'pending',
    PENDING_TEXT: 'pendingText',
    EDIT_PENDING_TEXT: 'editPendingText',
    PENDING_NO_REPLY: {
      key: 'PendingNoReply',
      label: 'Pending no reply',
      value: 1,
    },
    PENDING_LACK_DOCS: {
      key: 'PendingLackOfDocs',
      label: 'Pending lack of docs',
      value: 2,
    },
    PENDING_WRONG_DOCS: {
      key: 'PendingWrongDocs',
      label: 'Pending wrong docs',
      value: 3,
    },
    SUSPENSION_DOCS: {
      key: 'PendingSuspensionDocs',
      label: 'Pending suspension docs',
      value: 4,
    },
    IS_CHALLENGE_ABUSE: 'isChallengeAbuse',
  },
  VALIDATE: {
    EMAIL: 'email',
    PHONE: 'phone',
    NONE_SPECIAL_CHAR: 'noneSpecial',
    NAME: 'name',
    BANK_BRANCH: 'bankBranch',
  },
  LIST: {
    FOLLOWUP_CODE: 'fwd_claim_pending_status',
    DECLINE_CODE: 'fwd_decline_code',
    DECLINE_CODES: 'declineCodes',
    PENDING_CODE: 'fwd_pending_code',
    PROVINCE: 'provinces',
    FACILITY: 'facilities',
    MEDICAL_FACILITY: 'medicalFacilities',
    TREATMENT_METHOD: 'treatmentMethods',
    DISEASES: 'diseases',
    DOCUMENTS: 'docs',
    DISEASES_VALUE: 'diseasesListValue',
    CITIES: 'cities',
    FWD_PENDING_ATTACHMENT: 'fwd_pending_attachment',
  },
  MODAL: {
    IS_JET: 'isJet',
  },
  IMAGES: {
    Others: 0,
    HospitalDischargePaper: 1,
    AdditionPaper: 0,
    InvestigationPaper: 2,
    0: 'Others',
    1: 'HospitalDischargePaper',
  },
  ERROR: {
    NO_POLICY: 'Error_015',
    DUPLICATE_NATIONAL_ID: 'Error_013',
    TOKEN_ERROR: 'Error_008',
    INFO_ERROR: 'Error_011',
    CLAIM_CASE_DIDNT_CHANGE: 'Error_022',
    DUPLICATION_ERROR: 'Error_025',
    EXPIRE_PASSWORD_LETTER: 'Error_032',
    WRONG_PASSWORD_LETTER: 'Error_031',
    DECISION_REQUIRED_FWD: 'Error_044',
    FAIL_RCD: 'Error_046',
  },
  TRACKING_HISTORY_KEY: {
    PHONE: 'phone',
    PASSWORD: 'password',
    CLAIM_CASE_ID: 'claimCaseId',
    ERROR_PASSWORD: 'error',
  },
  API: {
    TIMEOUT: 60000,
  },
  UPLOAD_IMAGE: {
    FILE_SIZE: 100,
    HOSPITAL_MAX: 1,
    OTHER_MAX: 10,
    LIMIT: 5242880,
  },
  IS_ACCIDENT: 'isAccident',
  IS_JET: {
    KEY: 'isJet',
    TRUE: '1',
  },
  IS_ADD: {
    KEY: 'isAdd',
    TRUE: '1',
  },
  PENDING_CLAIM_LIMIT: 8,
  UPDATE_ADJUST: 'adjust',
  UPDATE_DECISION: 'decision',
  UPDATE_DETAIL: 'detail',
  UPDATE_PAPER: 'paper',
  DELETE_CLAIM: 'deleteClaim',
  PORTAL_PASSWORD: {
    OLD_PASSWORD: 'oldPassword',
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
  },
  ASSESSMENT_INPROGRESS: 'inProgress',
  ASSESSMENT_DECISION: 'notDecision',
  ASSESSMENT_INPROGRESS_JET: 'jet',
  ASSESSMENT_INPROGRESS_TAT: 'tat',
  ASSESSMENT_INPROGRESS_STP: 'stp',
  ASSESSMENT_INPROGRESS_NONE_JET: 'noneJet',
  ASSESSMENT_DECISION_NOT_YET: 'notYet',
  ASSESSMENT_DECISION_FAILED: 'failed',
  ASSESSMENT_PENDING: 'pending',
  ASSESSMENT_WAITING_DECISION: 'waitingDecision',
  ASSESSMENT_WAITING_RESULT: 'waitingResult',
  ASSESSMENT_ERROR: 'error',
  ASSESSMENT_DONE: 'done',
  ASSESSMENT_FAIL: 'fail',

  CLAIM_CASE_FIELD: {
    COVERAGE_EFFECTIVE_DATE: 'coverageEffectiveDate',
    COVERAGE_EXPIRY_DATE: 'coverageExpiryDate',
    COVERAGE_LAPSED_DATE: 'coverageLapsedDate',
    COVERAGE_REINSTATED_DATE: 'coverageReinstatedDate',
    COVERAGE_STATUS_CODE: 'coverageStatusCode',
    COMPONENT_CODE: 'componentCode',
    COVERAGE_SUM_ASSURED: 'coverageSumAssured',
    EXCLUSION_INFO: 'exclusionInfo',
    POLICY_NOTES: 'policyNotes',
    WATCHING_LIST_AGENT: 'watchListAgent',
    IS_IN_WAITING_TIME: 'isInWaitingTime',
    IS_LAPSED: 'isLapsed',
    IS_REINSTATED: 'isReinstated',
    POLICY_EFFECTIVE_DATE: 'policyEffectiveDate',
    POLICY_LAPSED_DATE: 'policyLapsedDate',
    POLICY_REINSTATED_DATE: 'policyReinstatedDate',
    POLICY_STATUS_CODE: 'policyStatusCode',
    HOSPITAL_WATCHING_LIST: 'watchListHospital',
    CLIENT_WATCH_LIST: 'watchListLa',
  },

  HISTORY_HEADER: {
    COVERAGE_EFFECTIVE_DATE: 'Ngày hiêu lực HS',
    COVERAGE_EXPIRY_DATE: 'Ngày hết hạn HS',
    COVERAGE_LAPSED_DATE: 'Ngày mất hiệu lực HS',
    COVERAGE_REINSTATED_DATE: 'Ngày khôi phục hiệu lực HS',
    COVERAGE_STATUS_CODE: 'Tình trạng HS',
    COMPONENT_CODE: 'Mã sản phẩm',
    COVERAGE_SUM_ASSURED: 'Số tiền bảo hiểm HS',
    EXCLUSION_INFO: 'Điều khoản loại trừ',
    POLICY_NOTES: 'Ghi chú hợp đồng',
    WATCHING_LIST: 'Đại lý bị theo dõi',
    IS_IN_WAITING_TIME: 'Trong thời gian chờ',
    IS_LAPSED: 'HĐ mất hiệu lực',
    IS_REINSTATED: 'Trong thời gian ân hạn',
    POLICY_EFFECTIVE_DATE: 'Ngày hiệu lực HĐ',
    POLICY_EXPIRED_DATE: 'Ngày đáo hạn HĐ',
    POLICY_LAPSED_DATE: 'Ngày mất hiệu lực HĐ',
    POLICY_REINSTATED_DATE: 'Ngày khôi phục hiệu lực HĐ',
    POLICY_STATUS_CODE: 'Tình trạng HĐ',
    HOSPITAL_WATCHING_LIST: 'Hospital watchlist',
    CLIENT_WATCH_LIST: 'Client watchlist',
  },
  LIFE_ASSURED_HEADER: {
    laName: 'Tên NĐBH',
    laNationalId: 'Số CMND/CCCD',
    laNationalIdIssueDate: 'Ngày cấp CMND/CCCD',
    laNationalIdIssuePlace: 'Nơi cấp CMND/CCCD',
    laDob: 'Ngày sinh',
    laSex: 'Giới tính',
    laMaritalStatus: 'Tình trạng hôn nhân',
    componentCode: 'Mã sản phẩm',
    agentCode: 'Mã TVTC',
    agentName: 'Tên TVTC',
    agentEmail: 'Email',
    agentPhone: 'Phone',
  },
  PRESIGNED_URL: {
    BUCKET_NAME: 'papaya-fwd-prod-stp',
    EXPIRED_MINUTE: 2,
  },
};
export default KEYS;
