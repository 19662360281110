import { FilterAssessmentType } from '../interface';
import utils from '../utils';

const endpoints = {
  LOGIN: '/Auth/login',
  GET_ME: '/Auth/me',
  CHANGE_PASSWORD: '/Auth/changePassword',
  GET_METADATA: (
    type: string,
    search?: string,
    excludedData?: string,
    limit: number = 10
  ) =>
    `/metadata/?type=${type}&limit=${limit}${
      (search && `&search=${search}`) || ''
    }${(excludedData && `&excludedData=${excludedData}`) || ''}`,
  GET_MEDICAL_PROVIDER: (search?: string, excludedData?: string) =>
    `/MedicalProviders/?limit=10${(search && `&search=${search}`) || ''}${
      (excludedData && `&excludedData=${excludedData}`) || ''
    }`,
  GET_CLAIM_LIST: (
    page: number = 1,
    search: string = '',
    filter?: FilterAssessmentType,
    sort?: string,
    isInProgress?: boolean,
    isDeleted?: boolean,
    FilterType?: string,
    isAll?: boolean
  ) =>
    `/Claims?limit=10&currentPage=${page}&search=${search}${
      (filter &&
        filter.ReceivedAt &&
        filter.ReceivedAt.from &&
        `&From=${utils.formatDateToStringDate(filter.ReceivedAt.from)}`) ||
      ''
    }
    ${
      (filter &&
        filter.ReceivedAt &&
        filter.ReceivedAt.to &&
        `&To=${utils.formatDateToStringDate(filter.ReceivedAt.to)}`) ||
      ''
    }
    ${(filter && filter.JetType && `&JetType=${filter.JetType}`) || ''}
    ${(sort && `&OrderBy=${utils.toUCFirst(sort)}`) || ''}
    ${(isInProgress && '&isInProgress=true') || '&isInProgress=false'}
    ${(isDeleted && '&isDeleted=true') || '&isDeleted=false'}
    ${(FilterType && `&FilterType=${FilterType}`) || ''}
    ${(isAll && '&isAll=true') || '&isAll=false'}`,
  PAYMENT: (id: string) => `/Claims/${id}/payment`,
  ORIGINAL_CLAIM: (id: string) => `/Claims/${id}/original`,
  DOCUMENT_CLAIM: (id: string) => `/Claims/${id}/documents`,
  GET_ASSESSMENT_CLAIM: (id: string) => `/Claims/${id}/assessment`,
  UPDATE_ASSESSMENT_JET_STATUS_CLAIM: (id: string) => `/Claims/${id}/jetStatus`,
  GET_ASSESSMENT_CASE: (id: string) => `/Claims/${id}/cases`,
  GET_PRESENT_CASES: (id: string) => `/Claims/${id}/cases/presentCases`,
  GET_ASSESSMENT_SIGNOFF_INFO: (id: string) => `/Claims/${id}/SignoffInfo`,
  SEND_SIGN_OFF: (id: string) => `/Claims/${id}/signOff`,
  UPDATE_ASSESSMENT_CASE: (claimId: string, caseId: string) =>
    `/Claims/${claimId}/cases/${caseId}`,
  SUBMIT_DOC_PORTAL: (id: string) => `/Claims/${id}/documents`,
  ADJUST_ASSESSMENT: (id: string) => `/Claims/${id}/adjustment`,
  DELETE_CLAIM: (id: string) => `/Claims/${id}/delete`,
  PUT_BANK_BRANCH: (id: string) => `/Claims/${id}/payment`,
  UPDATE_DETAIL_ASSESSMENT: (id: string) => `/Claims/${id}/details`,
  DECISION_ASSESSMENT: (id: string) => `/Claims/${id}/decisions`,
  UPDATE_DETAIL_CASE: (claimCaseId: string, polComId: string) =>
    `/ClaimCases/${claimCaseId}/policiesCompensation/${polComId}`,
  GET_CLAIM_CASE: (id: string) => `/ClaimCases/${id}`,
  CHECK_PAYMENT_CLAIM_CASE: (id: string) => `/ClaimCases/${id}/payment`,
  GET_CLAIM_CASE_HISTORY: (id: string) => `/ClaimCases/${id}/histories`,
  GET_CLAIM_CASE_POLICY: (id: string) => `/ClaimCases/${id}/policies`,
  UPDATE_CLAIM_CASE_PARTIALS: (id: string) => `/ClaimCases/${id}/partials`,
  POST_PENDING_LETTER: (id: string) => `/ClaimCases/${id}/letters`,
  GET_CLAIM_PENDING: (id: string) => `/Claims/${id}/pending`,
  UPDATE_CLAIM_PENDING: (id: string) => `/Claims/${id}/pending`,
  CLAIM_LETTERS: (id: string) => `/Claims/${id}/letters`,
  NOTIFY_CLAIM_LETTER: (id: string) => `/ClaimCaseLetters/${id}/notify`,
  GET_CLAIM_LETTER_BY_ID: (id: string) => `/ClaimCaseLetters/${id}`,
  UPLOAD_LETTER: (id: string) => `/claimCaseLetters/${id}`,
  UPLOAD_DOCS_FWD: (id: string) => `/Claims/${id}/uploadDocs`,
  DOC_DETAIL: (id: string, documentId: string) =>
    `/Claims/${id}/documents/${documentId}`,
  GET_COUNT: '/Claims/count',
  GET_TAT_COUNT: '/Claims/tat',
  GET_OCR_INFORMATION: (id: string) => `/Claims/${id}/ocr`,
  CHECK_FRAUD_CLAIM: (claimId: string) => `/Fraud/${claimId}`,
  CHECK_FRAUD_PAPER: (documentId: string) => `/Fraud/documents/${documentId}`,
  UPDATE_ASSESSMENT_CHALLENGE_ABUSE_CLAIM: (id: string) =>
    `/Claims/${id}/challengeAbuse`,
  GET_PRESIGNED_URL: (
    fileName: string,
    bucketName: string,
    expiredMinute: number
  ) =>
    `/Claims/GetPreSignedUrl?FileName=${fileName}&BucketName=${bucketName}&ExpiredMinute=${expiredMinute}`,
  GET_OBJECT_PRESIGNED_URL: (
    fileName: string,
    bucketName: string,
    expiredMinute: number
  ) =>
    `/Claims/GetObjectPreSignedUrl?FileName=${fileName}&BucketName=${bucketName}&ExpiredMinute=${expiredMinute}`,
};
export default endpoints;
