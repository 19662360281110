/**
 ** @class ReviewScreen
 */
import React from 'react';
import './style.css';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import KEYS from '../../../../config/key';
import utils from '../../../../config/utils';
import { ROUTES } from '../../../../config/ClaimWebsite/pathSetting';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  refresh: boolean;
}

interface IState {
  data?: {
    compensation?: {
      laName: string,
      riderName: string,
      medicalFacilityName: string,
      diseasesName: string,
      riderInHospitalDate: IRiderDetail,
      riderInIcuDate: IRiderDetail
    },
    paymentMethod?: {
      bankBranch?: string,
      bankCity?: string,
      bankName?: string,
      ownerAccount?: string,
      ownerName?: string,
      policyNumber?: string,
      receiverId?: string,
      receiverIdDate?: string,
      receiverIdLocation?: string,
      receiverName?: string,
      type: number
    }
  }
}

interface IRiderDetail {
  riderDetailCode: number,
  value: string,
  isSelected:boolean
}

const mapStateToProp = (state) => ({
  data: state.data
});

class ReviewScreen extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    if(!sessionStorage.getItem(KEYS.FIELD.CLAIM_ID)){
      this.props.history.replace(ROUTES.policy);
    }
  }

  componentDidMount() {
    this.getFirstLoad().then();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.refresh !== this.props.refresh){
      if (this.props.data.postBack
        && !Object.keys(this.props.data.postBack.paymentScreen).length) {
        this.props.history.replace(ROUTES.payment);
      } else {
        this.getFirstLoad().then();
      }
    }
  }

  getFirstLoad = async () => {
    const { mainData, postBack } = this.props.data;
    if (postBack
      && !Object.keys(postBack.paymentScreen).length) {
      this.props.history.replace(ROUTES.payment);
    }
    if (mainData && mainData[KEYS.FIELD.CLAIM_ID]) {
      const response =
        await ClaimService.getInitialState(mainData[KEYS.FIELD.CLAIM_ID]);
      this.getReviewInfo(response);
    }
  };

  getReviewInfo = (data) => {
    const procData = data;
    if (procData && !procData.status && procData.riders) {
      const riderName = procData.riders.filter(rider => rider.isSelected === true)
        .map(rider => KEYS.RIDER_OPTION[rider.riderDetailCode]).join(', ');
      const riderInHospitalDate = _.find(procData.riders,
        { riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0) });
      const riderInIcuDate = _.find(procData.riders,
        { riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0) });
      const medicalFacilityName = procData.medicalProvider &&
        procData.medicalProvider.name;
      const diseasesName = procData.diseases && procData.diseases.length &&
        procData.diseases.map(diseases => diseases.name).join(', ');
      procData.payment && delete procData.payment.amount;
      this.setState({
        data: {
          compensation: {
            laName: data.laName,
            riderName,
            medicalFacilityName,
            diseasesName,
            riderInHospitalDate,
            riderInIcuDate
          },
          paymentMethod: procData.payment
        }
      });
    }
  };

  render = () => {
    const { t } = this.props;
    const { data }: Readonly<IState> = this.state;
    return (
      <div className="reviewScreenContainer">
        <div className="reviewScreenHeader">
          {t('review.subHeader.description')}
        </div>
        <div className="reviewScreenContent">
          <div className="infoCompensationReview">
            {(data &&
              <div className="infoCompensationContent">
                <div className="firstRow">
                  <div className="reviewDetail">
                    <div className="reviewDetailHeader">{t('review.insuredInfo')}</div>
                    <div className="reviewDetailContent">
                      {data.compensation
                      && data.compensation.laName}
                    </div>
                  </div>
                  <div className="reviewDetail">
                    <div className="reviewDetailHeader">{t('review.benefitInfo')}</div>
                    <div className="reviewDetailContent">
                      {data.compensation
                      && data.compensation.riderName}
                    </div>
                  </div>
                  <div className="reviewDetail">
                    <div className="reviewDetailHeader">{t('review.medicalFacilityInfo')}</div>
                    <div className="reviewDetailContent">
                      {data.compensation
                      && data.compensation.medicalFacilityName}
                    </div>
                  </div>
                </div>
                <div className="secondRow">
                  <div className="reviewDetail">
                    <div className="reviewDetailHeader">{t('review.diagnoseInfo')}</div>
                    <div className="reviewDetailContent">
                      {data.compensation
                      && data.compensation.diseasesName}
                    </div>
                  </div>
                  {
                    data && data.compensation
                    && data.compensation.riderInHospitalDate &&
                    <div className="reviewDetail">
                      <div className="reviewDetailHeader">{t('review.timeInHospitalInfo')}</div>
                      <div className="reviewDetailContent">
                        {data.compensation
                        && utils.formatStringDateRangeToLocaleString(
                          data.compensation.riderInHospitalDate.value)}
                      </div>
                    </div>
                  }
                  {
                    data && data.compensation
                    && data.compensation.riderInIcuDate &&
                      data.compensation.riderInIcuDate.isSelected &&
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('review.timeInICUInfo')}</div>
                        <div className="reviewDetailContent">
                          {data.compensation
                        && utils.formatStringDateRangeToLocaleString(
                          data.compensation.riderInIcuDate.value)}
                        </div>
                      </div>
                  }
                </div>
              </div>
            ) || ''}
          </div>
          {(data && data.paymentMethod &&
            <div className="infoPaymentReview">
              <div className="infoReviewHeader">
                <div className="infoReviewPaymentMethod">
                  {t('review.paymentMethodInfo')}:
                </div>
                <div className="infoReviewPaymentMethodContent">
                  {
                    (data.paymentMethod.type.toString() ===
                      KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE
                      && KEYS.PAYMENT_TYPE.FORWARD_MONEY.NAME
                    ) ||
                    (data.paymentMethod.type.toString() ===
                      KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE
                      && KEYS.PAYMENT_TYPE.BANK_MONEY.NAME
                    ) ||
                    (data.paymentMethod.type.toString() ===
                      KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE
                      && KEYS.PAYMENT_TYPE.TRANSFER_MONEY.NAME
                    )
                  }
                </div>
              </div>
              <div className="infoPaymentReviewContent">
                {/* Chuyển đóng phí hợp đồng khác */}
                {(data.paymentMethod.type.toString() ===
                KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE &&

                  <div className="reviewDetail">
                    <div className="reviewDetailHeader">{t('policy.label.policyNumber')}</div>
                    <div className="reviewDetailContent">{data.paymentMethod.policyNumber}</div>
                  </div>
                ) || ''}
                <div className="infoCompensationContent">
                  {/* Nhận tiền mặt tại ngân hàng */}
                  {(data.paymentMethod.type.toString() ===
                  KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE &&
                  <>
                    <div className="firstRow">
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.bankName')}</div>
                        <div className="reviewDetailContent">{data.paymentMethod.bankName}</div>
                      </div>
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.bankCity')}</div>
                        <div className="reviewDetailContent" style={{ textTransform: 'uppercase'  }}>{data.paymentMethod.bankCity}</div>
                      </div>
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.bankBranch')}</div>
                        <div className="reviewDetailContent">{data.paymentMethod.bankBranch}</div>
                      </div>
                    </div>

                    <div className="secondRow">
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.receiverName')}</div>
                        <div className="reviewDetailContent">{data.paymentMethod.receiverName}</div>
                      </div>
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.receiverNationalId')}</div>
                        <div className="reviewDetailContent">{data.paymentMethod.receiverId}</div>
                      </div>
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.receiverIDDate')}</div>
                        <div className="reviewDetailContent">{data.paymentMethod.receiverIdDate}</div>
                      </div>
                    </div>
                    <div className="thirdRow">
                      <div className="reviewDetail">
                        <div className="reviewDetailHeader">{t('payment.label.receiverIDLocation')}</div>
                        <div className="reviewDetailContent">{data.paymentMethod.receiverIdLocation}</div>
                      </div>
                    </div>
                  </>
                  ) || ''}
                  {/* Chuyển khoản qua tài khoản ngân hàng */}
                  {(data.paymentMethod.type.toString() ===
                    KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE &&
                    <>
                      <div className="firstRow">
                        <div className="reviewDetail">
                          <div className="reviewDetailHeader">{t('payment.label.bankName')}</div>
                          <div className="reviewDetailContent">{data.paymentMethod.bankName}</div>
                        </div>
                        <div className="reviewDetail">
                          <div className="reviewDetailHeader">{t('payment.label.bankCity')}</div>
                          <div className="reviewDetailContent" style={{ textTransform: 'uppercase'  }}>{data.paymentMethod.bankCity}</div>
                        </div>
                        <div className="reviewDetail">
                          <div className="reviewDetailHeader">{t('payment.label.bankBranch')}</div>
                          <div className="reviewDetailContent">{data.paymentMethod.bankBranch}</div>
                        </div>
                      </div>
                      <div className="secondRow">
                        <div className="reviewDetail">
                          <div className="reviewDetailHeader">{t('payment.label.accountNumber')}</div>
                          <div className="reviewDetailContent">{data.paymentMethod.ownerAccount}</div>
                        </div>
                        <div className="reviewDetail">
                          <div className="reviewDetailHeader">{t('payment.label.ownerName')}</div>
                          <div className="reviewDetailContent">{data.paymentMethod.ownerName}</div>
                        </div>

                      </div>
                    </>)
                    || ''}
                </div>
              </div>
            </div>
          ) || ''}
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProp)(withTranslation('common')(ReviewScreen));
