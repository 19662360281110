import { SET_USER } from '../types';
import { UserAction } from '../interface';

const loadTokenFromLocal = () => {
  return localStorage.getItem('user');
};

const saveTokenFromLocal = (userName: string) => {
  return localStorage.setItem('user', userName);
};

export default function user(state = loadTokenFromLocal(), action: UserAction) {
  let newState = state;
  if (action.type === SET_USER) {
    newState = action.user;
    saveTokenFromLocal(action.user);
  }
  return newState;
}
