/**
 ** @class Footer
 */

import React from 'react';
// @ts-ignore
import './style.css';
import { Button } from 'papaya-ui';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FooterObjectType } from '../../../../config/interface';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  footer: FooterObjectType;
  dispatch: Function;
}

const mapStateToProp = (state) => ({
  footer: state.footer,
});

class Footer extends React.PureComponent<IProps> {
  render = () => {
    const { footer, t } = this.props;
    const { nextState, backState } = footer;
    return (
      <>
        <div className={`footer ${!footer.visible && 'hide'}`}>
          {(backState && backState.visible && (
            <Button
              className="back"
              disabled={backState.disabled}
              onClick={async () => {
                if (!backState.disabled) {
                  if (backState.callback) {
                    this.props.dispatch(setLoading(true));
                    await backState.callback();
                    backState.link && this.props.history.push(backState.link);
                    this.props.dispatch(setLoading(false));
                  } else {
                    backState.link && this.props.history.push(backState.link);
                  }
                }
              }}
            >
              {t('footer.back')}
            </Button>
          )) ||
            ''}

          {(nextState && nextState.visible && (
            <>
              <Button
                className="next"
                primary
                disabled={nextState.disabled}
                onClick={async () => {
                  if (!nextState.disabled) {
                    if (nextState.callback) {
                      this.props.dispatch(setLoading(true));
                      if (await nextState.callback()) {
                        nextState.link &&
                          this.props.history.push(nextState.link);
                      }
                      this.props.dispatch(setLoading(false));
                    } else {
                      nextState.link && this.props.history.push(nextState.link);
                    }
                  }
                }}
                // @ts-ignore
                primaryClassName={
                  (nextState && nextState.isFWD && 'orangeButton') ||
                  'primaryButton'
                }
              >
                {(nextState.text && t(nextState.text)) || t('footer.next')}
              </Button>
              <span className="description">{nextState.description}</span>
            </>
          )) ||
            ''}
        </div>

        <div className="responsiveFooter">
          {(backState && backState.visible && (
            <Button
              className="back"
              disabled={backState.disabled}
              onClick={async () => {
                if (!backState.disabled) {
                  if (backState.callback) {
                    await backState.callback();
                    backState.link && this.props.history.push(backState.link);
                  } else {
                    backState.link && this.props.history.push(backState.link);
                  }
                }
              }}
            >
              {t('footer.back')}
            </Button>
          )) ||
            ''}

          {(nextState && nextState.visible && (
            <>
              <Button
                className="next"
                primary
                disabled={nextState.disabled}
                onClick={async () => {
                  if (!nextState.disabled) {
                    if (nextState.callback) {
                      if (await nextState.callback()) {
                        nextState.link &&
                          this.props.history.push(nextState.link);
                      }
                    } else {
                      nextState.link && this.props.history.push(nextState.link);
                    }
                  }
                }}
                // @ts-ignore
                primaryClassName={
                  (nextState && nextState.isFWD && 'orangeButton') ||
                  'primaryButton'
                }
              >
                {(nextState.text && t(nextState.text)) || t('footer.next')}
              </Button>
              <span className="description">{nextState.description}</span>
            </>
          )) ||
            ''}
        </div>
      </>
    );
  };
}

export default connect(mapStateToProp)(withTranslation('common')(Footer));
