import React from 'react';
import { AnimatedSwitch } from 'react-router-transition';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal } from 'papaya-ui';
import caolan from 'async';
import { routesPortal } from '../config/ClaimPortal/routes';
import { Header, Sidebar } from './components/ClaimPortal';
import tabbar, { TabbarType } from '../config/ClaimPortal/tabbar';
import { prefix, ROUTES } from '../config/ClaimPortal/pathSetting';
import LoginScreen from './screens/ClaimPortal/LoginScreen';
import loadingImg from '../assets/images/loading.svg';
import ClaimPortalService from '../config/ClaimPortal/modelService';
import { ToastType } from '../config/interface';
import { UnSavedChangeModalType } from '../store/ClaimPortal/interface';
import { setUnSavedChange, setUnSavedChangeModal, setNotification } from '../store/ClaimPortal/commonActions';
import setToast from '../store/ShareStore/actions/toast';

interface IProps extends RouteComponentProps<string> {
  loading: boolean;
  dispatch: Function;
  isShowSidebar: boolean;
  toast: ToastType;
  user: string;
  unSavedChangeModal: UnSavedChangeModalType
}

interface IState {
  tabBar?: Array<{ name: string; path: string; notiKey: string }>;
  path: string;
  show: boolean;
}

const mapStateToProp = (state) => ({
  loading: state.loading,
  auth: state.auth,
  user: state.user,
  isShowSidebar: state.sidebar,
  toast: state.toast,
  unSavedChangeModal: state.unSavedChangeModal,
  notification: state.notification
});

class App extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      path: props.history.location.pathname,
      show: false,
    };
    this.checkValid();
  }
  
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.location.pathname !== prevState.path) {
      return { path: nextProps.history.location.pathname };
    }
    return null;
  }
  
  componentDidMount() {
    this.setState(this.initialState());
    this.syncNotification();
    setInterval(()=>{
      this.syncNotification();
    }, 600000); // 10min
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.path !== this.state.path) {
      this.checkValid();
      this.syncNotification();
      this.setState(this.initialState());
    }
  }
  
  syncNotification = () => {
    if(this.props.user){
      caolan.auto({
        getCountNotification: (callback)=>{
          ClaimPortalService.getCountNotification().then((data)=>{
            if(data){
              callback(null, data);
            } else {
              callback(null, null);
            }
          });
        },
        getTatNotification: (callback)=>{
          ClaimPortalService.getCountTAT().then((data)=>{
            if(data){
              callback(null, data.tat);
            } else {
              callback(null, null);
            }
          });
        }
      }, (err, data)=>{
        if(data.getCountNotification){
          const notiData = data.getCountNotification;
          if(data.getTatNotification){
            notiData.tat = data.getTatNotification;
          }
          this.props.dispatch(setNotification(notiData));
        }
      });
    }
  };
  
  
  checkValid = async () => {
    const response = await ClaimPortalService.me();
    if (!response) {
      this.setState({
        show: true,
      }, () => {
        this.props.history.replace(ROUTES.login);
      });
      
    } else {
      if (
        this.props.history.location.pathname.split('/').join('') === 'portal'
      ) {
        this.props.history.replace(ROUTES.dashboard);
      }
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 1000);
    }
  };
  
  backToHomePage = () => {
    this.props.history.replace(ROUTES.dashboard);
    return '';
  };
  
  initialState = () => {
    let tabBar;
    tabbar.forEach((tab: TabbarType) => {
      if (this.props.history.location.pathname.includes(tab.path)) {
        tabBar = tab.item;
      }
    });
    return {
      tabBar,
    };
  };
  
  ignoreEditableAction = () => {
    const { unSavedChangeModal } = this.props;
    if (unSavedChangeModal.callback) {
      unSavedChangeModal.callback();
      this.props.dispatch(setUnSavedChangeModal(
        {
          isShow: false,
          callback: () => {
          }
        }
      ));
      this.props.dispatch(setUnSavedChange(false));
    }
  };
  
  render() {
    const { loading, isShowSidebar, toast, unSavedChangeModal } = this.props;
    const { tabBar, path, show }: Readonly<IState> = this.state;
    return (
      <>
        {((loading || !show) && (
          <div
            className="globalLoading"
            style={{ backgroundImage: `url(${loadingImg})` }}
          />
        )) ||
        ''}
        
        {/* toast */}
        <div className={`globalToast ${(toast.isShow && 'show') || 'hide'} ${toast.type || ''}`}>
          <div className="line" />
          <div className="toastPortal">
            <div>
              {toast.text}
            </div>
            {(toast.preventClose &&
              <div
                tabIndex={0}
                className="closeToast"
                role="button"
                onClick={() => {
                  this.props.dispatch(setToast({
                    isShow: false,
                    text: ''
                  }));
                }}
              ><span className="closeToastText">✕</span>
              </div>) || ''}
          </div>
        
        </div>
        <div className="editableModalPortal">
          <Modal
            name="globalModal"
            className="globalModal"
            value={unSavedChangeModal.isShow}
            closeButton={true}
            onClose={() => {
              this.props.dispatch(setUnSavedChangeModal({ isShow: false }));
            }}
          >
            <div className="editableModal">
              <div className="editableHeader">
                Thay đổi thông tin chi tiết
              </div>
              <div className="editableDescription">
                Bạn có thay đổi chưa được lưu.
                <p>Rời trang sẽ hủy bỏ các thay đổi hiện tại.</p>
              </div>
              <div className="buttonEditableModal">
                <Button
                  className="buttonEditableCancel"
                  onClick={() => {
                    this.props.dispatch(setUnSavedChangeModal({ isShow: false }));
                  }}
                >
                  Hủy
                </Button>
                <Button
                  primary
                  className="buttonEditableConfirm"
                  onClick={() => this.ignoreEditableAction()}
                >
                  Xác nhận
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        
        {(path.includes('auth') && (
          <div className="claimPortalApp">
            <Route
              path={ROUTES.login}
              render={(props) => <LoginScreen {...props} />}
            />
          </div>
        )) ||
        (show && (
          <div className="claimPortalApp">
            <Route path={prefix} render={(props) => <Sidebar {...props} />} />
            <div className={['besideScreen', (!isShowSidebar && 'hideSidebar') || ''].join(' ')}>
              <AnimatedSwitch
                atLeave={
                  {
                    // blank
                  }
                }
                atEnter={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
              >
                {routesPortal.map((route) => (
                  <Route
                    key={route.name}
                    path={route.path}
                    render={(props) => (
                      <>
                        <Header
                          className={`${(!isShowSidebar && 'hideSidebar') || ''}`}
                          breadcrumb={route.name}
                          title={route.title}
                          tabBar={tabBar}
                          {...props}
                        />
                        <div
                          className={`
                            screenContainer
                            ${(tabBar && tabBar.length && 'hasTabBar') || ''}
                            ${(!isShowSidebar && 'hideSidebar')
                          }`}
                        >
                          {(route.component && (
                            <route.component {...props} />
                          )) ||
                          this.backToHomePage()}
                        </div>
                      </>
                    )}
                  />
                ))}
                <Redirect to={ROUTES.notfound} />
              </AnimatedSwitch>
            </div>
          </div>
        )) ||
        ''}
      </>
    );
  }
}

export default connect(mapStateToProp)(App);
