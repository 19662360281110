import { ROUTES } from '../../../../config/ClaimPortal/pathSetting';
import ChangePasswordScreen from './screens/ChangePasswordScreen';

const SettingRoutes = [
  {
    key: 'changePassword',
    name: 'Đổi mật khẩu',
    title: 'Đổi mật khẩu',
    path: ROUTES.changePassword,
    component: ChangePasswordScreen,
  },
];

export default SettingRoutes;
