import { CLEAR_DATA, DATA } from '../types';
import { SetDataAction } from '../../../config/interface';
import KEYS from '../../../config/key';

const syncToSession = (dataObject) => {
  sessionStorage.setItem(KEYS.FIELD.CLAIM_ID, dataObject);
};

const getFromSession = () => sessionStorage.getItem(KEYS.FIELD.CLAIM_ID);

const clearSession = () => {
  sessionStorage.clear();
};

export default function data(state = {
  mainData: {
    claimID: getFromSession()
  }
} || {}
, action: SetDataAction) {
  let newState = Object.assign(state);
  if (action.type === DATA) {
    newState = action.data;
    // sync to session
    if(action.data && action.data.mainData && action.data.mainData.claimID){
      syncToSession(action.data.mainData.claimID);
    }
  }
  if (action.type === CLEAR_DATA) {
    clearSession();
    newState = {};
  }
  
  return newState;
}
