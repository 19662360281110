import { NOTIFICATION } from '../types';
import { NotificationAction, NotificationData } from '../interface';

export default function setNotification(
  notification: NotificationData): NotificationAction{
  return {
    type: NOTIFICATION,
    notification,
  };
}
