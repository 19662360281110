/**
 ** @class EndScreen
 */

import React from 'react';
import './style.css';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import KEYS from '../../../../config/key';
import arrow from '../../../../assets/images/arrow.png';

interface IProps extends RouteComponentProps<string>, WithTranslation {}

interface IState {
  isJet: boolean
  isAdd: boolean
  isAnnouncement: string
}

class EndScreen extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const isJet = localStorage.getItem(KEYS.IS_JET.KEY);
    const isAdd = localStorage.getItem(KEYS.IS_ADD.KEY);
    const isAnnouncement =  localStorage.getItem(KEYS.ANNOUNCEMENT_CONTENT) || '';
    this.state = {
      isAnnouncement,
      isJet: isJet === KEYS.IS_JET.TRUE,
      isAdd: isAdd === KEYS.IS_ADD.TRUE
    };
  }

  goToHome = () => {
    this.props.history.push('/');
  };

  render = () => {
    const { t } = this.props;
    const { isJet, isAdd, isAnnouncement } = this.state;
    return (
      <div className="endScreenContainer">
        <div className="endScreen">
          <div className="endScreenImage" />
          <div className="endScreenHeading">
            <p style={(isAdd && { display: 'none' }) || {}} className="endScreenTitle">{t('end.title')}</p>
            <div className="endScreenContent">
              {(isAnnouncement && <p className="isAnnouncement" dangerouslySetInnerHTML={{ __html:isAnnouncement }} />) ||
              (<p className={(isAdd && 'trackingUpload') || ''}>{((isJet && t('end.jet')) || (isAdd && t('end.add'))) || t('end.normal')}</p>)}
              
            </div>
            <div className="workingHour">
              <div className="workingHourClock" />
              <div className="workingHourContent">
                <p className="workingHourContentStyle">
                  {t('end.working_hour_1')}
                </p>
                <p className="workingHourContentStyle">
                  {t('end.working_hour_2')}
                </p>
              </div>
            </div>
          </div>
          <div
            role="button"
            tabIndex={0}
            className="endScreenAction"
            onClick={() => this.goToHome()}
          >
            <div>
              <img src={arrow} alt="arrow" />
            </div>
            <div>
              <p>{t('end.backToHome')}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default (withTranslation('common')(EndScreen));
