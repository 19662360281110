import React from 'react';
import './styles.css';
import { Carousel } from 'papaya-ui';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'underscore';
import { connect } from 'react-redux';
import caolan from 'async';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import utils from '../../../../config/utils';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';
import KEYS from '../../../../config/key';

interface IProps extends RouteComponentProps<RouteData> {
  dispatch: Function;
}

interface RouteData {
  id?: string;
}

interface IState {
  data: InputData;
  ocrData: ocrData;
  documents: DocumentsData;
  toggleHolder: boolean;
  toggleClaim: boolean;
  toggleMoney: boolean;
}

interface DocumentsData {
  patchOne: Array<DocumentData>,
  patchTwo: Array<DocumentData>,
  patchThree: Array<DocumentData>
}

interface DocumentData {
  docType: number;
  id: string;
  mediaType: number;
  value: string;
  createdAt: string;
}

interface InputData {
  poName?: string;
  poEmail?: string;
  poPhone?: string;
  poNationalId?: string;
  laName?: string;
  diseases?: Array<{ name: string, value: string }>;
  estimatedAmount?: string;
  details?: Array<inputDetail>;
  claimNumber?: string;
  isAccident?: boolean;
  medicalProvider?: string;
  province?: string
}

interface inputDetail {
  riderDetailCode?: number;
  inputValue?: string;
  value?: number;
}

interface ocrData {
  admitDate?: string
  claimNumber?: string
  dischargeDate?: string
  diseases?: string
  hospital?: string
  laName?: string
  meanConfidence?: string
  province?: string
  isPassed?: boolean
}


class OriginalAssessmentScreen extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      toggleMoney: true,
      toggleClaim: true,
      toggleHolder: true,
      data: {},
      ocrData: {},
      documents: { patchOne: [], patchTwo: [], patchThree: [] },
    };
  }

  componentDidMount() {
    this.getClaimData().then();
  }

  getClaimData = async () => {
    const { id } = this.props.match.params;
    const { documents } = this.state;
    if (id) {
      this.props.dispatch(setLoading(true));
      const response = await ClaimPortalService.getOriginalClaim(id);
      const newDocuments = await ClaimPortalService.getDocumentsClaim(id);
      this.setState({
        data: response,
        documents: newDocuments || documents,
      }, () => {
        this.getDocumentsDetail();
        if (this.state.data.claimNumber) {
          this.getOCRData(this.state.data.claimNumber);
        }
      });
    }
  };

  getOCRData = async (claimNumber: string) => {
    const response = await ClaimPortalService.getOCR(claimNumber);
    this.setState({
      ocrData: response
    });
  };

  getDocumentsDetail = () => {
    const { id } = this.props.match.params;
    const { documents }: Readonly<IState> = this.state;

    const patchOneHospital =
      documents[KEYS.SUBMISSION_DOCS_PATCH].map((val) => {
        if (val.name === KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER) {
          return val.id;
        }
        return undefined;

      }).filter(function (element) {
        return element !== undefined;
      });

    const patchOneOther = documents[KEYS.SUBMISSION_DOCS_PATCH].map((val) => {
      if (val.name === KEYS.FIELD.OTHERS_PAPER) {
        return val.id;
      }
      return undefined;

    }).filter(function (element) {
      return element !== undefined;
    });

    if (id) {
      caolan.auto(
        {
          patchOneHospital: (callback) => {
            caolan.map(patchOneHospital, (idDoc, callbackMap) => {
              ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                callbackMap(null, data);
              });
            }, (err, results) => {
              callback(err, results);
            });
          },

          patchOneOther: (callback) => {
            caolan.map(patchOneOther, (idDoc, callbackMap) => {
              ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                callbackMap(null, data);
              });
            }, (err, results) => {
              callback(err, results);
            });
          },

        }, (err, data) => {
          const newDocuments = _.clone(documents);
          const patchOneOtherFinal =
            this.mapDocumentAfterCallApi(
              data.patchOneOther, KEYS.FIELD.OTHERS_PAPER);
          const patchOneHospitalFinal =
            this.mapDocumentAfterCallApi(
              data.patchOneHospital, KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER);
          newDocuments[KEYS.SUBMISSION_DOCS_PATCH] =
            patchOneHospitalFinal.concat(patchOneOtherFinal);
          this.setState({
            documents: newDocuments
          });
        }
      );
    }
  };

  mapDocumentAfterCallApi = (data: Array<string>, type: string) => {
    const newData: Array<{ name?: string, value?: string }> = [];
    data.map((val) => {
      const newArray: { name?: string, value?: string } = {};
      newArray.name = type;
      newArray.value = val;
      return newData.push(newArray);
    });
    return newData;
  };

  toggleClaim = () => {
    const { toggleClaim } = this.state;
    this.setState({
      toggleClaim: !toggleClaim,
    });
  };

  toggleHolder = () => {
    const { toggleHolder } = this.state;
    this.setState({
      toggleHolder: !toggleHolder,
    });
  };

  toggleMoney = () => {
    const { toggleMoney } = this.state;
    this.setState({
      toggleMoney: !toggleMoney,
    });
  };

  getValueDocuments = (documents: Array<{ name: string, value: DocumentData }>, label: string) => {
    const newDataDocument: DocumentData[] = [];
    if (documents) {
      documents.forEach((doc) => {
        if (doc.name && typeof doc.value === 'object') {
          if (doc.name === label) {
            newDataDocument.push(doc.value);
          }
        }
      });
    }
    return newDataDocument;
  };

  renderDiseases = (value: Array<{ name: string, value: string }>) => {
    let renderDiseases = '';
    renderDiseases = value.map((val) => {
      return `${val.value}: ${val.name}`;
    }).join('$SPLIT$ ');
    return utils.toTxtComp(renderDiseases, '$SPLIT$');
  };

  render() {
    const {
      toggleHolder,
      toggleClaim,
      toggleMoney,
      data,
      documents,
      ocrData
    } = this.state;
    return (
      <div className="originalClaimScreenContainer">
        <div className="holderOriginalScreen">
          <div
            className="holderLabel"
            role="button"
            tabIndex={0}
            onClick={() => {
              this.toggleHolder();
            }}
          >
            <p>Chủ hợp đồng</p>
            <div className={(toggleHolder && 'openExpand') || 'closeExpand'} />
          </div>

          {(toggleHolder && (
            <div className="holderContent">
              <div className="attributeHolder">
                <p className="attributeLabel">HỌ TÊN</p>
                {(data && data.poName && (
                  <p className="attributeContent">{data.poName}</p>
                )) ||
                ''}
              </div>
              <div className="attributeHolder">
                <p className="attributeLabel">EMAIL</p>
                {(data && data.poEmail && (
                  <p className="attributeContent">{data.poEmail}</p>
                )) ||
                ''}
              </div>
              <div className="attributeHolder">
                <p className="attributeLabel">PHONE NUMBER</p>
                {(data && data.poPhone && (
                  <p className="attributeContent">{data.poPhone}</p>
                )) ||
                ''}
              </div>
              <div className="attributeHolder">
                <p className="attributeLabel">CMND/CCCD/HC</p>
                {(data && data.poNationalId && (
                  <p className="attributeContent">{data.poNationalId}</p>
                )) ||
                ''}
              </div>
            </div>
          )) ||
          ''}
        </div>

        <div className="moneyOriginalScreen">
          <div
            className="moneyLabel"
            role="button"
            tabIndex={0}
            onClick={() => {
              this.toggleMoney();
            }}
          >
            <p>Quyền lợi bảo hiểm yêu cầu</p>
            <div className={(toggleMoney && 'openExpand') || 'closeExpand'} />
          </div>

          {(toggleMoney && (
            <div className="moneyContent">
              {data &&
              data.details &&
              data.details.map((detail) => {
                return (
                  <div
                    className="attributeMoney"
                    key={detail.riderDetailCode}
                  >
                    <p className="attributeLabel">
                      {(detail.riderDetailCode
                        === parseInt(KEYS.DAYS_IN_HOSPITAL, 5) &&
                        'Nằm viện thường') ||
                      (detail.riderDetailCode === parseInt(KEYS.DAYS_IN_ICU, 5) && 'Nằm viện ICU') ||
                      (detail.riderDetailCode
                        === parseInt(KEYS.TIMES_FOR_SURGERY, 5) &&
                        'Phẫu thuật') ||
                      (detail.riderDetailCode ===
                        parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 5) &&
                        'Đại phẫu')}
                    </p>
                    <p className="attributeContent">
                      {detail.value}{' '}
                      {((detail.riderDetailCode !== parseInt(KEYS.TIMES_FOR_SURGERY, 5) && (detail.riderDetailCode !== parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 5))) && 'ngày') ||
                      'lần'}
                    </p>
                  </div>
                );
              })}
            </div>
          )) ||
          ''}
        </div>

        <div className="claimRequestOriginalScreen">
          <div
            className="claimLabel"
            role="button"
            tabIndex={0}
            onClick={() => {
              this.toggleClaim();
            }}
          >
            <p>Yêu cầu bảo hiểm</p>
            <div className={(toggleClaim && 'openExpand') || 'closeExpand'} />
          </div>

          {(toggleClaim && (
            <div className="claimContent">
              <div className="claimDetailIllness">
                <div className="identityClaim claimNumber">
                  <p className="attributeLabel">Claim Number</p>
                  {(data && data.laName && (
                    <p className="attributeContent">{data.claimNumber}</p>
                  )) ||
                  ''}
                </div>
                <div className="illnessClaim meanConfidence">
                  <p className="attributeLabel">Mean Confidence</p>
                  {(ocrData && ocrData.meanConfidence && (
                    <div
                      className="attributeContent"
                    >{ocrData.meanConfidence}
                    </div>
                  )) ||
                  ''}
                </div>
                <div className="illnessClaim OCRResult">
                  <p className="attributeLabel">OCR Decision</p>
                  {(ocrData && (
                    <div
                      className="attributeContent OCRResult"
                      style={(ocrData.isPassed && { color: '#00a198' }) || { color: '#ED1B55' }}
                    >{(ocrData.isPassed && 'Passed  ✅') || 'Failed  ❌'}
                    </div>
                  )) ||
                  ''}
                </div>
              </div>

              <div className="claimDetailIllness">
                <div className="identityClaim">
                  <p className="attributeLabel">HỌ TÊN</p>
                  {(data && data.laName && (
                    <p className="attributeContent">{data.laName}</p>
                  )) ||
                  ''}

                  <p className="attributeLabel OCR">HỌ TÊN <span>(OCR)</span></p>
                  {(ocrData && ocrData.laName && (
                    <p className="attributeContent OCR laName">{ocrData.laName}</p>
                  )) ||
                  ''}
                </div>
                <div className="illnessClaim">
                  <p className="attributeLabel">Chẩn đoán bệnh</p>
                  {(data && data.diseases && (
                    <div
                      className="attributeContent"
                    >{this.renderDiseases(data.diseases)}
                    </div>
                  )) ||
                  ''}

                  <p className="attributeLabel OCR">Chẩn đoán bệnh <span>(OCR)</span></p>
                  {(ocrData && ocrData.diseases && (
                    <div
                      className="attributeContent OCR"
                    >{ocrData.diseases}
                    </div>
                  )) ||
                  ''}
                </div>
              </div>

              <div className="claimDetailIllness">
                <div className="identityClaim province">
                  <p className="attributeLabel">Tỉnh</p>
                  {(data && data.province && (
                    <p className="attributeContent">{data.province}</p>
                  )) ||
                  ''}

                  <p className="attributeLabel OCR">Tỉnh <span>(OCR)</span></p>
                  {(ocrData && ocrData.province && (
                    <p className="attributeContent">{ocrData.province}</p>
                  )) ||
                  ''}
                </div>
                <div className="illnessClaim hospital">
                  <p className="attributeLabel">Cơ sở khám</p>
                  {(data && data.medicalProvider && (
                    <p className="attributeContent">{data.medicalProvider}</p>
                  )) ||
                  ''}

                  <p className="attributeLabel OCR">Cơ sở khám <span>(OCR)</span></p>
                  {(ocrData && ocrData.hospital && (
                    <div
                      className="attributeContent"
                    >{ocrData.hospital}
                    </div>
                  )) ||
                  ''}
                </div>
              </div>

              <div className="productClaim">
                <p className="attributeLabel">SẢN PHẨM YÊU CẦU BẢO HIỂM</p>
                {data &&
                data.details &&
                data.details.map((detail) => {
                  return (
                    <p className="attributeContent" key={detail.riderDetailCode}>
                      <span className="spanContent">
                        {(detail.riderDetailCode
                          === parseInt(KEYS.DAYS_IN_HOSPITAL, 5) &&
                          'Tổng thời gian nằm viện') ||
                        (detail.riderDetailCode
                          === parseInt(KEYS.DAYS_IN_ICU, 5) && 'Nằm viện ICU') ||
                        (detail.riderDetailCode
                          === parseInt(KEYS.TIMES_FOR_SURGERY, 5) &&
                          'Phẫu thuật') ||
                        (detail.riderDetailCode
                          === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 5) &&
                          'Đại phẫu')}
                      </span>
                      {detail.inputValue}
                    </p>
                  );
                })}
              </div>

              <div className="productClaim">
                <p className="attributeLabel">SẢN PHẨM YÊU CẦU BẢO HIỂM</p>
                <p className="attributeContent">
                  <span className="spanContent OCR">Ngày vào viện <span>(OCR)</span></span>
                  {(ocrData && ocrData.admitDate) || ''}
                </p>
                <p className="attributeContent">
                  <span className="spanContent OCR">Ngày ra viện <span>(OCR)</span></span>
                  {(ocrData && ocrData.dischargeDate) || ''}
                </p>
              </div>

              <div className="paperClaim">
                <p className="attributeLabel">HÌNH ẢNH CUNG CẤP</p>
                <div className="carousel">
                  <div>
                    <Carousel
                      name="hospital"
                      label="Giấy ra viện"
                      options={this.getValueDocuments(
                        documents[KEYS.SUBMISSION_DOCS_PATCH],
                        KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER
                      )}
                      onVerify={(paperData) => {
                      }}
                    />
                  </div>

                  {(this.getValueDocuments(
                    documents[KEYS.SUBMISSION_DOCS_PATCH],
                    KEYS.FIELD.OTHERS_PAPER).length &&
                      <div className="carouselOther">
                        <Carousel
                          name="other"
                          label="Giấy tờ khác"
                          options={
                          this.getValueDocuments(
                            documents[KEYS.SUBMISSION_DOCS_PATCH],
                            KEYS.FIELD.OTHERS_PAPER
                          )
                        }
                          onVerify={(paperData) => {
                          }}
                        />
                      </div>
                  ) || ''}
                </div>
              </div>
            </div>
          )) ||
          ''}
        </div>
      </div>
    );
  }
}

export default connect()(OriginalAssessmentScreen);
