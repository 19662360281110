import React from 'react';
import './styles.css';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, PDFReader } from 'papaya-ui';
import { connect } from 'react-redux';
import _ from 'lodash';
import caolan from 'async';
import loadingImg from '../../../../assets/images/loading.svg';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import utils from '../../../../config/utils';
import setToast from '../../../../store/ShareStore/actions/toast';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';
import KEYS from '../../../../config/key';

interface IProps extends RouteComponentProps<Routedata> {
  dispatch: Function
}

interface Routedata {
  id?: string;
}

interface IState {
  currentLetter: string;
  previewLetter: string;
  letterList: {
    id: string,
    name: string,
    claimReference: string,
    isAttachable: boolean,
    isNotify: boolean,
    isUploading?: boolean,
    msgContent?: string,
    isSent: boolean
  }[];
  docTotalPage?: number;
  docCurrentPage: number;
  notifyLetters: string[];
  loading: boolean,
  modalConfirmShow: boolean,
  fileList?: File[] | null,
  fwdAttachmentList: { name: string, value: string }[],
  fwdAttachmentChosen: string[]
}

const MaxSize = 10;

class LetterListScreen extends React.Component<IProps, IState> {
  docName = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropZone: any;

  constructor(props) {
    super(props);
    this.state = {
      previewLetter: '',
      currentLetter: '',
      letterList: [],
      notifyLetters: [],
      docCurrentPage: 1,
      loading: false,
      modalConfirmShow: false,
      fwdAttachmentList: [],
      fwdAttachmentChosen: []
    };
    this.getFirstLoad().then();
    this.getFwdPendingAttachment().then();
  }

  getFirstLoad = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const response = await ClaimPortalService.getClaimLetters(id);
      if (response && response.length) {
        await this.getLetterById(response[0].id, false);
        this.docName = response[0].name;
        this.setState({
          currentLetter: response && response.length && response[0].id,
          letterList: response
        });
      }
      this.props.dispatch(setLoading(false));
    }
    this.setState({
      notifyLetters: [],
      modalConfirmShow: false
    });
  };

  getFwdPendingAttachment = async () => {
    const response = await ClaimPortalService.getMetadata(KEYS.LIST.FWD_PENDING_ATTACHMENT);
    this.setState({
      fwdAttachmentList: response
    });
  };

  initDropZone = () => {
    this.dropZone = document.getElementById('dropZone');
    if (this.dropZone) {
      this.dropZone.addEventListener('dragover', (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer) {
          e.dataTransfer.dropEffect = 'copy';
        }
        this.dropZone.style.backgroundColor = '#d7d7d7';
      });

      this.dropZone.addEventListener('dragleave', (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.dropZone.style.backgroundColor = 'rgb(251 251 251)';
      });

      this.dropZone.addEventListener('drop', (e) => {
        this.dropZone.style.backgroundColor = 'rgb(251 251 251)';
        e.stopPropagation();
        e.preventDefault();
        const fileList = e.dataTransfer?.files || undefined; // Array of all files
        this.checkAndSaveFileList(fileList);

      });
    }
  };

  checkAndSaveFileList = (fileList: FileList | null) => {
    if (fileList) {
      let checkFileType: boolean = true;
      let checkFileSize: boolean = true;
      // eslint-disable-next-line
      for (let i = 0, file; file = fileList[i]; i++) {
        if (!file.type.match('application/pdf')) {
          checkFileType = false;
          break;
        }
        if (file.size / (1024 * 1024) > MaxSize) {
          checkFileSize = false;
          break;
        }
      }

      if (checkFileType && checkFileSize) {
        this.setState({ fileList: Array.from(fileList) });
      } else {
        if (!checkFileSize) {
          this.props.dispatch(setToast({
            isShow: true,
            text: `Kích thước tập tin vượt quá ${MaxSize}MB`,
            type: 'warning'
          }));
        }
        if (!checkFileType) {
          this.props.dispatch(setToast({
            isShow: true,
            text: 'Không đúng định dạng file',
            type: 'warning'
          }));
        }
      }
    }
  };

  getLetterById = async (id, isReturn: boolean = false) => {
    const response = await ClaimPortalService.getClaimLetterById(id);
    if (response) {
      if (!isReturn) {
        this.setState({
          previewLetter: '',
          loading: true
        });
        setTimeout(() => {
          this.setState({
            previewLetter: response.content,
            loading: false
          });
        }, 5);

      } else {
        return response;
      }
    }
    return undefined;
  };

  loadPage = (page: number) => {
    const { docTotalPage }: Readonly<IState> = this.state;
    if (docTotalPage && page > 0 && page <= docTotalPage) {
      this.setState({
        docCurrentPage: page
      });
    }
  };

  downloadPDF = (pdf: string) => {
    const linkSource = pdf;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = this.docName || 'download.pdf';
    downloadLink.click();
  };

  fileSelectedHandler = (event: React.ChangeEventHandler<HTMLInputElement>) => {
    // @ts-ignore
    const target = event.target as HTMLInputElement;
    this.docName = (target && target.files && target.files[0].name) || '';
    const file = (target &&
      target.files &&
      target.files.length &&
      target.files[0]) || undefined;
    if (file && file.type.match('application/pdf')) {
      if (file.size / (1024 * 1024) > MaxSize) {
        this.props.dispatch(setToast({
          isShow: true,
          text: `Kích thước tập tin vượt quá ${MaxSize}MB`,
          type: 'warning'
        }));
        target.value = '';
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.uploadFile(reader.result, file.name).then();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  uploadFile = async (file: string | ArrayBuffer | null, fileName: string) => {
    if (file) {
      const response =
        await ClaimPortalService.uploadLetter(
          this.state.currentLetter,
          utils.base64ToFile(file.toString(), fileName));
      if (response && response.content) {
        this.props.dispatch(setToast({
          isShow: true,
          text: 'Cập nhật letter thành công',
          type: 'success'
        }));
        this.setState({
          previewLetter: '',
        });

        setTimeout(() => {
          this.setState({
            previewLetter: response.content
          });
        }, 5);
      } else {
        this.props.dispatch(setToast({
          isShow: true,
          text: 'Cập nhật thất bại',
          type: 'danger'
        }));
      }

    }
  };

  uploadDocs = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const response = await ClaimPortalService.uploadDocsFwd(id);
      if (response && !response.status) {
        this.props.dispatch(setToast({
          isShow: true,
          text: 'Gửi thành công',
          type: 'success'
        }));
        this.setState({
          letterList: response,
        });
      } else {
        this.props.dispatch(setToast({
          isShow: true,
          text: 'Something went wrong!',
          type: 'warning'
        }));
      }

    }
  };

  doNotify = async (
    letterId: string,
    index: number,
    length: number,
    files?: FormData,) => {
    const { fwdAttachmentChosen }: Readonly<IState> = this.state;
    const response =
      await ClaimPortalService.notifyHolderLetters(letterId, files, fwdAttachmentChosen);
    if (index === length) {
      this.props.dispatch(setToast({
        isShow: true,
        text: 'Gửi thành công',
        type: 'success'
      }));
      this.setState({ fwdAttachmentChosen: [] });
      this.getFirstLoad();
    }
    if (response.success) {
      return true;
    }
    if (response.errors) {
      this.props.dispatch(setToast({
        isShow: true,
        text: `Gửi thất bại ${response.errors}`,
        type: 'danger'
      }));
      return false;
    }

    return false;
  };

  checkFullyAttachable = () => {
    const { notifyLetters, letterList }: Readonly<IState> = this.state;
    let fullyAttachable: boolean = true;
    for (let i = 0; i < notifyLetters.length; i++) {
      if (!_.find(letterList, { id: notifyLetters[i] })?.isAttachable) {
        fullyAttachable = false;
        break;
      }
    }
    return fullyAttachable;
  };

  processingFile = (file: File, callback: Function) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        callback({ base64: reader.result.toString(), fileName: file.name });
      }
    };
    reader.readAsDataURL(file);
  };

  notifyHolderAndUpload = (id, index, length) => {
    const { fileList }: Readonly<IState> = this.state;
    if (fileList && fileList.length) {
      caolan.map(fileList, (file, callback) => {
        this.processingFile(file, (form) => callback(null, form));
      }, (err, results) => {

        // if any of the file processing produced an error, err would equal that error
        if (err) {
          this.props.dispatch(setToast({
            isShow: true,
            text: 'Processing file error try again!!!',
            type: 'danger'
          }));
        } else {
          this.doNotify(
            id,
            index,
            length,
            utils.ArrayBase64ToFile(results));
        }
      });
    } else {
      this.doNotify(
        id,
        index,
        length);
    }
  };

  notifyHolder = async () => {
    const { notifyLetters }: Readonly<IState> = this.state;
    if (notifyLetters.length) {
      this.props.dispatch(setLoading(true));
      const fullyAttachable = this.checkFullyAttachable();
      for (let i = 0; i < notifyLetters.length; i++) {
        if (fullyAttachable) {
          this.notifyHolderAndUpload(notifyLetters[i], i, notifyLetters.length - 1);
        } else {
          this.doNotify(notifyLetters[i], i, notifyLetters.length - 1);
        }
      }
      this.unloadDropZone();
    }
  };

  unloadDropZone = () => {
    if (this.dropZone) {
      this.setState({
        fileList: undefined
      });
      this.dropZone.removeEventListener('dragover', () => {
      });
      this.dropZone.removeEventListener('drop', () => {
      });
    }
  };

  openFile = (file) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);

    const blob = new Blob([file], { type: 'application/pdf' });

    const objectURL = window.URL.createObjectURL(blob);

    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.open(objectURL);
    } else {
      window.open(objectURL);
    }
  };

  render() {
    const {
      letterList,
      currentLetter,
      previewLetter,
      docCurrentPage,
      docTotalPage,
      loading,
      notifyLetters,
      modalConfirmShow,
      fileList,
      fwdAttachmentList,
      fwdAttachmentChosen
    }: Readonly<IState> = this.state;
    return (
      <div className="letterListScreenContainer">
        <div className={`letterPreviewContainer ${(loading && !previewLetter && 'noLetter') || ''}`}>
          <div style={{ paddingBottom: (loading && !previewLetter && 150) || 0 }}>
            {((!loading && (previewLetter &&
            <PDFReader
              page={docCurrentPage}
              onDocumentComplete={(totalPage) => {
                this.setState({
                  docTotalPage: totalPage
                });
              }}
              data={window.atob(previewLetter.split('base64,')[1])}
            />)) ||
            <div>
              <div className="backgroundNoMsg" />
              <h3>Không có letter</h3>
            </div>) || <div className="globalLoading" style={{ backgroundImage: `${loadingImg}` }} />}
          </div>
          {(previewLetter && !loading &&
            <div className="footerPdf">
              <div className="controlPdf">
                <div
                  role="button"
                  tabIndex={0}
                  className={`prev ${(docCurrentPage === 1 && 'invisible') || ''}`}
                  onClick={() => this.loadPage(docCurrentPage - 1)}
                >‹
                </div>
                <span className="info">Page {docCurrentPage} / {docTotalPage}</span>
                <div
                  role="button"
                  tabIndex={0}
                  className={`next ${(docTotalPage && docCurrentPage === docTotalPage && 'invisible') || ''}`}
                  onClick={() => this.loadPage(docCurrentPage + 1)}
                >›
                </div>
              </div>
              <div className="actionPdf">
                <div
                  role="button"
                  tabIndex={0}
                  className="download"
                  onClick={() => this.downloadPDF(previewLetter)}
                >Download
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  className="upload"
                  onClick={() => {
                    const uploadBtn = window.document.getElementById('uploadBtn');
                    if (uploadBtn) {
                      uploadBtn.click();
                    }
                  }}
                >Upload
                </div>

                <input
                  type="file"
                  id="uploadBtn"
                  accept="application/pdf"
                  // @ts-ignore
                  onChange={(event:
                  React.ChangeEventHandler<HTMLInputElement>
                  ) =>
                    this.fileSelectedHandler(event)}
                />
              </div>
            </div>
          ) || ''}
        </div>
        {(previewLetter && !loading &&
          <div className="letterListContainer">
            <div className="buttonContainer">
              <div
                className={`notifyHolderButton
                  ${(!notifyLetters.length && 'disabled') || ''}`}
                tabIndex={0}
                role="button"
                onClick={() => {
                  if (this.checkFullyAttachable()) {
                    this.initDropZone();
                  }
                  if (notifyLetters.length) {
                    this.setState({
                      modalConfirmShow: true
                    });
                  }
                }}
              >
                Thông báo chủ hợp đồng
              </div>
              <div className="updateFWDButton" tabIndex={0} role="button" onClick={() => this.uploadDocs()}>Tải
                qua FWD
              </div>
            </div>
            <span className="helperText">Chọn thư trước để làm tác vụ thông báo cho chủ hợp đồng.
            </span>

            <div className="list">
              <ul>
                {(letterList && letterList.length &&
                  letterList.map(letter =>
                    (
                      <li
                        key={letter.id}
                        role="presentation"
                        className={`${(currentLetter === letter.id && 'active') || ''}`}
                        onClick={() => {
                          if (letter.id !== currentLetter) {
                            this.docName = letter.name;
                            this.setState({
                              currentLetter: letter.id,
                              docCurrentPage: 1
                            });
                            this.getLetterById(letter.id).then();
                          }
                        }}
                      >
                        <div className="contentContainer">
                          <div className="input">
                            <input
                              type="checkbox"
                              checked={notifyLetters.includes(letter.id)}
                              onClick={(e) => {
                                e.stopPropagation();
                                const newNotifyLetters = _.clone(notifyLetters);
                                if (newNotifyLetters.includes(letter.id)) {
                                  newNotifyLetters.splice(
                                    notifyLetters.indexOf(letter.id), 1);
                                } else {
                                  newNotifyLetters.push(letter.id);
                                }
                                this.setState({
                                  notifyLetters: newNotifyLetters
                                });
                              }}
                            />
                          </div>
                          <div className="letterName">
                            <div>{letter.name} {(letter.isAttachable &&
                              <span className="attachable">︎✓︎ attachable</span>) || ''}
                            </div>

                            {(letter.isNotify && <span className="sentBtn">✓ Đã gửi chủ hợp đồng</span>) ||
                            <span className="sentBtn not">x Chưa gửi chủ hợp đồng</span>}
                            {(letter.isSent && <span className="sentBtn">✓ Đã gửi FWD</span>) ||
                            <span className="sentBtn not">x Chưa gửi FWD</span>}
                          </div>
                        </div>
                      </li>
                    ))) || ''}
              </ul>
            </div>
          </div>
        ) || ''}

        <Modal
          name="globalModal"
          className={['globalModal', !this.checkFullyAttachable() ? 'normalModal' : ''].join(' ')}
          value={modalConfirmShow}
          closeButton={true}
          onClose={() => {
            this.unloadDropZone();
            this.setState({ modalConfirmShow: false });
          }}
        >
          <div className="assessmentModal">
            <div className="assessmentHeader">
              Gửi thông báo cho chủ hợp đồng
            </div>
            <div className="assessmentDescription">
              Bạn có chắc gửi thông báo cho chủ hợp đồng ?
            </div>
            <div className="buttonAssessmentModal">
              <p>
                {(this.checkFullyAttachable() &&
                  <div className="attachmentContainer">
                    <div
                      id="dropZone"
                      className="buttonAssessmentUpload"
                    >
                      {(fileList
                        && fileList.length
                        && fileList.map(file => (
                          <p role="presentation" key={file.name} onClick={() => this.openFile(file)}>
                            <span className="attachment" title={file.name} role="img" aria-label={file.name}>
                              📎&nbsp;&nbsp;
                              <strong>
                                [{(file.size / 1000000).toFixed(2)}MB]
                              </strong>
                              &nbsp;&nbsp; <span className="fileName">{file.name}</span>
                              <span
                                className="clear"
                                role="presentation"
                                onClick={(e) => {
                                  const newFileList = fileList?.filter(fl => fl.name !== file.name);
                                  newFileList.length !== fileList?.length && this.setState({ fileList: newFileList });
                                  e.stopPropagation();
                                }}
                              >Xóa
                              </span>
                            </span>
                          </p>
                        )))
                      ||
                      <span className="labelAttachment" role="img" aria-label="attachable">
                        <input
                          type="file"
                          multiple={true}
                          title=" "
                          accept="application/pdf"
                          onChange={(e) => {
                            this.checkAndSaveFileList(e.target.files);
                          }}
                        /> hoặc kéo thả 📎&nbsp;tệp vào đây
                      </span>}
                    </div>
                    {this.checkFullyAttachable() ?
                      <div className="listSelectAttachment">
                        <p> Danh sách pending attachment</p>
                        <div>
                          {fwdAttachmentList && fwdAttachmentList.length ? fwdAttachmentList.map(att => (
                            <div key={att.value}>
                              <input
                                type="checkbox"
                                checked={fwdAttachmentChosen.includes(att.value)}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  let newFwdAttachmentChosen = _.clone(fwdAttachmentChosen);
                                  if (isChecked) {
                                    if (fwdAttachmentChosen.includes(att.value)) {
                                      newFwdAttachmentChosen = newFwdAttachmentChosen.filter(item => item !== att.value);
                                    } else {
                                      newFwdAttachmentChosen.push(att.value);
                                    }
                                  } else {
                                    newFwdAttachmentChosen = newFwdAttachmentChosen.filter(item => item !== att.value);
                                  }
                                  this.setState({ fwdAttachmentChosen: newFwdAttachmentChosen });

                                }}
                              /> {att.name}
                            </div>
                          )) : ''}
                        </div>
                      </div>
                      : ''}
                  </div>
                ) || ''}
              </p>
              {this.checkFullyAttachable() ?
                <div className="listAttachmentContainer">
                  <h4>Attachment đã chọn:</h4>
                  <div>
                    {
                      fwdAttachmentChosen && fwdAttachmentChosen.length ? fwdAttachmentChosen.map(att => {
                        const attName = fwdAttachmentList.find(item => item.value === att)?.name;
                        return (
                          <div key={att} className="attachmentItem">
                            <p role="presentation">
                              <span className="attachment" title={attName} role="img" aria-label={attName}>
                                📎&nbsp;&nbsp;
                                <span className="fileName">{attName}</span>
                              </span>
                            </p>
                          </div>
                        );
                      })
                        : ''
                    }
                  </div>
                </div>
                : ''}
              <Button
                className="buttonAssessmentCancel"
                onClick={() => {
                  this.unloadDropZone();
                  this.setState({ modalConfirmShow: false });
                }}
              >
                Hủy
              </Button>
              <Button
                className="buttonAssessmentConfirm"
                onClick={() => this.notifyHolder()}
              >
                Xác nhận
              </Button>

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect()(LetterListScreen);
