import { MODAL } from '../types';
import { ModalAction, ModalState } from '../../../config/interface';

export default function modal(state: ModalState = {
  isShow: false
}, action: ModalAction) {
  let newState = state;
  if (action.type === MODAL) {
    newState = action.modelState;
  }
  return newState;
}
