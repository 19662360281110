import { loading, auth, user, sidebar, unSavedChange, unSavedChangeModal, notification } from './commonReducers';
import toast from '../ShareStore/reducers/toast';

const reducers = {
  loading,
  auth,
  user,
  sidebar,
  toast,
  unSavedChange,
  unSavedChangeModal,
  notification
};

export default reducers;
