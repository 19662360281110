import React from 'react';
import './styles.css';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { Editor, ContentState, convertToRaw, EditorState, RichUtils } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Button, Modal } from 'papaya-ui';
import { connect } from 'react-redux';
import KEYS from '../../../../config/key';
import { ClaimCase, mailToData } from '../../../../config/interface';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import setToast from '../../../../store/ShareStore/actions/toast';

interface IProps extends RouteComponentProps<Routedata> {
  dispatch: Function
}

interface Routedata {
  id?: string;
}

interface IState {
  contentEditor: EditorState,
  currentTo: mailToData,
  showModal: boolean,
  presentCases: {
    claimCases?: ClaimCase[],
    content: string,
    subject: string,
    to: mailToData[]
  } | null
}

const inlineStyleButtons = [
  {
    value: 'B',
    style: 'BOLD'
  },

  {
    value: 'I',
    style: 'ITALIC'
  },

  {
    value: 'U',
    style: 'UNDERLINE'
  },

  {
    value: 'S',
    style: 'STRIKETHROUGH'
  },
];

const blockTypeButtons = [
  {
    value: '• UL',
    block: 'unordered-list-item'
  },

  {
    value: '1. OL',
    block: 'ordered-list-item'
  }
];


class PresentCaseScreen extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currentTo: { name: '', email: '' },
      contentEditor: EditorState.createEmpty(),
      presentCases: null
    };
    this.getFirstLoad().then();
  }

  getFirstLoad = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const presentCases = await ClaimPortalService.getPresentCases(id);
      this.setState({ presentCases, contentEditor: this.htmlToEditorState(presentCases.content) });
    }
  };

  deleteTo = (email: string) => {
    const { presentCases }: Readonly<IState> = this.state;
    if (presentCases) {
      const newPresentCases = _.clone(presentCases);
      newPresentCases.to = newPresentCases.to.filter(t => t.email !== email);
      this.setState({
        presentCases: newPresentCases
      });
    }

  };

  onSubjectChange = (subject: string) => {
    const { presentCases }: Readonly<IState> = this.state;
    const newPresentCases = _.clone(presentCases);
    if (newPresentCases) {
      newPresentCases.subject = subject;
      this.setState({ presentCases: newPresentCases });
    }
  };

  onContentChange = editorState => {
    const { presentCases }: Readonly<IState> = this.state;
    const newPresentCases = _.clone(presentCases);
    if (newPresentCases) {
      newPresentCases.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      this.setState({ presentCases: newPresentCases });
    }
    this.setState({ contentEditor: editorState });
  };

  htmlToEditorState = (html: string) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();

  };

  changeCurrentTo = (name: string | null, email: string | null) => {
    const { currentTo }: Readonly<IState> = this.state;
    if (name !== null) {
      currentTo.name = name;
      this.setState({ currentTo });
    }
    if (email !== null) {
      currentTo.email = email;
      this.setState({ currentTo });
    }
  };

  addCurrentTo = () => {
    const { presentCases, currentTo }: Readonly<IState> = this.state;
    const newPresentCases = _.clone(presentCases);
    if (newPresentCases && currentTo.email && currentTo.name) {
      newPresentCases.to.push(currentTo);
      this.setState({ presentCases: newPresentCases, showModal: false, currentTo: { name: '', email: '' } });
    } else {
      this.props.dispatch(setToast({
        isShow: true,
        type: 'danger',
        text: 'Tên và email không được để trống',
      }));
    }
  };

  sendPresentCase = async () => {
    const { presentCases }: Readonly<IState> = this.state;
    const { id } = this.props.match.params;
    if (id && presentCases) {
      const response = await ClaimPortalService.postPresentCases(id, presentCases);
      console.log(response);
      if (response && !response.Errors && response.isSuccess) {
        const newPresentCases = _.clone(presentCases);
        if (newPresentCases) {
          newPresentCases.claimCases = response.claimCases;
          this.setState({ presentCases: newPresentCases });
        }
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: 'Gửi thành công',
          })
        );
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: response.Errors[0].Message,
          })
        );
      }
    }
  };


  toggleInlineStyle(event) {
    event.preventDefault();
    const { contentEditor }: Readonly<IState> = this.state;
    const style = event.currentTarget.getAttribute('data-style');
    const newContentEditor = RichUtils.toggleInlineStyle(contentEditor, style);
    this.setState({
      contentEditor: newContentEditor
    });
  }

  toggleBlockType(event) {
    const { contentEditor }: Readonly<IState> = this.state;
    const block = event.currentTarget.getAttribute('data-block');
    const newContentEditor = RichUtils.toggleBlockType(contentEditor, block);
    event.preventDefault();
    this.setState({
      contentEditor: newContentEditor
    });
  }

  renderInlineStyleButton(value, style) {
    return (
      <input
        type="button"
        key={style}
        className="toolButton"
        value={value}
        data-style={style}
        onMouseDown={(e) => this.toggleInlineStyle(e)}
      />
    );
  }

  renderBlockButton(value, block) {
    return (
      <input
        type="button"
        key={block}
        className="toolButton"
        value={value}
        data-block={block}
        onMouseDown={(e) => this.toggleBlockType(e)}
      />
    );
  }

  render() {
    const { presentCases, contentEditor, currentTo, showModal }: Readonly<IState> = this.state;
    return (
      <div className="caseNotificationContainer">
        {(presentCases && presentCases.claimCases && presentCases.claimCases.length
          &&
          <>
            <div className="composerMailContainer">
              <div>
                <div>To:</div>
                <div className="toContainer">
                  {presentCases.to.map((to) => (
                    <div
                      key={to.email}
                    >{to.name} ({to.email})
                      <div role="presentation" onClick={() => this.deleteTo(to.email)} className="clear">✕</div>
                    </div>
                  ))}
                  <div role="presentation" className="new" onClick={() => this.setState({ showModal: true })}>Thêm mới
                  </div>
                </div>
              </div>
              <div>
                <div>Subject:</div>
                <textarea onChange={(e) => this.onSubjectChange(e.target.value)} value={presentCases.subject} />
              </div>
              <div>
                <div>Content:</div>
                <div className="contentEditor editorClassName">
                  <div className="tools">
                    {inlineStyleButtons.map((button) => {
                      return this.renderInlineStyleButton(button.value, button.style);
                    })}
                    {blockTypeButtons.map((button) => {
                      return this.renderBlockButton(button.value, button.block);
                    })}
                  </div>
                  <Editor
                    editorState={contentEditor}
                    onChange={(editorState) => this.onContentChange(editorState)}
                  />
                </div>
              </div>
            </div>
            <div className="caseListContainer">
              <div role="presentation" className="sendButton" onClick={() => this.sendPresentCase()}>Gửi thư →</div>
              <h3>Claim case list</h3>
              {presentCases.claimCases.map((claimCase) => (
                <div key={(claimCase.claimReference)} className="claimCase">
                  <div>#{claimCase.claimReference}</div>
                  <div>
                    {claimCase && claimCase.status &&
                    _.find(KEYS.STATUS_OPTIONS_CASE(),
                      { value: claimCase.status })?.name}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) ||
        (
          <div className="noClaimCaseContainer">
            <div style={{ paddingBottom: 0 }}>
              <div className="noClaimCaseBg" />
              <div>
                <h3>Không có claim cases</h3>
              </div>
            </div>
          </div>
        )}
        <Modal
          name="globalModal"
          className="globalModal"
          value={showModal}
          closeButton={true}
          onClose={() => this.setState({ showModal: false })}
        >
          <div className="assessmentModal">
            <div className="assessmentHeader">
              Thêm mới người gửi
            </div>
            <div className="assessmentDescription">
              <div>
                <div>Name:</div>
                <div>
                  <input
                    value={currentTo.name}
                    onChange={(e) => this.changeCurrentTo(e.target.value, null)}
                  />
                </div>
              </div>
              <div>
                <div>Email:</div>
                <div>
                  <input
                    value={currentTo.email}
                    onChange={(e) => this.changeCurrentTo(null, e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="buttonAssessmentModal">
              <Button
                className="buttonAssessmentCancel"
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                Hủy
              </Button>
              <Button
                className="buttonAssessmentConfirm"
                onClick={() => this.addCurrentTo()}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect()(PresentCaseScreen);
