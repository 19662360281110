/**
 ** @class DetailScreen
 */

import React from 'react';
import { Autocomplete, Button, CalendarRange, Checkbox, Modal, Switch } from 'papaya-ui';
import './style.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import utils from '../../../../config/utils';
import pathSetting, { ROUTES, } from '../../../../config/ClaimWebsite/pathSetting';
import setFooter from '../../../../store/ClaimWebsite/commonActions/footer';
import { setData } from '../../../../store/ClaimWebsite/commonActions';
import KEYS from '../../../../config/key';
import { ObjectBooleanType, riderObjectAPI, } from '../../../../config/interface';
import { showJetModal, showPaperModal } from '../../../../config/modal';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';
import setToast from '../../../../store/ShareStore/actions/toast';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  refresh: boolean;
}

interface IState {
  data: inputData;
  list?: listType; // list type
  startDate?: Date;
  riderEntity: ObjectBooleanType;
  modalShow: boolean;
  modalRCDShow: boolean;
}

type listType = {
  diseases?: Array<{ name: string; value: string }>;
  diseasesRoot?: Array<{ name: string; value: string }>;
  diseasesListValue?: Array<{ name: string; value: string }>;
  medicalFacilities?: Array<{ name: string; value: string }>;
  provinces?: Array<string>;
  cities?: Array<{ name: string; value: string }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  facilities?: { [index: string]: any };
};

type inputData = {
  medicalProvider?: string;
  treatmentMethod?: string;
  diseases?: Array<string>;
  riders: Array<string>;
  isAccident: boolean;
  province?: string;
  days_in_hospital?: ValueType;
  days_in_icu?: ValueType;
};

type ValueType = {
  from: Date | null;
  to: Date | null;
};

const mapStateToProp = (state) => ({
  data: state.data,
});

class DetailScreen extends React.Component<IProps, IState> {
  timeout;
  
  riderOptions = [
    {
      name: KEYS.RIDER_OPTION[KEYS.DAYS_IN_HOSPITAL],
      value: KEYS.DAYS_IN_HOSPITAL,
    },
    {
      name: KEYS.RIDER_OPTION[KEYS.DAYS_IN_ICU],
      value: KEYS.DAYS_IN_ICU,
    },
    {
      name: KEYS.RIDER_OPTION[KEYS.TIMES_FOR_SURGERY],
      value: KEYS.TIMES_FOR_SURGERY,
    },
    {
      name: KEYS.RIDER_OPTION[KEYS.TIMES_FOR_MAJOR_SURGERY],
      value: KEYS.TIMES_FOR_MAJOR_SURGERY,
    },
  ];
  
  constructor(props) {
    super(props);
    this.state = {
      data: {
        isAccident: false,
        riders: [],
      },
      riderEntity: {
        [KEYS.DAYS_IN_HOSPITAL]: false,
        [KEYS.DAYS_IN_ICU]: false,
        [KEYS.TIMES_FOR_SURGERY]: false,
        [KEYS.TIMES_FOR_MAJOR_SURGERY]: false,
      },
      modalShow: false,
      modalRCDShow: false
    };
    if (
      !props.data.mainData ||
      (props.data.mainData && !props.data.mainData[KEYS.FIELD.CLAIM_ID])
    ) {
      this.props.history.replace(ROUTES.policy);
    }
  }
  
  componentDidMount(): void {
    this.getFirstLoad().then();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh) {
      this.getFirstLoad().then();
    }
  }
  
  getFirstLoad = async () => {
    const { data } = this.props;
    const { postBack } = data;
    if (
      postBack &&
      postBack.detailScreen &&
      postBack.detailScreen.data &&
      postBack.detailScreen.list.provinces
    ) {
      this.props.dispatch(setLoading(true));
      this.setState(
        {
          data: postBack.detailScreen.data,
          list: postBack.detailScreen.list,
        },
        () => {
          this.handleSearchDiseases('', '');
          this.props.dispatch(setLoading(false));
          if (postBack.detailScreen.data[KEYS.FIELD.RIDERS]) {
            this.handleChangeRider(
              postBack.detailScreen.data[KEYS.FIELD.RIDERS]
            );
          }
          this.props.dispatch(setLoading(false));
        }
      );
    } else {
      await this.getAllOptionList().then();
      this.props.dispatch(setLoading(false));
    }
  };
  
  getAllOptionList = async () => {
    const medicalFacilities = await ClaimService.getMedicalProvider();
    const provinces = _.map(_.uniqBy(medicalFacilities, 'extra'), 'extra');
    const facilities = _.groupBy(medicalFacilities, 'extra');
    const diseases = await ClaimService.getMetadata(KEYS.LIST.DISEASES);
    const diseasesRoot = _.clone(diseases);
    this.setState({
      list: {
        provinces,
        facilities,
        medicalFacilities,
        diseases,
        diseasesRoot,
      },
    });
  };
  
  handleChange = (name, value) => {
    const { t } = this.props;
    const { data, list }: Readonly<IState> = this.state;
    const { riders } = data;
    const newData = _.clone(data || {});
    const newList = _.clone(list);
    
    if (newList && name === KEYS.FIELD.DISEASES) {
      if (value.length <= 10) {
        const newValue = _.difference(value, newData[KEYS.FIELD.DISEASES]);
        if (!newData[KEYS.FIELD.DISEASES]) newData[KEYS.FIELD.DISEASES] = [];
        const usedList = newList.diseasesRoot;
        const objectValue = _.find(usedList, ['value', newValue[0]]);
        if (!newList[KEYS.LIST.DISEASES_VALUE]) {
          newList[KEYS.LIST.DISEASES_VALUE] = [];
        }
        if (objectValue) {
          newList[KEYS.LIST.DISEASES_VALUE].push(objectValue);
        }
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'warning',
            text: t('errors.ALLOW_10_DISEASE'),
          })
        );
        return;
      }
    }
    newData[name] = value;
    if (
      (Array.isArray(value) && value.length) ||
      (value !== null && value !== undefined)
    ) {
      if (name === KEYS.DAYS_IN_HOSPITAL) {
        if (riders.includes(KEYS.DAYS_IN_ICU) && newData[KEYS.DAYS_IN_ICU]) {
          newData[KEYS.DAYS_IN_ICU] = { from: undefined, to: undefined };
        }
      }
    }
    if (value && Array.isArray(value) && !value.length) {
      delete newData[name];
    }
    this.setState({ data: newData, list: newList }, () => {
      if (this.checkAllLogic()) {
        this.readyToNext();
      } else {
        this.readyToNext(true);
      }
    });
  };
  
  readyToNext = (isNot?: boolean) => {
    const { footer } = pathSetting()[ROUTES.detail];
    footer.nextState.disabled = isNot;
    footer.nextState.callback = async () => this.updateClaimRequest();
    this.props.dispatch(setFooter(footer));
  };
  
  updateClaimRequest = async (isIgnore?: boolean) => {
    const { data, t } = this.props;
    const newData = _.clone(data);
    
    if (data.mainData[KEYS.FIELD.CLAIM_ID]) {
      if (!newData.postBack) newData.postBack = {};
      
      newData.postBack.detailScreen = {};
      newData.postBack.detailScreen = {
        data: this.state.data,
        list: this.state.list,
      };
      this.props.dispatch(setData(newData));
      this.saveListToSession(this.state.list);
      const detailData = {
        medicalProvider: this.state.data[KEYS.FIELD.MEDICAL_PROVIDER],
        diseases: utils.formatArrayToString(
          this.state.data[KEYS.FIELD.DISEASES]
        ),
        isAccident: this.state.data[KEYS.FIELD.IS_ACCIDENT],
        riders: this.convertRidersData(),
        ignoreDuplication: isIgnore || false
      };
      const response = await ClaimService.submitDetailClaim(
        data.mainData[KEYS.FIELD.CLAIM_ID],
        detailData
      );
      if (response && !response.status) {
        if (response[KEYS.MODAL.IS_JET]) {
          showJetModal();
          localStorage.setItem(KEYS.IS_JET.KEY, KEYS.IS_JET.TRUE);
          localStorage.removeItem(KEYS.IS_ADD.KEY);
        } else {
          showPaperModal();
          localStorage.removeItem(KEYS.IS_JET.KEY);
          localStorage.removeItem(KEYS.IS_ADD.KEY);
        }
        this.props.history.push(ROUTES.paperwork);
      } else if (response.status && response.data) {
        const error = response.data.Errors[0];
        if (error.Code === KEYS.ERROR.INFO_ERROR) {
          this.props.dispatch(
            setToast({
              isShow: true,
              type: 'danger',
              text: t('errors.WRONG_DATE_ICU'),
            })
          );
        }
        if (error.Code === KEYS.ERROR.DUPLICATION_ERROR) {
          this.setState({
            modalShow: true
          });
        }
        if (error.Code == KEYS.ERROR.FAIL_RCD){
          this.setState({
            modalRCDShow: true
          });
          this.readyToNext(true);
        }

      }
    }
  };
  
  saveListToSession = (list) => {
    sessionStorage.setItem('list', JSON.stringify(list));
  };
  
  convertRidersData = (): Array<riderObjectAPI> => {
    const { riderEntity, data }: Readonly<IState> = this.state;
    const ridersData: Array<riderObjectAPI> = [];
    if (riderEntity[KEYS.DAYS_IN_HOSPITAL]) {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 5),
        value: utils.formatDateRangeToStringDate(data[KEYS.DAYS_IN_HOSPITAL]),
        isSelected: true
      });
    } else {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 5),
        value: utils.formatDateRangeToStringDate(data[KEYS.DAYS_IN_HOSPITAL]),
        isSelected: false
      });
    }
    if (riderEntity[KEYS.DAYS_IN_ICU]) {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 5),
        value: utils.formatDateRangeToStringDate(data[KEYS.DAYS_IN_ICU]),
        isSelected: true
      });
    } else {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 5),
        value: 'false',
        isSelected: false
      });
    }
    if (riderEntity[KEYS.TIMES_FOR_SURGERY]) {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.TIMES_FOR_SURGERY, 5),
        value: 'true',
        isSelected:true
      });
    } else {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.TIMES_FOR_SURGERY, 5),
        value: 'false',
        isSelected:false
      });
    }
    if (riderEntity[KEYS.TIMES_FOR_MAJOR_SURGERY]) {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 5),
        value: 'true',
        isSelected:true
      });
    } else {
      ridersData.push({
        riderDetailCode: parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 5),
        value: 'false',
        isSelected:false
      });
    }
    
    return ridersData;
  };
  
  checkAllLogic = () => {
    const { data, riderEntity }: Readonly<IState> = this.state;
    
    let dataLength = Object.keys(data).length;
    if(!data[KEYS.FIELD.RIDERS].length){
      dataLength-=1;
    }
    let expectDataLength = 6;
    if(riderEntity[KEYS.DAYS_IN_ICU]) {
      expectDataLength +=1;
    }
    if (dataLength >= expectDataLength) {
      if (
        data[KEYS.DAYS_IN_HOSPITAL] &&
        (!data[KEYS.DAYS_IN_HOSPITAL].from || !data[KEYS.DAYS_IN_HOSPITAL].to)
      ) {
        return false;
      }
      
      return !(
        data[KEYS.DAYS_IN_ICU] &&
        (!data[KEYS.DAYS_IN_ICU].from || !data[KEYS.DAYS_IN_ICU].to)
      );
    }
    return false;
  };
  
  handleSearchDiseases = async (name: string, value: string) => {
    const { data, list }: Readonly<IState> = this.state;
    const newList = list;
    if (newList) {
      clearTimeout(this.timeout);
      newList[KEYS.LIST.DISEASES] = [];
      this.setState({
        list: newList,
      });
      newList[KEYS.LIST.DISEASES] = await ClaimService.getMetadata(
        KEYS.FIELD.DISEASES,
        value,
        data[KEYS.FIELD.DISEASES] &&
        utils.formatArrayToExcludeString(data[KEYS.FIELD.DISEASES])
      );
      newList.diseasesRoot = _.clone(newList[KEYS.LIST.DISEASES]);
      this.timeout = setTimeout(async () => {
        this.setState({
          list: newList,
        });
      }, 300);
    }
  };
  
  handleChangeRider = (value) => {
    const { riderEntity, data }: Readonly<IState> = this.state;
    const newRiders = riderEntity;
    let newRiderList = value;
    const newData = data;
    if (
      newRiderList.includes(KEYS.TIMES_FOR_SURGERY) &&
      newRiderList.includes(KEYS.TIMES_FOR_MAJOR_SURGERY)
    ) {
      if (!riderEntity[KEYS.TIMES_FOR_SURGERY]) {
        riderEntity[KEYS.TIMES_FOR_SURGERY] = true;
        riderEntity[KEYS.TIMES_FOR_MAJOR_SURGERY] = false;
        newRiderList = newRiderList.filter(
          (key) => key !== KEYS.TIMES_FOR_MAJOR_SURGERY
        );
      } else if (!riderEntity[KEYS.TIMES_FOR_MAJOR_SURGERY]) {
        riderEntity[KEYS.TIMES_FOR_SURGERY] = false;
        riderEntity[KEYS.TIMES_FOR_MAJOR_SURGERY] = true;
        newRiderList = newRiderList.filter(
          (key) => key !== KEYS.TIMES_FOR_SURGERY
        );
      }
    } else {
      Object.keys(riderEntity).forEach((rider) => {
        riderEntity[rider] = newRiderList.includes(rider);
      });
    }
    newData.riders = newRiderList;
    this.setState(
      {
        riderEntity: newRiders,
        data: newData,
      },
      () => {
        if (this.checkAllLogic()) {
          this.readyToNext();
        } else {
          this.readyToNext(true);
        }
      }
    );
  };
  
  handleChangeProvince = (value: string) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    newData[KEYS.FIELD.PROVINCE] = value;
    newData[KEYS.FIELD.MEDICAL_PROVIDER] = '';
    this.setState({
      data: newData,
    });
  };
  
  render = () => {
    const { t } = this.props;
    const {
      data,
      startDate,
      list,
      riderEntity,
      modalShow,
      modalRCDShow
    }: Readonly<IState> = this.state;
    return (
      <div className="detailContainer">
        <Modal
          name="globalModal"
          className="globalModal"
          value={modalRCDShow}
          onClose={() => {
            this.setState({ modalRCDShow: false });
          }}
          closeButton={true}
        >
          <div className="duplicateModal">
            <div className="dupHeader" >Lưu ý</div>
            <div className="dupDescription">
              {t('modal.description.failRCD')}
            </div>
            <div className="duplicateButton">
            <Button
                onClick={() => {
                  this.setState({ modalRCDShow: false });
                }}
              >
                {t('modal.confirm.gotIt')}
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className="globalModal"
          value={modalShow}
          closeButton={true}
          onClose={() => {
            this.setState({ modalShow: false });
          }}
        >
          <div className="duplicateModal">
            <div className="dupHeader" />
            <div className="dupDescription">
              {t('modal.description.duplication')}
            </div>
            <div className="duplicateButton">
              <Button
                onClick={() => {
                  this.setState({ modalShow: false });
                }}
              >
                {t('modal.confirm.cancel')}
              </Button>
              <Button
                onClick={() => {
                  this.updateClaimRequest(true).then();
                  this.setState({ modalShow: false });
                }}

              >
                {t('modal.confirm.agree')}
              </Button>
            </div>
          </div>
        </Modal>
        <div className="contentDetail">
          <Checkbox
            name="checkbox"
            className="riders"
            value={data.riders || []}
            label="Checkbox multiple input"
            multiple={true}
            colorStyle={{
              containerColor: '#183028',
              checkColor: '#183028',
            }}
            defaultValue={[]}
            options={this.riderOptions}
            onChange={(name, value) => this.handleChangeRider(value)}
          />
          <div className="ProductSwitch">
            <Switch
              name={KEYS.FIELD.IS_ACCIDENT}
              label={t('details.label.isAccident')}
              value={data && data[KEYS.FIELD.IS_ACCIDENT]}
              yesLabel={t('confirm.yes')}
              noLabel={t('confirm.no')}
              // @ts-ignore
              colorStyle="#183028"
              onChange={(name, value) => this.handleChange(name, value)}
              defaultValue={false}
              className="switch"
            />
          </div>
          
          <div className="headerDetail hospitalInfo">
            <p>{t('details.treatmentPlace')}</p>
          </div>
          
          <Autocomplete
            name={KEYS.FIELD.PROVINCE}
            className="detailField provinceField noneTransform"
            label={t('details.label.province')}
            placeHolder={t('details.placeHolder.province')}
            options={
              (list &&
                list[KEYS.LIST.PROVINCE] &&
                list[KEYS.LIST.PROVINCE].map((p) => ({
                  name: p,
                  value: p,
                }))) ||
              []
            }
            value={data[KEYS.FIELD.PROVINCE]}
            onChange={(name, value) => this.handleChangeProvince(value)}
            errorPosition="right"
          />
          <div id="separator" style={{ width: '.8vw' }} />
          <Autocomplete
            name={KEYS.FIELD.MEDICAL_PROVIDER}
            className="detailField medicalFacField noneTransform"
            label={t('details.label.medicalFacility')}
            placeHolder={t('details.placeHolder.medicalFacility')}
            options={
              (data[KEYS.FIELD.PROVINCE] &&
                list &&
                list[KEYS.LIST.FACILITY] &&
                list[KEYS.LIST.FACILITY][data[KEYS.FIELD.PROVINCE]]) ||
              []
            }
            value={data[KEYS.FIELD.MEDICAL_PROVIDER]}
            onChange={(name, value) => this.handleChange(name, value)}
            errorPosition="right"
          />
          <Autocomplete
            name={KEYS.FIELD.DISEASES}
            className="detailField diseaseField noneTransform"
            label={t('details.label.diagnosis')}
            placeHolder={t('details.placeHolder.diagnosis')}
            errorPosition="right"
            multiple
            options={(list && list[KEYS.LIST.DISEASES]) || []}
            extraOptions={(list && list[KEYS.LIST.DISEASES_VALUE]) || []}
            backendSearch
            onChangeSearch={(name, value) =>
              this.handleSearchDiseases(name, value)}
            onChange={(name, value) => this.handleChange(name, value)}
            value={
              data[KEYS.FIELD.DISEASES] && _.clone(data[KEYS.FIELD.DISEASES])
            }
          />
          <div className="labelCalendarRange">
            {t('details.label.treatmentTime')}
          </div>
          <CalendarRange
            className="fieldCalendarRange noneTransform"
            calenderClassName="calendarField"
            name={KEYS.DAYS_IN_HOSPITAL}
            label={t('details.label.treatmentTime')}
            labelFrom={t('date.label.from')}
            labelTo={t('date.label.to')}
            placeHolderFrom={t('date.placeHolder.from')}
            placeHolderTo={t('date.placeHolder.to')}
            rightShow={window.screen.width > 420}
            blockFrom={startDate && new Date(startDate)}
            blockTo={new Date()}
            errorPosition="right"
            pastBack={true}
            onChange={(name, value) => this.handleChange(name, value)}
            value={data[KEYS.DAYS_IN_HOSPITAL]}
          />
          
          {(riderEntity && riderEntity[KEYS.DAYS_IN_ICU] && (
            <div className="labelCalendarRange">
              {t('details.label.timeInICU')}
            </div>
          )) || ''}
          {(riderEntity && riderEntity[KEYS.DAYS_IN_ICU] && (
            <CalendarRange
              className="fieldCalendarRange noneTransform"
              calenderClassName="calendarField"
              name={KEYS.DAYS_IN_ICU}
              label={t('details.label.timeInICU')}
              labelFrom={t('date.label.from')}
              labelTo={t('date.label.to')}
              placeHolderFrom={t('date.placeHolder.from')}
              placeHolderTo={t('date.placeHolder.to')}
              blockFrom={
                (data[KEYS.DAYS_IN_HOSPITAL] &&
                  data[KEYS.DAYS_IN_HOSPITAL].from &&
                  new Date(data[KEYS.DAYS_IN_HOSPITAL].from)) ||
                (startDate && new Date(startDate)) ||
                undefined
              }
              blockTo={
                (data[KEYS.DAYS_IN_HOSPITAL] &&
                  data[KEYS.DAYS_IN_HOSPITAL].to &&
                  new Date(
                    new Date(data[KEYS.DAYS_IN_HOSPITAL].to).setDate(
                      new Date(data[KEYS.DAYS_IN_HOSPITAL].to).getDate() + 1
                    )
                  )) ||
                new Date()
              }
              rightShow={window.screen.width > 420}
              errorPosition="right"
              pastBack={true}
              onChange={(name, value) => this.handleChange(name, value)}
              value={data[KEYS.DAYS_IN_ICU] || { from:null, to:null }}
            />
          )) ||
          ''}
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProp)(withTranslation('common')(DetailScreen));
