/**
 ** @class PaperWorkScreen
 */

import React from 'react';
import './style.css';
import { UploadPicture, ReaderFile } from 'papaya-ui';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import u from 'underscore';
import { WithTranslation, withTranslation } from 'react-i18next';
import pathSetting, { ROUTES, } from '../../../../config/ClaimWebsite/pathSetting';
import setFooter from '../../../../store/ClaimWebsite/commonActions/footer';
import KEYS from '../../../../config/key';
import { iconShowTooltipBlackHospitalModal } from '../../../../config/modal';
import utils from '../../../../config/utils';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import setToast from '../../../../store/ShareStore/actions/toast';
import { setData } from '../../../../store/ClaimWebsite/commonActions';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  refresh: boolean;
}

interface IState {
  data: inputData;
  option: optionData;
  showImage: string,
  openPDF: boolean
}

type submitData = {
  HospitalDischargePaper?: Array<File>,
  Others?: Array<File>,
};

type inputData = {
  HospitalDischargePaper?: Array<ValueType>,
  Others?: Array<ValueType>,
};

type optionData = {
  HospitalDischargePaper: Array<OptionType>,
  Others: Array<OptionType>,
};

interface ValueType {
  name: string,
  value: string
}

interface OptionType {
  label: string,
  editable: boolean
}

const mapStateToProp = (state) => ({
  data: state.data,
});

class PaperWorkScreen extends React.Component<IProps, IState> {
  submitData: submitData = {};

  defaultData: optionData = { HospitalDischargePaper: [], Others: [] };

  postBackData: optionData = { HospitalDischargePaper: [], Others: [] };
  
  pdfName: string = '';

  pdfFile: string = '';
  
  fileName: Array<string> = [];
  
  fileNameDischargePaper: Array<string> = [];
  
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      openPDF: false,
      showImage: '',
      option: { HospitalDischargePaper: [], Others: [] }
    };
    if(!sessionStorage.getItem(KEYS.FIELD.CLAIM_ID)){
      this.props.history.replace(ROUTES.policy);
    }
  }
  
  componentDidMount(): void {
    this.getFirstLoad().then();
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.refresh !== this.props.refresh){
      if(this.props.data.postBack &&
        this.props.data.postBack.detailScreen &&
        this.props.data.postBack.detailScreen.list &&
        Object.keys(this.props.data.postBack.detailScreen.list).length){
        this.getFirstLoad().then();
      } else {
        this.props.history.replace(ROUTES.detail);
      }
    }
  }
  
  getFirstLoad = async () => {
    const { data } = this.props;
    const { postBack } = data;
    this.submitData = {};
    if(postBack &&
      !Object.keys(postBack.detailScreen.list).length){
      this.props.history.replace(ROUTES.detail);
    }
    if (postBack && postBack.paperworkScreen) {
      this.setState({ data: postBack.paperworkScreen }, () => {
        this.handleLogicAll();
        this.updateOption();
        this.setDefaultData();
      });
    } else {
      this.updateOption();
    }
  };
  
  setDefaultData = () => {
    const { data }:Readonly<IState> = this.state;
    const newData = _.clone(data);
  
    if(newData){
      if(newData[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]){
        this.defaultData.HospitalDischargePaper =
          _.clone(newData[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]);
      }
      if(newData[KEYS.FIELD.OTHERS_PAPER]){
        this.defaultData.Others = _.clone(newData[KEYS.FIELD.OTHERS_PAPER]);
      }
    }
  };
  
  updateOption = () => {
    const { option, data } = this.state;
    const newOption = _.clone(option);
    const newData = _.clone(data);
    const { t } = this.props;
    
    if(newData[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]
      && newData[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER].length) {
      newOption[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]
        = [{ label: t('paperwork.placeHolder.hospitalCheckout'), editable: false }];
    } else {
      newOption[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]
        = [{ label: t('paperwork.placeHolder.hospitalCheckout'), editable: true }];
    }
    
    if(newOption[KEYS.FIELD.OTHERS_PAPER]){
      for(let i = 0; i < KEYS.UPLOAD_IMAGE.OTHER_MAX; i++) {
        if(newData[KEYS.FIELD.OTHERS_PAPER]
          && i < newData[KEYS.FIELD.OTHERS_PAPER].length) {
          newOption[KEYS.FIELD.OTHERS_PAPER][i] =
            { label: t('paperwork.placeHolder.otherPaper'), editable: false };
        } else {
          newOption[KEYS.FIELD.OTHERS_PAPER][i] =
            { label: t('paperwork.placeHolder.otherPaper'), editable: true };
        }
      }
    }
    
    this.setState({
      option: newOption
    });
  };
  
  handleChange = (name, value, fileName) => {
    const { data }:Readonly<IState> = this.state;
    if(name === KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER) {
      this.fileNameDischargePaper = fileName;
    } else {
      this.fileName = fileName;
    }
    const newData = _.clone(data) || {};
    if(!newData[name]) {
      newData[name] = [];
    }
    newData[name] = this.defaultData[name].concat(value);
    if(value.length === 0 && newData[name].length === 0) {
      delete newData[name];
    }
    this.submitData[name] = value;
    this.setState({ data: newData }, () => {
      this.handleLogicAll();
    });
  };
  
  handleLogicAll = () => {
    const { data } = this.state;
    if (data && data.HospitalDischargePaper) {
      this.readyToNext();
    } else {
      this.hideFooter();
    }
  };
  
  updateDataBeforeUpload = () => {
    const newData = _.clone(this.state.data);
    const { footer } = pathSetting()[ROUTES.paperwork];
    footer.nextState.disabled = true;
    this.props.dispatch(setFooter(footer));
    
    if(newData) {
      this.submitData.HospitalDischargePaper = u.pluck(this.submitData.HospitalDischargePaper, 'value');
      this.submitData.Others = u.pluck(this.submitData.Others, 'value');
    }
    if(this.submitData.HospitalDischargePaper && this.submitData.Others) {
      if(!this.submitData.HospitalDischargePaper.length) {
        delete this.submitData.HospitalDischargePaper;
      }
      if(!this.submitData.Others.length) {
        delete this.submitData.Others;
      }
    }
    if(_.isEmpty(this.submitData)) {
      return true;
    }
    return this.uploadDocument();
  };
  
  uploadDocument = async () => {
    const { data } = this.props;
    const newData = data;
    
    const docList = Object.keys(this.submitData).map(key => {
      return {
        DocType: KEYS.IMAGES[key],
        value: this.submitData[key]
      };
    });
    const claimID = sessionStorage.getItem(KEYS.FIELD.CLAIM_ID);
    if (docList && docList.length && claimID) {
      for (const doc of docList) {
        if (doc && doc.value.length) {
          for (const base64 of doc.value) {
            if(base64) {
              const index = doc.value.indexOf(base64);
              const block = base64 && base64.split(';');
              const contentType = block[0].split(':')[1]; // In this case "image/gif"
              const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
              const blob = utils.b64toBlob(realData, contentType);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const file = new FormData();
              if(doc.DocType === KEYS.IMAGES.HospitalDischargePaper) {
                file.append(KEYS.FIELD.FILE, blob, this.fileNameDischargePaper[index]);
              } else {
                file.append(KEYS.FIELD.FILE, blob, this.fileName[index]);
              }
              file.append(KEYS.FIELD.DOC_TYPE, doc.DocType);
              try {
                const response =
                  // eslint-disable-next-line no-await-in-loop
                  await ClaimService.uploadDocument(claimID, file, () => {
                  });
                if (response && response.data && response.data.Errors) {
                  this.props.dispatch(
                    setToast({
                      isShow: true,
                      type: 'warning',
                      text: response.data && response.data.Errors
                        && response.data.Errors[0].Message,
                    })
                  );
                  this.hideFooter();
                  this.props.history.push(ROUTES.policy);
                  return false;
                } 
                this.postBackData[KEYS.IMAGES[response.docType]].push(
                  u.omit(u.omit(response, 'docType'), 'mediaType')
                );
                
              } catch (e) {
                return false;
              }
            }
          }
        }
      }
      if(!newData.postBack.paperworkScreen) {
        newData.postBack.paperworkScreen = {};
      }
      if(this.postBackData) {
        if(this.postBackData[KEYS.IMAGES[1]]
          && !newData.postBack.paperworkScreen[KEYS.IMAGES[1]]) {
          newData.postBack.paperworkScreen[KEYS.IMAGES[1]]
            = this.postBackData[KEYS.IMAGES[1]];
        }
        if(this.postBackData[KEYS.IMAGES[0]]) {
          if(newData.postBack.paperworkScreen[KEYS.IMAGES[0]]) {
            newData.postBack.paperworkScreen[KEYS.IMAGES[0]] =
              newData.postBack.paperworkScreen[KEYS.IMAGES[0]].concat(
                this.postBackData[KEYS.IMAGES[0]]
              );
          } else {
            newData.postBack.paperworkScreen[KEYS.IMAGES[0]]
              = this.postBackData[KEYS.IMAGES[0]];
          }
        }
        this.props.dispatch(setData(newData));
        return true;
      }
    }
    return false;
  };
  
  getFullImage = async (id: string) => {
    const claimID = sessionStorage.getItem(KEYS.FIELD.CLAIM_ID);
    if(claimID) {
      const response = await ClaimService.getDetailDocument(claimID, id);
      if(response && response.value) {
        if(this.checkFileType(response.value) === 'application/pdf') {
          this.openPDF(response.value);
        } else {
          this.setState({
            showImage: response.value
          });
        }
      }
    }
  };
  
  openPDF = (pdf: string) => {
    this.pdfFile = pdf;
    this.setState({
      openPDF: true
    });
  };
  
  closePopUpPDF = () => {
    this.setState({
      openPDF: false
    });
  };
  
  checkFileType = (base64: string) => {
    const type = base64.match(/[^:]\w+\/[\w-+\d.]+(?=[;,])/);
    if(type) {
      return type[0];
    }
    return null;
  };
  
  toggleImage = (image: string) => {
    this.setState({
      showImage: image
    });
  };
  
  hideFooter = () => {
    const { footer } = pathSetting()[ROUTES.paperwork];
    footer.nextState.disabled = true;
    this.props.dispatch(setFooter(footer));
  };
  
  readyToNext = () => {
    const { footer } = pathSetting()[ROUTES.paperwork];
    footer.nextState.disabled = false;
    footer.nextState.callback = (() => this.updateDataBeforeUpload());
    this.props.dispatch(setFooter(footer));
  };
  
  render = () => {
    const { t } = this.props;
    const { option, showImage, data, openPDF }:Readonly<IState> = this.state;
    return (
      <div className="paperWorkContainer">
        <div className="contentPaperWork">
          <UploadPicture
            className="hospital"
            name="HospitalDischargePaper"
            value={_.clone(data[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]) || []}
            label={t('paperwork.label.hospitalCheckout')}
            description=""
            multiple={true}
            distinct={false}
            max={KEYS.UPLOAD_IMAGE.HOSPITAL_MAX}
            min={1}
            maxSize={KEYS.UPLOAD_IMAGE.FILE_SIZE}
            endButton={iconShowTooltipBlackHospitalModal}
            showTooltip={true}
            guides={[
              {
                title: t('paperwork.guild.titleHC'),
                description: t('paperwork.guild.descriptionHC'),
                button: t('confirm.gotIt'),
                src:
                  'https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/hospital.png',
              },
            ]}
            options={option[KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER]}
            backEndImage={(id) => this.getFullImage(id)}
            onChange={(name, value, fileName) => this.handleChange(name, value, fileName)}
          />
          
          <UploadPicture
            className="other"
            name="Others"
            value={_.clone(data[KEYS.FIELD.OTHERS_PAPER]) || []}
            label={t('paperwork.label.otherPaper')}
            description=""
            multiple={true}
            distinct={false}
            max={KEYS.UPLOAD_IMAGE.OTHER_MAX}
            min={1}
            showTooltip={false}
            maxSize={KEYS.UPLOAD_IMAGE.FILE_SIZE}
            guides={[]}
            options={option[KEYS.FIELD.OTHERS_PAPER]}
            backEndImage={(id) => this.getFullImage(id)}
            onChange={(name, value, fileName) => this.handleChange(name, value, fileName)}
          />
        </div>
        {
          (showImage &&
          <div className="overlay">
            <div className="popupImage">
              <div className="backgroundBlur" style={{ backgroundImage: `url(${showImage})` }} />
              <img style={{ zIndex: 1 }} src={showImage} alt="fullSizeImage" />
            </div>
            <div className="closePopupImage" role="button" tabIndex={0} aria-label="close" onClick={() => this.toggleImage('')} />
          </div>) || ''
        }
  
        {
          openPDF &&
          <ReaderFile
            value={this.pdfFile}
            hideBackground={false}
            togglePopUp={() => this.closePopUpPDF()}
            name={this.pdfName || 'download.pdf'}
            showPercentage={false}
            transform={{ rotate: 0, scale: 1 }}
          />
        }
      </div>
    );
  };
}

export default connect(mapStateToProp)(withTranslation('common')(PaperWorkScreen));
