import { UNSAVED_CHANGE } from '../types';
import { UnSavedAction } from '../interface';

export default function unSavedChange(state = false, action: UnSavedAction) {
  let newState = state;
  if (action.type === UNSAVED_CHANGE) {
    newState = action.unSavedChange;
  }
  return newState;
}
