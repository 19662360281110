import React from 'react';
import './styles.css';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import key from '../../../../config/key';
import utils from '../../../../config/utils';

interface IProps extends RouteComponentProps<Routedata> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

type Routedata = {
  id?: string
};

interface IState {
  data: {
    bankName?: string;
    bankBranch?: string;
    bankCity?: string;
    ownerName?: string;
    ownerAccount?: string;
    branchAddress?: string;
    receiverName?: string;
    receiverId?: string;
    receiverIdDate?: Date;
    receiverIdLocation?: string;
    type?: string;
    policyNumber?: string;
    amount?: number;
  };
  editBankBranch: boolean;
  bankBranch: string;
}

class PaymentAssessmentScreen extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      editBankBranch: false,
      bankBranch: ''
    };
  }

  componentDidMount() {
    this.getPaymentInformation().then();
  }

  getPaymentInformation = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const response = await ClaimPortalService.getPaymentClaim(id);
      this.setState({ data: response, bankBranch: response.bankBranch });
    }
  };

  updateBankBranch = async () => {
    const { editBankBranch }: Readonly<IState> = this.state;
    const { id } = this.props.match.params;
    if (editBankBranch && id) {
      const { data }: Readonly<IState> = this.state;
      const newData = _.clone(data);
      const response = await ClaimPortalService.putBankBranchPayment(id, { BankBranch: this.state.bankBranch });
      if (response.bankBranch) {
        newData[key.FIELD.BANK_BRANCH] = response.bankBranch;
        this.setState({
          data: newData,
          bankBranch: response.bankBranch,
          editBankBranch: !editBankBranch
        });
      }

    } else {
      this.setState({
        editBankBranch: !editBankBranch
      });
    }

  };

  render() {
    const { data, editBankBranch, bankBranch } = this.state;
    return (
      <div className="paymentAssessmentContainer">
        {data && data.type && (
          <div className="paymentAssessmentType">
            {(data.type === key.PAYMENT_TYPE.TRANSFER_MONEY.VALUE
              && key.PAYMENT_TYPE.TRANSFER_MONEY.NAME) ||
            (data.type === key.PAYMENT_TYPE.BANK_MONEY.VALUE
              && key.PAYMENT_TYPE.BANK_MONEY.NAME) ||
            (data.type === key.PAYMENT_TYPE.FORWARD_MONEY.VALUE
              && key.PAYMENT_TYPE.FORWARD_MONEY.NAME)}
          </div>
        )}
        {(data && data.type
          && data.type === key.PAYMENT_TYPE.TRANSFER_MONEY.VALUE
          && (
            <div className="paymentAssessmentContent">
              <div className="paymentLeftColumn">
                {(data && data.bankName && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Ngân hàng</div>
                    <div className="paymentDetailContent">{data.bankName}</div>
                  </div>
                )) ||
                ''}

                {(data && data.ownerName && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Chủ tài khoản</div>
                    <div className="paymentDetailContent">{data.ownerName}</div>
                  </div>
                )) ||
                ''}

                {(data && data.ownerAccount && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Số tài khoản</div>
                    <div className="paymentDetailContent">
                      {data.ownerAccount}
                    </div>
                  </div>
                )) ||
                ''}
              </div>
              <div className="paymentRightColumn">

                {(data && data.bankBranch && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Tỉnh / Thành phố</div>
                    <div className="paymentDetailContent">
                      {data.bankCity}
                    </div>
                  </div>
                )) ||
                ''}

                {(data && data.bankBranch && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Chi nhánh</div>
                    <div className="paymentDetailContent">
                      {(editBankBranch &&
                        <input
                          value={bankBranch}
                          onChange={(e) => {
                            this.setState({
                              bankBranch: e.target.value
                            });
                          }}
                        />)
                      || (utils.highLightNumberInString(data.bankBranch))}
                      <span
                        className={`editAction ${(editBankBranch && 'done') || 'edit'}`}
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.updateBankBranch();
                        }}
                      >{(editBankBranch && 'Xong') || 'Sửa'}
                      </span>
                    </div>
                  </div>
                )) ||
                ''}

                {(data && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Số tiền chi trả</div>
                    <div className="paymentDetailContent">
                      {data.amount} VND
                    </div>
                  </div>
                )) ||
                ''}
              </div>
            </div>
          )) ||
        ''}

        {(data && data.type
          && data.type === key.PAYMENT_TYPE.FORWARD_MONEY.VALUE
          && (
            <div className="paymentAssessmentContent">
              <div className="paymentLeftColumn">
                {(data && data.policyNumber && (
                  <div className="paymentDetail">
                    <div className="paymentDetailHeader">Số hợp đồng</div>
                    <div className="paymentDetailContent">{data.policyNumber}</div>
                  </div>
                )) ||
                ''}
              </div>

            </div>
          )) ||
        ''}

        {(data && data.type
          && data.type === key.PAYMENT_TYPE.BANK_MONEY.VALUE
          && (
            <div className="paymentAssessmentBankCashContent">
              <div className="ownerPaymentContent">
                <div className="paymentLeftColumn">
                  {(data && data.bankName && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Ngân hàng</div>
                      <div className="paymentDetailContent">{data.bankName}</div>
                    </div>
                  )) ||
                  ''}

                  {(data && data.ownerName && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Chủ tài khoản</div>
                      <div className="paymentDetailContent">{data.ownerName}</div>
                    </div>
                  )) ||
                  ''}
                  {(data && data.bankBranch && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Tỉnh / Thành phố</div>
                      <div className="paymentDetailContent">
                        {data.bankCity}
                      </div>
                    </div>
                  )) ||
                  ''}
                </div>
                <div className="paymentRightColumn">
                  {(data && data.bankBranch && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Chi nhánh</div>
                      <div className="paymentDetailContent">
                        {data.bankBranch}
                      </div>
                    </div>
                  )) ||
                  ''}

                  {(data && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Số tiền chi trả</div>
                      <div className="paymentDetailContent">
                        {data.amount} VND
                      </div>
                    </div>
                  )) ||
                  ''}

                </div>
              </div>

              <div className="receiverPaymentHeader">Thông tin người nhận</div>
              <div className="receiverPaymentContent">
                <div className="paymentLeftColumn">
                  {(data && data.receiverName && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Người nhận</div>
                      <div className="paymentDetailContent">
                        {data.receiverName}
                      </div>
                    </div>
                  )) ||
                  ''}
                  {(data && data.receiverId && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">
                        CMND/CCCD/HC người nhận
                      </div>
                      <div className="paymentDetailContent">
                        {data.receiverId}
                      </div>
                    </div>
                  )) ||
                  ''}
                </div>
                <div className="paymentRightColumn">
                  {(data && data.receiverIdDate && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Ngày cấp</div>
                      <div className="paymentDetailContent">
                        {data.receiverIdDate}
                      </div>
                    </div>
                  )) ||
                  ''}
                  {(data && data.receiverIdLocation && (
                    <div className="paymentDetail">
                      <div className="paymentDetailHeader">Nơi cấp</div>
                      <div className="paymentDetailContent">
                        {data.receiverIdLocation}
                      </div>
                    </div>
                  )) ||
                  ''}
                </div>
              </div>
            </div>
          )) ||
        ''}
      </div>
    );
  }
}

export default PaymentAssessmentScreen;
