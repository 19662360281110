/**
 ** @class CheckScreen
 */

import React from 'react';
import { Calendar, TextField } from 'papaya-ui';
import './style.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import utils from '../../../../config/utils';
import pathSetting, { ROUTES, } from '../../../../config/ClaimWebsite/pathSetting';
import setFooter from '../../../../store/ClaimWebsite/commonActions/footer';
import { clearData, setData, setLoading } from '../../../../store/ClaimWebsite/commonActions';
import KEYS from '../../../../config/key';
import { iconShowNationalIdModal } from '../../../../config/modal';
import setToast from '../../../../store/ShareStore/actions/toast';



interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface IState {
  data: inputData;
  error: errorField;
}

interface errorField {
  PolicyOwnerNationalId?: string;
  Dob?: string;
}

interface inputData {
  PolicyOwnerNationalId?: string;
  Dob?: Date;
  captcha:string;
}

const mapStateToProps = (state) => ({
  data: state.data,
});

class TrackingScreen extends React.Component<IProps, IState> {
  timeOut;
  
  constructor(props) {
    super(props);
    this.state = {
      data: {
        captcha:''
      },
      error: {},
    };
  
    props.dispatch(clearData());
  }
  
  handleChange = (name, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = data || {};
    newData[name] = value;
    clearTimeout(this.timeOut);
    this.setState({ data: newData }, async () => {
      const flag =
        (name === KEYS.FIELD.POLICY_OWNER_ID
          && (await this.handleNationalId(data[KEYS.FIELD.POLICY_OWNER_ID]))) ||
        false;
      if (!flag) {
        await this.handleLogicAll();
      }
    });
  };
  
  handleNationalId = async (value) => {
    const { t } = this.props;
    const { error }: Readonly<IState> = this.state;
    const newError = error;
    if (!value) {
      delete newError[KEYS.FIELD.POLICY_OWNER_ID];
    } else if ((value && value.length < 8) || (value && value.length > 12)
      || !utils.validate(KEYS.VALIDATE.NONE_SPECIAL_CHAR, value)) {
      newError[KEYS.FIELD.POLICY_OWNER_ID] = t('errors.INFO_INCORRECT');
    } else {
      newError[KEYS.FIELD.POLICY_OWNER_ID] = '';
    }
    this.setState({ error: newError });
    return !!newError[KEYS.FIELD.POLICY_OWNER_ID];
  };
  
  handleLogicAll = async () => {
    const { data } = this.props;
    const newData = _.clone(data);
    const { error }: Readonly<IState> = this.state;
    const newError = error;
    this.hideFooter();
    this.timeOut = await setTimeout(async () => {
      if (this.state.data[KEYS.FIELD.POLICY_OWNER_ID]
        && this.state.data[KEYS.FIELD.DOB] && error[KEYS.FIELD.POLICY_OWNER_ID] === '') {
        if(!newData.postBack)  newData.postBack = {};
        newData.postBack.trackingInitialData = {};
        newData.postBack.trackingInitialData = {
          data: this.state.data,
        };
        this.props.dispatch(setData(newData));
        this.props.dispatch(setLoading(true));
        const response = await ClaimService.trackingHolder(
          this.state.data[KEYS.FIELD.POLICY_OWNER_ID],
          utils.formatDateToStringDate(this.state.data[KEYS.FIELD.DOB])
        );
        if (response) {
          this.props.dispatch(setLoading(false));
          if (response.status === 404) {
            this.props.dispatch(setLoading(false));
            this.props.history.push(ROUTES.productTracking);
          } else if (response.status === 500) {
            this.props.dispatch(setLoading(false));
            this.hideFooter();
          } else if (response.status === 400) {
            this.props.dispatch(setLoading(false));
            this.props.dispatch(
              setToast({
                isShow: true,
                type: 'warning',
                text: response.data && response.data.Errors
                  && response.data.Errors[0].Message,
              })
            );
            this.hideFooter();
          } else if (response.data) {
            if(!newData.postBack)  newData.postBack = {};
            newData.postBack.trackingDetail = {};
            newData.postBack.trackingDetail = {
              data: response,
            };
            this.readyToNext();
          }
        } else {
          this.props.dispatch(setLoading(false));
        }
      }
      this.setState({ error: newError });
    }, 500);
  };
  
  readyToNext = (isNot: boolean = false) => {
    const { footer } = pathSetting()[ROUTES.tracking];
    footer.nextState.disabled = isNot;
    this.props.dispatch(setFooter(footer));
  };
  
  hideFooter = () => {
    const { footer } = pathSetting()[ROUTES.tracking];
    footer.nextState.disabled = true;
    this.props.dispatch(setFooter(footer));
  };
  
  setCaptcha = token => {
    const { data }:Readonly<IState> = this.state;
    data.captcha = token;
    this.setState({
      data
    }, ()=>this.handleLogicAll());
  };
  
  render = () => {
    const { t } = this.props;
    const {
      data, error
    }: Readonly<IState> = this.state;
    return (
      <div className="checkContainer">
        <div className="checkHeader">
          <p>{t('tracking.header')}</p>
        </div>
        <div className="checkContent">
          <TextField
            className="fieldStyle policyId"
            name={KEYS.FIELD.POLICY_OWNER_ID}
            label={t('policy.label.nationalId')}
            placeHolder={t('policy.placeHolder.nationalId')}
            type="text"
            errorMsg={error[KEYS.FIELD.POLICY_OWNER_ID] || ''}
            errorPosition="bottom"
            value={data[KEYS.FIELD.POLICY_OWNER_ID] || ''}
            endButton={iconShowNationalIdModal}
            onChange={(name, value) => this.handleChange(name, value)}
          />
          <Calendar
            className="fieldStyle dob marginBottom20"
            name={KEYS.FIELD.DOB}
            rightShow={true}
            pastBack
            label={t('policy.label.dob')}
            placeHolder={t('policy.placeHolder.dob')}
            errorMsg={error[KEYS.FIELD.DOB]}
            errorPosition="bottom"
            onChange={(name, value) => this.handleChange(name, value)}
            value={data[KEYS.FIELD.DOB]}
          />
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProps)(withTranslation('common')(TrackingScreen));
