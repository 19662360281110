/**
 ** @class Header
 */

import React from 'react';
import { SelectionLanguage } from 'papaya-ui';
import { RouteComponentProps } from 'react-router-dom';
import fwd from '../../../../assets/images/fwd_logo.png';
import headPhone from '../../../../assets/images/headphone.png';
import './style.css';
import i18next from '../../../../translation';
import { ROUTES } from '../../../../config/ClaimWebsite/pathSetting';

interface IState {
  lang:string
}

interface IProps extends RouteComponentProps<string> {

}

export default class Header extends React.PureComponent<IProps, IState> {
  constructor(props:IProps) {
    super(props);
    this.state = {
      lang:'vi'
    };
  }

  render = () => {
    return (
      <div className="header">
        <div className="firstLine">
          <div className="insurerLogo" style={{ cursor: 'pointer' }} role="button" tabIndex={0} onClick={() => this.props.history.push(ROUTES.home)}>
            <img src={fwd} alt="FWD" />
          </div>
          <div className="option">
            <div className="hotLine" tabIndex={0} role="button" onClick={() => window.open('tel:1900055550')}>
              <div className="headphone" style={{ cursor: 'pointer' }}>
                <img alt="headphone" src={headPhone} />
              </div>
              <div className="contact" style={{ cursor: 'pointer' }}>
                <span>1900 055 550</span>
              </div>
            </div>
            
            
            <div className="localizeAndGuild" style={{ display: 'none' }}>
              <SelectionLanguage
                name="Language"
                className="selectLanguage"
                value={this.state.lang}
                label="Selection Language"
                options={[
                  {
                    name: 'Việt Nam',
                    value: 'vi',
                    labelValue:'Vie'
                  },
                ]}
                onChange={(name, value) => {
                  i18next.changeLanguage(
                    'vi'
                    // value
                  );
                  this.setState({ lang:
                  'vi'
                  //   value
                  });
                }}
                defaultValue="Vie"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}
