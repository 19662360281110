import React from 'react';
import './styles.css';
import { TabBar } from 'papaya-ui/dist';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { prefix } from '../../../../config/ClaimPortal/pathSetting';
import { setUnSavedChangeModal } from '../../../../store/ClaimPortal/commonActions';
import { NotificationData } from '../../../../store/ClaimPortal/interface';

interface IProps extends RouteComponentProps<{ id: string, status: string }> {
  breadcrumb: string;
  dispatch: Function;
  unSavedChange: boolean;
  title: string;
  tabBar?: Array<{ name: string; path: string, notiKey: string }>;
  className?: string;
  notification: NotificationData
}

const mapStateToProp = (state) => ({
  unSavedChange: state.unSavedChange,
  notification: state.notification
});

class Header extends React.PureComponent<IProps> {
  componentDidMount() {
    const { params } = this.props.match;
    if (params && params.id === ':id') {
      this.props.history.replace(prefix);
    }
  }
  
  tabBarNavigation = (value) => {
    const { unSavedChange } = this.props;
    var page = 0;
    var path = '';
    if(unSavedChange) {
      this.props.dispatch(setUnSavedChangeModal(
        {
          isShow: true,
          callback: () => {
            if((this.props.location.state as any) && (this.props.location.state as any).sessionPage != null)
            {
              page = (this.props.location.state as any).sessionPage;
              path = (this.props.location.state as any).path;
              this.props.history.replace({
                pathname: value,
                state: {
                  sessionPage: page,
                  path: path
                }
              });
            }
            else {
              this.props.history.replace(value);
            }
          }
        }
      ));
    } else {
      if((this.props.location.state as any) && (this.props.location.state as any).sessionPage != null)
      {
        page = (this.props.location.state as any).sessionPage;
        path = (this.props.location.state as any).path;
        this.props.history.replace({
          pathname: value,
          state: {
            sessionPage: page,
            path: path
          }
        });
      }
      else {
        this.props.history.replace(value);
      }
      
    }
  };

  render() {
    const { breadcrumb, title, tabBar, match, className, notification } = this.props;
    const { params } = match;
    return (
      <div className={`portalHeader ${className}`}>
        <div className="headerMain">
          <div className="headerContent">
            <h4>
              {breadcrumb} &nbsp;
              <span style={{ color: '#858B9A' }}>
                {params && params.id && `/ ${params.id}`}
              </span>
            </h4>
            <h2>{title}</h2>
          </div>
          <div className="notiBtn">
            <div className="notiNum">{(_.sum(_.values(notification)) > 100 && '100+') || _.sum(_.values(notification))}</div>
          </div>
        </div>
        {(tabBar && tabBar.length && (
          <TabBar
            name="TabBar"
            className="headerTabBar"
            current={this.props.history.location.pathname}
            options={tabBar.map((tab) => ({
              name: tab.name,
              value:
                (params.id && tab.path.replace(':id', params.id)) ||
                (params.status && tab.path.replace(':status', params.status)) || tab.path,
              notification: (notification && notification[tab.notiKey]) || ''
            }))}
            onChange={(name, value) => this.tabBarNavigation(value)}
          />
        )) || <div className="blankTabBar" />}
      </div>
    );
  }
}

export default connect(mapStateToProp)(Header);
