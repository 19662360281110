import { SET_TOKEN } from '../types';
import { AuthAction } from '../interface';

function setToken(token: string, expiredDate: number): AuthAction {
  return {
    type: SET_TOKEN,
    token,
    expiredDate,
  };
}

function clearToken(): AuthAction {
  return {
    type: SET_TOKEN,
    token: '',
    expiredDate: 0,
  };
}

export { setToken, clearToken };
