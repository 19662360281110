import { CLEAR_DATA, DATA } from '../types';
import { SetDataAction } from '../../../config/interface';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setData(data: any): SetDataAction {
  return {
    type: DATA,
    data,
  };
}

export function clearData(): SetDataAction {
  return {
    type: CLEAR_DATA,
  };
}
