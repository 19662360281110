import React, { Fragment } from 'react';
import './styles.css';
import { RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import caolan from 'async';
import _ from 'lodash';
import {
  Button,
  CalendarRange,
  Modal,
  Selection,
  SelectionStatus,
} from 'papaya-ui';
import { connect } from 'react-redux';
import {
  AssessmentClaim,
  ClaimCase,
  HistoryClaimCase,
  Policy,
  PolicyClaimCase,
} from '../../../../config/interface';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import KEYS from '../../../../config/key';
import setToast from '../../../../store/ShareStore/actions/toast';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import utils from '../../../../config/utils';

interface IProps extends RouteComponentProps<Routedata>, WithTranslation {
  dispatch: Function;
}

interface Routedata {
  id?: string;
  caseId?: string;
}

interface IState {
  data: dataType;
  showLetterChoice: boolean;
  showLetterModal: boolean;
  showLetterKey: string;
  startEndDate?: { from: Date; to: Date };
  showLetterLabel: string;
  isChanged: boolean;
  firstTimeInit: number;
  editHospitalCase: boolean;
  editICUCase: boolean;
  modalShow: boolean;
  messageCheckPayment: string;
  editSurgeryCase: boolean;
  editMajorSurgeryCase: boolean;
  hospitalCaseValue: string | number;
  iCUCaseValue: string | number;
  surgeryCaseValue: string | number;
  majorSurgeryCaseValue: string | number;
  pendingNotices: string[];
  isInWaitingTime: boolean;
  isLapsedTime: boolean;
  isReinstated: boolean;
}

interface documentData {
  name?: string;
  value?: string;
}

type dataType = {
  cases: ClaimCase;
  declineCodes: { name: string; value: string }[];
  declineReason?: {
    declineCode?: number;
    declineDescription?: string;
    updatePolicyStatus?: string;
  };
  assessment: AssessmentClaim;
  caseHistory: HistoryClaimCase[];
  policies: PolicyClaimCase;
};

class RequestCaseAssessmentScreen extends React.Component<IProps, IState> {
  popupMail;

  private noticeFocus: number = 0;

  private toggle: boolean = false;

  constructor(props) {
    super(props);
    this.state = {
      data: {
        cases: {},
        policies: {},
        caseHistory: [],
        assessment: {},
        declineCodes: [],
      },
      pendingNotices: [''],
      showLetterModal: false,
      showLetterKey: '',
      showLetterLabel: '',
      showLetterChoice: false,
      firstTimeInit: 0,
      isChanged: false,
      messageCheckPayment: '',
      modalShow: false,
      editHospitalCase: false,
      editICUCase: false,
      editSurgeryCase: false,
      editMajorSurgeryCase: false,
      hospitalCaseValue: 0,
      iCUCaseValue: 0,
      surgeryCaseValue: 0,
      majorSurgeryCaseValue: 0,
      isInWaitingTime: false,
      isLapsedTime: false,
      isReinstated: false,
    };
  }

  componentDidMount = () => {
    this.getFirstLoad();
  };

  getFirstLoad = async () => {
    await caolan.auto(
      {
        getAssessments: (callback) => {
          this.getAssessment().then((data) => {
            callback(null, data);
          });
        },
        getCases: (callback) => {
          this.getCases().then((data) => {
            callback(null, data);
          });
        },
        declineCodes: (callback) => {
          ClaimService.getMetadata(KEYS.LIST.DECLINE_CODE).then((data) => {
            callback(null, data);
          });
        },
        getHistories: (callback) => {
          this.getHistory().then((data) => {
            callback(null, data);
          });
        },
        getPolicies: (callback) => {
          this.getPolicies().then((data) => {
            callback(null, data);
          });
        },
      },
      (error, results) => {
        const { data }: Readonly<IState> = this.state;
        const newData = _.clone(data);
        newData[KEYS.FIELD.ASSESSMENT] = results.getAssessments;
        newData[KEYS.FIELD.CLAIM_CASE_HISTORY] = results.getHistories;
        newData[KEYS.FIELD.CLAIM_CASE_POLICY] = results.getPolicies;
        newData[KEYS.FIELD.CLAIM_CASE] = results.getCases;
        newData[KEYS.LIST.DECLINE_CODES] = results.declineCodes;
        newData[KEYS.FIELD.DECLINE_REASON] = {
          [KEYS.FIELD.DECLINE_CODE]:
            (newData[KEYS.FIELD.CLAIM_CASE] &&
              newData[KEYS.FIELD.CLAIM_CASE][KEYS.FIELD.DECLINE_CODE]) ||
            '',
          [KEYS.FIELD.DECLINE_DESCRIPTION]:
            (newData[KEYS.FIELD.CLAIM_CASE] &&
              newData[KEYS.FIELD.CLAIM_CASE][KEYS.FIELD.DECLINE_DESCRIPTION]) ||
            '',
          [KEYS.FIELD.UPDATE_POLICY_STATUS]:
            (newData[KEYS.FIELD.CLAIM_CASE] &&
              newData[KEYS.FIELD.CLAIM_CASE][
                KEYS.FIELD.UPDATE_POLICY_STATUS
              ]) ||
            '',
        };
        results.getCases &&
          results.getCases.policies.forEach((policy) => {
            this.handleChange(policy.value, policy);
          });
        this.setState({ data: newData });
      }
    );
  };

  getCases = async (isNotReturn: boolean = false) => {
    const { caseId } = this.props.match.params;

    if (caseId) {
      const response = await ClaimPortalService.getClaimCase(caseId);

      if (response) {
        if (isNotReturn) {
          const { data }: Readonly<IState> = this.state;
          const newData = _.clone(data);
          newData.cases = response;
          this.setState({
            data: newData,
          });
        } else {
          return response;
        }
      }
    }
    return undefined;
  };

  getAssessment = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const response = await ClaimPortalService.getAssessmentClaim(id);
      if (response && !response.status) {
        return response;
      }
    }
    return undefined;
  };

  getHistory = async () => {
    const { caseId } = this.props.match.params;
    if (caseId) {
      const response = await ClaimPortalService.getHistoryClaimCase(caseId);
      if (response && !response.status) {
        return response;
      }
    }
    return undefined;
  };

  getPolicies = async () => {
    const { caseId } = this.props.match.params;
    if (caseId) {
      const response = await ClaimPortalService.getPoliciesClaimCase(caseId);

      if (response && !response.status) {
        this.setState({
          isInWaitingTime: response[KEYS.CLAIM_CASE_FIELD.IS_IN_WAITING_TIME],
          isLapsedTime: response[KEYS.CLAIM_CASE_FIELD.IS_LAPSED],
          isReinstated: response[KEYS.CLAIM_CASE_FIELD.IS_REINSTATED],
        });
        return response;
      }
    }
    return undefined;
  };

  editHandler = (policy) => {
    const {
      editHospitalCase,
      editICUCase,
      editSurgeryCase,
      editMajorSurgeryCase,
    }: Readonly<IState> = this.state;
    if (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_SURGERY, 0)) {
      this.setState({
        editSurgeryCase: !editSurgeryCase,
      });
    }
    if (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0)) {
      this.setState({
        editMajorSurgeryCase: !editMajorSurgeryCase,
      });
    }
    if (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_HOSPITAL, 0)) {
      this.setState({
        editHospitalCase: !editHospitalCase,
      });
    }
    if (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_ICU, 0)) {
      this.setState({
        editICUCase: !editICUCase,
      });
    }
  };

  isEdit = (policy) => {
    const {
      editHospitalCase,
      editICUCase,
      editSurgeryCase,
      editMajorSurgeryCase,
    }: Readonly<IState> = this.state;
    return (
      (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
        editSurgeryCase) ||
      (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
        editMajorSurgeryCase) ||
      (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
        editHospitalCase) ||
      (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_ICU, 0) && editICUCase)
    );
  };

  changeStatus = (status) => {
    const { data }: Readonly<IState> = this.state;
    data[KEYS.FIELD.CLAIM_CASE].status = status;
    this.setState({
      data,
    });
  };

  handleChange = (value, policy) => {
    const { firstTimeInit }: Readonly<IState> = this.state;
    if (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_SURGERY, 0)) {
      this.setState({
        surgeryCaseValue: value,
        majorSurgeryCaseValue: 0,
      });
    }
    if (
      policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
      value !== 0
    ) {
      this.setState({
        majorSurgeryCaseValue: value,
        surgeryCaseValue: 0,
      });
    }
    if (
      policy.riderDetailCode === parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
      value !== 0
    ) {
      this.setState({
        hospitalCaseValue: value,
      });
    }
    if (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_ICU, 0)) {
      this.setState({
        iCUCaseValue: value,
      });
    }
    if (firstTimeInit > 3) {
      this.setState({
        isChanged: true,
      });
    } else {
      this.setState({
        firstTimeInit: firstTimeInit + 1,
      });
    }
  };

  policyValue = (policy) => {
    const {
      surgeryCaseValue,
      majorSurgeryCaseValue,
      hospitalCaseValue,
      iCUCaseValue,
    }: Readonly<IState> = this.state;
    if (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_SURGERY, 0)) {
      return surgeryCaseValue;
    }
    if (policy.riderDetailCode === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0)) {
      return majorSurgeryCaseValue;
    }
    if (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_HOSPITAL, 0)) {
      return hospitalCaseValue;
    }
    if (policy.riderDetailCode === parseInt(KEYS.DAYS_IN_ICU, 0)) {
      return iCUCaseValue;
    }
    return 0;
  };

  checkPaymentClaimCase = async () => {
    const { caseId } = this.props.match.params;
    const { t } = this.props;
    const { data }: Readonly<IState> = this.state;
    if (caseId) {
      const response = await ClaimPortalService.checkPaymentClaimCase(caseId);
      if (response && response.isPaid) {
        data[KEYS.FIELD.CLAIM_CASE].status =
          KEYS.STATUS_OPTIONS_CASE().PAYMENT.value;
        this.setState({
          data,
          modalShow: true,
          messageCheckPayment: t('portal.modal.check_payment.success_message'),
        });
      } else if (response && response.Errors) {
        this.setState({
          modalShow: true,
          messageCheckPayment: response.Errors[0].Message,
        });
      } else {
        this.setState({
          modalShow: true,
          messageCheckPayment: t('portal.modal.check_payment.fail_message'),
        });
      }
    }
  };

  updateDetailCase = async (caseId, policy: Policy, value) => {
    const { t } = this.props;
    const { isChanged }: Readonly<IState> = this.state;
    if (isChanged) {
      const valueData = {
        value: parseInt(value, 0),
        partialHeading: policy.partialHeading,
        partialReason: policy.partialReason,
      };
      const response = await ClaimPortalService.updateDetailCase(
        caseId,
        policy.id,
        valueData
      );
      if (response.errors) {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: t('portal.assessment.action.failDetail'),
          })
        );
      } else {
        await this.getCases(true);
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: t('portal.assessment.action.successDecision'),
          })
        );
      }
      this.setState({
        isChanged: false,
      });
    }
  };

  decisionCase = async () => {
    const { t } = this.props;
    const { id, caseId } = this.props.match.params;
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    const caseData = {
      status: data[KEYS.FIELD.CLAIM_CASE].status,
      declineCode:
        (data[KEYS.FIELD.DECLINE_REASON] &&
          data[KEYS.FIELD.DECLINE_REASON][KEYS.FIELD.DECLINE_CODE]) ||
        null,
      declineDescription:
        (data[KEYS.FIELD.DECLINE_REASON] &&
          data[KEYS.FIELD.DECLINE_REASON][KEYS.FIELD.DECLINE_DESCRIPTION]) ||
        '',
      updatePolicyStatus:
        (data[KEYS.FIELD.DECLINE_REASON] &&
          data[KEYS.FIELD.DECLINE_REASON][KEYS.FIELD.UPDATE_POLICY_STATUS]) ||
        '',
    };
    if (id) {
      const response = await ClaimPortalService.updateAssessmentClaimCases(
        id,
        data[KEYS.FIELD.CLAIM_CASE].id,
        caseData
      );
      const cases = await ClaimPortalService.getAssessmentClaimCases(id);
      if (response && response.Errors) {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: response.Errors[0].Message,
          })
        );
      } else {
        newData.cases = _.find(cases, { id: caseId });
        newData[KEYS.FIELD.DECLINE_REASON] = {
          [KEYS.FIELD.DECLINE_CODE]:
            newData[KEYS.FIELD.CLAIM_CASE][KEYS.FIELD.DECLINE_CODE],
          [KEYS.FIELD.DECLINE_DESCRIPTION]:
            newData[KEYS.FIELD.CLAIM_CASE][KEYS.FIELD.DECLINE_DESCRIPTION],
        };
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: t('portal.assessment.action.successDecision'),
          })
        );
        this.setState({ data });
      }
    }
    return 0;
  };

  onChangeReason = (type: string, policy: Policy, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    const newPolicy = _.find(data[KEYS.FIELD.CLAIM_CASE].policies, {
      id: policy.id,
    });
    const indexPolicy = _.findIndex(data[KEYS.FIELD.CLAIM_CASE].policies, {
      id: policy.id,
    });
    if (type === 'heading') {
      newPolicy.partialHeading = value;
    }
    if (type === 'reasons') {
      newPolicy.partialReason = value;
    }
    newData[KEYS.FIELD.CLAIM_CASE].policies[indexPolicy] = newPolicy;
    this.setState({
      data: newData,
      isChanged: true,
    });
  };

  handleLetterPush = async (letterType: {
    key: string;
    value: number;
    label: string;
  }) => {
    this.toggle = true;
    this.setState({
      showLetterModal: true,
      showLetterKey: letterType.key,
      showLetterLabel: letterType.label,
    });
    this.setState({ showLetterChoice: false }, () => {
      this.toggle = false;
    });
  };

  publishPendingLetter = async (type: number, notices?: string[]) => {
    const { data, startEndDate }: Readonly<IState> = this.state;
    const { t } = this.props;
    if (startEndDate) {
      const response = await ClaimPortalService.postClaimCaseLetters(
        data[KEYS.FIELD.CLAIM_CASE].id,
        {
          letterType: type,
          notices,
          startEndDate: utils.formatDateRangeToStringDate(startEndDate),
        }
      );
      if (response.isSuccess) {
        this.setState({
          showLetterModal: false,
          pendingNotices: [''],
          startEndDate: undefined,
        });
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: t('portal.assessment.action.successDecision'),
          })
        );
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: 'Có gì đó sai sai ?',
          })
        );
      }
    } else {
      this.props.dispatch(
        setToast({
          isShow: true,
          type: 'danger',
          text: 'Start end date không được bỏ trống !',
        })
      );
    }
  };

  handleCleanNotices = (notices: string[]) => {
    return notices.filter((notice) => notice && notice !== '');
  };

  handleChangeNotices = (notice: string, index: number) => {
    this.noticeFocus = index;
    const { pendingNotices }: Readonly<IState> = this.state;
    const newPendingNotices = _.clone(pendingNotices);
    newPendingNotices[index] = notice;
    this.setState({
      pendingNotices: newPendingNotices,
    });
  };

  addNewNotices = () => {
    const { pendingNotices }: Readonly<IState> = this.state;
    const newPendingNotices = _.clone(pendingNotices);
    newPendingNotices.push('');
    this.setState({
      pendingNotices: newPendingNotices,
    });
  };

  updateSelectList = (
    list: Array<{ name: string; value: number }>,
    value: number
  ) => {
    return _.filter(list, (n) => {
      return n.value !== value;
    });
  };

  mapHistory = (array: Array<string>) => {
    const lastItem: string = array[array.length - 1];
    array.pop();
    array.unshift(lastItem);
    return array;
  };

  mapHeaderHistory = (header: string) => {
    return KEYS.HISTORY_HEADER[header] || header;
  };

  mapHeaderLifeAssured = (header: string) => {
    return KEYS.LIFE_ASSURED_HEADER[header] || header;
  };

  render() {
    const { t } = this.props;
    const {
      data,
      modalShow,
      messageCheckPayment,
      editHospitalCase,
      editICUCase,
      editSurgeryCase,
      editMajorSurgeryCase,
      showLetterChoice,
      pendingNotices,
      showLetterModal,
      showLetterKey,
      showLetterLabel,
      startEndDate,
      isInWaitingTime,
      isLapsedTime,
      isReinstated,
    }: Readonly<IState> = this.state;
    return (
      <div className="assessmentCaseScreenContainer">
        <Modal
          name="globalModal"
          className="globalModal"
          value={modalShow}
          closeButton={true}
          onClose={() => {
            this.setState({ modalShow: false });
          }}
        >
          <div className="paymentModal">
            <div className="paymentHeader">
              {t('portal.modal.check_payment.header')}
            </div>
            <div className="paymentDescription">{messageCheckPayment}</div>
            <div className="buttonPaymentModal">
              <Button
                onClick={() => {
                  this.setState({ modalShow: false });
                }}
              >
                {t('portal.modal.check_payment.button')}
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className="modalPopup"
          value={showLetterModal}
          onClose={() => {
            this.setState({
              showLetterModal: false,
              pendingNotices: [''],
              startEndDate: undefined,
            });
          }}
          closeButton={true}
        >
          <div className="formPopup">
            <div className="formHeader">
              <h1>{showLetterLabel}</h1>
            </div>
            <CalendarRange
              className="fieldCalendarRange noneTransform"
              calenderClassName="calendarField"
              name="startEndDate"
              label=""
              labelFrom="Ngày bắt đầu"
              labelTo="Ngày kết thúc"
              placeHolderFrom="Ngày bắt đầu"
              placeHolderTo="Ngày kết thúc"
              bottomShow={true}
              blockFrom={undefined}
              blockTo={undefined}
              errorPosition="right"
              pastBack={false}
              onChange={(name, value) => {
                this.setState({ startEndDate: value });
              }}
              value={startEndDate}
            />
            {((showLetterKey === KEYS.FIELD.PENDING_WRONG_DOCS.key ||
              showLetterKey === KEYS.FIELD.SUSPENSION_DOCS.key) && (
              <>
                <h2>Notices</h2>
                {(pendingNotices &&
                  pendingNotices.length &&
                  pendingNotices.map((pending, index) => (
                    <Fragment key={pending + Math.random()}>
                      <p>#{index + 1}</p>
                      <input
                        type="text"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={this.noticeFocus === index}
                        placeholder={`Input the notice ${index + 1}...`}
                        value={pendingNotices[index]}
                        onChange={(e) =>
                          this.handleChangeNotices(e.target.value, index)
                        }
                      />
                    </Fragment>
                  ))) ||
                  ''}
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                <span
                  role="button"
                  tabIndex={0}
                  onClick={() => this.addNewNotices()}
                >
                  ＋Thêm dòng
                </span>
                <div
                  role="button"
                  tabIndex={0}
                  className="updateButton"
                  onClick={() =>
                    this.publishPendingLetter(
                      (showLetterKey === KEYS.FIELD.PENDING_WRONG_DOCS.key &&
                        KEYS.FIELD.PENDING_WRONG_DOCS.value) ||
                        KEYS.FIELD.SUSPENSION_DOCS.value,
                      this.handleCleanNotices(pendingNotices)
                    )
                  }
                >
                  Cập nhật
                </div>
              </>
            )) || (
              <div
                role="button"
                tabIndex={0}
                className="updateButton"
                onClick={() =>
                  this.publishPendingLetter(
                    (showLetterKey === KEYS.FIELD.PENDING_NO_REPLY.key &&
                      KEYS.FIELD.PENDING_NO_REPLY.value) ||
                      KEYS.FIELD.PENDING_LACK_DOCS.value
                  )
                }
              >
                Cập nhật
              </div>
            )}
          </div>
        </Modal>

        <div className="mainContainer">
          <div className="historyContainer">
            <table>
              <thead>
                <tr>
                  {(data[KEYS.FIELD.CLAIM_CASE_HISTORY] &&
                    data[KEYS.FIELD.CLAIM_CASE_HISTORY].length &&
                    this.mapHistory(
                      Object.keys(
                        _.omit(
                          this.state.data[KEYS.FIELD.CLAIM_CASE_HISTORY][0],
                          'id',
                          'policyNumber',
                          'laId'
                        )
                      )
                    ).map((header) => <th key={header}>{header}</th>)) || (
                    <td>Không có lịch sử claim</td>
                  )}
                </tr>
              </thead>
              <tbody>
                {(data[KEYS.FIELD.CLAIM_CASE_HISTORY] &&
                  data[KEYS.FIELD.CLAIM_CASE_HISTORY].length &&
                  data[KEYS.FIELD.CLAIM_CASE_HISTORY].map((rowData) => (
                    <tr key={`row${Math.random()}`}>
                      {rowData &&
                        Object.keys(rowData) &&
                        Object.keys(rowData).length &&
                        this.mapHistory(
                          Object.keys(
                            _.omit(rowData, 'id', 'policyNumber', 'laId')
                          )
                        ).map((key) => <td key={key}>{rowData[key]}</td>)}
                    </tr>
                  ))) || <tr />}
              </tbody>
            </table>
          </div>
          <div
            className={`infoContainer ${
              (data[KEYS.FIELD.PARTIAL_STATUS] && 'expand') || ''
            }`}
          >
            {/* policy */}
            <div className="policyContainer">
              <div className="policyInfoContainer">
                <p className="headerInfo">Thông tin hợp đồng</p>
                {(data[KEYS.FIELD.CLAIM_CASE_POLICY] && (
                  <div className="policyInfoContent">
                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_EFFECTIVE_DATE
                        ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.POLICY_EFFECTIVE_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_EFFECTIVE_DATE
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_LAPSED_DATE
                        ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.POLICY_LAPSED_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_LAPSED_DATE
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_REINSTATED_DATE
                        ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.POLICY_REINSTATED_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_REINSTATED_DATE
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_STATUS_CODE
                        ] !== null &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.POLICY_STATUS_CODE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_STATUS_CODE
                        ] || '...'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COVERAGE_EFFECTIVE_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {
                          data[KEYS.FIELD.CLAIM_CASE_POLICY][
                            KEYS.CLAIM_CASE_FIELD.COVERAGE_EFFECTIVE_DATE
                          ]
                        }
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COVERAGE_EXPIRY_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_EXPIRY_DATE
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_LAPSED_DATE
                        ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COVERAGE_LAPSED_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_LAPSED_DATE
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_REINSTATED_DATE
                        ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COVERAGE_REINSTATED_DATE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_REINSTATED_DATE
                        ] || '...'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COMPONENT_CODE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COMPONENT_CODE
                        ] || '...'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COVERAGE_SUM_ASSURED
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_SUM_ASSURED
                        ] || '...'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.COVERAGE_STATUS_CODE
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.COVERAGE_STATUS_CODE
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.EXCLUSION_INFO
                        ] !== null &&
                          data[KEYS.FIELD.CLAIM_CASE_POLICY][
                            KEYS.CLAIM_CASE_FIELD.EXCLUSION_INFO
                          ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.EXCLUSION_INFO
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.EXCLUSION_INFO
                        ] || '...'}
                      </p>
                    </div>

                    <div
                      className={[
                        'policyInfoBlock',
                        (data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_NOTES
                        ] !== null &&
                          data[KEYS.FIELD.CLAIM_CASE_POLICY][
                            KEYS.CLAIM_CASE_FIELD.POLICY_NOTES
                          ] !== '' &&
                          'highlight') ||
                          '',
                      ].join(' ')}
                    >
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.POLICY_NOTES
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.POLICY_NOTES
                        ] || '...'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.WATCHING_LIST
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.WATCHING_LIST_AGENT
                        ]
                          ? 'Có'
                          : 'Không'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.HOSPITAL_WATCHING_LIST
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.HOSPITAL_WATCHING_LIST
                        ]
                          ? 'Có'
                          : 'Không'}
                      </p>
                    </div>

                    <div className="policyInfoBlock">
                      <p className="attributeLabel">
                        {this.mapHeaderHistory(
                          KEYS.HISTORY_HEADER.CLIENT_WATCH_LIST
                        )}
                      </p>
                      <p className="attributeContent">
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][
                          KEYS.CLAIM_CASE_FIELD.CLIENT_WATCH_LIST
                        ]
                          ? 'Có'
                          : 'Không'}
                      </p>
                    </div>

                    <div className="policyInfoBlock special">
                      <p className="attributeLabel">Thông tin lưu ý</p>
                      <div className="notePolicy">
                        <div className="flag">
                          <input
                            id="isInWaitingTime"
                            name="isInWaitingTime"
                            type="checkbox"
                            className="flagCheckbox"
                            disabled={!isInWaitingTime}
                            checked={isInWaitingTime}
                            readOnly={true}
                          />

                          <label
                            htmlFor="isInWaitingTime"
                            className={`flagLabel ${
                              (!isInWaitingTime && 'disabled') || ''
                            }`}
                          >
                            {this.mapHeaderHistory(
                              KEYS.HISTORY_HEADER.IS_IN_WAITING_TIME
                            )}
                          </label>
                        </div>

                        <div className="flag">
                          <input
                            id="isLapsed"
                            name="isLapsed"
                            type="checkbox"
                            className="flagCheckbox"
                            disabled={!isLapsedTime}
                            checked={isLapsedTime}
                            readOnly={true}
                          />

                          <label
                            htmlFor="isLapsed"
                            className={`flagLabel ${
                              (!isLapsedTime && 'disabled') || ''
                            }`}
                          >
                            {this.mapHeaderHistory(
                              KEYS.HISTORY_HEADER.IS_LAPSED
                            )}
                          </label>
                        </div>

                        <div className="flag">
                          <input
                            id="isReinstated"
                            name="isReinstated"
                            type="checkbox"
                            className="flagCheckbox"
                            disabled={!isReinstated}
                            checked={isReinstated}
                            readOnly={true}
                          />

                          <label
                            htmlFor="isReinstated"
                            className={`flagLabel ${
                              (!isReinstated && 'disabled') || ''
                            }`}
                          >
                            {this.mapHeaderHistory(
                              KEYS.HISTORY_HEADER.IS_REINSTATED
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )) ||
                  ''}
              </div>
              <div className="laInfoContainer">
                <p className="headerInfo">Thông tin người được bảo hiểm</p>
                {data[KEYS.FIELD.CLAIM_CASE_POLICY] &&
                  Object.keys(data[KEYS.FIELD.CLAIM_CASE_POLICY]).length &&
                  Object.keys(
                    _.omit(
                      data[KEYS.FIELD.CLAIM_CASE_POLICY],
                      'coverageEffectiveDate',
                      'coverageExpiryDate',
                      'coverageLapsedDate',
                      'coveragePremium',
                      'coverageReinstatedDate',
                      'coverageStatusCode',
                      'coverageSumAssured',
                      'exclusionInfo',
                      'isInWaitingTime',
                      'isLapsed',
                      'isReinstated',
                      'poDob',
                      'poMaritalStatus',
                      'poName',
                      'poNationalId',
                      'poSex',
                      'policyNotes',
                      'policyNumber',
                      'poPhone',
                      'watchListAgent',
                      'policyEffectiveDate',
                      'policyLapsedDate',
                      'policyReinstatedDate',
                      'watchListHospital'
                    )
                  ).map((key) => (
                    <div key={key} className="row">
                      <div>{this.mapHeaderLifeAssured(key)}</div>
                      <div>
                        {data[KEYS.FIELD.CLAIM_CASE_POLICY][key] || '...'}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="claimCase">
              <div className="claimCaseHeader">
                <div className="claimCaseId">
                  #{data[KEYS.FIELD.CLAIM_CASE].claimReference}
                </div>
                <div className="sendMailContainer" ref={this.popupMail}>
                  <div
                    role="button"
                    tabIndex={0}
                    onBlur={() => {
                      if (!this.toggle) {
                        this.setState({ showLetterChoice: false });
                      }
                    }}
                    className={`popupContainer ${
                      (showLetterChoice && 'show') || ''
                    }`}
                  >
                    <ul>
                      <li>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            this.handleLetterPush(KEYS.FIELD.PENDING_NO_REPLY);
                          }}
                        >
                          Pending No Reply
                        </div>
                      </li>
                      <li>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            this.handleLetterPush(KEYS.FIELD.PENDING_LACK_DOCS);
                          }}
                        >
                          Pending Lack Of Docs
                        </div>
                      </li>
                      <li>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            this.handleLetterPush(
                              KEYS.FIELD.PENDING_WRONG_DOCS
                            );
                          }}
                        >
                          Pending Wrong Docs
                        </div>
                      </li>
                      <li>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            this.handleLetterPush(KEYS.FIELD.SUSPENSION_DOCS);
                          }}
                        >
                          Pending Suspension Docs
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="sendMail"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.setState({
                        showLetterChoice: !showLetterChoice,
                      });
                    }}
                  >
                    <span aria-label="mail" role="img" className="mailIcon">
                      ✉️ &nbsp;&nbsp;
                    </span>
                    <span>Soạn thư</span>
                  </div>
                </div>
              </div>
              <div className="checkBoxClaim">
                {(data.cases[KEYS.FIELD.PARTIAL_STATUS] && (
                  <span aria-label="partial" role="img" className="green">
                    ◉&nbsp;&nbsp;&nbsp;&nbsp;Partial
                  </span>
                )) || (
                  <span aria-label="partial" role="img">
                    ◯&nbsp;&nbsp;&nbsp;&nbsp;Partial
                  </span>
                )}
                <div
                  className={`checkPaymentClaim ${
                    (data[KEYS.FIELD.CLAIM_CASE].status ===
                      KEYS.STATUS_OPTIONS_CASE().PAYMENT.value &&
                      'disabled') ||
                    ''
                  }`}
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    if (
                      data[KEYS.FIELD.CLAIM_CASE].status ===
                      KEYS.STATUS_OPTIONS_CASE().PAYMENT.value
                    ) {
                      e.preventDefault();
                    } else {
                      this.checkPaymentClaimCase();
                    }
                  }}
                >
                  <span aria-label="payment" role="img">
                    💳&nbsp;&nbsp;&nbsp;Kiểm tra thanh toán
                  </span>
                </div>
              </div>
              {data[KEYS.FIELD.CLAIM_CASE] &&
                data[KEYS.FIELD.CLAIM_CASE].policies &&
                data[KEYS.FIELD.CLAIM_CASE].policies.map((policy) => {
                  return (
                    <Fragment key={policy.id}>
                      <div>
                        <div className="policyPartialContainer">
                          {((policy.partialReason || policy.partialHeading) && (
                            <span
                              aria-label="partial"
                              role="img"
                              className="green"
                            >
                              ◉
                            </span>
                          )) ||
                            ''}
                        </div>
                        <div
                          style={{
                            overflow: 'hidden',
                            maxWidth: 250,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {(policy.riderDetailCode ===
                            parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                            'Tổng thời gian nằm viện') ||
                            (policy.riderDetailCode ===
                              parseInt(KEYS.DAYS_IN_ICU, 0) &&
                              'Nằm viện ICU') ||
                            (policy.riderDetailCode ===
                              parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                              'Phẫu thuật') ||
                            (policy.riderDetailCode ===
                              parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                              'Đại phẫu')}
                        </div>
                        <div>
                          {policy.value}
                          {(policy.riderDetailCode !==
                            parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                            policy.riderDetailCode !==
                              parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                            ' ngày') ||
                            ' lần'}
                        </div>
                        <div>{policy.amount}</div>
                        <div>
                          <span
                            role="button"
                            tabIndex={0}
                            className={`editAction ${
                              (this.isEdit(policy) && 'done') || 'edit'
                            }`}
                            onClick={() => {
                              if (!this.isEdit(policy)) {
                                this.editHandler(policy);
                              } else {
                                this.updateDetailCase(
                                  data[KEYS.FIELD.CLAIM_CASE].id,
                                  policy,
                                  this.policyValue(policy)
                                ).then();
                                this.editHandler(policy);
                              }
                            }}
                          >
                            {(this.isEdit(policy) && 'Xong') || 'Sửa'}
                          </span>
                        </div>
                      </div>
                      {(((policy.riderDetailCode ===
                        parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                        editHospitalCase) ||
                        (policy.riderDetailCode ===
                          parseInt(KEYS.DAYS_IN_ICU, 0) &&
                          editICUCase) ||
                        (policy.riderDetailCode ===
                          parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                          editSurgeryCase) ||
                        (policy.riderDetailCode ===
                          parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                          editMajorSurgeryCase)) && (
                        <div className="reasonContainer">
                          <div className="isPartialContainer">
                            <h3>Partial heading and reasons</h3>
                            <div className="question">
                              <p>Heading</p>
                              <input
                                placeholder="Nhập heading..."
                                value={policy.partialHeading || ''}
                                onChange={(e) =>
                                  this.onChangeReason(
                                    'heading',
                                    policy,
                                    e.target.value
                                  )
                                }
                              />
                              <p>Reason</p>
                              <textarea
                                placeholder="Nhập reasons..."
                                value={policy.partialReason || ''}
                                onChange={(e) =>
                                  this.onChangeReason(
                                    'reasons',
                                    policy,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )) ||
                        ''}
                    </Fragment>
                  );
                })}
              <div className="informationDetail">
                <div className="informationTitle ">Tổng cộng</div>
                <div className="informationMoney">
                  {data[KEYS.FIELD.CLAIM_CASE].sumAssured} VND
                </div>
              </div>
              <div className="informationDetail">
                <SelectionStatus
                  name={data[KEYS.FIELD.CLAIM_CASE].id}
                  className="selectionStatusClaimCase"
                  // @ts-ignore
                  value={data[KEYS.FIELD.CLAIM_CASE].status}
                  label="Selection Status"
                  // @ts-ignore
                  options={_.values(KEYS.STATUS_OPTIONS_CASE())}
                  onChange={(name, value) => this.changeStatus(value)}
                  // @ts-ignore
                  selectList={this.updateSelectList(
                    _.values(KEYS.STATUS_SELECTION_CASE()),
                    data[KEYS.FIELD.CLAIM_CASE].status
                  )}
                />
                <div
                  className={`updateStatusButton ${
                    ((data[KEYS.FIELD.CLAIM_CASE].status ===
                      KEYS.STATUS_OPTIONS_CASE().DECLINE_BY_ASSESSOR.value ||
                      data[KEYS.FIELD.CLAIM_CASE].status ===
                        KEYS.STATUS_OPTIONS_CASE().DECLINE_BY_MANAGER.value) &&
                      (!data[KEYS.FIELD.DECLINE_REASON] ||
                        (data[KEYS.FIELD.DECLINE_REASON] &&
                          (!data[KEYS.FIELD.DECLINE_REASON][
                            KEYS.FIELD.DECLINE_CODE
                          ] ||
                            !data[KEYS.FIELD.DECLINE_REASON][
                              KEYS.FIELD.DECLINE_DESCRIPTION
                            ]))) &&
                      'disabled') ||
                    (data[KEYS.FIELD.CLAIM_CASE].status ===
                      KEYS.STATUS_OPTIONS_CASE().PAYMENT.value &&
                      'disabled') ||
                    ''
                  }`}
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    if (
                      ((data[KEYS.FIELD.CLAIM_CASE].status ===
                        KEYS.STATUS_OPTIONS_CASE().DECLINE_BY_ASSESSOR.value ||
                        data[KEYS.FIELD.CLAIM_CASE].status ===
                          KEYS.STATUS_OPTIONS_CASE().DECLINE_BY_MANAGER
                            .value) &&
                        (!data[KEYS.FIELD.DECLINE_REASON] ||
                          (data[KEYS.FIELD.DECLINE_REASON] &&
                            (!data[KEYS.FIELD.DECLINE_REASON][
                              KEYS.FIELD.DECLINE_CODE
                            ] ||
                              !data[KEYS.FIELD.DECLINE_REASON][
                                KEYS.FIELD.DECLINE_DESCRIPTION
                              ])))) ||
                      data[KEYS.FIELD.CLAIM_CASE].status ===
                        KEYS.STATUS_OPTIONS_CASE().PAYMENT.value
                    ) {
                      e.preventDefault();
                    } else {
                      this.decisionCase();
                    }
                  }}
                >
                  Cập nhật
                </div>
              </div>
              {((data[KEYS.FIELD.CLAIM_CASE].status ===
                KEYS.STATUS_OPTIONS_CASE().DECLINE_BY_ASSESSOR.value ||
                data[KEYS.FIELD.CLAIM_CASE].status ===
                  KEYS.STATUS_OPTIONS_CASE().DECLINE_BY_MANAGER.value) && (
                <div className="informationDetailReason">
                  <Selection
                    name="declineReason"
                    label="Lý do"
                    placeHolder="Lý do"
                    className="declineReason"
                    options={data[KEYS.LIST.DECLINE_CODES]}
                    errorPosition="bottom"
                    onChange={(name, value) => {
                      const newData = _.clone(data);
                      if (!newData[KEYS.FIELD.DECLINE_REASON])
                        newData[KEYS.FIELD.DECLINE_REASON] = {};
                      newData[KEYS.FIELD.DECLINE_REASON][
                        KEYS.FIELD.DECLINE_CODE
                      ] = value;
                      this.setState({
                        data: newData,
                      });
                    }}
                    value={
                      (data[KEYS.FIELD.DECLINE_REASON] &&
                        data[KEYS.FIELD.DECLINE_REASON][
                          KEYS.FIELD.DECLINE_CODE
                        ]) ||
                      ''
                    }
                  />
                  <div className="declineReasonDescription">
                    <p className="label">Nội dung chi tiết</p>
                    <textarea
                      placeholder="Nhập nội dung chi tiết..."
                      onChange={(e) => {
                        const newData = _.clone(data);
                        if (!newData[KEYS.FIELD.DECLINE_REASON])
                          newData[KEYS.FIELD.DECLINE_REASON] = {};
                        newData[KEYS.FIELD.DECLINE_REASON][
                          KEYS.FIELD.DECLINE_DESCRIPTION
                        ] = e.target.value;
                        this.setState({
                          data: newData,
                        });
                      }}
                      value={
                        (data[KEYS.FIELD.DECLINE_REASON] &&
                          data[KEYS.FIELD.DECLINE_REASON][
                            KEYS.FIELD.DECLINE_DESCRIPTION
                          ]) ||
                        ''
                      }
                    />
                  </div>
                  <div className="declineReasonDescription">
                    <p className="label">Update Policy Status</p>
                    <textarea
                      placeholder="Nhập update policy status..."
                      onChange={(e) => {
                        const newData = _.clone(data);
                        if (!newData[KEYS.FIELD.DECLINE_REASON])
                          newData[KEYS.FIELD.DECLINE_REASON] = {};
                        newData[KEYS.FIELD.DECLINE_REASON][
                          KEYS.FIELD.UPDATE_POLICY_STATUS
                        ] = e.target.value;
                        this.setState({
                          data: newData,
                        });
                      }}
                      value={
                        (data[KEYS.FIELD.DECLINE_REASON] &&
                          data[KEYS.FIELD.DECLINE_REASON][
                            KEYS.FIELD.UPDATE_POLICY_STATUS
                          ]) ||
                        ''
                      }
                    />
                  </div>
                </div>
              )) ||
                ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(
  withTranslation('common')(RequestCaseAssessmentScreen)
);
