import React from 'react';

class NotFoundScreen extends React.PureComponent {
  render() {
    return (
      <div>
        <h1>
          Ops, this page cannot be found. Are you go to the correct page ?{' '}
          <span role="img" aria-label="smile">
            🙂
          </span>
        </h1>
      </div>
    );
  }
}

export default NotFoundScreen;
