import React from 'react';
import { Button } from 'papaya-ui';
import './styles.css';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import setModal from '../../../store/ClaimWebsite/commonActions/modal';

interface IProps extends WithTranslation{
  dispatch:Function
}

class NationalIdModal extends React.PureComponent<IProps> {
  render() {
    const { t } = this.props;
    return (
      <div className="nationalIdModal">
        <h2 className="nationalId1">{t('modal.title.nationalId1')}</h2>
        <h2 className="nationalId2">{t('modal.title.nationalId2')}</h2>
        <h3>{t('modal.description.nationalId')}
        </h3>
        <div className="buttonNationalModal">
          <Button
            className="btn-dark" 
            onClick={() => this.props.dispatch(
              setModal({ isShow:false }))}
          >
            {t('modal.confirm.gotIt')}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation('common')(NationalIdModal));