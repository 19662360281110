import React from 'react';
import { Button } from 'papaya-ui';
import './styles.css';
import { connect } from 'react-redux';
// @ts-ignore
import { WithTranslation, withTranslation } from 'react-i18next';
import setModal from '../../../store/ClaimWebsite/commonActions/modal';
import { showPaperModal } from '../index';

interface IProps extends WithTranslation{
  dispatch:Function
}

class JetModal extends React.PureComponent<IProps> {
  render() {
    const { t } = this.props;
    return (
      <div className="jetModal">
        <div className="jetHeader" />
        <div className="jetDescription">
          {t('modal.description.jet')}
        </div>
        <div className="button">
          <Button
            className="btn-dark"
            onClick={() => {
              this.props.dispatch(setModal({ isShow:false }));
              showPaperModal();
            }}
          >
            {t('modal.confirm.continue')}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation('common')(JetModal));