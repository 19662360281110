import React from 'react';
import './styles.css';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import sidebar, { SidebarType } from '../../../../config/ClaimPortal/sidebar';
import defaultAvatar from '../../../../assets/images/avatar.jpg';
import { clearToken, setUnSavedChangeModal, showSidebar } from '../../../../store/ClaimPortal/commonActions';
import { ROUTES } from '../../../../config/ClaimPortal/pathSetting';
import { NotificationData } from '../../../../store/ClaimPortal/interface';

interface IProps extends RouteComponentProps<string> {
  dispatch: Function;
  user: string;
  isShow: boolean;
  unSavedChange: boolean,
  notification: NotificationData
}

const mapStateToProp = (state) => ({
  user: state.user,
  isShow: state.sidebar,
  unSavedChange: state.unSavedChange,
  notification: state.notification
});

class Sidebar extends React.Component<IProps> {
  getFirstLoad = () => {
  };
  
  doLogout = () => {
    // eslint-disable-next-line no-alert
    const isLogout = window.confirm('Bạn có chắc là muốn đăng xuất ?');
    if (isLogout) {
      this.props.dispatch(clearToken());
      this.props.history.push(ROUTES.login);
    }
  };
  
  goDashBoard = () => {
    const { unSavedChange } = this.props;
    if (unSavedChange) {
      this.props.dispatch(setUnSavedChangeModal(
        {
          isShow: true,
          callback: () => this.props.history.push('/portal/dashboard')
        }
      ));
    } else {
      this.props.history.push('/portal/dashboard');
    }
  };
  
  goSetting = () => {
    const { unSavedChange } = this.props;
    if (unSavedChange) {
      this.props.dispatch(setUnSavedChangeModal(
        {
          isShow: true,
          callback: () => this.props.history.push(ROUTES.setting)
        }
      ));
    } else {
      this.props.history.push(ROUTES.setting);
    }
  };
  
  goItemPath = (path) => {
    const { unSavedChange } = this.props;
    if (unSavedChange) {
      this.props.dispatch(setUnSavedChangeModal(
        {
          isShow: true,
          callback: () => this.props.history.push(path)
        }
      ));
    } else {
      this.props.history.push(path);
    }
  };
  
  isActive = (item) => {
    const currentPathName = this.props.history.location.pathname;
    const allPath = currentPathName.split('/');
    allPath.splice(currentPathName.length - 1, 1);
    const newPath = item.path.split('/');
    newPath.splice(allPath.length - 1, 1);
    return (allPath.join('/').includes(newPath.join('/')) && ['active', item.activeIcon].join(' ')) || '';
  };
  
  render() {
    const { user, isShow, notification } = this.props;
    return (
      <div
        className={['sidebarContainer', (isShow && 'show') || 'hide'].join(' ')}
      >
        <div
          aria-label="home"
          role="button"
          tabIndex={0}
          className="logo"
          onClick={() => this.goDashBoard()}
        />
        <div
          role="button"
          tabIndex={0}
          className="hideMenu"
          onClick={() => {
            this.props.dispatch(showSidebar(!isShow));
          }}
        >Ẩn menu
        </div>
        {sidebar &&
        sidebar.length &&
        sidebar.map((item: SidebarType) => (
          (!item.isHeading &&
          <div
            key={`item_${item.name}_${Math.random()}`}
            role="button"
            tabIndex={0}
            className={[
              'menuItem',
              item.icon,
              this.isActive(item)
            ].join(' ')}
            onClick={() => this.goItemPath(item.path)}
          >
            <span
              role="button"
              tabIndex={0}
              onClick={() => this.goItemPath(item.path)}
            >
              {item.title}
            </span>
            {(item.notiKey &&
              <span className="notis">
                {(notification && notification[item.notiKey]) || 0}
              </span>
            ) || ''}
          </div>)
          ||
          (
            <div key={`item_${item.name}_${Math.random()}`} className="headingItem">
              <span>
                {item.title}
              </span>
            </div>
          )
        
        ))}
        <div
          role="presentation"
          className={`info ${(this.props.history.location.pathname.includes(ROUTES.setting) && 'active') || ''}`}
          onClick={() => this.goSetting()}
        >
          <img alt="avatar" className="avatar" src={defaultAvatar} />
          <div className="nameTitle">
            <span className="name">
              {user && user.split(' ')[user.split(' ').length - 1]}
            </span>
            <span className="title">Member</span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProp)(Sidebar);
