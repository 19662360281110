import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'draft-js/dist/Draft.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from './translation';
import ClaimWebsiteApp from './app/ClaimWebsiteApp';
import ClaimPortalApp from './app/ClaimPortalApp';
import * as serviceWorker from './serviceWorker';
import ClaimWebsiteStore from './store/ClaimWebsite/store';
import ClaimPortalStore from './store/ClaimPortal/store';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Router>
      <Switch>
        <Route
          path="/portal"
          render={(props) => (
            <Provider store={ClaimPortalStore}>
              <ClaimPortalApp {...props} />
            </Provider>
          )}
        />
        <Route
          path="/"
          render={(props) => (
            <Provider store={ClaimWebsiteStore}>
              <ClaimWebsiteApp {...props} />
            </Provider>
          )}
        />
      </Switch>
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
