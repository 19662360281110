/**
 ** @class ProductScreen
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './style.css';

interface IProps extends WithTranslation{

}

class ProductScreen extends React.PureComponent<IProps> {
  render = () => {
    const { t } = this.props;
    return (
      <div className="ProductContainer">
        <div className="detail">
          <p>
            {t('emptyProduct.message')}
          </p>
          <p>
            Vui lòng&nbsp;
            <span
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              className="redirect"
              onClick={() =>
                window.open(
                  'https://portal.fwd.com.vn/e-service/?_ga=2.142568827.896042299.1593597312-1991748770.1585552258#/login'
                )}
            >
              NHẤP VÀO ĐÂY&nbsp;
            </span>
            để tiếp tục gửi yêu cầu giải quyết quyền lợi bảo hiểm khác
          </p>
        </div>
      </div>
    );
  };
}

export default (withTranslation('common')(ProductScreen));
