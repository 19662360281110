import { SHOW_SIDEBAR } from '../types';
import { SidebarAction } from '../interface';

export default function sidebar(state = Boolean(localStorage.getItem('showSideBar')), action: SidebarAction) {
  let newState = state;
  if (action.type === SHOW_SIDEBAR) {
    newState = action.isShow;
    localStorage.setItem('showSideBar', (action.isShow && 'yes') || '');
  }
  return newState;
}
