import { LOADING } from '../types';
import { LoadingAction } from '../../../config/interface';

export default function loading(state = false, action: LoadingAction) {
  let newState = state;
  if (action.type === LOADING) {
    newState = action.isLoading;
  }
  return newState;
}
