/**
 ** @class TrackingDetail
 */

import React from 'react';
import './style.css';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ROUTES } from '../../../../config/ClaimWebsite/pathSetting';
import { setLoading } from '../../../../store/ClaimWebsite/commonActions';
import KEYS from '../../../../config/key';
import ClaimService from '../../../../config/ClaimWebsite/modelService';


interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface IState {
  dataListClaim: DataListClaim
}

interface DataListClaim {
  poName?: string,
  data?: Array<TableClaim>
}

interface TableClaim {
  claimReference?: string
  daysInHospital?: string
  laName?: string
  status?: number
}

const mapStateToProps = (state) => ({
  data: state.data,
});

class TrackingDetail extends React.Component<IProps, IState> {
  timeOut;
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataListClaim: {},
    };
  
    if (!props.data.postBack
      || (props.data.postBack && !props.data.postBack.trackingDetail)
      || (props.data.postBack
        && !props.data.postBack.trackingDetail.data)) {
      this.props.history.replace(ROUTES.tracking);
    }
  }
  
  componentDidMount() {
    const { data } = this.props;
    const newData = _.clone(data);
    if (newData.postBack && newData.postBack.trackingDetail) {
      this.setState({
        dataListClaim: newData.postBack.trackingDetail.data
      });
    }
    if(newData.postBack && newData.postBack.trackingUpload) {
      delete newData.postBack.trackingUpload;
    }
  }
  
  getDetail = async (claimNumber: string) => {
    const { data } = this.props;
    const newData = _.clone(data);
    this.props.dispatch(setLoading(true));
    const response = await ClaimService.getDetailTracking(claimNumber);
    if (response && response.status === 400) {
      // error
      this.props.dispatch(setLoading(false));
    } else {
      this.props.dispatch(setLoading(false));
      if(!newData.postBack)  newData.postBack = {};
      newData.postBack.trackingDetailHistory = {};
      newData.postBack.trackingDetailHistory = {
        data: response,
        claimCaseId: claimNumber
      };
      this.readyToNext();
    }
  };
  
  readyToNext = () => {
    this.props.history.replace(ROUTES.trackingDetailHistory);
  };
  
  render = () => {
    const { t } = this.props;
    const { dataListClaim } = this.state;
    return (
      <div className="tableClaimContainer">
        <div className="tableClaimHeader">
          <p
            className="headerTableClaim"
          >{t('tracking.tableClaim.header', { 'name': dataListClaim.poName || ' ' })}
          </p>
          <p className="descriptionTableClaim">{t('tracking.tableClaim.description')}</p>
        </div>
        {dataListClaim.data && (
          <div className="tableContent">
            <table className="tableTracking">
              <thead>
                <tr>
                  {Object.keys(_.omit(dataListClaim.data[0], 'id'))
                    .map((header) =>
                      <th className="headerTracking" key={header}>{t(`table.header.${header}`)}</th>
                    )}
                </tr>
              </thead>
              <tbody>
                {dataListClaim.data && dataListClaim.data.length
              && dataListClaim.data.map((row, index) =>
                <tr
                  tabIndex={0}
                  role='button'
                  key={row.claimReference && row.claimReference + index}
                  onClick={() =>
                    this.getDetail(row[KEYS.TABLE.ID])}
                >
                  {dataListClaim.data && dataListClaim.data.length &&
                  Object.keys(_.omit(dataListClaim.data[0], 'id')).map(key =>
                    <td className="bodyTracking" key={key}>
                      {row[key]}
                    </td>)}
                </tr>
              )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };
}

export default connect(mapStateToProps)(withTranslation('common')(TrackingDetail));
