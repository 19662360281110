import { FOOTER } from '../types';
import { SetFooterAction } from '../../../config/interface';

const initialFooter = {
  visible: false,
  nextState: {},
  backState: {},
};

export default function footer(state = initialFooter, action: SetFooterAction) {
  let newState = Object.assign(state);
  if (action.type === FOOTER) {
    newState = action.footer;
  }
  return newState;
}
