import { ClaimWebsiteStore } from '../../store';
import { clearData, setData } from '../../store/ClaimWebsite/commonActions';
import i18next from '../../translation';
import KEYS from '../key';
import ClaimService from './modelService';
import setToast from '../../store/ShareStore/actions/toast';

export const pathSeries = [
  {
    index: 1,
    text: 'Chi tiết điều trị',
  },
  {
    index: 2,
    text: 'Tải hình ảnh chứng từ',
  },
  {
    index: 3,
    text: 'Thông tin nhận quyền lợi bảo hiểm',
  },
];

export enum ROUTES {
  product = '/product',
  payment = '/payment',
  paperwork = '/paperwork',
  policy = '/policy',
  detail = '/detail',
  term = '/term',
  review = '/review',
  end = '/end',
  tracking = '/tracking',
  trackingOTP = '/trackingOTP',
  trackingDetail = '/tracking/detail',
  trackingDetailHistory = '/tracking/history',
  trackingUpload = '/tracking/upload',
  productTracking= '/productTracking',
  // claims = '/tracking/claims',
  home = '/',
}

const pathSetting = () => {
  const newConfig = {};
  newConfig[ROUTES.home] = {};
  newConfig[ROUTES.term] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: false,
        description:
          i18next.t('termAndUsed.description'),
        link: ROUTES.policy,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.home,
      },
    },
  };
  newConfig[ROUTES.policy] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: true,
        description: '',
        link: ROUTES.detail,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.term,
      },
    },
  };
  newConfig[ROUTES.product] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: false,
        isFWD: true,
        text: 'footer.backFwd',
        className: 'backFwd',
        callback: () => {
          window.location.replace('https://www.fwd.com.vn/en/');
        },
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.policy,
      },
    },
  };
  newConfig[ROUTES.detail] = {
    index: 1,
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: true,
        description: '',
        link: ROUTES.paperwork,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.policy,
        callback: async () => {
          ClaimWebsiteStore.dispatch(setData({}));
        },
      },
    },
  };
  newConfig[ROUTES.paperwork] = {
    index: 2,
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: true,
        description: '',
        link: ROUTES.payment,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.detail,
      },
    },
  };
  newConfig[ROUTES.payment] = {
    index: 3,
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: true,
        description: '',
        link: ROUTES.review,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.paperwork,
      },
    },
  };
  newConfig[ROUTES.review] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: false,
        description: '',
        link: ROUTES.end,
        text: 'Nộp hồ sơ',
        callback: async () => {
          const claimID = sessionStorage.getItem(KEYS.FIELD.CLAIM_ID);
          if(claimID) {
            const result = await ClaimService.doneClaim(claimID);
            if (result && result.status) {
              ClaimWebsiteStore.dispatch(
                setToast({
                  isShow: true,
                  text: result.data.Errors[0].Message,
                })
              );
              return false;
            }
          }
          ClaimWebsiteStore.dispatch(clearData());
          return true;
        },
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.payment,
      },
    },
  };
  newConfig[ROUTES.end] = {};
  newConfig[ROUTES.tracking] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: true,
        description: '',
        link: ROUTES.trackingDetail,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.home,
      },
    },
  };
  newConfig[ROUTES.productTracking] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: false,
        isFWD: true,
        text: 'footer.backFwd',
        className: 'backFwd',
        callback: () => {
          window.location.replace('https://www.fwd.com.vn/en/');
        },
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.tracking,
      },
    },
  };
  newConfig[ROUTES.trackingDetail] = {
    footer: {
      visible: true,
      nextState: {
        visible: false,
        disabled: true,
        description: '',
        link: ROUTES.trackingDetailHistory
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.tracking,
      },
    },
  };
  newConfig[ROUTES.trackingDetailHistory] = {
    footer: {
      visible: true,
      nextState: {
        visible: false,
        disabled: true,
        description: '',
        link: ROUTES.end,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.trackingDetail,
      },
    },
  };
  newConfig[ROUTES.trackingUpload] = {
    footer: {
      visible: true,
      nextState: {
        visible: true,
        disabled: true,
        description: '',
        link: ROUTES.end,
      },
      backState: {
        visible: true,
        disabled: false,
        link: ROUTES.trackingDetailHistory,
      },
    },
  };
  return newConfig;
};

export default pathSetting;
