/**
 ** @class TrackingUpload
 */

import React from 'react';
import './style.css';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UploadPicture } from 'papaya-ui';
import { connect } from 'react-redux';
import _ from 'lodash';
import u from 'underscore';
import pathSetting, { ROUTES } from '../../../../config/ClaimWebsite/pathSetting';
import KEYS from '../../../../config/key';

import setFooter from '../../../../store/ClaimWebsite/commonActions/footer';
import utils from '../../../../config/utils';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import setToast from '../../../../store/ShareStore/actions/toast';


interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface IState {
  data: inputData;
}

type inputData = {
  AdditionPaper?: Array<ValueType>
  claimReference?: string,
  pendingReasons?: string[]
  claimCaseId?: string,
  maximumUpload?: number
};

type submitData = {
  AdditionPaper?: Array<File>,
};

interface ValueType {
  id: string,
  value: string
}

const mapStateToProps = (state) => ({
  data: state.data,
});

class TrackingUpload extends React.Component<IProps, IState> {
  timeOut;
  
  fileName: Array<string> = [];
  
  submitData: submitData = {};
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: {},
    };
    
    if (!props.data.postBack
      || (props.data.postBack && !props.data.postBack.trackingInitialData)
      || (props.data.postBack
        && !props.data.postBack.trackingInitialData.data)) {
      this.props.history.replace(ROUTES.tracking);
    }
    
    if (!props.data.postBack
      || (props.data.postBack && !props.data.postBack.trackingDetailHistory)
      || (props.data.postBack
        && !props.data.postBack.trackingDetailHistory.data)) {
      this.props.history.replace(ROUTES.trackingDetailHistory);
    }
  }
  
  componentDidMount() {
    localStorage.setItem(KEYS.IS_ADD.KEY, KEYS.IS_ADD.TRUE);
    localStorage.removeItem(KEYS.IS_JET.KEY);
    const { data } = this.state;
    const newStateData = _.clone(data);
    const newData = _.clone(this.props.data);
    if (newStateData && newData.postBack) {
      const { trackingDetailHistory, trackingUpload } = newData.postBack;
      if (trackingDetailHistory
        && trackingDetailHistory.data) {
        if(trackingDetailHistory.data.claimReference){
          newStateData.claimReference
            = trackingDetailHistory.data.claimReference;
        }
        if(trackingDetailHistory.data.pendingReasons){
          newStateData.pendingReasons
            = trackingDetailHistory.data.pendingReasons;
        }
        if(trackingDetailHistory.claimCaseId) {
          newStateData.claimCaseId = trackingDetailHistory.claimCaseId;
        }
        
        if(trackingDetailHistory.data.maximumUpload) {
          newStateData.maximumUpload = trackingDetailHistory.data.maximumUpload;
        } else {
          newStateData.maximumUpload = 0;
        }
      }
      if (trackingUpload
        && trackingUpload.data) {
        newStateData.AdditionPaper
          = trackingUpload.data;
      }
    }
    
    this.setState({
      data: newStateData
    }, () => this.handleLogicAll());
  }
  
  handleChange = (name, value, fileName) => {
    const { data } = this.state;
    const newData = data || {};
    newData[name] = value;
    this.fileName = fileName;
    if (value.length === 0) {
      delete newData[name];
    }
    this.setState({ data: newData }, () => {
      this.handleLogicAll();
    });
  };
  
  handleLogicAll = () => {
    if (this.state.data && this.state.data.AdditionPaper) {
      this.readyToNext();
    } else {
      this.hideFooter();
    }
  };
  
  updateDataBeforeUpload = () => {
    const newData = _.clone(this.state.data);
    const { footer } = pathSetting()[ROUTES.trackingUpload];
    footer.nextState.disabled = true;
    this.props.dispatch(setFooter(footer));
    
    if(newData) {
      this.submitData.AdditionPaper = u.pluck(newData.AdditionPaper, 'value');
    }
    
    if(this.submitData.AdditionPaper) {
      if(!this.submitData.AdditionPaper.length) {
        delete this.submitData.AdditionPaper;
      }
    }
    
    if(_.isEmpty(this.submitData)) {
      return true;
    }
    return this.uploadDocument();
  };
  
  uploadDocument = async () => {
    const docList = Object.keys(this.submitData).map(key => {
      return {
        DocType: KEYS.IMAGES[key],
        value: this.submitData[key]
      };
    });
    const { claimCaseId } = this.state.data;
    if (docList && docList.length && claimCaseId) {
      for (const doc of docList) {
        if (doc && doc.value.length) {
          let index = 0;
          for (const base64 of doc.value) {
            index+=1;
            if(base64) {
              const indexFileName = doc.value.indexOf(base64);
              const block = base64 && base64.split(';');
              const contentType = block[0].split(':')[1]; // In this case "image/gif"
              const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
              const blob = utils.b64toBlob(realData, contentType);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const file = new FormData();
              file.append(KEYS.FIELD.FILE, blob, this.fileName[indexFileName]);
              file.append(KEYS.FIELD.DOC_TYPE, doc.DocType);
              if(index === doc.value.length){
                file.append('isLast', 'true');
              }
              try {
                const response =
                  // eslint-disable-next-line no-await-in-loop
                  await ClaimService.uploadDocumentTracking(
                    claimCaseId, file, () => {
                    });
                if (response && response.data && response.data.Errors) {
                  this.props.dispatch(
                    setToast({
                      isShow: true,
                      type: 'warning',
                      text: response.data && response.data.Errors
                        && response.data.Errors[0].Message,
                    })
                  );
                  this.hideFooter();
                  this.props.history.push(ROUTES.tracking);
                  return false;
                }
              } catch (e) {
                return false;
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };
  
  hideFooter = () => {
    const { footer } = pathSetting()[ROUTES.trackingUpload];
    footer.nextState.disabled = true;
    this.props.dispatch(setFooter(footer));
  };
  
  readyToNext = () => {
    const { footer } = pathSetting()[ROUTES.trackingUpload];
    footer.nextState.disabled = false;
    footer.nextState.callback = () => this.updateDataBeforeUpload();
    this.props.dispatch(setFooter(footer));
  };
  
  mapOption = () => {
    const { data } = this.state;
    const { t } = this.props;
    const max = data.maximumUpload || 10;
    const options = [];
    for(let i = 0; i < max; i++) {
      // @ts-ignore
      options[i] = { label: t('tracking.upload.placeholder'), editable: true };
    }
    return options;
  };
  
  render = () => {
    const { t } = this.props;
    const { data } = this.state;
    return (
      <div className="trackingUploadContainer">
        <div className="trackingUploadHeader">
          {`(#${data.claimReference})`} {t('tracking.upload.header')}
        </div>
        
        <div className="trackingUploadContent">
          <div className="trackingUploadContentHeader">
            {t('tracking.upload.contentHeader')}
          </div>
          <div className="trackingUploadContentBody">
            {data.pendingReasons && data.pendingReasons.map((reasons=>
              <div key={Math.random()} className="trackingPaper">
                • {reasons}
              </div>))}
            {(data.maximumUpload === 0 &&
            <div className="maximumContent">{t('tracking.upload.maximumFile')}</div>) ||
            <div className="trackingUploadPaper">
              <UploadPicture
                className="additionPaper"
                name="AdditionPaper"
                value={_.clone(data.AdditionPaper) || []}
                label=""
                description=""
                multiple={true}
                distinct={false}
                max={data.maximumUpload || KEYS.UPLOAD_IMAGE.OTHER_MAX}
                min={1}
                showTooltip={false}
                maxSize={KEYS.UPLOAD_IMAGE.FILE_SIZE}
                guides={[]}
                options={this.mapOption()}
                onChange={(name, value, fileName) => this.handleChange(name, value, fileName)}
              />
            </div>}
          
          </div>
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProps)(withTranslation('common')(TrackingUpload));
