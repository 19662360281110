import { ROUTES } from './pathSetting';

export type TabbarType = {
  path: string;
  item: Array<{ name: string; path?: string; notiKey: string }>;
};

const tabbar: Array<TabbarType> = [
  {
    path: ROUTES.assessmentDetail,
    item: [
      {
        name: 'Thẩm định',
        path: ROUTES.requestAssessment,
        notiKey: '',
      },
      {
        name: 'Thanh toán',
        path: ROUTES.paymentAssessment,
        notiKey: '',
      },
      {
        name: 'Claim gốc',
        path: ROUTES.originalAssessment,
        notiKey: '',
      },
      {
        name: 'Danh sách letters',
        path: ROUTES.letterListAssessment,
        notiKey: '',
      },
      {
        name: 'Trình case',
        path: ROUTES.notificationAssessment,
        notiKey: '',
      },
    ],
  },
  {
    path: ROUTES.shortAssessmentInProgress,
    item: [
      {
        name: 'STP',
        path: ROUTES.assessmentStp,
        notiKey: 'stp',
      },
      {
        name: 'Jet',
        path: ROUTES.assessmentJet,
        notiKey: 'jet',
      },
      {
        name: 'TAT',
        path: ROUTES.assessmentTat,
        notiKey: 'tat',
      },
      {
        name: 'None Jet',
        path: ROUTES.assessmentNoneJet,
        notiKey: 'noneJet',
      },
      {
        name: 'Pending',
        path: ROUTES.assessmentPending,
        notiKey: 'waitingPendings',
      },
      {
        name: 'Waiting',
        path: ROUTES.assessmentWaitingResult,
        notiKey: 'waitingResult',
      },
      {
        name: 'Fail',
        path: ROUTES.assessmentFail,
        notiKey: 'fail',
      },
    ],
  },
  {
    path: ROUTES.shortAssessmentNotDecision,
    item: [
      {
        name: 'Not yet',
        path: ROUTES.assessmentNotYetDecision,
        notiKey: 'notYetDecision',
      },
      {
        name: 'Failed',
        path: ROUTES.assessmentFailedDecision,
        notiKey: 'decisionFail',
      },
      {
        name: 'Waiting',
        path: ROUTES.assessmentWaitingDecision,
        notiKey: 'waitingDecision',
      },
    ],
  },
];

export default tabbar;
