import { ROUTES } from './pathSetting';

export type SidebarType = {
  isHeading?:boolean;
  name: string;
  title: string;
  path?: string;
  icon?: Icon;
  activeIcon?: Icon;
  notiKey?:string;
};

enum Icon {
  DASHBOARD_ICON = 'dashboard-icon',
  DASHBOARD_ACTIVE_ICON = 'dashboard-active-icon',
  ASSESSMENT_ICON = 'assessment-icon',
  ASSESSMENT_ACTIVE_ICON = 'assessment-active-icon',
  ASSESSMENT_INPROGRESS_ICON = 'inProgress-icon',
  ASSESSMENT_INPROGRESS_ACTIVE_ICON = 'inProgress-active-icon',
  ASSESSMENT_DONE_ICON = 'done-icon',
  ASSESSMENT_DONE_ACTIVE_ICON = 'done-active-icon',
  ASSESSMENT_NOTDECISION_ICON = 'notDecision-icon',
  ASSESSMENT_NOTDECISION_ACTIVE_ICON = 'notDecision-active-icon',
  ASSESSMENT_ERROR_ICON = 'error-icon',
  ASSESSMENT_ERROR_ACTIVE_ICON = 'error-active-icon',
}

const sidebar: Array<SidebarType> = [
  {
    name: 'dashboard',
    title: 'Tổng quát',
    path: ROUTES.dashboard,
    icon: Icon.DASHBOARD_ICON,
    activeIcon: Icon.DASHBOARD_ACTIVE_ICON,
  },
  {
    name: 'heading',
    title: 'Thẩm định bảo hiểm',
    isHeading: true
  },
  {
    name: 'assessmentList',
    title: 'All list',
    path: ROUTES.assessmentList,
    icon: Icon.ASSESSMENT_ICON,
    activeIcon: Icon.ASSESSMENT_ACTIVE_ICON,
  },
  {
    name: 'assessmentIP',
    title: 'In progress',
    path: ROUTES.assessmentInProgress,
    icon: Icon.ASSESSMENT_INPROGRESS_ICON,
    activeIcon: Icon.ASSESSMENT_INPROGRESS_ACTIVE_ICON,
  },
  {
    name: 'assessmentDE',
    title: 'Decision',
    path: ROUTES.assessmentDecision,
    icon: Icon.ASSESSMENT_NOTDECISION_ICON,
    activeIcon: Icon.ASSESSMENT_NOTDECISION_ACTIVE_ICON,
    notiKey: 'totalDecision'
  },
  {
    name: 'assessmentD',
    title: 'Done',
    path: ROUTES.assessmentDone,
    icon: Icon.ASSESSMENT_DONE_ICON,
    activeIcon: Icon.ASSESSMENT_DONE_ACTIVE_ICON,
  },
  {
    name: 'assessmentE',
    title: 'Error',
    path: ROUTES.assessmentError,
    icon: Icon.ASSESSMENT_ERROR_ICON,
    activeIcon: Icon.ASSESSMENT_ERROR_ACTIVE_ICON,
  }
];

export default sidebar;
