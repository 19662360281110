import React from 'react';
import { Button } from 'papaya-ui';
import './styles.css';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import setModal from '../../../store/ClaimWebsite/commonActions/modal';

interface IProps extends WithTranslation{
  name?: string
  dispatch:Function
}

class TooltipModal extends React.PureComponent<IProps> {
  render() {
    const { name, t }: Readonly<IProps>= this.props;
    return (
      <div className="tooltipModal">
        <div className="tooltipHeader">{ ((name && name === 'hospital') && t('modal.title.hospital')) || t('paperwork.label.otherPaper')}</div>
        <div className="tooltipImage" />
        <div className="tooltipDescription">
          {t('modal.description.imageUpload')}
        </div>
        <div className="button">
          <Button
            className="btn-dark"
            onClick={() => this.props.dispatch(
              setModal({ isShow:false }))}
          >
            {t('modal.confirm.agree')}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation('common')(TooltipModal));