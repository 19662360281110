import React from 'react';
import './styles.css';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Modal } from 'papaya-ui';
import setToast from '../../../../../../store/ShareStore/actions/toast';
import defaultAvatar from '../../../../../../assets/images/avatar.jpg';
import KEYS from '../../../../../../config/key';
import ClaimPortalService from '../../../../../../config/ClaimPortal/modelService';
import { clearToken } from '../../../../../../store/ClaimPortal/commonActions';
import { ROUTES } from '../../../../../../config/ClaimPortal/pathSetting';

interface IProps extends RouteComponentProps<Routedata>, WithTranslation {
  dispatch: Function;
  user: string;
}

interface Routedata {
  id?: string;
}

interface IState {
  data?: inputData,
  eightCharacter: boolean,
  lowerUpperCase: boolean,
  numberCase: boolean,
  specialCase: boolean,
  toggleOldPassword: boolean,
  toggleNewPassword: boolean,
  toggleConfirmPassword: boolean,
  modalConfirmShow: boolean
}

interface inputData {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
}

const mapStateToProp = (state) => ({
  user: state.user,
  isShow: state.sidebar
});

class ChangePasswordScreen extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      eightCharacter: false,
      lowerUpperCase: false,
      numberCase: false,
      specialCase: false,
      toggleOldPassword: false,
      toggleNewPassword: false,
      toggleConfirmPassword: false,
      modalConfirmShow: false
    };
  }

  handleChange = (name: string, event) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    if (newData) {
      newData[name] = event.target.value;
    }

    if (name === KEYS.PORTAL_PASSWORD.NEW_PASSWORD) {
      this.checkLogicNewPassword(event.target.value);
    }

    this.setState({
      data: newData
    });
  };

  checkLogicNewPassword = (value: string) => {
    let eightCharacter: boolean = false;
    let lowerUpperCase: boolean = false;
    let specialCase: boolean = false;
    let numberCase: boolean = false;

    if (value.length >= 8) {
      eightCharacter = true;
    }
    if (/^(?=.*[a-z])(?=.*[A-Z]).*$/gm.test(value)) {
      lowerUpperCase = true;
    }
    if (/(?=.*[0-9]).*$/gm.test(value)) {
      numberCase = true;
    }
    if (/(?=.*[@#$%^&+!=])/gm.test(value)) {
      specialCase = true;
    }

    this.setState({
      eightCharacter,
      lowerUpperCase,
      numberCase,
      specialCase,
    });
  };

  togglePassword = (value) => {
    const {
      toggleOldPassword,
      toggleNewPassword,
      toggleConfirmPassword
    } = this.state;
    let toggleOldPasswordUpdate: boolean = toggleOldPassword;
    let toggleNewPasswordUpdate: boolean = toggleNewPassword;
    let toggleConfirmPasswordUpdate: boolean = toggleConfirmPassword;
    if (value === KEYS.PORTAL_PASSWORD.OLD_PASSWORD) {
      toggleOldPasswordUpdate = !toggleOldPassword;
    } else if (value === KEYS.PORTAL_PASSWORD.NEW_PASSWORD) {
      toggleNewPasswordUpdate = !toggleNewPassword;
    } else if (value === KEYS.PORTAL_PASSWORD.CONFIRM_PASSWORD) {
      toggleConfirmPasswordUpdate = !toggleConfirmPassword;
    }

    this.setState({
      toggleOldPassword: toggleOldPasswordUpdate,
      toggleNewPassword: toggleNewPasswordUpdate,
      toggleConfirmPassword: toggleConfirmPasswordUpdate
    });
  };

  handleLogicAll = () => {
    const {
      eightCharacter,
      lowerUpperCase, numberCase, specialCase, data
    } = this.state;
    let logicAll: boolean = false;
    if (eightCharacter && lowerUpperCase && numberCase && specialCase) {
      if (data &&
        data[KEYS.PORTAL_PASSWORD.NEW_PASSWORD]
        === data[KEYS.PORTAL_PASSWORD.CONFIRM_PASSWORD]
        && data[KEYS.PORTAL_PASSWORD.OLD_PASSWORD].length
        && data[KEYS.PORTAL_PASSWORD.OLD_PASSWORD]
        !== data[KEYS.PORTAL_PASSWORD.NEW_PASSWORD]) {
        logicAll = true;
      }
    }
    return logicAll;
  };

  changePassword = async () => {
    const { data }: Readonly<IState> = this.state;
    if (data) {
      const response = await ClaimPortalService.changePassword(data);
      if (response && response.isSuccess) {
        this.props.dispatch(setToast({
          isShow: true,
          type: 'success',
          text: 'Đổi mật khẩu thành công'
        }));
        this.props.dispatch(clearToken());
        this.props.history.push(ROUTES.login);
      } else {
        this.props.dispatch(setToast({
          isShow: true,
          type: 'danger',
          text: response && response.Errors && response.Errors[0].Message
        }));
      }
      this.setState({
        modalConfirmShow: false
      });
    }
  };

  toggleModalConfirm = () => {
    const { modalConfirmShow } = this.state;
    this.setState({
      modalConfirmShow: !modalConfirmShow
    });
  };

  render() {
    const { user } = this.props;
    const {
      data,
      eightCharacter,
      lowerUpperCase,
      numberCase,
      specialCase,
      toggleConfirmPassword,
      toggleNewPassword,
      toggleOldPassword,
      modalConfirmShow
    } = this.state;
    return (
      <div className="changePassword">
        <div className="changePasswordHeader">
          <img alt="avatar" className="avatar" src={defaultAvatar} />
          <div className="nameTitle">
            <span className="name">
              {user && user.split(' ')[user.split(' ').length - 1]}
            </span>
            <span className="title"> - Member</span>
          </div>
        </div>
        <div className="changePasswordContent">
          <div className="inputPassword">
            <p className="inputPasswordHeader">
              Mật khẩu cũ:
            </p>
            <input
              id="oldPassword"
              className="inputPasswordContent"
              type={(toggleOldPassword && 'text') || 'password'}
              name="oldPassword"
              onChange={(event) =>
                this.handleChange(KEYS.PORTAL_PASSWORD.OLD_PASSWORD, event)}
            />
            <div
              role="button"
              aria-label="eyePassword"
              tabIndex={-1}
              className={`eyePassword ${(toggleOldPassword && 'true') || 'false'}`}
              onClick={() => this.togglePassword(KEYS.PORTAL_PASSWORD.OLD_PASSWORD)}
            />
          </div>
          <div className="inputPassword">
            <p className="inputPasswordHeader">
              Mật khẩu mới:
            </p>
            <input
              id="newPassword"
              className="inputPasswordContent"
              type={(toggleNewPassword && 'text') || 'password'}
              name="newPassword"
              onChange={(event) =>
                this.handleChange(KEYS.PORTAL_PASSWORD.NEW_PASSWORD, event)}
            />
            <div
              role="button"
              aria-label="eyePassword"
              tabIndex={-1}
              className={`eyePassword ${(toggleNewPassword && 'true') || 'false'}`}
              onClick={() => this.togglePassword(KEYS.PORTAL_PASSWORD.NEW_PASSWORD)}
            />
          </div>
          <div className="inputPassword">
            <p className="inputPasswordHeader">
              Xác nhận mật khẩu mới:
            </p>
            <input
              id="confirmPassword"
              className="inputPasswordContent"
              type={(toggleConfirmPassword && 'text') || 'password'}
              name="confirmPassword"
              onChange={(event) =>
                this.handleChange(KEYS.PORTAL_PASSWORD.CONFIRM_PASSWORD, event)}
            />
            <div
              role="button"
              aria-label="eyePassword"
              tabIndex={-1}
              className={`eyePassword ${(toggleConfirmPassword && 'true') || 'false'}`}
              onClick={() =>
                this.togglePassword(KEYS.PORTAL_PASSWORD.CONFIRM_PASSWORD)}
            />
          </div>
        </div>
        <div className="validatePassword">
          <div className="passwordRule">
            <span className={['iconRule',
              (data && data[KEYS.PORTAL_PASSWORD.OLD_PASSWORD]
                && data[KEYS.PORTAL_PASSWORD.NEW_PASSWORD]
                && data[KEYS.PORTAL_PASSWORD.OLD_PASSWORD] !== data[KEYS.PORTAL_PASSWORD.NEW_PASSWORD]
                && 'true')
              || 'false'].join(' ')}
            >✓
            </span>
            <span className={['textRule', (data
              && data[KEYS.PORTAL_PASSWORD.OLD_PASSWORD]
              && data[KEYS.PORTAL_PASSWORD.NEW_PASSWORD]
              && data[KEYS.PORTAL_PASSWORD.OLD_PASSWORD] !== data[KEYS.PORTAL_PASSWORD.NEW_PASSWORD]
              && 'true')
            || 'false'].join(' ')}
            >Mật khẩu mới không được trùng mật khẩu cũ
            </span>
          </div>
          <div className="passwordRule">
            <span className={['iconRule', (eightCharacter && 'true')
            || 'false'].join(' ')}
            >✓
            </span>
            <span className={['textRule', (eightCharacter && 'true')
            || 'false'].join(' ')}
            >Ít nhất 8 kí tự
            </span>
          </div>
          <div className="passwordRule">
            <span className={['iconRule', (lowerUpperCase && 'true')
            || 'false'].join(' ')}
            >✓
            </span>
            <span className={['textRule', (lowerUpperCase && 'true')
            || 'false'].join(' ')}
            >Bao gồm kí tự viết hoa và viết thường
            </span>
          </div>
          <div className="passwordRule">
            <span className={['iconRule', (numberCase && 'true')
            || 'false'].join(' ')}
            >✓
            </span>
            <span className={['textRule', (numberCase && 'true')
            || 'false'].join(' ')}
            >Bao gồm kí tự số
            </span>
          </div>
          <div className="passwordRule">
            <span className={['iconRule', (specialCase && 'true')
            || 'false'].join(' ')}
            >✓
            </span>
            <span className={['textRule', (specialCase && 'true')
            || 'false'].join(' ')}
            >Bao gồm kí tự đặc biệt
            </span>
          </div>
          <div
            role="button"
            tabIndex={0}
            className={['buttonPassword', (this.handleLogicAll() && 'true')
            || 'false'].join(' ')}
            onClick={(e) => {
              if (!this.handleLogicAll()) {
                e.preventDefault();
              } else {
                this.toggleModalConfirm();
              }
            }}
          >
            Đổi mật khẩu
          </div>
        </div>
        <Modal
          name="globalModal"
          className="globalModal"
          value={modalConfirmShow}
          closeButton={true}
          onClose={() => {
            this.setState({ modalConfirmShow: false });
          }}
        >
          <div className="assessmentModal">
            <div className="assessmentHeader">
              Xác nhận đổi mật khẩu
            </div>
            <div className="assessmentDescription">
              Bạn sẽ phải đăng nhập lại sau khi đổi mật khẩu thành công. Bạn có muốn tiếp tục?
            </div>
            <div className="buttonAssessmentModal">
              <Button
                className="buttonAssessmentCancel"
                onClick={() => {
                  this.setState({ modalConfirmShow: false });
                }}
              >
                Hủy
              </Button>
              <Button
                primary
                className="buttonAssessmentConfirm"
                onClick={() => this.changePassword()}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

}

export default connect(mapStateToProp)(ChangePasswordScreen);
