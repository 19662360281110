import React from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import setModal from '../../../store/ClaimWebsite/commonActions/modal';

interface IProps extends WithTranslation{
  dispatch:Function
}

class PaperModal extends React.PureComponent<IProps> {
  render() {
    const { t }: Readonly<IProps>= this.props;
    return (
      <div className="paperModal">
        <div className="paperModalHeader">
          {t('paperwork.modal.header')}
        </div>
        <div className="paperModalContent">
          <div className="paperModalContentColOne">
            <div className="paperModalNotice">
              <div className="paperModalCheck" />
              <div className="paperModalText">
                {t('paperwork.modal.text1')}
              </div>
            </div>
        
            <div className="paperModalNotice bottom">
              <div className="paperModalCheck" />
              <div className="paperModalText">
                {t('paperwork.modal.text3')}
              </div>
            </div>
          </div>
      
          <div className="paperModalContentColTwo">
            <div className="paperModalNotice">
              <div className="paperModalCheck" />
              <div className="paperModalText">
                {t('paperwork.modal.text2')}
              </div>
            </div>
          </div>
        </div>
    
        <div className="paperModalPicture">
          <div className="paperModalPictureHeader">
            {t('paperwork.modal.unqualifiedHeader')}
          </div>
      
          <div className="listPicture">
            <div className="singlePaper">
              <img className="paper" alt='paper' src="https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/unqualified_01.png" />
              <p className="titlePaper">
                {t('paperwork.modal.unqualified1')}
              </p>
            </div>
        
            <div className="singlePaper">
              <img className="paper" alt='paper' src="https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/unqualified_02.png" />
              <p className="titlePaper">
                {t('paperwork.modal.unqualified2')}
              </p>
            </div>
        
            <div className="singlePaper">
              <img className="paper" alt='paper' src="https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/unqualified_03.png" />
              <p className="titlePaper">
                {t('paperwork.modal.unqualified3')}
              </p>
            </div>
        
            <div className="singlePaper">
              <img className="paper" alt='paper' src="https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/unqualified_04.png" />
              <p className="titlePaper">
                {t('paperwork.modal.unqualified4')}
              </p>
            </div>
          </div>
      
          <div className="paperModalPictureHeader">
            {t('paperwork.modal.qualifiedHeader')}
          </div>
      
          <div className="listPicture qualified">
            <div className="singlePaper">
              <img className="paper" alt='paper' src="https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/qualified_01.png" />
            </div>
        
            <div className="singlePaper">
              <img className="paper" alt='paper' src="https://s3-ap-southeast-1.amazonaws.com/static.papaya.asia/assets/claim/qualified_02.png" />
            </div>
          </div>
        </div>
        <div
          className="paperModalButton"
          role="button"
          tabIndex={0}
          onClick={() => this.props.dispatch(
            setModal({ isShow:false }))}
        >
          {t('paperwork.modal.button')}
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation('common')(PaperModal));