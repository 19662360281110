import React from 'react';
import './styles.css';
import {
  Autocomplete,
  Button,
  ButtonSquare,
  CalendarRange,
  Carousel,
  Modal,
  SelectionStatus,
  UploadPicture,
} from 'papaya-ui';
import { RouteComponentProps } from 'react-router-dom';
import u from 'underscore';
import _ from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import caolan from 'async';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import utils from '../../../../config/utils';
import KEYS from '../../../../config/key';
import setToast from '../../../../store/ShareStore/actions/toast';
import {
  setLoading,
  setUnSavedChange,
  setUnSavedChangeModal,
} from '../../../../store/ClaimPortal/commonActions';
import { ROUTES } from '../../../../config/ClaimPortal/pathSetting';
import logo from '../../../../assets/images/Logo.png';

interface IProps extends RouteComponentProps<RouteData>, WithTranslation {
  dispatch: Function;
  user: string;
  unSavedChange: boolean;
}

interface RouteData {
  id?: string;
}

interface IState {
  data: inputData;
  pendingData: Array<PendingData>;
  updatePendingData: UpdatePendingClaimData;
  documents: DocumentsData;
  list?: listType; // list type
  startDate?: Date;
  docType: number;
  showAlert: boolean;
  decisionData?: {
    claimReference: string;
    isSuccess: boolean;
    message: string;
  }[];
  editSurgery: boolean;
  editMajorSurgery: boolean;
  editHospital: boolean;
  editDiseases: boolean;
  editICU: boolean;
  editSurgeryCase?: { [n: string]: boolean };
  editMajorSurgeryCase?: { [m: string]: boolean };
  editHospitalCase?: { [p: string]: boolean };
  editICUCase?: { [q: string]: boolean };
  dataCase: { [n: string]: boolean };
  editMedicalFacilities: boolean;
  toggleHolder: boolean;
  toggleFraud: boolean;
  toggleClaim: boolean;
  toggleMoney: boolean;
  toggleDocument: boolean;
  toggleSignOff: boolean;
  togglePendingCreate: boolean;
  modalPendingShow: boolean;
  modalEditPendingShow: boolean;
  editPendingId?: string;
  modalConfirmShow: boolean;
  editTotalHospitalDays: boolean;
  editTotalICUDays: boolean;
  claimNumberDel: string;
  signOffInfo: string;
  confirmDecision: boolean;
  decisionMessage?: string;
  modalFraudShow: boolean;
  dataFraudClaim: IFraudData[];
  showComparisonPaperModal: boolean;
  urlComparisonPaper: string;
}

type IFraudData = {
  claimReference: string;
  message: string;
  data: IFraudCase[];
};

type IFraudCase = {
  fraudId: string;
  fraud_meaning: string;
  additional_info: string;
};

type listType = {
  diseases?: Array<{ name: string; value: string }>;
  diseasesRoot?: Array<{ name: string; value: string }>;
  medicalFacilities?: Array<{ name: string; value: string }>;
  provinces?: Array<string>;
  declineCodes?: Array<{ name: string; value: string }>;
  pending?: Array<{ name: string; value: string }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  facilities?: { [index: string]: any };
  followUpCodes?: Array<{ name: string; value: string }>;
};

interface documentData {
  docType: number;
  id: string;
  mediaType: number;
  value: string;
  createdAt: string;
}

type inputData = {
  adjustInfo: string | null;
  diseases?: Array<inputDisease>;
  diseasesList?: Array<string>;
  declineReason?: {
    [n: string]: { declineCode: string; declineDescription: string };
  };
  estimatedAmount?: string;
  isPartial?: boolean;
  isAccident?: boolean;
  isDecision: boolean;
  laName?: string;
  medicalProvider?: string;
  jetStatus?: number;
  poEmail?: string;
  poName?: string;
  claimNumber?: string;
  poNationalId?: string;
  poPhone?: string;
  poDob?: string;
  province?: string;
  signOffNotes?: Array<signOffData>;
  signOffNotes_input?: string;
  pendingText?: string;
  editPendingText?: string;
  0?: ValueType;
  1?: ValueType;
  picAddition?: Array<pictureValue>;
  details?: Array<inputDetail>;
  cases?: Array<InputCase>;
  pending?: Array<string>;
  pendings?: Array<pendingAssessment>;
  pendingLimit: number;
  updatedAt: Date;
  dob?: string;
  sex?: string;
};

interface signOffData {
  content?: string;
  createdAt?: string;
  createdBy?: string;
  id?: string;
  type?: number;
  updatedAt?: string;
}

interface pictureValue {
  id: string;
  value: string;
}

interface pendingAssessment {
  id: string;
  code: string;
  status: string;
  extendedText: string;
  createdAt: string;
  startedAt: string;
  removedAt: string;
  isEditable: boolean;
}

interface UpdatePendingClaimData {
  pendings: Array<UpdatePendingData>;
}

interface UpdatePendingData {
  id: string;
  status: string;
  extendedText?: string;
}

interface PendingData {
  code: string;
  extendedText?: string;
}

interface DocumentsData {
  patchOne: Array<DocumentData>;
  patchTwo: Array<DocumentData>;
  patchThree: Array<DocumentData>;
}

interface DocumentData {
  id: string;
  name: string;
  value: string;
  patch: number;
}

type ValueType = {
  from: Date;
  to: Date;
};

interface InputCase {
  policies?: Array<inputDetailCase>;
  policyNumber: string;
  status: number;
  id: string;
  claimReference?: string;
  sumAssured?: string;
  declineCode?: string;
  decisionBy?: string;
  declineDescription?: string;
  decisionAt?: string;
  decisionStatus?: string;
}

interface inputDetailCase {
  riderDetailCode?: number;
  inputValue?: string;
  value?: number;
  isOverride?: boolean;
  id: string;
  amount?: string;
}

interface inputDetail {
  riderDetailCode?: number;
  inputValue?: string;
  value?: number;
  amount?: string;
  isOverride?: boolean;
}

interface inputDisease {
  name?: string;
  value?: string;
}

const mapStateToProp = (state) => ({
  user: state.user,
  unSavedChange: state.unSavedChange,
});

class RequestAssessmentScreen extends React.Component<IProps, IState> {
  timeout;

  submitData = { picAddition: [] };

  modalContext: string = '';

  fileName: Array<string> = [];

  // @ts-ignore
  rootData: inputData = {};

  constructor(props) {
    super(props);
    this.state = {
      data: {
        pendingLimit: 5,
        picAddition: [],
        adjustInfo: null,
        isDecision: false,
        updatedAt: new Date(),
      },
      pendingData: [],
      updatePendingData: { pendings: [] },
      dataCase: {},
      documents: { patchOne: [], patchTwo: [], patchThree: [] },
      editMedicalFacilities: false,
      editSurgery: false,
      editMajorSurgery: false,
      editHospital: false,
      editICU: false,
      editDiseases: false,
      editTotalHospitalDays: false,
      editTotalICUDays: false,
      toggleMoney: true,
      toggleFraud: true,
      toggleClaim: true,
      toggleHolder: true,
      toggleDocument: true,
      toggleSignOff: true,
      modalPendingShow: false,
      modalEditPendingShow: false,
      modalConfirmShow: false,
      togglePendingCreate: true,
      claimNumberDel: '',
      docType: 0,
      showAlert: false,
      signOffInfo: '',
      confirmDecision: false,
      modalFraudShow: false,
      dataFraudClaim: [],
      showComparisonPaperModal: false,
      urlComparisonPaper: '',
    };
    props.dispatch(setLoading(true));
  }

  componentDidMount() {
    this.getFirstLoad().then();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.unSavedChange !== prevProps.unSavedChange) {
      if (this.props.unSavedChange) {
        window.onbeforeunload = (event) => {
          return '';
        };
      } else {
        window.onbeforeunload = null;
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    this.rootData = {};
  }

  getFirstLoad = async () => {
    const { id } = this.props.match.params;
    this.props.dispatch(setLoading(true));
    await caolan.auto(
      {
        medicalFacilities: (callback) => {
          ClaimPortalService.getMedicalProvider().then((data) => {
            callback(null, data);
          });
        },
        declineCodes: (callback) => {
          ClaimPortalService.getMetadata(KEYS.LIST.DECLINE_CODE).then(
            (data) => {
              callback(null, data);
            }
          );
        },
        pendingCodes: (callback) => {
          ClaimPortalService.getMetadata(
            KEYS.LIST.PENDING_CODE,
            undefined,
            undefined,
            100
          ).then((data) => {
            callback(null, data);
          });
        },
        getClaimData: (callback) => {
          this.getClaimData(true).then((data) => {
            callback(null, data);
          });
        },
        getDocuments: (callback) => {
          this.getDocuments(true).then((data) => {
            callback(null, data);
          });
        },
        getClaimCase: (callback) => {
          if (id) {
            ClaimPortalService.getAssessmentClaimCases(id).then((data) => {
              callback(null, data);
            });
          } else {
            callback(null, null);
          }
        },
        getFollowUpCode: (callback) => {
          ClaimPortalService.getMetadata(KEYS.LIST.FOLLOWUP_CODE).then(
            (data) => {
              callback(null, data);
            }
          );
        },
        getFraudClaim: (callback) => {
          if (id) {
            ClaimPortalService.checkFraudClaim(id).then((data) => {
              callback(null, data);
            });
          } else {
            callback(null, null);
          }
        },
        getSignoffInfo: (callback) => {
          if (id) {
            ClaimPortalService.getAssessmentSignOff(id).then((data) => {
              callback(null, data);
            });
          } else {
            callback(null, null);
          }
        },
        getDiseasesList: [
          'getClaimData',
          (results, callback) => {
            const diseaseList =
              results.getClaimData &&
              results.getClaimData.diseases &&
              results.getClaimData.diseases.length &&
              results.getClaimData.diseases.map((disease) => {
                return disease.value;
              });
            callback(null, diseaseList);
          },
        ],
        getDiseases: [
          'getDiseasesList',
          (results, callback) => {
            const diseaseList = results.getClaimData.diseases.map((disease) => {
              return disease.value;
            });
            ClaimPortalService.getMetadata(
              KEYS.LIST.DISEASES,
              '',
              diseaseList && utils.formatArrayToExcludeString(diseaseList)
            ).then((data) => callback(null, data));
          },
        ],
        getRiderDate: [
          'getClaimData',
          (results, callback) => {
            const data = results.getClaimData;
            if (data && data.details) {
              const hospital = _.find(data.details, {
                riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0),
              });
              const icu = _.find(data.details, {
                riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0),
              });
              if (hospital) {
                data[KEYS.DAYS_IN_HOSPITAL] = this.formatRiderDate(
                  hospital.inputValue
                );
              }
              if (icu) {
                data[KEYS.DAYS_IN_ICU] = this.formatRiderDate(icu.inputValue);
              }
              callback(null, data);
            } else {
              callback(null, null);
            }
          },
        ],
        setListState: [
          'medicalFacilities',
          'getDiseases',
          'declineCodes',
          'pendingCodes',
          'getFollowUpCode',
          (results, callback) => {
            const provinces = _.map(
              _.uniqBy(results.medicalFacilities, 'extra'),
              'extra'
            );
            const facilities = _.groupBy(results.medicalFacilities, 'extra');
            this.setState(
              {
                list: {
                  provinces,
                  facilities,
                  diseases: results.getDiseases,
                  diseasesRoot: _.clone(results.getDiseases),
                  medicalFacilities: results.medicalFacilities,
                  declineCodes: results.declineCodes,
                  pending: results.pendingCodes,
                  followUpCodes: results.getFollowUpCode,
                },
              },
              callback
            );
          },
        ],
        setDataState: [
          'getRiderDate',
          'getClaimCase',
          'getDiseasesList',
          (results, callback) => {
            const data = results.getRiderDate;
            data.cases = results.getClaimCase;
            data.diseasesList = results.getDiseasesList;
            this.rootData = _.cloneDeep(data);
            this.setState(
              {
                data,
              },
              callback
            );
          },
        ],
        setEditTotalDays: [
          'getClaimData',
          (results, callback) => {
            const data = results.getClaimData;
            let editTotalHospitalDays = false;
            let editTotalICUDays = false;
            if (data && data.details) {
              const hospital = _.find(data.details, {
                riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0),
              });
              const icu = _.find(data.details, {
                riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0),
              });
              if (hospital) {
                editTotalHospitalDays = hospital.isOverride;
              }
              if (icu) {
                editTotalICUDays = icu.isOverride;
              }
              this.setState(
                {
                  editTotalHospitalDays,
                  editTotalICUDays,
                },
                callback
              );
            }
          },
        ],
        setToggleFraudState: [
          'getFraudClaim',
          'getSignoffInfo',
          (results, callback) => {
            this.setState(
              {
                dataFraudClaim: results.getFraudClaim,
                signOffInfo: results.getSignoffInfo,
              },
              callback
            );
          },
        ],
      },
      (error, results) => {
        this.setState(
          {
            documents: results.getDocuments,
          },
          () => {
            this.getDocumentsDetail();
          }
        );
      }
    );
  };

  getDocumentsDetail = () => {
    const { id } = this.props.match.params;
    const { documents }: Readonly<IState> = this.state;

    const patchOneHospital = documents[KEYS.SUBMISSION_DOCS_PATCH]
      .map((val) => {
        if (val.name === KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER) {
          return val.id;
        }
        return undefined;
      })
      .filter((element) => {
        return element !== undefined;
      });

    const patchOneOther = documents[KEYS.SUBMISSION_DOCS_PATCH]
      .map((val) => {
        if (val.name === KEYS.FIELD.OTHERS_PAPER) {
          return val.id;
        }
        return undefined;
      })
      .filter((element) => {
        return element !== undefined;
      });

    const patchTwoOther = documents[KEYS.PORTAL_DOCS_PATCH]
      .map((val) => {
        if (val.name === KEYS.FIELD.OTHERS_PAPER) {
          return val.id;
        }
        return undefined;
      })
      .filter((element) => {
        return element !== undefined;
      });

    const patchTwoInvestigation = documents[KEYS.PORTAL_DOCS_PATCH]
      .map((val) => {
        if (val.name === KEYS.FIELD.INVESTIGATION_PAPER) {
          return val.id;
        }
        return undefined;
      })
      .filter((element) => {
        return element !== undefined;
      });

    const patchThree = documents[KEYS.TRACKING_DOCS_PATCH].map((val) => {
      return val.id;
    });

    if (id) {
      caolan.auto(
        {
          patchOneHospital: (callback) => {
            caolan.map(
              patchOneHospital,
              (idDoc, callbackMap) => {
                ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                  callbackMap(null, data);
                });
              },
              (err, results) => {
                callback(err, results);
              }
            );
          },

          patchOneOther: (callback) => {
            caolan.map(
              patchOneOther,
              (idDoc, callbackMap) => {
                ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                  callbackMap(null, data);
                });
              },
              (err, results) => {
                callback(err, results);
              }
            );
          },

          patchTwoOther: (callback) => {
            caolan.map(
              patchTwoOther,
              (idDoc, callbackMap) => {
                ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                  callbackMap(null, data);
                });
              },
              (err, results) => {
                callback(err, results);
              }
            );
          },

          patchTwoInvestigation: (callback) => {
            caolan.map(
              patchTwoInvestigation,
              (idDoc, callbackMap) => {
                ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                  callbackMap(null, data);
                });
              },
              (err, results) => {
                callback(err, results);
              }
            );
          },

          patchThree: (callback) => {
            caolan.map(
              patchThree,
              (idDoc, callbackMap) => {
                ClaimPortalService.getDetailDocument(id, idDoc).then((data) => {
                  callbackMap(null, data);
                });
              },
              (err, results) => {
                callback(err, results);
              }
            );
          },
        },
        async (err, data) => {
          const newDocuments = _.clone(documents);
          const patchOneOtherFinal = this.mapDocumentAfterCallApi(
            data.patchOneOther,
            KEYS.FIELD.OTHERS_PAPER
          );
          const patchOneHospitalFinal = this.mapDocumentAfterCallApi(
            data.patchOneHospital,
            KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER
          );
          const patchTwoInvestigationFinal = this.mapDocumentAfterCallApi(
            data.patchTwoInvestigation,
            KEYS.FIELD.INVESTIGATION_PAPER
          );
          const patchTwoOtherFinal = this.mapDocumentAfterCallApi(
            data.patchTwoOther,
            KEYS.FIELD.OTHERS_PAPER
          );
          newDocuments[KEYS.SUBMISSION_DOCS_PATCH] =
            patchOneHospitalFinal.concat(patchOneOtherFinal);
          newDocuments[KEYS.PORTAL_DOCS_PATCH] =
            patchTwoInvestigationFinal.concat(patchTwoOtherFinal);
          newDocuments[KEYS.TRACKING_DOCS_PATCH] = this.mapDocumentAfterCallApi(
            data.patchThree,
            KEYS.FIELD.OTHERS_PAPER
          );

          const patchOne = await this.setNewPatchDocument(
            newDocuments.patchOne
          );
          const patchTwo = await this.setNewPatchDocument(
            newDocuments.patchTwo
          );
          const patchThree = await this.setNewPatchDocument(
            newDocuments.patchThree
          );
          const newDocumentsWithLargeFile = {
            patchOne,
            patchTwo,
            patchThree,
          };

          this.setState({
            documents: newDocumentsWithLargeFile,
          });
        }
      );
    }
  };

  setNewPatchDocument = async (patch: DocumentData[]) => {
    const response = patch.map(async (document) => {
      const documentDetail = document.value as any;
      if (documentDetail.isLargeFile) {
        const url = await ClaimPortalService.getObjectPreSignedUrl(
          documentDetail.fileNameS3,
          KEYS.PRESIGNED_URL.BUCKET_NAME,
          KEYS.PRESIGNED_URL.EXPIRED_MINUTE
        );

        const image = await fetch(url, {
          method: 'GET',
        });

        const base64 = await utils.blobToBase64(await image.blob());

        const newDocumentDetail = { ...documentDetail, value: base64 };
        return { ...document, value: newDocumentDetail };
      }
      return document;
    });

    return await Promise.all(response);
  };

  mapDocumentAfterCallApi = (data: Array<string>, typePaper: string) => {
    const newData: Array<{ name?: string; value?: string }> = [];
    data.map((val) => {
      const newArray: { name?: string; value?: string } = {};
      newArray.name = typePaper;
      newArray.value = val;
      return newData.push(newArray);
    });
    return newData;
  };

  getClaimData = async (isReturn = false) => {
    const { id } = this.props.match.params;
    const { data } = this.state;
    if (id) {
      const newData = await ClaimPortalService.getAssessmentClaim(id);
      newData.cases = data.cases;

      if (isReturn) {
        if (newData && newData.details) {
          const hospital = _.find(newData.details, {
            riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0),
          });
          const icu = _.find(newData.details, {
            riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0),
          });
          if (hospital) {
            newData[KEYS.DAYS_IN_HOSPITAL] = this.formatRiderDate(
              hospital.inputValue
            );
          }
          if (icu) {
            newData[KEYS.DAYS_IN_ICU] = this.formatRiderDate(icu.inputValue);
          }
        }
        return newData;
      }

      this.setState({ data: newData });
    }
    return null;
  };

  getDocuments = async (isReturn = false) => {
    const { id } = this.props.match.params;
    const { documents } = this.state;
    if (id) {
      const newDocuments = await ClaimPortalService.getDocumentsClaim(id);
      if (newDocuments === null) {
        return documents;
      }
      if (isReturn) {
        return newDocuments;
      }
      this.setState(
        {
          documents: newDocuments,
        },
        () => {
          this.getDocumentsDetail();
        }
      );
    }
    return null;
  };

  getDataAfterPendingAction = async () => {
    const { id } = this.props.match.params;
    this.props.dispatch(setLoading(true));
    await caolan.auto(
      {
        getClaimData: (callback) => {
          this.getClaimData(true).then((data) => {
            callback(null, data);
          });
        },
        getClaimCase: (callback) => {
          if (id) {
            ClaimPortalService.getAssessmentClaimCases(id).then((data) => {
              callback(null, data);
            });
          } else {
            callback(null, null);
          }
        },
        getRiderDate: [
          'getClaimData',
          (results, callback) => {
            const data = results.getClaimData;
            if (data && data.details) {
              const hospital = _.find(data.details, {
                riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0),
              });
              const icu = _.find(data.details, {
                riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0),
              });
              if (hospital) {
                data[KEYS.DAYS_IN_HOSPITAL] = this.formatRiderDate(
                  hospital.inputValue
                );
              }
              if (icu) {
                data[KEYS.DAYS_IN_ICU] = this.formatRiderDate(icu.inputValue);
              }
              callback(null, data);
            } else {
              callback(null, null);
            }
          },
        ],
        getDiseasesList: [
          'getClaimData',
          (results, callback) => {
            const diseaseList =
              results.getClaimData &&
              results.getClaimData.diseases &&
              results.getClaimData.diseases.length &&
              results.getClaimData.diseases.map((disease) => {
                return disease.value;
              });
            callback(null, diseaseList);
          },
        ],
        setDataState: [
          'getRiderDate',
          'getClaimCase',
          'getDiseasesList',
          (results, callback) => {
            const data = results.getRiderDate;
            data.cases = results.getClaimCase;
            data.diseasesList = results.getDiseasesList;
            this.setState(
              {
                data,
                pendingData: [],
              },
              callback
            );
          },
        ],
      },
      (error, results) => {}
    );
  };

  handleChangeProvince = (value: string) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    newData[KEYS.FIELD.PROVINCE] = value;
    newData[KEYS.FIELD.MEDICAL_PROVIDER] = '';
    this.setState({
      data: newData,
    });
  };

  formatRiderDate = (date: string) => {
    if (date) {
      const fromDate = new Date(
        utils.formatDateString(date.split('-')[0].trim())
      );
      const toDate = new Date(
        utils.formatDateString(date.split('-')[1].trim())
      );
      return { from: fromDate, to: toDate };
    }
    return 0;
  };

  updateRiderDate = (name: string) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    if (newData[name] && newData[name].from && newData[name].to) {
      const hospital = _.find(newData.details, {
        riderDetailCode: parseInt(name, 0),
      });
      if (hospital) {
        hospital.inputValue = utils
          .formatDateRangeToStringDateDMY({
            from: newData[name].from,
            to: newData[name].to,
          })
          .replace('&', ' - ');
      }
    }
  };

  editDiseases = () => {
    const { editDiseases } = this.state;
    this.setState(
      {
        editDiseases: !editDiseases,
      },
      () => {
        this.checkUnSavedChange();
      }
    );
  };

  editMedicalFacilities = () => {
    const { editMedicalFacilities } = this.state;
    this.setState(
      {
        editMedicalFacilities: !editMedicalFacilities,
      },
      () => {
        this.checkUnSavedChange();
      }
    );
  };

  editRider = (name: number | undefined) => {
    const {
      editSurgery,
      editHospital,
      editICU,
      editMajorSurgery,
      data,
    }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    if (name === parseInt(KEYS.TIMES_FOR_SURGERY, 0)) {
      this.setState(
        {
          editSurgery: !editSurgery,
        },
        () => {
          this.checkUnSavedChange();
        }
      );
    }
    if (name === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0)) {
      this.setState(
        {
          editMajorSurgery: !editMajorSurgery,
        },
        () => {
          this.checkUnSavedChange();
        }
      );
    }
    if (name === parseInt(KEYS.DAYS_IN_HOSPITAL, 0)) {
      if (
        !newData[KEYS.DAYS_IN_HOSPITAL].from ||
        !newData[KEYS.DAYS_IN_HOSPITAL].to
      ) {
        // If empty value and no ICU value -> API value ELSE ICU value
        if (!newData[KEYS.DAYS_IN_ICU].from || !newData[KEYS.DAYS_IN_ICU].to) {
          const hospital = _.find(newData.details, {
            riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0),
          });
          if (hospital) {
            newData[KEYS.DAYS_IN_HOSPITAL] = this.formatRiderDate(
              // @ts-ignore
              hospital.inputValue
            );
          }
          this.setState(
            {
              data: newData,
              editHospital: !editHospital,
            },
            () => {
              this.checkUnSavedChange();
            }
          );
        } else {
          const hospital = _.find(newData.details, {
            riderDetailCode: parseInt(KEYS.DAYS_IN_HOSPITAL, 0),
          });
          if (hospital) {
            hospital.inputValue = utils.formatDateRangeToStringDateDMY(
              newData[KEYS.DAYS_IN_ICU]
            );
          }
          newData[KEYS.DAYS_IN_HOSPITAL] = newData[KEYS.DAYS_IN_ICU];
          this.setState(
            {
              data: newData,
              editHospital: !editHospital,
            },
            () => {
              this.checkUnSavedChange();
            }
          );
        }
      } else {
        this.setState(
          {
            editHospital: !editHospital,
          },
          () => {
            this.checkUnSavedChange();
          }
        );
      }
    }
    if (name === parseInt(KEYS.DAYS_IN_ICU, 0)) {
      if (!newData[KEYS.DAYS_IN_ICU].from || !newData[KEYS.DAYS_IN_ICU].to) {
        const icu = _.find(newData.details, {
          riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0),
        });
        if (icu) {
          icu.inputValue = '';
        }
      }
      this.setState(
        {
          editICU: !editICU,
        },
        () => {
          this.checkUnSavedChange();
        }
      );
    }
  };

  toggleFraud = () => {
    const { toggleFraud } = this.state;
    this.setState({
      toggleFraud: !toggleFraud,
    });
  };

  toggleClaim = () => {
    const { toggleClaim } = this.state;
    this.setState({
      toggleClaim: !toggleClaim,
    });
  };

  toggleHolder = () => {
    const { toggleHolder } = this.state;
    this.setState({
      toggleHolder: !toggleHolder,
    });
  };

  toggleMoney = () => {
    const { toggleMoney } = this.state;
    this.setState({
      toggleMoney: !toggleMoney,
    });
  };

  toggleDocument = () => {
    const { toggleDocument } = this.state;
    this.setState({
      toggleDocument: !toggleDocument,
    });
  };

  toggleSignOff = () => {
    const { toggleSignOff } = this.state;
    this.setState({
      toggleSignOff: !toggleSignOff,
    });
  };

  togglePendingCreate = () => {
    const { togglePendingCreate } = this.state;
    this.setState({
      togglePendingCreate: !togglePendingCreate,
    });
  };

  updateRider = (riders: Array<inputDetail>, name: number, value: string) => {
    if (name === KEYS.TIMES_FOR_SURGERY_NAME) {
      const majorSurgery = _.find(riders, {
        riderDetailCode: KEYS.TIMES_FOR_MAJOR_SURGERY_NAME,
      });
      if (majorSurgery) {
        majorSurgery.inputValue = '0';
      }
    } else {
      const surgery = _.find(riders, {
        riderDetailCode: KEYS.TIMES_FOR_SURGERY_NAME,
      });
      if (surgery) {
        surgery.inputValue = '0';
      }
    }
    const rider = _.find(riders, { riderDetailCode: name });
    if (rider) {
      rider.inputValue = value;
    }
  };

  handleCase = (cases?: Array<InputCase>) => {
    if (cases) {
      return u.pluck(cases, 'id');
    }
    return [];
  };

  handleRiderDataCase = (id: string, value) => {
    const { dataCase } = this.state;
    const newDataCase = _.clone(dataCase);
    newDataCase[id] = value.target.value;
    this.setState({
      dataCase: newDataCase,
    });
  };

  handleCreatePending = (name, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);

    if (name === KEYS.FIELD.PENDING && value) {
      const newPendingData = _.clone(this.state.pendingData);
      if (!newData[name]) {
        newData[name] = [];
      }
      if (
        newPendingData.length < data.pendingLimit &&
        newData[name].length < data.pendingLimit
      ) {
        newData[name] = value;
        if (newPendingData.length === value.length) {
          this.setState({
            pendingData: newPendingData,
            modalPendingShow: false,
          });
        } else {
          this.setState({
            modalPendingShow: true,
          });
        }
        newData[name] = value;
        this.setState({
          data: newData,
        });
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'warning',
            text: `Chỉ được chọn tối đa ${data.pendingLimit} pending codes, hãy kiểm tra lại`,
          })
        );
      }
      newData[KEYS.FIELD.PENDING_TEXT] = '';
    }
  };

  handleCreatePendingAfterUpdate = (name, value, oldValue) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);

    if (name === KEYS.FIELD.PENDING && value) {
      const newPendingData = _.clone(this.state.pendingData);
      const oldPendingData = _.clone(this.state.data.pendings);
      if (!newData[name]) {
        newData[name] = [];
      }
      if (
        oldPendingData &&
        newPendingData.length + oldPendingData.length < data.pendingLimit
      ) {
        if (_.difference(value, oldValue).length) {
          newData[name] = value;
          if (newPendingData.length === value.length) {
            this.setState({
              pendingData: newPendingData,
              modalPendingShow: false,
            });
          } else {
            this.setState({
              modalPendingShow: true,
            });
          }
          this.setState({
            data: newData,
          });
        }
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'warning',
            text: `Chỉ được chọn tối đa ${data.pendingLimit} pending codes, hãy kiểm tra lại`,
          })
        );
      }
      newData[KEYS.FIELD.PENDING_TEXT] = '';
    }
  };

  getPendingValue = (data: inputData) => {
    let valuePending: string[] = [];
    if (data && data.pendings) {
      data.pendings.forEach(
        (pending) => !pending.removedAt && valuePending.push(pending.code)
      );
    }
    if (data.pending) {
      valuePending = valuePending.concat(
        _.difference(data.pending, valuePending)
      );
    }
    return valuePending;
  };

  handleTextArea = (name, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    if (value.target.value === '') {
      delete newData[name];
    } else if (
      name === `${KEYS.FIELD.SIGNS_OFF}_input` ||
      name === KEYS.FIELD.PENDING_TEXT
    ) {
      newData[name] = value.target.value;
    }
    this.setState({ data: newData }, () => {
      this.compareDataAndRootData(this.state.data, this.rootData);
    });
  };

  handleChangeTotalDays = (name, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    if (
      newData.details &&
      (name === KEYS.DAYS_IN_HOSPITAL || name === KEYS.DAYS_IN_ICU)
    ) {
      const rider = _.find(newData.details, {
        riderDetailCode: parseInt(name, 0),
      });
      if (rider) {
        rider.value = parseInt(value.target.value, 0);
      }
      this.setState({
        data: newData,
      });
    }
  };

  handleChange = (name, value, fileName?) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    if (fileName) {
      this.fileName = fileName;
    }
    // @ts-ignore
    if (this.handleCase(newData.cases).includes(name)) {
      this.updateClaimCase(name, value, newData.cases);
    }

    if (
      newData.details &&
      (name === KEYS.TIMES_FOR_MAJOR_SURGERY || name === KEYS.TIMES_FOR_SURGERY)
    ) {
      this.updateRider(newData.details, parseInt(name, 0), value.target.value);
    }

    if (
      (Array.isArray(value) && value.length) ||
      (value !== null && value !== undefined)
    ) {
      if (name === KEYS.DAYS_IN_HOSPITAL) {
        newData[KEYS.DAYS_IN_ICU] = { from: null, to: null };
        this.updateRiderDate(name);
        const icu = _.find(newData.details, {
          riderDetailCode: parseInt(KEYS.DAYS_IN_ICU, 0),
        });
        if (icu) {
          icu.inputValue = ' ';
        }
      }
      if (name === KEYS.DAYS_IN_ICU) {
        this.updateRiderDate(name);
      }
    }

    newData[name] = value;
    this.setState({ data: newData }, () => {
      this.compareDataAndRootData(this.state.data, this.rootData);
    });
  };

  removePendingCode = (code: string) => {
    let newPendingData = _.clone(this.state.pendingData);
    const newData = _.clone(this.state.data);
    if (newPendingData && newData) {
      newPendingData = _.filter(newPendingData, function (pending) {
        return pending.code !== code;
      });
      newData[KEYS.FIELD.PENDING] = _.remove(
        newData[KEYS.FIELD.PENDING],
        function (n) {
          return n !== code;
        }
      );
      this.setState(
        {
          pendingData: newPendingData,
          data: newData,
        },
        () => this.compareDataAndRootData(this.state.data, this.rootData)
      );
    }
  };

  handleFollowUpStatus = (
    id: string,
    status: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { updatePendingData, data } = this.state;
    const newUpdatePendingData = _.clone(updatePendingData);
    const pending = _.find(newUpdatePendingData.pendings, ['id', id]);

    if (pending) {
      pending.status = status.target.value;
    }

    this.setState(
      {
        updatePendingData: newUpdatePendingData,
      },
      () => this.compareDataAndRootData(data, this.rootData)
    );
  };

  checkExistIdUpdatePending = (id: string) => {
    const { updatePendingData } = this.state;
    const newUpdatePendingData = _.clone(updatePendingData);

    const element = _.find(newUpdatePendingData.pendings, ['id', id]);
    return !!element;
  };

  removeUpdatedPending = (code: string, status: string) => {
    const { updatePendingData, data } = this.state;
    const newUpdatePendingData: UpdatePendingClaimData =
      _.clone(updatePendingData);
    const pending = _.find(newUpdatePendingData.pendings, ['id', code]);

    if (pending) {
      newUpdatePendingData.pendings = _.remove(
        newUpdatePendingData.pendings,
        function (object) {
          return object.id !== code;
        }
      );
    } else {
      newUpdatePendingData.pendings.push({ id: code, status: status });
    }

    this.setState(
      {
        updatePendingData: newUpdatePendingData,
      },
      () => this.compareDataAndRootData(data, this.rootData)
    );
  };

  createPendingClaim = async () => {
    const { id } = this.props.match.params;
    const { t } = this.props;
    const { pendingData } = this.state;
    if (id && pendingData) {
      const response = await ClaimPortalService.createClaimPending(
        id,
        pendingData
      );
      if (response && response.Errors) {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: response.Errors[0].Message,
          })
        );
      } else {
        this.props.dispatch(setUnSavedChange(false));
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: t('portal.assessment.pending_logic.create_successfully'),
          })
        );
        this.getDataAfterPendingAction().then();
      }
    }
  };

  updatePendingClaim = async () => {
    const { id } = this.props.match.params;
    const { t } = this.props;
    const { updatePendingData } = this.state;
    if (id && updatePendingData) {
      const response = await ClaimPortalService.updateClaimPending(
        id,
        updatePendingData
      );
      if (response && response.Errors) {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: response.Errors[0].Message,
          })
        );
      } else {
        this.props.dispatch(setUnSavedChange(false));
        this.setState({
          updatePendingData: { pendings: [] },
        });
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: t('portal.assessment.pending_logic.update_successfully'),
          })
        );
        this.getDataAfterPendingAction().then();
      }
    }
  };

  getValueDocuments = (
    documents: Array<{ name: string; value: documentData }>,
    label: string
  ) => {
    const newDataDocument: documentData[] = [];
    documents.forEach((doc) => {
      if (doc.name && typeof doc.value === 'object') {
        if (doc.name === label) {
          newDataDocument.push(doc.value);
        }
      }
    });
    return newDataDocument;
  };

  mapValueToNameMedicalFacility = (
    medicalFacilities: Array<{ name: string; value: string }>,
    value: string
  ) => {
    const medicalProvider = _.find(medicalFacilities, { value });
    if (medicalProvider) {
      return medicalProvider.name;
    }
    return null;
  };

  handleClosePendingCode = () => {
    const newData = _.clone(this.state.data);
    if (newData && newData.pending) {
      const code = newData.pending[newData.pending.length - 1];
      newData[KEYS.FIELD.PENDING] = _.remove(
        newData[KEYS.FIELD.PENDING],
        function (n) {
          return n !== code;
        }
      );
      this.setState({
        modalPendingShow: false,
        data: newData,
      });
    }
  };

  handleToggleAssessmentModal = (modalContext: string) => {
    const { modalConfirmShow } = this.state;
    this.modalContext = modalContext;
    this.setState({
      modalConfirmShow: !modalConfirmShow,
    });
  };

  handleConfirmAssessmentModal = () => {
    this.setState({
      modalConfirmShow: false,
    });
    if (this.modalContext === KEYS.UPDATE_DECISION) {
      this.decisionCaseFWD();
    } else if (this.modalContext === KEYS.UPDATE_ADJUST) {
      this.adjustAssessmentFWD();
    } else if (this.modalContext === KEYS.UPDATE_DETAIL) {
      this.updateDetailAssessment();
    } else if (this.modalContext === KEYS.DELETE_CLAIM) {
      this.deleteClaim();
    } else if (this.modalContext === KEYS.UPDATE_PAPER) {
      this.handleUpdateDocument();
    }
  };

  saveCreatePendingCode = () => {
    const { data, pendingData } = this.state;
    const newPendingData = _.clone(pendingData);
    if (newPendingData && data.pending) {
      const code = data.pending[data.pending.length - 1];
      newPendingData.push({ code: code, extendedText: data.pendingText || '' });
    }
    this.setState(
      {
        pendingData: newPendingData,
        modalPendingShow: false,
      },
      () => this.compareDataAndRootData(data, this.rootData)
    );
  };

  saveEditPendingCode = () => {
    const { data, pendingData } = this.state;
    const newPendingData = _.clone(pendingData);
    if (newPendingData && data.pending) {
      const code = data.pending[data.pending.length - 1];
      newPendingData.push({ code: code, extendedText: data.pendingText || '' });
    }
    this.setState(
      {
        pendingData: newPendingData,
        modalPendingShow: false,
      },
      () => this.compareDataAndRootData(data, this.rootData)
    );
  };

  saveEditPendingExtendText = () => {
    const { data, editPendingId, updatePendingData } = this.state;
    const newData = _.clone(data);
    let newUpdatePendingData = _.clone(updatePendingData);
    const pendingList = newData.pendings;
    const pendingIndex = _.findIndex(pendingList, { id: editPendingId });
    if (pendingList && pendingList[pendingIndex] && newData.editPendingText) {
      pendingList[pendingIndex].extendedText = newData.editPendingText;
    }
    const newPendings = newUpdatePendingData.pendings;
    if (updatePendingData) {
      if (!updatePendingData.pendings) {
        newUpdatePendingData = { pendings: [] };
      }
      const indexUpdatePending = _.findIndex(newUpdatePendingData.pendings, {
        id: editPendingId,
      });

      if (editPendingId) {
        if (indexUpdatePending !== -1) {
          newPendings[indexUpdatePending] = {
            id: editPendingId,
            status: 'o',
            extendedText: newData.editPendingText,
          };
        } else {
          newPendings.push({
            id: editPendingId,
            status: 'o',
            extendedText: newData.editPendingText,
          });
        }
        newUpdatePendingData.pendings = newPendings;
      }
    }
    newData.editPendingText = '';
    newData.pendings = pendingList;
    this.setState({
      data: newData,
      updatePendingData: newUpdatePendingData,
      modalEditPendingShow: false,
    });
  };

  handleUpdateDocument = async () => {
    const { data, docType } = this.state;
    const { id } = this.props.match.params;
    const newData = _.clone(data);
    this.submitData.picAddition = u.pluck(newData.picAddition, 'value');
    const docs = [];
    let DocType: number = KEYS.IMAGES.Others;
    if (docType === KEYS.IMAGES.InvestigationPaper) {
      DocType = KEYS.IMAGES.InvestigationPaper;
    }

    if (newData.picAddition && id) {
      const sessionDocs = {
        DocType,
        value: this.submitData.picAddition,
      };
      // @ts-ignore
      docs[0] = sessionDocs;
      if (docs && docs.length) {
        for (const doc of docs) {
          // @ts-ignore
          if (doc && doc.value.length) {
            // @ts-ignore
            for (const base64 of doc.value) {
              // @ts-ignore
              const index = doc.value.indexOf(base64);
              const block = base64.split(';');
              const contentType = block[0].split(':')[1]; // In this case "image/gif"
              const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
              const blob = utils.b64toBlob(realData, contentType);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const file = new FormData();

              // @ts-ignore
              file.append(KEYS.FIELD.DOC_TYPE, doc.DocType);
              // eslint-disable-next-line no-await-in-loop

              if (blob.size > KEYS.UPLOAD_IMAGE.LIMIT) {
                const now = new Date();
                let fileName = `${now.toISOString()}_${this.fileName[index]}`;

                const url = await ClaimPortalService.getPreSignedUrl(
                  fileName,
                  KEYS.PRESIGNED_URL.BUCKET_NAME,
                  KEYS.PRESIGNED_URL.EXPIRED_MINUTE
                );

                const response = await fetch(url, {
                  method: 'PUT',
                  body: blob,
                });

                if (response) {
                  const fileType = logo.split(':')[1];
                  const base64 = logo.split(',')[1];
                  const data = utils.b64toBlob(base64, fileType);
                  file.append(KEYS.FIELD.FILE, data, 'Logo.png');
                  file.append('LargeFile.FileName', fileName);
                  file.append('LargeFile.ContentType', contentType);
                  file.append(
                    'LargeFile.BucketName',
                    KEYS.PRESIGNED_URL.BUCKET_NAME
                  );
                }
              } else {
                file.append(KEYS.FIELD.FILE, blob, this.fileName[index]);
              }
              await ClaimPortalService.uploadDocumentPortal(id, file, () => {});
            }
          }
        }
        await this.getDocuments().then();
        newData.picAddition = [];
        this.setState({
          data: newData,
        });
        return true;
      }
      return false;
    }
    return false;
  };

  handleSearchDiseases = async (name: string, value: string) => {
    const { data, list }: Readonly<IState> = this.state;
    const newList = list;
    if (newList) {
      clearTimeout(this.timeout);
      newList[KEYS.LIST.DISEASES] = [];
      this.setState({
        list: newList,
      });
      newList[KEYS.LIST.DISEASES] = await ClaimPortalService.getMetadata(
        KEYS.FIELD.DISEASES,
        value,
        data.diseasesList &&
          utils.formatArrayToExcludeString(data.diseasesList),
        10,
        true
      );
      newList.diseasesRoot = _.clone(newList[KEYS.LIST.DISEASES]);
      this.timeout = setTimeout(async () => {
        this.setState({
          list: newList,
        });
      }, 300);
    }
  };

  updateClaimCase = (name: string, value: number, cases?: Array<InputCase>) => {
    if (cases) {
      const claimCase = _.find(cases, { id: name });
      if (claimCase) {
        claimCase.status = value;
      }
    }
  };

  adjustAssessmentFWD = async () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    if (id) {
      const response = await ClaimPortalService.adjustAssessment(id);
      if (response.adjustInfo) {
        const { data }: Readonly<IState> = this.state;
        const newData = _.clone(data);
        newData.isDecision = response.isDecision;
        newData.adjustInfo = response.adjustInfo;
        this.setState({
          data: newData,
        });
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'success',
            text: t('portal.assessment.action.successAdjust'),
          })
        );
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: t('portal.assessment.action.errorAdjust'),
          })
        );
      }
    }
  };

  decisionCaseFWD = async (isConfirm?: boolean) => {
    const { id } = this.props.match.params;
    if (id) {
      const response =
        (isConfirm &&
          (await ClaimPortalService.decisionAssessment(id, {
            isConfirm: true,
          }))) ||
        (await ClaimPortalService.decisionAssessment(id));
      if (response && !response.Errors) {
        if (id) {
          const { data }: Readonly<IState> = this.state;
          ClaimPortalService.getAssessmentClaimCases(id).then((caseData) => {
            data.cases = caseData;
            this.setState({
              data,
            });
          });
        }
        this.setState({
          showAlert: true,
          confirmDecision: false,
          decisionData: response,
        });
      } else if (
        response &&
        response.Errors &&
        response.Errors[0].Code === KEYS.ERROR.DECISION_REQUIRED_FWD
      ) {
        this.setState({
          confirmDecision: true,
          decisionMessage: response.Errors[0].Message,
        });
      } else {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text: response.Errors[0].Message,
          })
        );
      }
    }
  };

  renderDiseases = (value: Array<string>) => {
    const { data, list } = this.state;
    let renderDiseases = '';
    if (data && data[KEYS.FIELD.DISEASES]) {
      renderDiseases = value
        .map((disease) => {
          let diseaseItem = _.find(data[KEYS.FIELD.DISEASES], {
            value: disease,
          });
          if (diseaseItem) {
            return `${diseaseItem.value}: ${diseaseItem.name}`;
          }
          if (list && list.diseasesRoot) {
            diseaseItem = _.find(list.diseasesRoot, { value: disease });
            if (diseaseItem) {
              return `${diseaseItem.value}: ${diseaseItem.name}`;
            }
          }

          return '';
        })
        .join('$SPLIT$ ');
    }
    return utils.toTxtComp(renderDiseases, '$SPLIT$');
  };

  updateDetailAssessment = async () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const { data, editTotalHospitalDays, editTotalICUDays } = this.state;
    let daysHospital = '';
    let daysICU = '';
    let totalICUDays: number = 0;
    let totalHospitalDays: number = 0;
    if (id && data) {
      if (
        data[KEYS.DAYS_IN_HOSPITAL] &&
        data[KEYS.DAYS_IN_HOSPITAL].from &&
        data[KEYS.DAYS_IN_HOSPITAL].to
      ) {
        daysHospital = utils.formatDateRangeToStringDate(
          data[KEYS.DAYS_IN_HOSPITAL]
        );
        const totalDays = _.find(data.details, {
          riderDetailCode: KEYS.DAYS_IN_HOSPITAL_NAME,
        });
        if (totalDays && totalDays.value) {
          totalHospitalDays = totalDays.value;
        }
      }
      if (
        data[KEYS.DAYS_IN_ICU] &&
        data[KEYS.DAYS_IN_ICU].from &&
        data[KEYS.DAYS_IN_ICU].to
      ) {
        daysICU =
          data[KEYS.DAYS_IN_ICU] &&
          utils.formatDateRangeToStringDate(data[KEYS.DAYS_IN_ICU]);
        const totalDays = _.find(data.details, {
          riderDetailCode: KEYS.DAYS_IN_ICU_NAME,
        });
        if (totalDays && totalDays.value) {
          totalICUDays = totalDays.value;
        }
      }

      const surgery = _.find(data.details, {
        riderDetailCode: KEYS.TIMES_FOR_SURGERY_NAME,
      });
      const majorSurgery = _.find(data.details, {
        riderDetailCode: KEYS.TIMES_FOR_MAJOR_SURGERY_NAME,
      });
      const detailAssessmentData = {
        medicalProvider: data.medicalProvider,
        updatedAt: data.updatedAt,
        diseases:
          data.diseasesList && utils.formatArrayToString(data.diseasesList),
        riders: [
          {
            riderDetailCode: KEYS.DAYS_IN_HOSPITAL_NAME,
            inputValue: daysHospital,
            value: totalHospitalDays,
            isOverride: editTotalHospitalDays,
          },
          {
            riderDetailCode: KEYS.DAYS_IN_ICU_NAME,
            inputValue: daysICU,
            value: totalICUDays,
            isOverride: editTotalICUDays,
          },
          {
            riderDetailCode: KEYS.TIMES_FOR_SURGERY_NAME,
            inputValue:
              (surgery && ((surgery.inputValue !== '0' && 'true') || null)) ||
              null,
            value: 0,
            isOverride: false,
          },
          {
            riderDetailCode: KEYS.TIMES_FOR_MAJOR_SURGERY_NAME,
            inputValue:
              (majorSurgery &&
                ((majorSurgery.inputValue !== '0' && 'true') || null)) ||
              null,
            value: 0,
            isOverride: false,
          },
        ],
      };
      if (data.signOffNotes_input && detailAssessmentData) {
        detailAssessmentData[KEYS.FIELD.SIGN_OFF] = data.signOffNotes_input;
      }
      const response = await ClaimPortalService.updateDetailAssessmentClaim(
        id,
        detailAssessmentData
      );

      if (response.Errors || response.status === 400) {
        this.props.dispatch(
          setToast({
            isShow: true,
            type: 'danger',
            text:
              response.Errors[0].Message ||
              t('portal.assessment.action.failDetail'),
          })
        );
      } else {
        this.props.dispatch(setUnSavedChange(false));
        const newData = await this.getClaimData(true);
        if (newData && !newData.Errors) {
          newData[KEYS.FIELD.DISEASES_LIST_PORTAL] = newData.diseases.map(
            (disease) => {
              return disease.value;
            }
          );
          const claimCases = await ClaimPortalService.getAssessmentClaimCases(
            id
          );
          if (claimCases) {
            newData.cases = claimCases;
          }
          let isOverrideHospital: boolean = false;
          let isOverrideICU: boolean = false;

          if (newData.details) {
            const hospital = _.find(newData.details, {
              riderDetailCode: KEYS.DAYS_IN_HOSPITAL_NAME,
            });
            const icu = _.find(newData.details, {
              riderDetailCode: KEYS.DAYS_IN_ICU_NAME,
            });
            if (hospital && icu) {
              isOverrideHospital = hospital.isOverride;
              isOverrideICU = icu.isOverride;
            }
          }
          this.rootData = newData;
          this.setState({
            data: newData,
            editTotalHospitalDays: isOverrideHospital,
            editTotalICUDays: isOverrideICU,
          });
          this.props.dispatch(
            setToast({
              isShow: true,
              type: 'success',
              text: t('portal.assessment.action.successDetail'),
            })
          );
        } else {
          this.props.dispatch(
            setToast({
              isShow: true,
              type: 'danger',
              text: newData.Errors[0].Message,
            })
          );
        }
      }
    }
  };

  updateJetStatus = async (jetStatus: boolean) => {
    const wantToChange = window.confirm(
      'Bạn có chắc muốn thay đổi trạng thái jet không ?'
    );
    if (wantToChange) {
      const { id } = this.props.match.params;
      if (id) {
        await ClaimPortalService.updateJetStatus(id, jetStatus);
        await this.getClaimData();
      }
    }
  };

  updateChallengeAbuse = async (challengeAbuse: boolean) => {
    const wantToChange = window.confirm(
      'Bạn có chắc muốn thay đổi thành Challenge Abuse không ?'
    );
    if (wantToChange) {
      const { id } = this.props.match.params;
      if (id) {
        await ClaimPortalService.updateChallengeAbuse(id, challengeAbuse);
        await this.getClaimData();
      }
    }
  };

  editTotalDays = (code: number | undefined) => {
    const { editTotalICUDays, editTotalHospitalDays } = this.state;
    if (code !== undefined) {
      if (code) {
        this.setState({
          editTotalICUDays: !editTotalICUDays,
        });
      } else {
        this.setState({
          editTotalHospitalDays: !editTotalHospitalDays,
        });
      }
    }
  };

  deleteClaim = async () => {
    const { claimNumberDel, data }: Readonly<IState> = this.state;
    if (claimNumberDel === data.claimNumber) {
      const { id } = this.props.match.params;
      if (id) {
        const response = await ClaimPortalService.deleteClaim(id);
        if (response.isSuccess) {
          this.props.dispatch(
            setToast({
              text: 'Xóa claim thành công',
              type: 'success',
              isShow: true,
            })
          );
          this.props.history.push(ROUTES.assessment);
        } else {
          this.props.dispatch(
            setToast({
              text: 'Xóa claim thất bại',
              type: 'danger',
              isShow: true,
            })
          );
        }
      }
    }
    this.setState({
      claimNumberDel: '',
    });
  };

  handleChangeDocType = (name, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    newData.picAddition = [];
    if (name === KEYS.FIELD.DOC_TYPE) {
      this.setState({
        data: newData,
        docType: value,
      });
    }
  };

  renderCodeBlock = (data) => {
    if (data.adjustInfo !== null) {
      try {
        return (
          <pre>{JSON.stringify(JSON.parse(data.adjustInfo), null, 2)}</pre>
        );
      } catch (e) {
        return <span className="adjusted">{data.adjustInfo}</span>;
      }
    } else {
      return <span className="notAdjusted">Not adjusted</span>;
    }
  };

  compareDataAndRootData = (data: inputData, rootData: inputData) => {
    const { pendingData, updatePendingData } = this.state;
    let result = false;
    // @ts-ignore
    if (_.differenceWith(data.details, rootData.details, _.isEqual).length) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else if (!_.isEqual(data.diseasesList, rootData.diseasesList)) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else if (data.medicalProvider !== rootData.medicalProvider) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else if (data.signOffNotes_input !== rootData.signOffNotes_input) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else if (
      ((data.picAddition && data.picAddition.length) || 0) !==
      ((rootData.picAddition && rootData.picAddition.length) || 0)
    ) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else if (updatePendingData.pendings.length) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else if (pendingData.length) {
      this.props.dispatch(setUnSavedChange(true));
      result = true;
    } else {
      this.props.dispatch(setUnSavedChange(false));
      return result;
    }
    return result;
  };

  checkUnSavedChange = () => {
    const { unSavedChange } = this.props;
    const {
      editMedicalFacilities,
      editSurgery,
      editMajorSurgery,
      editHospital,
      editICU,
      editDiseases,
      data,
    } = this.state;
    let newEditable: boolean;
    if (unSavedChange && this.compareDataAndRootData(data, this.rootData)) {
      // nothing
    } else {
      newEditable =
        !editMedicalFacilities &&
        !editSurgery &&
        !editMajorSurgery &&
        !editHospital &&
        !editICU &&
        !editDiseases;
      this.props.dispatch(setUnSavedChange(!newEditable));
    }
  };

  goToClaimCase = (path: string) => {
    const { unSavedChange } = this.props;
    if (unSavedChange) {
      this.props.dispatch(
        setUnSavedChangeModal({
          isShow: true,
          callback: () => this.props.history.push(path),
        })
      );
    } else {
      this.props.history.push(path);
    }
  };

  sendSignoff = async () => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    const { id } = this.props.match.params;
    if (data.signOffNotes_input && id) {
      await ClaimPortalService.sendSignOff(id, data.signOffNotes_input);
      newData.signOffNotes_input = '';
      this.setState(
        {
          data: newData,
        },
        async () => {
          const { t } = this.props;
          this.props.dispatch(setUnSavedChange(false));
          const resData = await this.getClaimData(true);
          if (resData && !resData.Errors) {
            resData[KEYS.FIELD.DISEASES_LIST_PORTAL] = resData.diseases.map(
              (disease) => {
                return disease.value;
              }
            );
            const claimCases = await ClaimPortalService.getAssessmentClaimCases(
              id
            );
            if (claimCases) {
              resData.cases = claimCases;
            }
            let isOverrideHospital: boolean = false;
            let isOverrideICU: boolean = false;

            if (resData.details) {
              const hospital = _.find(resData.details, {
                riderDetailCode: KEYS.DAYS_IN_HOSPITAL_NAME,
              });
              const icu = _.find(resData.details, {
                riderDetailCode: KEYS.DAYS_IN_ICU_NAME,
              });
              if (hospital && icu) {
                isOverrideHospital = hospital.isOverride;
                isOverrideICU = icu.isOverride;
              }
            }
            this.rootData = resData;
            this.setState({
              data: resData,
              editTotalHospitalDays: isOverrideHospital,
              editTotalICUDays: isOverrideICU,
            });
            this.props.dispatch(
              setToast({
                isShow: true,
                type: 'success',
                text: t('portal.assessment.action.successSignOff'),
              })
            );
          } else {
            this.props.dispatch(
              setToast({
                isShow: true,
                type: 'danger',
                text: resData.Errors[0].Message,
              })
            );
          }
        }
      );
    }
  };

  getSignoffInfo = async (signOffInfo) => {
    const { id } = this.props.match.params;
    if (id) {
      this.modalContext = KEYS.SHOW_SIGNOFF_INFO;

      if (signOffInfo && !signOffInfo.Errors) {
        this.setState({ signOffInfo, modalConfirmShow: true });
      }

      if (signOffInfo && signOffInfo.Errors) {
        this.setState({
          signOffInfo: signOffInfo.Errors[0].Message,
          modalConfirmShow: true,
        });
      }
    }
  };

  // TODO: Comparison paper
  verifyPaper = async (data) => {
    if (data && data.id) {
      const response = await ClaimPortalService.checkFraudPaper(data.id);
      if (response && response.url) {
        this.setState({
          urlComparisonPaper: response.url,
          showComparisonPaperModal: true,
        });
        // window.open(response.url);
      }
    }
  };

  // TODO: Handle check fraud
  handleCheckFraud = async () => {
    const { id } = this.props.match.params;
    if (id) {
      const response = await ClaimPortalService.checkFraudClaim(id);
      if (response) {
        this.setState(
          {
            dataFraudClaim: response,
          },
          () => {
            this.setState({
              modalFraudShow: true,
            });
          }
        );
      }
    }
  };

  renderToggleFraudContent = (dataFraudClaim, signOffInfo) => {
    if (signOffInfo && signOffInfo.Errors) return;
    const signOffInfoIndex =
      signOffInfo && signOffInfo.indexOf('Đại lý bị theo dõi');
    const signOffs =
      signOffInfo &&
      signOffInfo.substring(signOffInfoIndex).split('\n').filter(Boolean);

    const fraudRows = (dataFraud) => {
      const jsx = (
        <>
          {dataFraud.data &&
            dataFraud.data.map((dataFraudObject) => (
              <tr key={dataFraudObject.fraudId}>
                <td>{dataFraudObject.fraud_meaning}</td>
                <td>{dataFraudObject.additional_info}</td>
              </tr>
            ))}
        </>
      );
      return jsx;
    };

    const signOffRows =
      signOffs &&
      signOffs.map((signOff) => (
        <>
          {signOff.split(':')[1]?.trim() === 'Có' ? (
            <tr key={signOff.split(':')[0]}>
              <td>{signOff.split(':')[0]}</td>
              <td>{signOff.split(':')[1] ?? ''}</td>
            </tr>
          ) : undefined}
        </>
      ));

    const content =
      dataFraudClaim &&
      dataFraudClaim.map((dataFraud) => (
        <div className="fraudContent" key={dataFraud.claimReference}>
          <div className="fraudLabel">
            <span role="img" aria-label="diamond">
              🔸
            </span>{' '}
            {dataFraud.claimReference} :{' '}
            <span className="fraudMessage">{dataFraud.message}</span>
          </div>

          <div className="fraudTableContainer">
            <table className="fraudTable">
              <thead>
                <tr>
                  <th>Fraud Meaning</th>
                  <th>Additional Information</th>
                </tr>
              </thead>
              <tbody>
                {dataFraud && fraudRows(dataFraud)}
                {signOffs && signOffRows}
              </tbody>
            </table>
          </div>
        </div>
      ));

    return content;
  };

  render() {
    var sessionPage = 1;
    var path = '';
    const { t } = this.props;
    const {
      toggleHolder,
      toggleFraud,
      toggleClaim,
      toggleMoney,
      toggleDocument,
      toggleSignOff,
      togglePendingCreate,
      editMedicalFacilities,
      editHospital,
      editICU,
      editSurgery,
      editMajorSurgery,
      editHospitalCase,
      editICUCase,
      editSurgeryCase,
      editMajorSurgeryCase,
      editDiseases,
      data,
      documents,
      list,
      docType,
      startDate,
      modalPendingShow,
      modalEditPendingShow,
      modalConfirmShow,
      pendingData,
      updatePendingData,
      editTotalICUDays,
      editTotalHospitalDays,
      editPendingId,
      claimNumberDel,
      showAlert,
      decisionData,
      signOffInfo,
      confirmDecision,
      decisionMessage,
      modalFraudShow,
      dataFraudClaim,
      showComparisonPaperModal,
      urlComparisonPaper,
    }: Readonly<IState> = this.state;
    return (
      <div className="assessmentScreenContainer">
        <Modal
          name="globalModal"
          className="comparisonModal"
          value={showComparisonPaperModal}
          closeButton={true}
          onClose={() => {
            this.setState({
              showComparisonPaperModal: false,
            });
          }}
        >
          <div className="comparisonPaperContainer">
            {(urlComparisonPaper && (
              <iframe
                src={urlComparisonPaper}
                width="100%"
                height="100%"
                title="comparisonPaper"
              />
            )) ||
              'Không có dữ liệu'}
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className="fraudModal"
          value={modalFraudShow}
          closeButton={true}
          onClose={() => {
            this.setState({
              modalFraudShow: false,
            });
          }}
        >
          <div className="fraudContainer">
            <div className="fraudHeader">Nghi ngờ có dấu hiệu trục lợi</div>
            {dataFraudClaim &&
              dataFraudClaim.map((dataFraud) => (
                <div className="fraudContent" key={dataFraud.claimReference}>
                  <div className="fraudLabel">
                    <p className="fraudClaimCase">
                      <span role="img" aria-label="diamond">
                        🔸
                      </span>{' '}
                      {dataFraud.claimReference}:
                      <span className="fraudMessage">{dataFraud.message}</span>
                    </p>
                  </div>

                  <div className="fraudTableContainer">
                    {(dataFraud.data && dataFraud.data.length && (
                      <table className="fraudTable">
                        <thead>
                          <tr>
                            <th>Fraud Meaning</th>
                            <th>Additional Information</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataFraud.data.map((dataFraudObject) => (
                            <tr key={dataFraudObject.fraudId}>
                              <td>{dataFraudObject.fraud_meaning}</td>
                              <td>{dataFraudObject.additional_info}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )) ||
                      ''}
                  </div>
                </div>
              ))}

            <div className="buttonConfirmFraud">
              <Button
                primary
                primaryClassName="buttonConfirmFraudConfirm"
                onClick={() => this.setState({ modalFraudShow: false })}
              >
                Đóng
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className="globalModal"
          value={confirmDecision}
          closeButton={true}
          onClose={() => {
            this.setState({
              confirmDecision: false,
            });
          }}
        >
          <div className="confirmDecision">
            <div className="confirmDecisionHeader">Decision</div>
            <div className="confirmDecisionContent">
              {decisionMessage || ''}
            </div>
            <div className="buttonConfirmDecision">
              <Button
                className="buttonConfirmDecisionCancel"
                onClick={() => {
                  this.setState({ confirmDecision: false });
                }}
              >
                Dừng
              </Button>
              <Button
                primary
                primaryClassName="buttonConfirmDecisionConfirm"
                onClick={() => this.decisionCaseFWD(true)}
              >
                Đã trình
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className="globalModal"
          value={modalPendingShow}
          closeButton={true}
          onClose={() => {
            this.handleClosePendingCode();
          }}
        >
          <div className="pendingModal">
            <div className="pendingHeader">
              Text of pending -{' '}
              {data.pending && data.pending[data.pending.length - 1]}
            </div>
            <div
              className="pendingDescription"
              onLoad={() => this.getSignoffInfo(signOffInfo)}
            >
              SIGNOFF NOTE
            </div>
            <div className="pendingContent">
              {data[KEYS.FIELD.SIGNS_OFF] && (
                <div
                  className="signOffBefore"
                  style={{ margin: '0 15px 0 20px' }}
                >
                  {data[KEYS.FIELD.SIGNS_OFF] &&
                    data[KEYS.FIELD.SIGNS_OFF].map((signOffText) => {
                      if (signOffText) {
                        return (
                          <div className="messages">
                            <div className="signOffAuthentication">
                              <div className="author">
                                {signOffText.createdBy || ''}
                              </div>
                              <div className="date">
                                {utils.formatDateToStringDateDMYHM(
                                  new Date(signOffText.createdAt)
                                ) || ''}
                              </div>
                            </div>
                            <div className="signOffMsg">
                              {signOffText.content}
                            </div>
                          </div>
                        );
                      }
                      return '';
                    })}
                </div>
              )}
            </div>
            <div className="pendingDescription">
              Input pending extended text
            </div>
            <div className="pendingContent">
              <textarea
                className="pendingText"
                name={KEYS.FIELD.PENDING_TEXT}
                placeholder="Nội dung pending..."
                value={data[KEYS.FIELD.PENDING_TEXT] || ''}
                onChange={(event) =>
                  this.handleTextArea(KEYS.FIELD.PENDING_TEXT, event)
                }
              />
            </div>
            <div className="buttonPendingModal">
              <Button
                onClick={() => {
                  this.saveCreatePendingCode();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className="globalModal"
          value={modalEditPendingShow}
          closeButton={true}
          onClose={() => {
            const newData = _.clone(data);
            newData.editPendingText = '';
            this.setState({
              modalEditPendingShow: false,
              data: newData,
            });
          }}
        >
          <div className="pendingModal">
            <div className="pendingHeader">
              Text of pending -
              {_.find(data.pendings, { id: editPendingId })?.code}
            </div>
            <div className="pendingDescription">
              Input pending extended text
            </div>
            <div className="pendingContent">
              <textarea
                className="pendingText"
                name={KEYS.FIELD.EDIT_PENDING_TEXT}
                placeholder="Nội dung pending..."
                value={data[KEYS.FIELD.EDIT_PENDING_TEXT] || ''}
                onChange={(e) => {
                  const newData = _.clone(data);
                  newData[KEYS.FIELD.EDIT_PENDING_TEXT] = e.target.value;
                  this.setState({ data: newData });
                }}
              />
            </div>
            <div className="buttonPendingModal">
              <Button
                onClick={() => {
                  this.saveEditPendingExtendText();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          name="globalModal"
          className={`globalModal ${
            (this.modalContext === KEYS.SHOW_SIGNOFF_INFO && 'signoff') || ''
          }`}
          value={modalConfirmShow}
          closeButton={true}
          onClose={() => {
            this.setState({ modalConfirmShow: false });
          }}
        >
          <div className="assessmentModal">
            <div className="assessmentHeader">
              {(this.modalContext === KEYS.DELETE_CLAIM && 'Xóa Claim') ||
                (this.modalContext === KEYS.SHOW_SIGNOFF_INFO &&
                  'Sign Off Info') ||
                t('portal.modal.assessment_modal.header')}
            </div>
            <div className="assessmentDescription">
              {(this.modalContext === KEYS.UPDATE_ADJUST &&
                t('portal.modal.assessment_modal.description_adjust')) ||
                (this.modalContext === KEYS.UPDATE_DECISION &&
                  t('portal.modal.assessment_modal.description_decision')) ||
                (this.modalContext === KEYS.UPDATE_PAPER &&
                  ((docType === KEYS.IMAGES.InvestigationPaper &&
                    t('portal.modal.assessment_modal.description_paper', {
                      name: 'Giấy điều tra',
                    })) ||
                    t('portal.modal.assessment_modal.description_paper', {
                      name: 'Giấy tờ khác',
                    }))) ||
                (this.modalContext === KEYS.UPDATE_DETAIL &&
                  t('portal.modal.assessment_modal.description_detail')) ||
                (this.modalContext === KEYS.SHOW_SIGNOFF_INFO && (
                  <div
                    role="button"
                    tabIndex={0}
                    className="signOffInfo"
                    onClick={() => {
                      window.navigator.clipboard
                        .writeText(signOffInfo || '')
                        .then(() => {
                          this.props.dispatch(
                            setToast({
                              isShow: true,
                              type: 'success',
                              text: 'Copied to clipboard',
                            })
                          );
                        });
                    }}
                  >
                    <pre>{signOffInfo}</pre>
                  </div>
                )) ||
                (this.modalContext === KEYS.DELETE_CLAIM && (
                  <div className="deletePopup">
                    <span>
                      {t('portal.modal.assessment_modal.delete_claim')}
                    </span>
                    <div className="claimId">
                      <span className="attributeContent claimNumber">
                        {data.claimNumber}
                      </span>
                      {(data.claimNumber && (
                        <div
                          role="button"
                          tabIndex={0}
                          className="copy"
                          aria-label="Copy"
                          onClick={() => {
                            window.navigator.clipboard
                              .writeText(data.claimNumber || '')
                              .then(() => {
                                alert('Copied to clipboard');
                              });
                          }}
                        />
                      )) ||
                        ''}
                    </div>
                    <input
                      value={claimNumberDel}
                      onChange={(e) =>
                        this.setState({ claimNumberDel: e.target.value })
                      }
                      maxLength={14}
                    />
                  </div>
                ))}
            </div>
            <div className="buttonAssessmentModal">
              <Button
                className="buttonAssessmentCancel"
                onClick={() => {
                  this.setState({ modalConfirmShow: false });
                }}
              >
                {t('portal.modal.assessment_modal.button_cancel')}
              </Button>
              {(this.modalContext !== KEYS.DELETE_CLAIM &&
                this.modalContext !== KEYS.SHOW_SIGNOFF_INFO && (
                  <Button
                    primary
                    primaryClassName="buttonAssessmentConfirm"
                    onClick={() => this.handleConfirmAssessmentModal()}
                  >
                    {t('portal.modal.assessment_modal.button_confirm')}
                  </Button>
                )) ||
                ''}
              {(this.modalContext === KEYS.SHOW_SIGNOFF_INFO && (
                <Button
                  primary
                  primaryClassName="buttonAssessmentConfirm"
                  onClick={() => {
                    window.navigator.clipboard
                      .writeText(signOffInfo || '')
                      .then(() => {
                        this.props.dispatch(
                          setToast({
                            isShow: true,
                            type: 'success',
                            text: 'Copied to clipboard',
                          })
                        );
                      });
                  }}
                >
                  {' '}
                  Copy
                </Button>
              )) ||
                ''}
              {(this.modalContext === KEYS.DELETE_CLAIM && (
                <Button
                  primary
                  disabled={claimNumberDel !== data.claimNumber}
                  primaryClassName="buttonAssessmentConfirm"
                  onClick={() => this.handleConfirmAssessmentModal()}
                >
                  {t('portal.modal.assessment_modal.button_confirm')}
                </Button>
              )) ||
                ''}
            </div>
          </div>
        </Modal>

        <div className="requestAssessmentScreenContainer">
          <div className="holderRequestScreen">
            <Button
              className="back"
              styles={{ border: '2px solid', width: '100px', padding: '0' }}
              onClick={() => {
                if (
                  (this.props.location.state as any) &&
                  (this.props.location.state as any).sessionPage != undefined
                ) {
                  sessionPage = (this.props.location.state as any).sessionPage;
                  path = (this.props.location.state as any).path;
                } else {
                  path = '';
                }
                this.props.history.push({
                  pathname: path,
                  state: { sessionPage },
                });
              }}
              disabled={
                !(
                  (this.props.location.state as any) &&
                  (this.props.location.state as any).sessionPage != undefined
                )
              }
            >
              {t('footer.back')}
            </Button>
          </div>
          <div className="holderRequestScreen">
            <div
              className="labelClaimField"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.toggleHolder();
              }}
            >
              <p>
                <span aria-label="PO" role="img">
                  👤
                </span>{' '}
                Chủ hợp đồng
              </p>
              <div
                className={(toggleHolder && 'openExpand') || 'closeExpand'}
              />
            </div>

            {(toggleHolder && (
              <div className="holderContent">
                <div className="attributeHolder">
                  <p className="attributeLabel">HỌ TÊN</p>
                  <p
                    className={`attributeContent name
                  ${
                    (data[KEYS.FIELD.JET_STATUS] ===
                      KEYS.JET_STATUS.CONFIRM_JET &&
                      'jet') ||
                    ''
                  }`}
                  >
                    {data.poName}{' '}
                    {(data[KEYS.FIELD.JET_STATUS] ===
                      KEYS.JET_STATUS.CONFIRM_JET &&
                      ' 🚀') ||
                      ''}
                  </p>
                </div>
                <div className="attributeHolder">
                  <p className="attributeLabel">EMAIL</p>
                  <p className="attributeContent">{data.poEmail}</p>
                </div>
                <div className="attributeHolder">
                  <p className="attributeLabel">PHONE NUMBER</p>
                  <p className="attributeContent">{data.poPhone}</p>
                </div>
                <div className="attributeHolder">
                  <p className="attributeLabel">CMND/CCCD/HC</p>
                  <p className="attributeContent nationalId">
                    {data.poNationalId}
                  </p>
                </div>
                <div className="attributeHolder">
                  <p className="attributeLabel">DATE OF BIRTH</p>
                  <p className="attributeContent">{data.poDob}</p>
                </div>
              </div>
            )) ||
              ''}
          </div>

          <div className="claimRequestAssessmentScreen">
            <div
              className="labelClaimField"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.toggleFraud();
              }}
            >
              <p>
                <span aria-label="Request" role="img">
                  📄
                </span>{' '}
                Fraud
              </p>
              <div className={(toggleFraud && 'openExpand') || 'closeExpand'} />
            </div>

            {(toggleFraud && (
              <div className="fraudContainer">
                {this.renderToggleFraudContent(dataFraudClaim, signOffInfo)}
              </div>
            )) ||
              ''}
          </div>

          <div className="claimRequestAssessmentScreen">
            <div
              className="labelClaimField"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.toggleClaim();
              }}
            >
              <p>
                <span aria-label="Request" role="img">
                  📄
                </span>{' '}
                Yêu cầu bảo hiểm
              </p>
              <div className={(toggleClaim && 'openExpand') || 'closeExpand'} />
            </div>

            {(toggleClaim && (
              <div className="claimContent">
                <div className="claimDetail">
                  <div className="claimNumberDetail">
                    <p className="attributeLabel">Mã Claim</p>
                    <div className="claimValue">
                      <span className="attributeContent claimNumber">
                        {data.claimNumber}
                      </span>
                      {(data.claimNumber && (
                        <div
                          role="button"
                          tabIndex={0}
                          className="copy"
                          aria-label="Copy"
                          onClick={() => {
                            window.navigator.clipboard
                              .writeText(data.claimNumber || '')
                              .then(() => {
                                this.props.dispatch(
                                  setToast({
                                    isShow: true,
                                    type: 'success',
                                    text: 'Copied to clipboard',
                                  })
                                );
                              });
                          }}
                        />
                      )) ||
                        ''}
                    </div>
                  </div>
                </div>

                <div className="claimDetail">
                  <div className="firstClaimDetail laName">
                    <p className="attributeLabel laNameHeader">HỌ TÊN</p>
                    <div className="attributeContent laName">
                      <span>{data.laName}</span>
                    </div>
                    <p className="attributeLabel laNameHeader">NGÀY SINH</p>
                    <div className="attributeContent laName">
                      <span>{data.dob && data.dob}</span>
                    </div>
                    <p className="attributeLabel laNameHeader">GIỚI TÍNH</p>
                    <div className="attributeContent laName">
                      <span>{data.sex && data.sex == 'M' ? 'Nam' : 'Nữ'}</span>
                    </div>
                  </div>
                  <div className="secondClaimDetail">
                    <p className="attributeLabel">Chẩn đoán bệnh</p>
                    <div className="attributeContent">
                      <Autocomplete
                        name={KEYS.FIELD.DISEASES_LIST_PORTAL}
                        className="editDisease"
                        label="Chuẩn đoán bệnh"
                        styles={
                          (editDiseases && { display: 'block' }) || {
                            display: 'none',
                          }
                        }
                        placeHolder="Chuẩn đoán bệnh ?"
                        errorPosition="right"
                        multiple
                        extraOptions={(data && data[KEYS.FIELD.DISEASES]) || []}
                        options={(list && list[KEYS.LIST.DISEASES]) || []}
                        backendSearch
                        onChangeSearch={(name, value) =>
                          this.handleSearchDiseases(name, value)
                        }
                        onChange={(name, value) =>
                          this.handleChange(name, value)
                        }
                        value={data[KEYS.FIELD.DISEASES_LIST_PORTAL]}
                      />
                      <span
                        className={`editAction ${
                          (!editDiseases && 'edit') || 'done'
                        }`}
                        role="button"
                        tabIndex={0}
                        onClick={() => this.editDiseases()}
                      >
                        {(editDiseases && 'Xong') || 'Sửa'}
                      </span>
                      {(editDiseases && ' ') ||
                        (data.diseasesList && (
                          <span>{this.renderDiseases(data.diseasesList)}</span>
                        )) ||
                        ''}
                    </div>
                  </div>
                </div>

                <div className="claimDetail">
                  <div className="firstClaimDetail" style={{ borderTop: '0' }}>
                    <p className="attributeLabel">ĐIỀU TRỊ TẠI TỈNH/ TP</p>
                    <div className="attributeContent">
                      <Autocomplete
                        name={KEYS.FIELD.PROVINCE}
                        className="editProvince provinceField"
                        styles={
                          (editMedicalFacilities && { display: 'block' }) || {
                            display: 'none',
                          }
                        }
                        label="Tỉnh"
                        placeHolder="Tỉnh ?"
                        options={
                          (list &&
                            list[KEYS.LIST.PROVINCE] &&
                            list[KEYS.LIST.PROVINCE].map((p) => ({
                              name: p,
                              value: p,
                            }))) ||
                          []
                        }
                        value={data[KEYS.FIELD.PROVINCE]}
                        onChange={(name, value) =>
                          this.handleChangeProvince(value)
                        }
                        errorPosition="right"
                      />
                      {(editMedicalFacilities && ' ') || (
                        <span>{data.province}</span>
                      )}
                    </div>
                  </div>
                  <div className="secondClaimDetail" style={{ borderTop: '0' }}>
                    <p className="attributeLabel">TÊN CƠ SỞ KHÁM</p>
                    <div className="attributeContent">
                      <Autocomplete
                        name={KEYS.FIELD.MEDICAL_PROVIDER}
                        styles={
                          (editMedicalFacilities && { display: 'block' }) || {
                            display: 'none',
                          }
                        }
                        className="editMedicalFac medicalFacField"
                        label="Cơ sở khám "
                        placeHolder="Cơ sở khám"
                        options={
                          (data[KEYS.FIELD.PROVINCE] &&
                            list &&
                            list[KEYS.LIST.FACILITY] &&
                            list[KEYS.LIST.FACILITY][
                              data[KEYS.FIELD.PROVINCE]
                            ]) ||
                          []
                        }
                        value={data[KEYS.FIELD.MEDICAL_PROVIDER]}
                        onChange={(name, value) =>
                          this.handleChange(name, value)
                        }
                        errorPosition="right"
                      />
                      {(editMedicalFacilities && ' ') ||
                        (list &&
                          list[KEYS.LIST.FACILITY] &&
                          data.medicalProvider && (
                            <span>
                              {this.mapValueToNameMedicalFacility(
                                list[KEYS.LIST.FACILITY][
                                  data[KEYS.FIELD.PROVINCE]
                                ],
                                data.medicalProvider
                              )}
                            </span>
                          ))}
                      <span
                        className={`editAction ${
                          (!editMedicalFacilities && 'edit') || 'done'
                        }`}
                        role="button"
                        tabIndex={0}
                        onClick={() => this.editMedicalFacilities()}
                      >
                        {(editMedicalFacilities && 'Xong') || 'Sửa'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="claimField">
                  <p className="attributeLabel">SẢN PHẨM YÊU CẦU BẢO HIỂM</p>

                  {data &&
                    data.details &&
                    data.details.map((detail) => {
                      return (
                        <div
                          className="attributeContent"
                          key={detail.riderDetailCode}
                        >
                          <p className="textContent">
                            {(detail.riderDetailCode ===
                              parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                              'Tổng thời gian nằm viện') ||
                              (detail.riderDetailCode ===
                                parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                'Nằm viện ICU') ||
                              (detail.riderDetailCode ===
                                parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                'Phẫu thuật') ||
                              (detail.riderDetailCode ===
                                parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                                'Đại phẫu')}
                          </p>
                          <input
                            name={
                              (detail.riderDetailCode &&
                                detail.riderDetailCode.toString()) ||
                              ''
                            }
                            className="editInputRider"
                            type="number"
                            min="0"
                            style={
                              (detail.riderDetailCode &&
                                ((detail.riderDetailCode ===
                                  parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                  editSurgery) ||
                                  (detail.riderDetailCode ===
                                    parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                                    editMajorSurgery)) && {
                                  display: 'inline-block',
                                }) || { display: 'none' }
                            }
                            onChange={(event) =>
                              this.handleChange(
                                (detail.riderDetailCode &&
                                  detail.riderDetailCode.toString()) ||
                                  '',
                                event
                              )
                            }
                            value={detail.inputValue}
                          />
                          <div
                            className="editRiderDate"
                            style={
                              (typeof detail.riderDetailCode === 'number' &&
                                ((detail.riderDetailCode ===
                                  parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                  editICU) ||
                                  (detail.riderDetailCode ===
                                    parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                    editHospital)) && {
                                  display: 'inline-block',
                                }) || { display: 'none' }
                            }
                          >
                            <CalendarRange
                              className="fieldCalendarRange"
                              calenderClassName="calendarField"
                              name={
                                (typeof detail.riderDetailCode === 'number' &&
                                  detail.riderDetailCode.toString()) ||
                                ''
                              }
                              label=""
                              labelFrom="Từ ngày"
                              labelTo="Đến ngày"
                              placeHolderFrom="Từ ngày ?"
                              placeHolderTo="Đến ngày ?"
                              topShow
                              blockFrom={
                                (data[KEYS.DAYS_IN_HOSPITAL] &&
                                  data[KEYS.DAYS_IN_HOSPITAL].from &&
                                  new Date(data[KEYS.DAYS_IN_HOSPITAL].from)) ||
                                (startDate && new Date(startDate)) ||
                                undefined
                              }
                              blockTo={new Date()}
                              errorPosition="right"
                              pastBack={true}
                              onChange={(name, value) =>
                                this.handleChange(name, value)
                              }
                              value={
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                  data[KEYS.DAYS_IN_HOSPITAL]) ||
                                data[KEYS.DAYS_IN_ICU]
                              }
                            />
                            <div className="totalDays">
                              Tổng số ngày chi trả
                              <div className="editTotalDays">
                                <p>Bạn chắc chắn muốn thay đổi?</p>
                                <input
                                  type="checkbox"
                                  checked={
                                    typeof detail.riderDetailCode ===
                                      'number' &&
                                    ((detail.riderDetailCode ===
                                      parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                      editTotalHospitalDays &&
                                      true) ||
                                      false ||
                                      (detail.riderDetailCode ===
                                        parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                        editTotalICUDays &&
                                        true) ||
                                      false)
                                  }
                                  name={
                                    (detail.riderDetailCode &&
                                      detail.riderDetailCode.toString()) ||
                                    ''
                                  }
                                  onChange={() =>
                                    this.editTotalDays(detail.riderDetailCode)
                                  }
                                />
                              </div>
                              <div className="finalTotalDays">
                                <p>Total: </p>
                                <input
                                  name={
                                    (detail.riderDetailCode &&
                                      detail.riderDetailCode.toString()) ||
                                    ''
                                  }
                                  style={{ height: '13px' }}
                                  className="editInputRider"
                                  type="number"
                                  min="0"
                                  max="100"
                                  onChange={(event) =>
                                    this.handleChangeTotalDays(
                                      (typeof detail.riderDetailCode ===
                                        'number' &&
                                        detail.riderDetailCode.toString()) ||
                                        '',
                                      event
                                    )
                                  }
                                  disabled={
                                    typeof detail.riderDetailCode ===
                                      'number' &&
                                    ((detail.riderDetailCode ===
                                      parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                      !editTotalHospitalDays &&
                                      true) ||
                                      false ||
                                      (detail.riderDetailCode ===
                                        parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                        !editTotalICUDays &&
                                        true) ||
                                      false)
                                  }
                                  value={detail.value}
                                />
                              </div>
                            </div>
                          </div>
                          {(typeof detail.riderDetailCode === 'number' &&
                            ((detail.riderDetailCode ===
                              parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                              editHospital) ||
                              (detail.riderDetailCode ===
                                parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                editICU) ||
                              (detail.riderDetailCode ===
                                parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                editSurgery) ||
                              (detail.riderDetailCode ===
                                parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                                editMajorSurgery)) &&
                            ' ') ||
                            detail.inputValue}
                          <span
                            className={`editAction ${
                              (((detail.riderDetailCode ===
                                parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                !editHospital) ||
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                  !editICU) ||
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                  !editSurgery) ||
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                                  !editMajorSurgery)) &&
                                'edit') ||
                              'done'
                            }`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.editRider(detail.riderDetailCode)
                            }
                          >
                            {(typeof detail.riderDetailCode === 'number' &&
                              ((detail.riderDetailCode ===
                                parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                editHospital) ||
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                  editICU) ||
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                  editSurgery) ||
                                (detail.riderDetailCode ===
                                  parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0) &&
                                  editMajorSurgery)) &&
                              'Xong') ||
                              'Sửa'}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div className="claimField updateButton">
                  <div
                    className="updateStatusButton"
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      this.handleToggleAssessmentModal(KEYS.UPDATE_DETAIL)
                    }
                  >
                    <p>Cập nhật</p>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>

          <div className="claimRequestAssessmentScreen">
            <div
              className="labelClaimField"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.toggleSignOff();
              }}
            >
              <p>🏷 SIGNOFF</p>
              <div
                className={(toggleSignOff && 'openExpand') || 'closeExpand'}
              />
            </div>
            {(toggleSignOff && (
              <div className="claimContent">
                <div className="claimField">
                  <p className="signOffHeader">SIGNOFF</p>
                  {(data[KEYS.FIELD.SIGNS_OFF] && (
                    <div className="signOffBefore">
                      {data[KEYS.FIELD.SIGNS_OFF] &&
                        data[KEYS.FIELD.SIGNS_OFF].map((signOffText) => {
                          if (signOffText) {
                            return (
                              <div className="messages">
                                <div className="signOffAuthentication">
                                  <div className="author">
                                    {signOffText.createdBy || ''}
                                  </div>
                                  <div className="date">
                                    {utils.formatDateToStringDateDMYHM(
                                      new Date(signOffText.createdAt)
                                    ) || ''}
                                  </div>
                                </div>
                                <div className="signOffMsg">
                                  {signOffText.content}
                                </div>
                              </div>
                            );
                          }
                          return '';
                        })}
                    </div>
                  )) || <p>Chưa có Signoff</p>}
                  <div className="signOffContent">
                    <p
                      className="signOffHeader"
                      role="presentation"
                      onClick={() => this.getSignoffInfo(signOffInfo)}
                    >
                      SIGNOFF NOTE
                    </p>
                    <textarea
                      className="signOffText"
                      name={`${KEYS.FIELD.SIGNS_OFF}_input`}
                      placeholder="Nội dung signoff..."
                      value={data[`${KEYS.FIELD.SIGNS_OFF}_input`] || ''}
                      onChange={(event) =>
                        this.handleTextArea(
                          `${KEYS.FIELD.SIGNS_OFF}_input`,
                          event
                        )
                      }
                    />
                    <br />
                    <div
                      role="button"
                      tabIndex={0}
                      className="sendSignoffButton"
                      onClick={() => this.sendSignoff()}
                    >
                      Send
                    </div>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>

          <div className="claimRequestAssessmentScreen">
            <div
              className="labelClaimField"
              role="button"
              tabIndex={0}
              onClick={() => {
                this.toggleDocument();
              }}
            >
              <p>🗂 Tài liệu</p>
              <div
                className={(toggleDocument && 'openExpand') || 'closeExpand'}
              />
            </div>
            {(toggleDocument && (
              <div className="claimContent">
                <div className="claimField">
                  <p className="attributeLabel">Hình ảnh cung cấp </p>
                  <div className="carousel">
                    {(documents[KEYS.SUBMISSION_DOCS_PATCH] &&
                      documents[KEYS.SUBMISSION_DOCS_PATCH].length && (
                        <div>
                          <p>SUBMISSION</p>
                          <div>
                            <Carousel
                              name="hospital"
                              label="Giấy ra viện"
                              options={this.getValueDocuments(
                                documents[KEYS.SUBMISSION_DOCS_PATCH],
                                KEYS.FIELD.HOSPITAL_DISCHARGE_PAPER
                              )}
                              onVerify={(paperData) =>
                                this.verifyPaper(paperData)
                              }
                            />
                            <div style={{ marginLeft: '20px' }}>
                              <Carousel
                                name="other"
                                label="Giấy tờ khác"
                                options={this.getValueDocuments(
                                  documents[KEYS.SUBMISSION_DOCS_PATCH],
                                  KEYS.FIELD.OTHERS_PAPER
                                )}
                                onVerify={(paperData) =>
                                  this.verifyPaper(paperData)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )) ||
                      ''}

                    {(documents[KEYS.PORTAL_DOCS_PATCH] &&
                      documents[KEYS.PORTAL_DOCS_PATCH].length && (
                        <div>
                          <p>PORTAL</p>
                          <div>
                            {(documents[KEYS.PORTAL_DOCS_PATCH].length && (
                              <div style={{ marginRight: '20px' }}>
                                <Carousel
                                  name="other"
                                  label="Giấy tờ khác"
                                  options={this.getValueDocuments(
                                    documents[KEYS.PORTAL_DOCS_PATCH],
                                    KEYS.FIELD.OTHERS_PAPER
                                  )}
                                  onVerify={(paperData) =>
                                    this.verifyPaper(paperData)
                                  }
                                />
                              </div>
                            )) ||
                              ''}

                            <Carousel
                              name="investigation"
                              label="Giấy điều tra"
                              options={this.getValueDocuments(
                                documents[KEYS.PORTAL_DOCS_PATCH],
                                KEYS.FIELD.INVESTIGATION_PAPER
                              )}
                              onVerify={(paperData) =>
                                this.verifyPaper(paperData)
                              }
                            />
                          </div>
                        </div>
                      )) ||
                      ''}

                    {(documents[KEYS.TRACKING_DOCS_PATCH] &&
                      documents[KEYS.TRACKING_DOCS_PATCH].length && (
                        <div>
                          <p>TRACKING</p>
                          <div>
                            <Carousel
                              name="other"
                              label="Giấy tờ khác"
                              options={this.getValueDocuments(
                                documents[KEYS.TRACKING_DOCS_PATCH],
                                KEYS.FIELD.OTHERS_PAPER
                              )}
                              onVerify={(paperData) =>
                                this.verifyPaper(paperData)
                              }
                            />
                          </div>
                        </div>
                      )) ||
                      ''}
                  </div>
                  <div className="uploadPictureAddition">
                    <UploadPicture
                      className="pictureAddition"
                      name="picAddition"
                      value={data.picAddition || []}
                      label=""
                      description=""
                      multiple={true}
                      distinct={false}
                      max={10}
                      min={1}
                      showTooltip={false}
                      guides={[]}
                      maxSize={KEYS.UPLOAD_IMAGE.FILE_SIZE}
                      options={[
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                        {
                          label: 'Tải lên hình ảnh',
                          editable: true,
                        },
                      ]}
                      onChange={(name, value, fileName) =>
                        this.handleChange(name, value, fileName)
                      }
                    />
                  </div>
                </div>
                <div className="claimField updateButton">
                  <div className="selectionPaper">
                    <SelectionStatus
                      name={KEYS.FIELD.DOC_TYPE}
                      className="selectionTypePaper"
                      // @ts-ignore
                      value={docType}
                      label="Selection Status"
                      multiple={false}
                      selectList={[
                        // @ts-ignore
                        { name: 'Giấy tờ khác', value: 0 },
                        // @ts-ignore
                        { name: 'Giấy điều tra', value: 2 },
                      ]}
                      options={[
                        // @ts-ignore
                        { name: 'Giấy tờ khác', value: 0 },
                        // @ts-ignore
                        { name: 'Giấy điều tra', value: 2 },
                      ]}
                      onChange={(name, value) =>
                        this.handleChangeDocType(name, value)
                      }
                      defaultValue="Giấy tờ khác"
                    />
                  </div>
                  <div
                    className="updateStatusButton"
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      this.handleToggleAssessmentModal(KEYS.UPDATE_PAPER)
                    }
                  >
                    <p>Cập nhật</p>
                  </div>
                </div>
              </div>
            )) ||
              ''}
          </div>

          {(data && (
            // && (!data.pendings ||
            // (data.pendings && !data.pendings.length)))
            <div className="claimRequestAssessmentScreen">
              <div
                className="labelClaimField"
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.togglePendingCreate();
                }}
              >
                <p>
                  <span aria-label="PendingCreate" role="img">
                    🔖
                  </span>{' '}
                  Pending Create
                </p>
                <div
                  className={
                    (togglePendingCreate && 'openExpand') || 'closeExpand'
                  }
                />
              </div>
              {(togglePendingCreate && (
                <div className="claimContent">
                  <div className="claimField">
                    <p className="attributeLabel">Claim Pending</p>
                    {(!data.pendings && (
                      <SelectionStatus
                        name={KEYS.FIELD.PENDING}
                        className="selectionPending"
                        // @ts-ignore
                        value={
                          (data && _.clone(data[KEYS.FIELD.PENDING])) || []
                        }
                        label="Selection Status"
                        multiple={true}
                        // @ts-ignore
                        options={(list && list[KEYS.FIELD.PENDING]) || []}
                        selectList={(list && list[KEYS.FIELD.PENDING]) || []}
                        onChange={(name, value) =>
                          this.handleCreatePending(name, value)
                        }
                        defaultValue="Select pending"
                      />
                    )) || (
                      <SelectionStatus
                        name={KEYS.FIELD.PENDING}
                        className="selectionPending"
                        // @ts-ignore
                        value={data && this.getPendingValue(data)}
                        label="Selection Status"
                        multiple={true}
                        // @ts-ignore
                        options={(list && list[KEYS.FIELD.PENDING]) || []}
                        selectList={(list && list[KEYS.FIELD.PENDING]) || []}
                        onChange={(name, value) =>
                          this.handleCreatePendingAfterUpdate(
                            name,
                            value,
                            this.getPendingValue(data)
                          )
                        }
                        defaultValue="Select pending"
                      />
                    )}
                  </div>

                  {(pendingData.length > 0 && (
                    <div className="tablePending">
                      <p className="tablePendingHeader">
                        Summary pending claim request
                      </p>
                      <table className="tablePendingStyle">
                        <thead>
                          <tr>
                            <th>Mã</th>
                            <th colSpan={6}>Ghi chú</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingData.map((row) => (
                            <tr key={row.code}>
                              <td className="bodyTablePending">{row.code}</td>
                              <td className="bodyTablePending" colSpan={6}>
                                {row.extendedText}
                              </td>
                              <td className="bodyTablePending">
                                <span
                                  tabIndex={0}
                                  role="button"
                                  className="tablePendingAction"
                                  onClick={() =>
                                    this.removePendingCode(row.code)
                                  }
                                >
                                  <span aria-label="delete" role="img">
                                    ❌
                                  </span>{' '}
                                  DELETE
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )) ||
                    ''}
                  <div className="claimField updateButton">
                    <div
                      className="updateStatusButton"
                      role="button"
                      tabIndex={0}
                      onClick={() => this.createPendingClaim()}
                    >
                      <p>Cập nhật</p>
                    </div>
                  </div>
                </div>
              )) ||
                ''}
            </div>
          )) ||
            ''}

          {(data && data.pendings && data.pendings.length && (
            <div className="claimRequestAssessmentScreen">
              <div
                className="labelClaimField"
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.togglePendingCreate();
                }}
              >
                <p>
                  <span aria-label="Pending" role="img">
                    📑
                  </span>{' '}
                  Pending Update
                </p>
                <div
                  className={
                    (togglePendingCreate && 'openExpand') || 'closeExpand'
                  }
                />
              </div>
              {(togglePendingCreate && (
                <div className="claimContent">
                  {(data.pendings.length > 0 && (
                    <div className="tablePending">
                      <p className="tablePendingHeader">
                        Summary pending claim request
                      </p>
                      <table className="tablePendingStyle">
                        <thead>
                          <tr>
                            <th>Mã</th>
                            <th>Trạng Thái</th>
                            <th colSpan={6}>Ghi chú</th>
                            <th>Ngày tạo</th>
                            <th>Ngày TAT</th>
                            <th>Ngày Gỡ</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.pendings.map((row) => (
                            <tr key={row.code}>
                              <td className="bodyTablePending">{row.code}</td>
                              <td className="bodyTablePending">{row.status}</td>
                              <td className="bodyTablePending" colSpan={6}>
                                {row.extendedText}
                              </td>
                              <td className="bodyTablePending">
                                {utils.formatToLocaleDate(
                                  new Date(row.createdAt)
                                )}
                              </td>
                              <td className="bodyTablePending">
                                {utils.formatToLocaleDate(
                                  new Date(row.startedAt)
                                )}
                              </td>
                              <td className="bodyTablePending">
                                {row.removedAt &&
                                  utils.formatToLocaleDate(
                                    new Date(row.removedAt)
                                  )}
                              </td>
                              <td className="bodyTablePending">
                                {row.isEditable && (
                                  <div
                                    role="button"
                                    tabIndex={0}
                                    className="editPendingRow"
                                    onClick={() =>
                                      this.setState({
                                        modalEditPendingShow: true,
                                        editPendingId: row.id,
                                      })
                                    }
                                  >
                                    <span aria-label="edit" role="img">
                                      ✏️
                                    </span>{' '}
                                    EDIT
                                  </div>
                                )}
                                {(updatePendingData &&
                                  updatePendingData.pendings[
                                    _.findIndex(updatePendingData.pendings, {
                                      id: row.id,
                                    })
                                  ]?.status !== 'o' && (
                                    <span
                                      tabIndex={0}
                                      role="button"
                                      className={
                                        (!row.removedAt &&
                                          ((updatePendingData &&
                                            this.checkExistIdUpdatePending(
                                              row.id
                                            ) &&
                                            'tableUndoAction') ||
                                            'tablePendingAction')) ||
                                        ''
                                      }
                                      onClick={(e) => {
                                        if (!row.removedAt) {
                                          this.removeUpdatedPending(
                                            row.id,
                                            row.status
                                          );
                                        } else {
                                          e.preventDefault();
                                        }
                                      }}
                                    >
                                      {(updatePendingData &&
                                        !row.removedAt &&
                                        ((this.checkExistIdUpdatePending(
                                          row.id
                                        ) &&
                                          '👈 UNDO') ||
                                          '❌ REMOVE')) ||
                                        ''}
                                    </span>
                                  )) ||
                                  ''}

                                {(updatePendingData &&
                                  updatePendingData.pendings[
                                    _.findIndex(updatePendingData.pendings, {
                                      id: row.id,
                                    })
                                  ]?.status !== 'o' && (
                                    <div className="followUpStatus">
                                      {(updatePendingData &&
                                        !row.removedAt &&
                                        this.checkExistIdUpdatePending(
                                          row.id
                                        ) && (
                                          <div className="followUpStatusCode">
                                            {list &&
                                              list.followUpCodes &&
                                              list.followUpCodes.map((code) => (
                                                <div
                                                  key={code.value}
                                                  style={
                                                    (code.value === 'O' && {
                                                      display: 'none',
                                                    }) || {
                                                      display: 'block',
                                                      marginTop: '10px',
                                                    }
                                                  }
                                                >
                                                  <input
                                                    type="radio"
                                                    id={row.id + code.value}
                                                    name={row.id}
                                                    value={code.value}
                                                    onChange={(e) =>
                                                      this.handleFollowUpStatus(
                                                        row.id,
                                                        e
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={
                                                      row.id + code.value
                                                    }
                                                  >
                                                    {code.name}
                                                  </label>
                                                </div>
                                              ))}
                                          </div>
                                        )) ||
                                        ''}
                                    </div>
                                  )) ||
                                  ''}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )) ||
                    ''}
                  <div className="claimField updateButton">
                    <div
                      className={
                        (updatePendingData.pendings.length &&
                          'updateStatusButton') ||
                        'updateStatusButton disabled'
                      }
                      role="button"
                      tabIndex={0}
                      onClick={(e) => {
                        if (updatePendingData.pendings.length) {
                          this.updatePendingClaim();
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      <p>Cập nhật</p>
                    </div>
                  </div>
                </div>
              )) ||
                ''}
            </div>
          )) ||
            ''}
          {(data && data.cases && data.cases.length && (
            <div className="claimCaseAssessmentScreen">
              <div
                className="labelClaimField"
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.toggleMoney();
                }}
              >
                <p>
                  <span aria-label="Payment" role="img">
                    💳
                  </span>{' '}
                  Claim cases
                </p>
                <div
                  className={(toggleMoney && 'openExpand') || 'closeExpand'}
                />
              </div>

              {(toggleMoney && (
                <div className="claimCaseContent">
                  {data &&
                    data.cases &&
                    data.cases.map((claimCase) => {
                      return (
                        <div className="claimCase" key={claimCase.id}>
                          <span
                            role="presentation"
                            className="claimPolicyNumber"
                            onClick={(e) => {
                              if (e.ctrlKey) {
                                window.open(
                                  `${this.props.location.pathname}/${claimCase.id}`
                                );
                              } else {
                                if (
                                  (this.props.location.state as any) &&
                                  (this.props.location.state as any)
                                    .sessionPage != undefined
                                ) {
                                  sessionPage = (
                                    this.props.location.state as any
                                  ).sessionPage;
                                  path = (this.props.location.state as any)
                                    .path;
                                } else {
                                  path = '';
                                }
                                if (path == '') {
                                  this.goToClaimCase(
                                    `${this.props.location.pathname}/${claimCase.id}`
                                  );
                                } else {
                                  this.props.history.push({
                                    pathname: `${this.props.location.pathname}/${claimCase.id}`,
                                    state: {
                                      sessionPage: sessionPage,
                                      path: path,
                                    },
                                  });
                                }
                              }
                            }}
                          >
                            {claimCase.claimReference}
                          </span>
                          {(claimCase.claimReference && (
                            <div
                              role="button"
                              tabIndex={0}
                              className="copy"
                              aria-label="Copy"
                              onClick={() => {
                                window.navigator.clipboard
                                  .writeText(claimCase.claimReference || '')
                                  .then(() => {
                                    this.props.dispatch(
                                      setToast({
                                        isShow: true,
                                        type: 'success',
                                        text: 'Copied to clipboard',
                                      })
                                    );
                                  });
                              }}
                            />
                          )) ||
                            ''}
                          {claimCase &&
                            claimCase.policies &&
                            claimCase.policies.map((policy) => {
                              return (
                                <div
                                  className="informationDetail"
                                  key={policy.riderDetailCode}
                                >
                                  <div className="informationTitle">
                                    {(policy.riderDetailCode ===
                                      parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                      'Nằm viện thường') ||
                                      (policy.riderDetailCode ===
                                        parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                        'Nằm viện ICU') ||
                                      (policy.riderDetailCode ===
                                        parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                        'Phẫu thuật') ||
                                      (policy.riderDetailCode ===
                                        parseInt(
                                          KEYS.TIMES_FOR_MAJOR_SURGERY,
                                          0
                                        ) &&
                                        'Đại phẫu')}
                                  </div>
                                  <div className="informationDay">
                                    <input
                                      name={
                                        (policy.riderDetailCode &&
                                          policy.riderDetailCode.toString()) ||
                                        ''
                                      }
                                      type="number"
                                      min={0}
                                      className="editInputRider"
                                      style={
                                        (typeof policy.riderDetailCode ===
                                          'number' &&
                                          ((policy.riderDetailCode ===
                                            parseInt(
                                              KEYS.TIMES_FOR_SURGERY,
                                              0
                                            ) &&
                                            editSurgeryCase &&
                                            editSurgeryCase[policy.id]) ||
                                            (policy.riderDetailCode ===
                                              parseInt(
                                                KEYS.TIMES_FOR_MAJOR_SURGERY,
                                                0
                                              ) &&
                                              editMajorSurgeryCase &&
                                              editMajorSurgeryCase[
                                                policy.id
                                              ]) ||
                                            (policy.riderDetailCode ===
                                              parseInt(
                                                KEYS.DAYS_IN_HOSPITAL,
                                                0
                                              ) &&
                                              editHospitalCase &&
                                              editHospitalCase[policy.id]) ||
                                            (policy.riderDetailCode ===
                                              parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                              editICUCase &&
                                              editICUCase[policy.id])) && {
                                            display: 'inline-block',
                                            width: '2vw',
                                          }) || { display: 'none' }
                                      }
                                      onChange={(event) =>
                                        this.handleRiderDataCase(
                                          policy.id,
                                          event
                                        )
                                      }
                                      value={policy.inputValue}
                                    />
                                    {(typeof policy.riderDetailCode ===
                                      'number' &&
                                      ((policy.riderDetailCode ===
                                        parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                        editSurgeryCase &&
                                        editSurgeryCase[policy.id]) ||
                                        (policy.riderDetailCode ===
                                          parseInt(
                                            KEYS.TIMES_FOR_MAJOR_SURGERY,
                                            0
                                          ) &&
                                          editMajorSurgeryCase &&
                                          editMajorSurgeryCase[policy.id]) ||
                                        (policy.riderDetailCode ===
                                          parseInt(KEYS.DAYS_IN_HOSPITAL, 0) &&
                                          editHospitalCase &&
                                          editHospitalCase[policy.id]) ||
                                        (policy.riderDetailCode ===
                                          parseInt(KEYS.DAYS_IN_ICU, 0) &&
                                          editICUCase &&
                                          editICUCase[policy.id])) &&
                                      ' ') ||
                                      policy.value}
                                    {(policy.riderDetailCode !==
                                      parseInt(KEYS.TIMES_FOR_SURGERY, 0) &&
                                      policy.riderDetailCode !==
                                        parseInt(
                                          KEYS.TIMES_FOR_MAJOR_SURGERY,
                                          0
                                        ) &&
                                      ' ngày') ||
                                      ' lần'}
                                  </div>
                                  <div className="informationMoney">
                                    {policy.amount}
                                  </div>
                                </div>
                              );
                            })}

                          <div className="informationDetail">
                            <div className="informationTitle ">Tổng cộng</div>
                            <div className="informationMoney">
                              {claimCase.sumAssured} VND
                            </div>
                          </div>
                          {(claimCase.decisionStatus && (
                            <div className="informationDetailDecisionFirst">
                              <div className="informationTitle ">
                                Trạng thái Decision
                                {(claimCase.decisionAt &&
                                  ` (Cập nhật lần cuối ${utils.formatDateToStringDateDMYHM(
                                    new Date(claimCase.decisionAt)
                                  )})`) ||
                                  ''}
                              </div>
                              <div className="informationMoney">
                                {claimCase.decisionStatus}
                              </div>
                            </div>
                          )) ||
                            ''}
                          {(claimCase.decisionBy && (
                            <div className="informationDetailDecisionSecond">
                              <div className="informationTitle ">
                                Decision bởi
                              </div>
                              <div className="informationMoney">
                                {claimCase.decisionBy}
                              </div>
                            </div>
                          )) ||
                            ''}
                          <div
                            role="button"
                            tabIndex={0}
                            className="informationDetail statusRow"
                            onClick={() => {
                              if (
                                (this.props.location.state as any) &&
                                (this.props.location.state as any)
                                  .sessionPage != undefined
                              ) {
                                sessionPage = (this.props.location.state as any)
                                  .sessionPage;
                                path = (this.props.location.state as any).path;
                              } else {
                                path = '';
                              }
                              if (path == '') {
                                this.goToClaimCase(
                                  `${this.props.location.pathname}/${claimCase.id}`
                                );
                              } else {
                                this.props.history.push({
                                  pathname: `${this.props.location.pathname}/${claimCase.id}`,
                                  state: {
                                    sessionPage: sessionPage,
                                    path: path,
                                  },
                                });
                              }
                            }}
                          >
                            <div className="informationTitle ">Trạng thái</div>
                            <div
                              className="informationMoney statusBtn"
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                if (
                                  (this.props.location.state as any) &&
                                  (this.props.location.state as any)
                                    .sessionPage != undefined
                                ) {
                                  sessionPage = (
                                    this.props.location.state as any
                                  ).sessionPage;
                                  path = (this.props.location.state as any)
                                    .path;
                                } else {
                                  path = '';
                                }
                                if (path == '') {
                                  this.goToClaimCase(
                                    `${this.props.location.pathname}/${claimCase.id}`
                                  );
                                } else {
                                  this.props.history.push({
                                    pathname: `${this.props.location.pathname}/${claimCase.id}`,
                                    state: {
                                      sessionPage: sessionPage,
                                      path: path,
                                    },
                                  });
                                }
                              }}
                            >
                              {claimCase &&
                                claimCase.status &&
                                _.find(KEYS.STATUS_OPTIONS_CASE(), {
                                  value: claimCase.status,
                                }) &&
                                // @ts-ignore
                                _.find(KEYS.STATUS_OPTIONS_CASE(), {
                                  value: claimCase.status,
                                }).name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )) ||
                ''}
            </div>
          )) ||
            ''}
        </div>

        <div className="noteAssessmentScreenContainer">
          <span className="noteHeader">Lưu ý</span>
          <div className="noteMsgContainer">
            {(data[KEYS.FIELD.JET_STATUS] === KEYS.JET_STATUS.CONFIRM_JET && (
              <div className="noteMsgContent danger">
                <p>
                  Đây là claim <span className="jet">JET</span> 🚀
                </p>
              </div>
            )) ||
              ''}
            {(data[KEYS.FIELD.STP] === KEYS.STP_STATUS.SUCCESS && (
              <div className="noteMsgContent primary">
                <p>
                  Đây là claim <span className="stp">STP</span> 🤖
                </p>
              </div>
            )) ||
              ''}
            <div className="checkBoxClaim">
              <label
                htmlFor="isAccident"
                className={`accident ${
                  (data[KEYS.IS_ACCIDENT] && 'active') || 'disabled'
                }`}
              >
                <input
                  id="isAccident"
                  name="isAccident"
                  type="checkbox"
                  className="accidentCheck"
                  disabled={(!data[KEYS.IS_ACCIDENT] && true) || false}
                  checked={data[KEYS.IS_ACCIDENT]}
                  readOnly={true}
                />
                Tai nạn
              </label>

              <label
                htmlFor="noneJet"
                className={`noneJet ${
                  (data[KEYS.FIELD.JET_STATUS] === 3 && 'active') || ''
                }
                ${
                  ((data[KEYS.FIELD.JET_STATUS] === KEYS.JET_STATUS.NONE_JET ||
                    data[KEYS.FIELD.JET_STATUS] === KEYS.JET_STATUS.JET ||
                    data[KEYS.FIELD.JET_STATUS] ===
                      KEYS.JET_STATUS.ASSESSMENT_JET) &&
                    'disabled') ||
                  ''
                }`}
              >
                <input
                  id="noneJet"
                  name="noneJet"
                  type="checkbox"
                  className="jetCheck"
                  disabled={
                    data[KEYS.FIELD.JET_STATUS] === KEYS.JET_STATUS.NONE_JET ||
                    data[KEYS.FIELD.JET_STATUS] === KEYS.JET_STATUS.JET ||
                    data[KEYS.FIELD.JET_STATUS] ===
                      KEYS.JET_STATUS.ASSESSMENT_JET
                  }
                  checked={data[KEYS.FIELD.JET_STATUS] === 3}
                  onChange={(e) => {
                    if (
                      data[KEYS.FIELD.JET_STATUS] ===
                      KEYS.JET_STATUS.CONFIRM_JET
                    ) {
                      if (e.target.value === 'on') {
                        this.updateJetStatus(true);
                      }
                    }
                  }}
                />
                None Jet
              </label>

              <label
                htmlFor="challengeAbuse"
                className={`challengeAbuse ${
                  (data[KEYS.FIELD.IS_CHALLENGE_ABUSE] && 'active') || ''
                }`}
              >
                <input
                  id="challengeAbuse"
                  name="challengeAbuse"
                  type="checkbox"
                  className="challengeAbuseCheck"
                  disabled={
                    (data[KEYS.FIELD.IS_CHALLENGE_ABUSE] && true) || false
                  }
                  checked={data[KEYS.FIELD.IS_CHALLENGE_ABUSE]}
                  onChange={(e) => {
                    if (e.target.value === 'on') {
                      this.updateChallengeAbuse(
                        !data[KEYS.FIELD.IS_CHALLENGE_ABUSE]
                      );
                    }
                  }}
                />
                Challenge
              </label>
            </div>
          </div>
          <span className="noteHeader">Thao tác</span>
          <div className="updateStatusRequestAssessment">
            <ButtonSquare
              className="confirm adjust"
              onClick={() =>
                this.handleToggleAssessmentModal(KEYS.UPDATE_ADJUST)
              }
            >
              {t('portal.assessment.action.adjust')}
            </ButtonSquare>
            <ButtonSquare
              disabled={!data.isDecision}
              className={`confirm decision ${!data.isDecision && 'disabled'}`}
              onClick={() => {
                if (data.isDecision) {
                  this.handleToggleAssessmentModal(KEYS.UPDATE_DECISION);
                }
              }}
            >
              {t('portal.assessment.action.decision')}
            </ButtonSquare>
            <ButtonSquare
              className="confirm fraud"
              onClick={() => this.handleCheckFraud()}
            >
              Fraud
            </ButtonSquare>
          </div>
          <div className="codeBlock">{this.renderCodeBlock(data)}</div>
          <div className="deleteContainer">
            <ButtonSquare
              className="deleteClaim"
              onClick={() =>
                this.handleToggleAssessmentModal(KEYS.DELETE_CLAIM)
              }
            >
              XÓA CLAIM
            </ButtonSquare>
          </div>
        </div>
        <div />
        {/*
        This is alert box for decision
        */}
        <div className={`alertContainer ${(showAlert && 'show') || ''}`}>
          <div
            aria-label="overlay"
            tabIndex={0}
            role="button"
            className="alertOverlay"
            onClick={() =>
              this.setState({
                showAlert: false,
              })
            }
          />
          <div className="alertBox">
            <div
              tabIndex={0}
              role="button"
              onClick={() =>
                this.setState({
                  showAlert: false,
                })
              }
            >
              ĐÓNG
            </div>
            <h1>Trạng thái Decision</h1>
            <div>
              {(decisionData &&
                decisionData.length &&
                decisionData.map((deData) => {
                  return (
                    <div key={deData.claimReference}>
                      <span>{(deData.isSuccess && '✅') || '❌'}</span>
                      <span className="refs">{deData.claimReference} </span>
                      <span className="msg">{deData.message}</span>
                    </div>
                  );
                })) ||
                ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProp)(
  withTranslation('common')(RequestAssessmentScreen)
);
