const endpoints = {
  TERM_OF_USE: '/metadata/?type=terms_and_uses',
  GET_METADATA: (type: string, search?: string, excludedData?: string) =>
    `/metadata/?type=${type}&limit=10${(search && `&search=${search}`) || ''}${
      (excludedData && `&excludedData=${excludedData}`) || ''
    }`,
  INSURERS: '/insurers',
  HOLDER: '/holders',
  HOLDER_VERIFY: (nationalId: string, insurerId: string) =>
    `/Holders/verify?HolderNationalId=${nationalId}&InsurerId=${insurerId}`,
  GET_INSUREDS: '/po',
  GET_CONTRACT_POLICIES: (insuredId: string) =>
    `/Insureds/${insuredId}/contracts-policies`,
  GET_START_DATE_POLICIES: (id: string, isAccident: boolean) =>
    `/Insureds/${id}/start-date?isAccident=${isAccident}`,
  BANKS: '/metadata/?type=bank_list',
  CITIES: '/metadata/?type=city_vn',
  TRACKING_HOLDER: '/Tracking',
  CLAIM_PO_VERIFY: (claimId: string, name: string) =>
    `/Claims/${claimId}/claim-po?name=${name}`,
  UPDATE_PAYMENT: (claimId: string) =>
    `/Claims/${claimId}/payment`,
  UPDATE_DETAIL_CLAIM: (id: string)  => `/Claims/${id}/details`,
  SUBMIT_CLAIM:(claimId?:string)=> `/Claims${(claimId && (`/${claimId}`)) || ''}`,
  SUBMIT_DOC: (id: string) => `/Claims/${id}/documents`,
  DOC_DETAIL: (id: string, documentId: string) => `/Claims/${id}/documents/${documentId}`,
  GET_INITIAL_STATE: (id: string) => `/Claims/${id}/website`,
  GET_MEDICAL_PROVIDER: (search?: string, excludedData?: string)=>
    `/MedicalProviders/?limit=10${(search && `&search=${search}`) || ''}${
      (excludedData && `&excludedData=${excludedData}`) || ''
    }`,
  GET_DETAIL_TRACKING: (claimNumber: string) =>  `/Tracking/${claimNumber}`,
  UPDATE_OTP_TRACKING: (poId: string) => `/Tracking/po/${poId}`,
  GET_LETTER_TRACKING: (claimCaseId: string) => `/Tracking/${claimCaseId}/letters`,
  UPLOAD_FILE_TRACKING: (claimCaseId: string) => `/Tracking/${claimCaseId}/documents`,
  ANNOUNCEMENT_SERVICES: '/metadata/announcement'
};
export default endpoints;
