import { SET_USER } from '../types';
import { UserAction } from '../interface';

function setUser(user: string): UserAction {
  return {
    type: SET_USER,
    user,
  };
}

export default setUser;
