/**
 ** @class TermAndUseScreen
 */

import React from 'react';
import './style.css';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';
import KEYS from '../../../../config/key';
import { showAnnouncementModal } from '../../../../config/modal';

interface IProps {
  dispatch: Function;
}

interface IState {
  data: sampleData;
}

type sampleData = {
  name: string;
  value: string;
};

class TermAndUseScreen extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: '',
        value: '',
      },
    };
  }

  componentDidMount(): void {
    this.getFirstLoad().then();
  }

  getFirstLoad = async () => {
    const announcement = await ClaimService.getAnnouncement();
    announcement.content ? localStorage.setItem(KEYS.ANNOUNCEMENT_CONTENT, announcement.content)
      : localStorage.removeItem(KEYS.ANNOUNCEMENT_CONTENT);
    announcement.message ? localStorage.setItem(KEYS.ANNOUNCEMENT_MESSAGE, announcement.message)
      : localStorage.removeItem(KEYS.ANNOUNCEMENT_MESSAGE);
    if (announcement && announcement.message) {
      showAnnouncementModal();
    }
    
    this.props.dispatch(setLoading(true));
    const response = await ClaimService.getTermAndUse();
    if (response) {
      this.setState({ data: response[0] }, ()=>{
        this.props.dispatch(setLoading(false));
      });
    }
  };

  render = () => {
    const { data }: Readonly<IState> = this.state;
    return (
      <div className="termsAndUsesContainer">
        <div className="termsAndUsesHeading">
          <p>{data && data.name}</p>
        </div>
        <div className="termsAndUsesContent">
          {(data && ReactHtmlParser(data.value)) || ''}
        </div>
      </div>
    );
  };
}

export default connect()(TermAndUseScreen);
