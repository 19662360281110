/**
 ** @class TrackingDetailHistory
 */

import React from 'react';
import './style.css';
import { Modal, StepperVertical, Button } from 'papaya-ui';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import u from 'underscore';
import { ROUTES } from '../../../../config/ClaimWebsite/pathSetting';
import KEYS from '../../../../config/key';
import utils from '../../../../config/utils';
import { HistoryClaim, HistoryClaimStatus } from '../../../../config/interface';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import setToast from '../../../../store/ShareStore/actions/toast';



interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface IState {
  modalShow: boolean,
  tooltipShow: boolean,
  data: inputData,
  history: Array<HistoryStatus>
  historyClaim: HistoryClaim
  indexTooltip?: number
}

interface inputData {
  phone?: string,
  password?: string,
  claimCaseId?: string
  error?: boolean
}

interface HistoryStatus {
  date?: string,
  isActive?: boolean,
  value?: string | number,
  button?: boolean
}

const mapStateToProps = (state) => ({
  data: state.data,
});

class TrackingDetailHistory extends React.Component<IProps, IState> {
  timeOut;

  pdfName: string = '';
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      history: [],
      historyClaim: {},
      modalShow: false,
      tooltipShow: false,
      data: {}
    };
    
    if (!props.data.postBack
      || (props.data.postBack && !props.data.postBack.trackingDetail)
      || (props.data.postBack
        && !props.data.postBack.trackingDetail.data)) {
      this.props.history.replace(ROUTES.tracking);
    }
  
    if (!props.data.postBack
      || (props.data.postBack && !props.data.postBack.trackingDetailHistory)
      || (props.data.postBack
        && !props.data.postBack.trackingDetailHistory.data)) {
      this.props.history.replace(ROUTES.trackingDetail);
    }
  }
  
  componentDidMount() {
    const { data } = this.props;
    const newData = _.clone(data);
    if(newData.postBack && newData.postBack.trackingDetailHistory) {
      this.setState({
        historyClaim: newData.postBack.trackingDetailHistory.data
      }, () => {
        this.handleLogicAll();
      });
    }
  }
  
  handleLogicAll = () => {
    if(this.state.historyClaim.status) {
      this.getOptionValue(this.state.historyClaim.status);
    }
    if(this.state.historyClaim.riderDetails) {
      this.getBenefitRequest(this.state.historyClaim.riderDetails);
    }
  };
  
  handleTrackingUpload = () => {
    this.props.history.replace(ROUTES.trackingUpload);
  };
  
  getBenefitRequest = (
    options: Array<{ riderDetailCode: number; value: string }>
  ) => {
    const { historyClaim } = this.state;
    const newHistoryClaim = _.clone(historyClaim);
    newHistoryClaim.benefitRequest = u.pluck(options, 'riderDetailCode').map((val) => {
      let newVal = '';
      switch (val) {
        case 0:
          newVal = KEYS.STATUS.HOSPITAL.REQUEST;
          break;
        case 1:
          newVal = KEYS.STATUS.ICU.REQUEST;
          break;
        case 2:
          newVal = KEYS.STATUS.SURGERY.REQUEST;
          break;
        case 3:
          newVal = KEYS.STATUS.MAJOR_SURGERY.REQUEST;
          break;
      }
      return newVal;
    });
    this.setState({
      historyClaim: newHistoryClaim
    });
  };
  
  getOptionValue = (getHistory: Array<HistoryClaimStatus>) => {
    const newHistory: Array<HistoryStatus> = [];
    
    getHistory.map((data) => {
      const newData: HistoryStatus = {};
      newData.date = utils.formatDateToStringDateDMYHM(new Date(data.date));
      newData.isActive = data.isActive;
      newData.value = data.statusDescription;
      if((data.status === KEYS.STATUS.INPROGRESS.VALUE
        || data.status === KEYS.STATUS.PENDING.VALUE
        || data.status === KEYS.STATUS.RECEIVE_UPLOAD.VALUE)
        && data.isActive) {
        newData.button = true;
      }
      return newHistory.push(newData);
    });
    
    this.setState({
      history: newHistory
    });
  };
  
  openPopUpPassword = async (claimCaseId?: string, letterName?: string) => {
    if(letterName) {
      this.pdfName =  letterName;
    }
    const { data } = this.props;
    const newData = _.clone(this.state.data);
    if(data && data.postBack && data.postBack.trackingDetail.data) {
      if (data.postBack.trackingDetail.data.poId) {
        const response =
          await ClaimService.updateOTPTracking(
            data.postBack.trackingDetail.data.poId, data.postBack.trackingDetailHistory.claimCaseId);
        if (response.status === '200' || response.status === '201') {
          newData[KEYS.TRACKING_HISTORY_KEY.PHONE] = response.message;
          newData[KEYS.TRACKING_HISTORY_KEY.CLAIM_CASE_ID] = claimCaseId;
          this.setState({
            data: newData,
            modalShow: true,
            tooltipShow: false
          });
        }
      }
    }
  };
  
  closePopUpPassword = () => {
    const { data }: Readonly<IState> = this.state;
    const newData = _.clone(data);
    newData[KEYS.TRACKING_HISTORY_KEY.ERROR_PASSWORD] = '';
    this.setState({ modalShow: false, data: newData });
  };
  
  togglePassword = () => {
    const element = document.getElementById('password') as HTMLInputElement;
    if (element) {
      if(element.type === 'password') {
        element.type = 'text';
      } else {
        element.type = 'password';
      }
    }
  };
  
  openTooltip = (index) => {
    this.setState({
      tooltipShow: true,
      indexTooltip: index
    });
  };
  
  handleChange = (event) => {
    const { data } = this.state;
    const newData = _.clone(data);
    newData[KEYS.TRACKING_HISTORY_KEY.PASSWORD] = event.target.value;
    newData[KEYS.TRACKING_HISTORY_KEY.ERROR_PASSWORD] = false;
    this.setState({
      data: newData
    });
  };
  
  getLetterTracking = async () => {
    const { data } = this.state;
    const newData = _.clone(data);
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout( async () => {
      if (data.claimCaseId && data.password) {
        const letterTrackingData = {
          id: data.claimCaseId,
          otp: data.password
        };
        const response =
          await ClaimService.getLetterTracking(
            data.claimCaseId, letterTrackingData);

        if (response && response.Errors
          && response.Errors[0].Code === KEYS.ERROR.WRONG_PASSWORD_LETTER) {
          newData[KEYS.TRACKING_HISTORY_KEY.ERROR_PASSWORD] = true;
          this.setState({
            data: newData
          });
        } else if (response && response.Errors
          && response.Errors[0].Code === KEYS.ERROR.EXPIRE_PASSWORD_LETTER) {
          this.props.dispatch(
            setToast({
              isShow: true,
              type: 'warning',
              text: response && response.Errors
                && response.Errors[0].Message,
            })
          );
          this.setState({
            modalShow: false
          });
        } else if (response.data) {
          this.setState({
            modalShow: false
          }, () => {
            this.downloadPDF(response.data);
          });
        }
      }
    }, 500);
  };
  
  downloadPDF = (pdf: string) =>  {
    const linkSource = pdf;
    const downloadLink = document.createElement('a');
    const fileName = this.pdfName;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  
  renderDiseases = (value: Array<{name: string, value: string}>) => {
    let renderDiseases = '';
    renderDiseases = value.map((val) => {
      return val.name;
    }).join('$SPLIT$ ');
    return utils.toTxtComp(renderDiseases, '$SPLIT$');
  };
  
  render = () => {
    const { t } = this.props;
    const {
      historyClaim, history, modalShow,
      data, tooltipShow, indexTooltip
    } = this.state;
    return (
      <div className="statusContainer">
        <Modal
          name="globalModal"
          className="globalPasswordModal"
          value={modalShow}
          closeButton={true}
          onClose={() => this.closePopUpPassword()}
        >
          <div className="passwordModal">
            <div className="passwordHeader">
              {t('tracking.history.passwordModal.header')}
            </div>
            <div className="passwordContent">
              <p className="passwordContentHead">{t('tracking.history.passwordModal.content')} </p>
              <span className="phoneNumber">{data[KEYS.TRACKING_HISTORY_KEY.PHONE]} </span>
              <div className="inputPassword">
                <input
                  maxLength={6}
                  className="inputEffect"
                  id="password"
                  type="password"
                  placeholder="Nhập mật khẩu"
                  onChange={(event) => this.handleChange(event)}
                />
                <span className="focus-border">
                  <i />
                </span>
                <div
                  role="button"
                  aria-label="eyePassword"
                  tabIndex={0}
                  className="eyePassword"
                  onClick={() => this.togglePassword()}
                />
              </div>
              {(data[KEYS.TRACKING_HISTORY_KEY.ERROR_PASSWORD] &&
              <div className="inputError">
                {t('tracking.history.passwordModal.error')}
              </div>) || ''}
            </div>
            <div className="passwordDescription">
              <div className="warningDescription" />
              <p>{t('tracking.history.passwordModal.warning')}</p>
            </div>
            <div className="passwordButton">
              <Button
                styles={((!data[KEYS.TRACKING_HISTORY_KEY.PASSWORD] || data[KEYS.TRACKING_HISTORY_KEY.PASSWORD] === '')
                  && { 'backgroundColor':'#C4CDD5' }) || { 'backgroundColor':'#183028' }}
                disabled={((!data[KEYS.TRACKING_HISTORY_KEY.PASSWORD]
                  || data[KEYS.TRACKING_HISTORY_KEY.PASSWORD] === '') && true) || false}
                onClick={() => this.getLetterTracking()}
              >
                {t('tracking.history.passwordModal.button')}
              </Button>
            </div>
            <div className="passwordExplain">
              {t('tracking.history.passwordModal.explanation')}
            </div>
          </div>
        </Modal>
        
        <div className="statusHeader">
          {`(#${historyClaim.claimReference})`} Trạng thái bồi thường
        </div>
        <div className="statusContent">
          <div className="historyStatus">
            <StepperVertical
              className="StepperHistory"
              options={history}
              current={1}
              onClick={() => this.handleTrackingUpload()}
            />
          </div>
          <div className="claimDetail">
            <div className="detailRow">
              <p className="detailTitle">{t('tracking.history.claimReference')}</p>
              <p className="detailContent">{historyClaim.claimReference}</p>
            </div>
        
            <div className="detailRow">
              <p className="detailTitle">{t('tracking.history.laName')}</p>
              <p className="detailContent">{historyClaim.laName}</p>
            </div>
        
            <div className="detailRow">
              <p className="detailTitle">{t('tracking.history.medicalProvider')}</p>
              <p className="detailContent">{historyClaim.medicalProvider}</p>
            </div>
        
            {historyClaim.riderDetails
            && historyClaim.riderDetails.map((rider) => {
              return (
                <div className="detailRow" key={rider.riderDetailCode}>
                  <p className="detailTitle">
                    {(rider.riderDetailCode ===
                      parseInt(KEYS.DAYS_IN_HOSPITAL, 0)
                      && KEYS.STATUS.HOSPITAL.RIDER)
                    || (rider.riderDetailCode
                      === parseInt(KEYS.DAYS_IN_ICU, 0)
                      && KEYS.STATUS.ICU.RIDER)
                    || (rider.riderDetailCode
                      === parseInt(KEYS.TIMES_FOR_SURGERY, 0)
                      && KEYS.STATUS.SURGERY.RIDER)
                    || (rider.riderDetailCode
                      === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0)
                      && KEYS.STATUS.MAJOR_SURGERY.RIDER)}
                  </p>
                  <p className="detailContent">{
                    (rider.riderDetailCode ===
                      parseInt(KEYS.DAYS_IN_HOSPITAL, 0)
                      && rider.value)
                    || (rider.riderDetailCode
                      === parseInt(KEYS.DAYS_IN_ICU, 0)
                      && rider.value)
                    ||
                    (rider.riderDetailCode
                      === parseInt(KEYS.TIMES_FOR_SURGERY, 0)
                      && '1 lần')
                    || (rider.riderDetailCode
                      === parseInt(KEYS.TIMES_FOR_MAJOR_SURGERY, 0)
                      && '1 lần')
                  }
                  </p>
                </div>
              );
            })}
        
            <div className="detailRow">
              <p style={{ marginBottom: '24px' }} className="detailTitle">{t('tracking.history.disease')}</p>
              <div
                style={{ marginBottom: '24px' }}
                className="detailContent"
              >
                {historyClaim.diseases
              && this.renderDiseases(historyClaim.diseases)}
              </div>
            </div>
        
            <div className="detailRow">
              <p style={{ marginBottom: '24px' }} className="detailTitle">
                {t('tracking.history.rider')}
              </p>
              <div
                style={{ marginBottom: '24px' }}
                className="detailContent"
              >{
                historyClaim.benefitRequest &&
                historyClaim.benefitRequest.map((val) => {
                  return <p key={val} className="benefitRequests">{val}</p>;
                })
              }
              </div>
            </div>
            {((historyClaim && historyClaim.letters) &&
            <div className="detailRow">
              <p style={{ marginBottom: '24px' }} className="detailTitle">{t('tracking.history.letter')}</p>
              <div
                style={{ marginBottom: '24px', color: '#0097A9', cursor: 'pointer' }}
                className="detailContent"
              >
                {historyClaim.letters
                && historyClaim.letters.map((letter, index) => {
                  return (
                    <div key={letter.name}>
                      {(tooltipShow && indexTooltip === index
                      && letter.id == null
                      &&
                      <div className="tooltipLetter" onBlur={() => this.setState({ tooltipShow: false })}>
                        <div className="tooltipLetterHeader">
                          {t('tracking.history.tooltipLetter.header')}
                        </div>
                        <div className="tooltipLetterContent">
                          {t('tracking.history.tooltipLetter.content')}
                        </div>
                        <div
                          className="tooltipLetterButton"
                          role="button"
                          tabIndex={0}
                          onClick={() => this.setState({ tooltipShow: false })}
                        >
                          {t('tracking.history.tooltipLetter.button')}
                        </div>
                      </div>) || ''}
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          if(letter.id) {
                            this.openPopUpPassword(letter.id, letter.name);
                          } else {
                            this.openTooltip(index);
                          }
                        }}
                        className={(letter.id && 'validLetter') || 'inValidLetter'}
                      >{letter.name}
                      </div>
                    </div>);
                })}
              </div>
            </div>) || ''}
          </div>
        </div>
        <div className="tableClaimHeader">
          <Button
            onClick={() => this.props.history.replace(ROUTES.trackingDetail)}
            className="backButton"
          >{t('footer.back')}
          </Button>
        </div>

      </div>
    );
  };
}

export default connect(mapStateToProps)(withTranslation('common')(TrackingDetailHistory));
