import { SET_TOKEN } from '../types';
import { AuthAction } from '../interface';
import KEYS from '../../../config/key';

const loadTokenFromLocal = () => {
  return localStorage.getItem('auth_token');
};

const saveTokenFromLocal = (token: string) => {
  return localStorage.setItem('auth_token', token);
};

const clearTokenFromLocal = () => {
  localStorage.removeItem('user');
  return localStorage.removeItem('auth_token');
};

export type AuthType = {
  token?: string;
};

export default function auth(
  state: AuthType = {
    token: loadTokenFromLocal() || '',
  },
  action: AuthAction
) {
  const newState = state;
  if (action.type === SET_TOKEN) {
    newState.token = action.token;
    if (newState.token) {
      saveTokenFromLocal(
        `${action.token}${KEYS.DELIMITER}${action.expiredDate}`
      );
    } else {
      clearTokenFromLocal();
      delete newState.token;
    }
  }
  return newState;
}
