/**
 ** @class HomeScreen
 */

import React from 'react';
import { ButtonSquare } from 'papaya-ui';
import './style.css';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearData } from '../../../../store/ClaimWebsite/commonActions';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
}

class HomeScreen extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    props.dispatch(clearData());
  }

  goToTerm = () => {
    this.props.history.push('/term');
  };

  goToCheck = () => {
    this.props.history.push('/tracking');
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="homeScreenContainer">
        <div className="homeScreen">
          <div className="homeScreenImage" />
          <div className="homeScreenHeading">
            <div className="homeScreenHeadingClock" />
            <p>{t('home.solveBenefitInsurance')}</p>
          </div>
          <div className="homeScreenAction">
            <ButtonSquare
              className="circleCreate"
              icon="circlePlus"
              onClick={() => this.goToTerm()}
            >
              {t('home.createClaimRequest')}
            </ButtonSquare>
            <ButtonSquare
              styles={{ marginTop: '16px' }}
              className="circleTracking"
              icon="circleTimeFlash"
              onClick={() => this.goToCheck()}
            >
              {t('home.watchClaimRequest')}
            </ButtonSquare>
          </div>
        </div>
      </div>
    );
  };
}

export default connect()(withTranslation('common')(HomeScreen));
