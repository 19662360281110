/**
 ** @class SubHeader
 */

import React from 'react';
import './style.css';
import { StepperHorizontal } from 'papaya-ui/dist';
import { WithTranslation, withTranslation } from 'react-i18next';
import { pathSeries } from '../../../../config/ClaimWebsite/pathSetting';

interface IProps extends WithTranslation {
  current: number;
}

class SubHeader extends React.PureComponent<IProps> {
  render = () => {
    const { current } = this.props;
    return (
      <div className="subHeader">
        <div className="text label">{pathSeries[current - 1].text}</div>
        <StepperHorizontal
          className="step"
          // @ts-ignore
          colorStyle="#183028"
          options={pathSeries}
          current={current || 1}
        />
        <div className="blank" />
      </div>
    );
  };
}

export default withTranslation('common')(SubHeader);
