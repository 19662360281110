import { CLAIM_WEBSITE, Service } from '../services';
import endpoints from './endpoint';
import { DetailClaimData, PaymentClaimData, SubmitClaimData, LetterTrackingData } from '../interface';
import { ClaimWebsiteStore } from '../../store';
import setToast from '../../store/ShareStore/actions/toast';
import { setLoading } from '../../store/ClaimPortal/commonActions';
import setFooter from '../../store/ClaimWebsite/commonActions/footer';
import i18next from '../../translation';

i18next.setDefaultNamespace('common');

const handleErrorException = (response) => {
  if (response.error.status === 500) {
    ClaimWebsiteStore.dispatch(setToast({
      isShow: true,
      text: response.error.data.Errors[0].Message
    }));
  }
  if (!response.error.status) {
    const { footer } = ClaimWebsiteStore.getState();
    footer.nextState.disabled = true;
    ClaimWebsiteStore.dispatch(setFooter(footer));
    ClaimWebsiteStore.dispatch(setToast({
      isShow: true,
      text: i18next.t('errors.SERVER_ERROR')
    }));
    ClaimWebsiteStore.dispatch(setLoading(false));
  }
  return { error: true };
};

const ClaimService = {
  getAnnouncement: async () => {
    const response = await Service(CLAIM_WEBSITE).get(endpoints.ANNOUNCEMENT_SERVICES);
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getTermAndUse: async () => {
  
    const response = await Service(CLAIM_WEBSITE).get(endpoints.TERM_OF_USE);
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getMetadata: async (type: string, search?: string, excludedData?: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.GET_METADATA(type, search, excludedData)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getMedicalProvider: async (search?: string, excludedData?: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.GET_MEDICAL_PROVIDER(search, excludedData)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getInsurers: async () => {
    const response = await Service(CLAIM_WEBSITE).get(endpoints.INSURERS);
    if (response.error) {
      handleErrorException(response);
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getBanks: async () => {
    const response = await Service(CLAIM_WEBSITE).get(endpoints.BANKS);
    if (response.error) {
      handleErrorException(response);
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getCities: async () => {
    const response = await Service(CLAIM_WEBSITE).get(endpoints.CITIES);
    if (response.error) {
      handleErrorException(response);
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  verifyHolder: async (nationalId: string, insurerId: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.HOLDER_VERIFY(nationalId, insurerId)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getInsured: async (nationalId: string,
    dob: string, policyNumber?: string) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.GET_INSUREDS, {
        PolicyOwnerNationalId: nationalId,
        Dob: dob,
        PolicyNumber: policyNumber
      }
    );
    
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getContractPolicies: async (insuredId: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.GET_CONTRACT_POLICIES(insuredId)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getStartDatePolicies: async (
    insuredId: string,
    isAccident: boolean = false
  ) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.GET_START_DATE_POLICIES(insuredId, isAccident)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  
  getInitialState: async (claimId: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.GET_INITIAL_STATE(claimId)
    );
    if (response.error) {
      return handleErrorException(response);
    }
    return (response && response.data && response.data.data) || null;
  },
  verifyOwnerName: async (claimId: string, name: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.CLAIM_PO_VERIFY(claimId, name)
    );
    
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  updatePayment: async (claimId: string, paymentData: PaymentClaimData) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.UPDATE_PAYMENT(claimId),
      paymentData
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  trackingHolder: async (policyOwnerNationalId: string, dob: string) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.TRACKING_HOLDER, { policyOwnerNationalId, dob }
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  submitDetailClaim:
    async (claimId: string, detailClaimData: DetailClaimData) => {
      const response = await Service(CLAIM_WEBSITE).post(
        endpoints.UPDATE_DETAIL_CLAIM(claimId),
        detailClaimData
      );
      if (response.error) {
        handleErrorException(response);
        return response.error;
      }
      return (response && response.data && response.data.data) || null;
    },
  submitClaim: async (submitClaimData: SubmitClaimData) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.SUBMIT_CLAIM(),
      submitClaimData
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  doneClaim: async (claimId: string) => {
    const response = await Service(CLAIM_WEBSITE).patch(
      endpoints.SUBMIT_CLAIM(claimId)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  uploadDocument: async (
    claimId: string,
    file: FormData = new FormData(),
    callback?: Function
  ) => {
    const response = await Service(CLAIM_WEBSITE).upload(
      endpoints.SUBMIT_DOC(claimId),
      file,
      callback
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getDetailDocument: async (claimId: string, documentId: string) => {
    const response = await Service(CLAIM_WEBSITE).get(
      endpoints.DOC_DETAIL(claimId, documentId)
    );
    
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getDetailTracking: async (claimNumber: string) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.GET_DETAIL_TRACKING(claimNumber)
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateOTPTracking: async (poId: string, claimCaseId: string) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.UPDATE_OTP_TRACKING(poId), { claimCaseId }
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
  getLetterTracking: async (claimCaseId: string,
    letterTrackingData: LetterTrackingData) => {
    const response = await Service(CLAIM_WEBSITE).post(
      endpoints.GET_LETTER_TRACKING(claimCaseId),
      letterTrackingData
    );
    if (response.error) {
      handleErrorException(response);
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  uploadDocumentTracking: async (
    claimCaseId: string,
    file: FormData = new FormData(),
    callback?: Function
  ) => {
    const response = await Service(CLAIM_WEBSITE).upload(
      endpoints.UPLOAD_FILE_TRACKING(claimCaseId),
      file,
      callback
    );
    if (response.error) {
      handleErrorException(response);
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },
};
export default ClaimService;
