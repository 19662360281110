/**
 ** @class PaymentScreen
 */

import React from 'react';
import './style.css';
import { Radio } from 'papaya-ui';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import Transfer, { TransferType } from '../SubcomponentPayment/Transfer/index';
import Bank, { BankType } from '../SubcomponentPayment/Bank/index';
import Forward, { forwardType } from '../SubcomponentPayment/Forward/index';
import pathSetting, {
  ROUTES,
} from '../../../../config/ClaimWebsite/pathSetting';
import setFooter from '../../../../store/ClaimWebsite/commonActions/footer';
import KEYS from '../../../../config/key';
import { setData } from '../../../../store/ClaimWebsite/commonActions';
import { ObjectBooleanType } from '../../../../config/interface';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  refresh: boolean;
}

interface IState {
  data: inputData;
  child: childData;
  error: errorData;
  banks: Array<{ name: string; value: string }>;
  cities: Array<{ name: string; value: string }>;
}

interface errorData {
  transfer?: TransferType;
  cash?: BankType;
  forward?: forwardType;
}

interface childData {
  transfer?: TransferType;
  cash?: BankType;
  forward?: forwardType;
}

type inputData = {
  PaymentType?: Array<string> & string;
  ValidType: ObjectBooleanType;
};

const validType: ObjectBooleanType = {
  [KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE]: false,
  [KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE]: false,
  [KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE]: false,
};

const mapStateToProp = (state) => ({
  data: state.data,
});

class PaymentScreen extends React.Component<IProps, IState> {
  validType: ObjectBooleanType = {
    [KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE]: false,
    [KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE]: false,
    [KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE]: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: {
        ValidType: validType,
      },
      error: {},
      child: {},
      banks: [],
      cities: [],
    };
    if (!sessionStorage.getItem(KEYS.FIELD.CLAIM_ID)) {
      this.props.history.replace(ROUTES.policy);
    }
  }

  componentDidMount(): void {
    this.getFirstLoad().then();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh) {
      if (
        this.props.data.postBack &&
        !Object.keys(this.props.data.postBack.paperworkScreen).length
      ) {
        this.props.history.replace(ROUTES.paperwork);
      } else {
        this.getFirstLoad().then();
      }
    }
  }
  
  checkInitialData = () => {
    const { child, data }: Readonly<IState> = this.state;
    const newChild = _.clone(child);
    const newData = _.clone(data);
    
    if(_.isEmpty(newChild)) {
      newData.ValidType = this.validType;
    }
    
    this.setState({
      data: newData
    });
  };

  getFirstLoad = async () => {
    const { data } = this.props;
    const { postBack } = data;
    if (postBack &&!Object.keys(postBack.paperworkScreen).length) {
      this.props.history.replace(ROUTES.paperwork);
    }
    this.getBankList().then();
    this.getCityList().then();
    if (postBack && postBack.paymentScreen && postBack.paymentScreen.data) {
      this.setState(
        {
          data: postBack.paymentScreen.data,
          child: postBack.paymentScreen.child,
        },
        () => {
          this.handleLogicAll();
        }
      );
    }
  };

  getBankList = async () => {
    const response = await ClaimService.getBanks();
    if (response) {
      this.setState({ banks: response });
    }
  };

  getCityList = async () => {
    const response = await ClaimService.getCities();
    if (response) {
      this.setState({ cities: response });
    }
  };

  handleLogicAll = async () => {
    const { data }: Readonly<IState> = this.state;
    if (
      data &&
      data.PaymentType &&
      data.ValidType[
        (Array.isArray(data.PaymentType) && data.PaymentType.join('')) ||
          data.PaymentType
      ]
    ) {
      this.readyToNext();
    } else {
      this.readyToNext(true);
    }
  };

  readyToNext = (isNot: boolean = false) => {
    const { footer } = pathSetting()[ROUTES.payment];
    footer.nextState.disabled = isNot;
    footer.nextState.callback = async () => this.updatePayment();
    this.props.dispatch(setFooter(footer));
  };

  updatePayment = async () => {
    const { data } = this.props;
    if (data.mainData[KEYS.FIELD.CLAIM_ID]) {
      const newData = data;
      newData.postBack.paymentScreen = {};
      newData.postBack.paymentScreen = this.state;
      this.props.dispatch(setData(newData));
      let paymentData = {};
      if (
        this.state.data.PaymentType &&
        this.state.data.PaymentType === KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE
      ) {
        const newPaymentData = this.state.child.transfer || {};
        newPaymentData.ownerName = newPaymentData.ownerName?.trim();
        newPaymentData.paymentType = this.state.data.PaymentType;
        paymentData = newPaymentData;
      } else if (
        this.state.data.PaymentType &&
        this.state.data.PaymentType === KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE
      ) {
        const newPaymentData = this.state.child.cash || {};
        newPaymentData.receiverName = newPaymentData.receiverName?.trim();
        newPaymentData.paymentType = this.state.data.PaymentType;
        paymentData = newPaymentData;
      } else if (
        this.state.data.PaymentType &&
        this.state.data.PaymentType === KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE
      ) {
        const newPaymentData = this.state.child.forward || {};
        newPaymentData.paymentType = this.state.data.PaymentType;
        paymentData = newPaymentData;
      }
      const response = await ClaimService.updatePayment(
        data.mainData[KEYS.FIELD.CLAIM_ID],
        paymentData
      );
      return !(response && response.status);
    }
    return false;
  };

  handleChange = (name, value, isChild) => {
    const { data, child }: Readonly<IState> = this.state;
    const newData = data || {};
    if (isChild === true) {
      child[name] = value;
    }
    newData[name] = value;

    this.setState({ data: newData }, async () => {
      await this.handleLogicAll();
    });
  };

  handleError = (name, value) => {
    const { error }: Readonly<IState> = this.state;
    error[name] = value;
  };

  handleChangeValidPaymentType = (name: string, value: boolean) => {
    const { data }: Readonly<IState> = this.state;
    const newData = data;
    newData.ValidType[name] = value;
    this.setState(
      {
        data: newData,
      },
      () => {
        this.handleLogicAll().then();
      }
    );
  };

  render = () => {
    const { data, child, banks, cities, error }: Readonly<IState> = this.state;
    return (
      <div className="paymentContainer">
        <div className="methodPayment">
          <div className="methodContent" role="button" tabIndex={0} onClick={() => this.checkInitialData()}>
            <Radio
              className="methodPayment"
              name="PaymentType"
              value={(data && data.PaymentType) || []}
              label="Radio multiple input"
              defaultValue={[]}
              // @ts-ignore
              colorStyle={{
                checkColor: '#183028',
                containerColor: '#0097A9',
              }}
              options={[
                {
                  name: KEYS.PAYMENT_TYPE.TRANSFER_MONEY.NAME,
                  value: KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE,
                  component: (
                    <Transfer
                      onValid={(name, value) =>
                        this.handleChangeValidPaymentType(name, value)}
                      childValue={child.transfer}
                      error={error.transfer}
                      {...this.props}
                      onError={(name, value) => this.handleError(name, value)}
                      banksData={banks || []}
                      citiesData={cities || []}
                      onChange={(name, value) =>
                        this.handleChange(name, value, true)}
                    />
                  ),
                },
                {
                  name: KEYS.PAYMENT_TYPE.BANK_MONEY.NAME,
                  value: KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE,
                  component: (
                    <Bank
                      {...this.props}
                      onValid={(name, value) =>
                        this.handleChangeValidPaymentType(name, value)}
                      childValue={child.cash}
                      error={error.cash}
                      onError={(name, value) => this.handleError(name, value)}
                      banksData={banks || []}
                      citiesData={cities || []}
                      onChange={(name, value) =>
                        this.handleChange(name, value, true)}
                    />
                  ),
                  className: 'test',
                },
                {
                  name: KEYS.PAYMENT_TYPE.FORWARD_MONEY.NAME,
                  value: KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE,
                  component: (
                    <Forward
                      {...this.props}
                      onValid={(name, value) =>
                        this.handleChangeValidPaymentType(name, value)}
                      childValue={child.forward}
                      onChange={(name, value) =>
                        this.handleChange(name, value, true)}
                    />
                  ),
                },
              ]}
              onChange={(name, value) => this.handleChange(name, value, false)}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProp)(
  withTranslation('common')(PaymentScreen)
);
