/**
 ** @class StatusScreen
 */

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CardStatus } from 'papaya-ui';
import logoFWD from '../../../../assets/images/fwd.png';
import './style.css';

interface IProps extends RouteComponentProps<string> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface IState {
  data?: inputData;
}

interface inputData {}

class ClaimsScreen extends React.Component<IProps, IState> {
  timeOut;

  render = () => {
    return (
      <div className="statusContainer">
        <div className="statusHeader">
          <p>Kiểm tra trạng thái bồi thường</p>
        </div>
        <div className="statusSubHeader">
          <p>Chào Trần Thế Thái</p>
          <p className="statusDescription">
            Dưới đây là tất cả các hợp đồng bảo hiểm bạn đã yêu cầu bồi thường
          </p>
        </div>

        <div className="statusContent">
          <CardStatus
            options={[
              {
                id: '1',
                illness: 'Nhiễm virut COVID-19',
                type: 'Nằm viện ICU',
                status: 'pending',
                status_name: 'Yêu cầu nộp bổ sung',
                logo: logoFWD,
              },
              {
                id: '2',
                illness: 'Nhiễm virut COVID-19',
                type: 'Nằm viện ICU',
                status: 'approve',
                status_name: 'Yêu cầu nộp bổ sung',
                logo: logoFWD,
              },
              {
                id: '3',
                illness: 'Nhiễm virut COVID-19',
                type: 'Nằm viện ICU',
                status: 'reject',
                reason: 'Không hỗ trợ bệnh lý này',
                logo: logoFWD,
              },
            ]}
            button="Xem chi tiết"
          />
        </div>
      </div>
    );
  };
}

export default ClaimsScreen;
