import { ElementType } from 'react';
import {
  DetailScreen,
  EndScreen,
  HomeScreen,
  PaperWorkScreen,
  PaymentScreen,
  PolicyScreen,
  ProductScreen,
  ReviewScreen,
  TermAndUseScreen,
  TrackingScreen,
  TrackingProductScreen,
  TrackingDetail,
  TrackingDetailHistory,
  TrackingUpload
} from '../../app/screens/ClaimWebsite';
import { ROUTES } from './pathSetting';

export type RouteType = {
  name: string;
  path: string;
  component: ElementType;
};

const routesSubmit: Array<RouteType> = [
  {
    name: 'Product',
    path: ROUTES.product,
    component: ProductScreen,
  },
  {
    name: 'TrackingProduct',
    path: ROUTES.productTracking,
    component: TrackingProductScreen,
  },
  {
    name: 'PaymentScreen',
    path: ROUTES.payment,
    component: PaymentScreen,
  },
  {
    name: 'PaperWork',
    path: ROUTES.paperwork,
    component: PaperWorkScreen,
  },
  {
    name: 'Contract',
    path: ROUTES.policy,
    component: PolicyScreen,
  },
  {
    name: 'Detail',
    path: ROUTES.detail,
    component: DetailScreen,
  },
  {
    name: 'TermOfUse',
    path: ROUTES.term,
    component: TermAndUseScreen,
  },
  {
    name: 'Review',
    path: ROUTES.review,
    component: ReviewScreen,
  },
  {
    name: 'End',
    path: ROUTES.end,
    component: EndScreen,
  },
  {
    name: 'Home',
    path: ROUTES.home,
    component: HomeScreen,
  },
];

const routesHistory: Array<RouteType> = [
  {
    name: 'TrackingUpload',
    path: ROUTES.trackingUpload,
    component: TrackingUpload
  },
  {
    name: 'TrackingDetailHistory',
    path: ROUTES.trackingDetailHistory,
    component: TrackingDetailHistory
  },
  {
    name: 'TrackingDetail',
    path: ROUTES.trackingDetail,
    component: TrackingDetail
  },
  {
    name: 'CheckInformation',
    path: ROUTES.tracking,
    component: TrackingScreen,
  },
];

export { routesSubmit, routesHistory };
