import React from 'react';
import { Button } from 'papaya-ui';
import './styles.css';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import setModal from '../../../store/ClaimWebsite/commonActions/modal';
import KEYS from '../../key';

interface IProps extends WithTranslation {
  dispatch: Function
}

class AnnouncementModal extends React.PureComponent<IProps> {
  render() {
    const { t } = this.props;
    return (
      <div className="announcementModal">
        <h2 className="announcementModalHeader">{t('termAndUsed.modal.header')}</h2>
        <h3 dangerouslySetInnerHTML={{ __html: `${localStorage.getItem(KEYS.ANNOUNCEMENT_MESSAGE)}` }} />
        <div className="buttonAnnouncementModal">
          <Button
            className="btn-dark"
            onClick={() => this.props.dispatch(
              setModal({ isShow: false }))}
          >
            {t('modal.confirm.continue')}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation('common')(AnnouncementModal));