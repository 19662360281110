import { loading } from './commonReducers';
import footer from './commonReducers/footer';
import data from './commonReducers/data';
import modal from './commonReducers/modal';
import toast from '../ShareStore/reducers/toast';

const reducers = {
  loading,
  footer,
  data,
  modal,
  toast
};

export default reducers;
