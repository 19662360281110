import { SHOW_UNSAVEDCHANGEMODAL } from '../types';
import { SetUnSavedChangeModalAction } from '../interface';

const initialEditableModal = {
  isShow: false,
  callback: () => {}
};

export default function unSavedChangeModal(state = initialEditableModal, action: SetUnSavedChangeModalAction) {
  let newState = Object.assign(state);
  if (action.type === SHOW_UNSAVEDCHANGEMODAL) {
    newState = action.unSavedChangeModal;
  }
  return newState;
}
