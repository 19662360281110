import { CLAIM_PORTAL, Service } from '../services';
import endpoints from './endpoint';
import {
  clearToken,
  setLoading,
  setToken,
} from '../../store/ClaimPortal/commonActions';
import ClaimPortalStore from '../../store/ClaimPortal/store';
import KEYS from '../key';
import setToast from '../../store/ShareStore/actions/toast';
import i18next from '../../translation';
import {
  CasePortalData,
  ChangePasswordData,
  ClaimCase,
  ClaimPendingAssessmentData,
  DetailAssessmentData,
  DetailCaseAssessmentData,
  FilterAssessmentType,
  LetterType,
  mailToData,
  PartialReasons,
  UpdatePendingClaimData,
  ConfirmDecisionFWD,
} from '../interface';
import utils from '../utils';

i18next.setDefaultNamespace('common');

type packageAuth = {
  accessToken: string;
  expiresIn: number;
};

const ClaimPortalService = {
  login: async (email: string, password: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(CLAIM_PORTAL).post(endpoints.LOGIN, {
      email,
      password,
    });
    ClaimPortalStore.dispatch(setLoading(false));
    const packageData: packageAuth = response.data && response.data.data;
    if (packageData) {
      ClaimPortalStore.dispatch(
        setToken(packageData.accessToken, packageData.expiresIn)
      );
      return packageData.accessToken;
    }
    return false;
  },
  me: async () => {
    const { token } = ClaimPortalStore.getState().auth;
    if (token) {
      const response = await Service(
        CLAIM_PORTAL,
        ClaimPortalStore.getState().auth.token
      ).get(endpoints.GET_ME);
      if (response.error) {
        const errCode =
          response.error.data &&
          response.error.data.Errors &&
          response.error.data.Errors[0] &&
          response.error.data.Errors[0].Code;
        if (
          (errCode && errCode === KEYS.ERROR.TOKEN_ERROR) ||
          (response.error && response.error.status)
        ) {
          ClaimPortalStore.dispatch(clearToken());
          ClaimPortalStore.dispatch(
            setToast({
              isShow: true,
              text: i18next.t('errors.EXPIRED_TOKEN_ERROR'),
            })
          );
        } else {
          ClaimPortalStore.dispatch(
            setToast({
              isShow: true,
              text: i18next.t('errors.SERVER_ERROR'),
            })
          );
          ClaimPortalStore.dispatch(setLoading(false));
        }
        return false;
      }
      return (response && response.data && response.data.data) || null;
    }
    return false;
  },
  changePassword: async (changePasswordData: ChangePasswordData) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.CHANGE_PASSWORD, changePasswordData);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getClaimList: async (
    isAll: boolean = false,
    page: number,
    search: string,
    filter?: FilterAssessmentType,
    sort?: string,
    isInProgress?: boolean,
    isDeleted?: boolean,
    filterType?: string
  ) => {
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(
      endpoints.GET_CLAIM_LIST(
        page,
        search,
        filter,
        sort,
        isInProgress,
        isDeleted,
        filterType,
        isAll
      )
    );
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getMetadata: async (
    type: string,
    search?: string,
    excludedData?: string,
    limit: number = 10,
    needToken?: boolean
  ) => {
    const response = await Service(
      CLAIM_PORTAL,
      (needToken && ClaimPortalStore.getState().auth.token) || undefined
    ).get(endpoints.GET_METADATA(type, search, excludedData, limit));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getMedicalProvider: async (search?: string, excludedData?: string) => {
    const response = await Service(CLAIM_PORTAL).get(
      endpoints.GET_MEDICAL_PROVIDER(search, excludedData)
    );
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getPaymentClaim: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.PAYMENT(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },

  getOriginalClaim: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.ORIGINAL_CLAIM(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },

  getDocumentsClaim: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.DOCUMENT_CLAIM(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return null;
    }
    return (response && response.data && response.data.data) || null;
  },

  uploadDocumentPortal: async (
    claimId: string,
    file: FormData = new FormData(),
    callback?: Function
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).upload(endpoints.SUBMIT_DOC_PORTAL(claimId), file, callback);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error;
    }
    return (response && response.data && response.data.data) || null;
  },

  getAssessmentClaim: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_ASSESSMENT_CLAIM(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },

  getClaimCase: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_CLAIM_CASE(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getHistoryClaimCase: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_CLAIM_CASE_HISTORY(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getPoliciesClaimCase: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_CLAIM_CASE_POLICY(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getAssessmentClaimCases: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_ASSESSMENT_CASE(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getPresentCases: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_PRESENT_CASES(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  postPresentCases: async (
    id: string,
    presentCases: {
      claimCases?: ClaimCase[];
      content: string;
      subject: string;
      to: mailToData[];
    }
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.GET_PRESENT_CASES(id), presentCases);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getAssessmentSignOff: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_ASSESSMENT_SIGNOFF_INFO(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getClaimLetters: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.CLAIM_LETTERS(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  notifyHolderLetters: async (
    id: string,
    files: FormData = new FormData(),
    selectedFiles: string[] = []
  ) => {
    files.append('selectedFiles', utils.formatArrayToString(selectedFiles));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.NOTIFY_CLAIM_LETTER(id), files);
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getClaimLetterById: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_CLAIM_LETTER_BY_ID(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  uploadLetter: async (id: string, file: FormData = new FormData()) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).uploadPut(endpoints.UPLOAD_LETTER(id), file, () => {});
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  uploadDocsFwd: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.UPLOAD_DOCS_FWD(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateAssessmentClaimCases: async (
    claimId: string,
    caseId: string,
    caseData: CasePortalData
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.UPDATE_ASSESSMENT_CASE(claimId, caseId), caseData);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateClaimCasePartials: async (
    caseId: string,
    partialReasons: PartialReasons[]
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.UPDATE_CLAIM_CASE_PARTIALS(caseId), partialReasons);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  postClaimCaseLetters: async (caseId: string, letterData: LetterType) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.POST_PENDING_LETTER(caseId), letterData);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateDetailAssessmentClaim: async (
    id: string,
    detailAssessment: DetailAssessmentData
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).put(endpoints.UPDATE_DETAIL_ASSESSMENT(id), detailAssessment);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  adjustAssessment: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.ADJUST_ASSESSMENT(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  decisionAssessment: async (
    id: string,
    confirmDecisionFWD?: ConfirmDecisionFWD
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.DECISION_ASSESSMENT(id), confirmDecisionFWD);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  deleteClaim: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.DELETE_CLAIM(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateDetailCase: async (
    claimCaseId: string,
    polComId: string,
    value: DetailCaseAssessmentData
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.UPDATE_DETAIL_CASE(claimCaseId, polComId), value);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateJetStatus: async (claimId: string, jetStatus: boolean) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.UPDATE_ASSESSMENT_JET_STATUS_CLAIM(claimId), {
      IsNoneJet: jetStatus,
    });
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  checkPaymentClaimCase: async (id: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.CHECK_PAYMENT_CLAIM_CASE(id));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  createClaimPending: async (
    id: string,
    pendingData: Array<ClaimPendingAssessmentData>
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.GET_CLAIM_PENDING(id), pendingData);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateClaimPending: async (
    id: string,
    updatePendingClaimData: UpdatePendingClaimData
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).put(endpoints.UPDATE_CLAIM_PENDING(id), updatePendingClaimData);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getDetailDocument: async (claimId: string, documentId: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.DOC_DETAIL(claimId, documentId));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  putBankBranchPayment: async (
    claimId: string,
    BankBranch: { BankBranch: string }
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).put(endpoints.PUT_BANK_BRANCH(claimId), BankBranch);
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getCountNotification: async () => {
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_COUNT);
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getCountTAT: async () => {
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_TAT_COUNT);
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getOCR: async (claimNumber: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_OCR_INFORMATION(claimNumber));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  sendSignOff: async (id: string, signOffNote: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.SEND_SIGN_OFF(id), { signOffNote });
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  checkFraudClaim: async (claimId: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.CHECK_FRAUD_CLAIM(claimId));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  checkFraudPaper: async (documentId: string) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.CHECK_FRAUD_PAPER(documentId));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  updateChallengeAbuse: async (claimId: string, challengeAbuse: boolean) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).post(endpoints.UPDATE_ASSESSMENT_CHALLENGE_ABUSE_CLAIM(claimId), {
      IsChallengeAbuse: challengeAbuse,
    });
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getPreSignedUrl: async (
    fileName: string,
    bucketName: string,
    expiredMinute: number
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(endpoints.GET_PRESIGNED_URL(fileName, bucketName, expiredMinute));
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
  getObjectPreSignedUrl: async (
    fileName: string,
    bucketName: string,
    expiredMinute: number
  ) => {
    ClaimPortalStore.dispatch(setLoading(true));
    const response = await Service(
      CLAIM_PORTAL,
      ClaimPortalStore.getState().auth.token
    ).get(
      endpoints.GET_OBJECT_PRESIGNED_URL(fileName, bucketName, expiredMinute)
    );
    ClaimPortalStore.dispatch(setLoading(false));
    if (response.error) {
      return response.error.data;
    }
    return (response && response.data && response.data.data) || null;
  },
};
export default ClaimPortalService;
