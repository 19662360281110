import { ElementType } from 'react';
import { ROUTES } from './pathSetting';
import DashboardScreen from '../../app/screens/ClaimPortal/DashboardScreen';
import OriginalAssessmentScreen from '../../app/screens/ClaimPortal/OriginalAssessmentScreen';
import RequestAssessmentScreen from '../../app/screens/ClaimPortal/RequestAssessmentScreen';
import PaymentAssessmentScreen from '../../app/screens/ClaimPortal/PaymentAssessmentScreen';
import AssessmentScreen from '../../app/screens/ClaimPortal/AssessmentScreen';
import NotFoundScreen from '../../app/screens/ClaimPortal/Notfound';
import LoginScreen from '../../app/screens/ClaimPortal/LoginScreen';
import LetterListScreen from '../../app/screens/ClaimPortal/LetterListScreen';
import RequestCaseAssessmentScreen from '../../app/screens/ClaimPortal/ClaimCaseInfoScreen';
import SettingPages from '../../app/screens/ClaimPortal/SettingPage';
import PresentCaseScreen from '../../app/screens/ClaimPortal/PresentCaseScreen';


export type RouteType = {
  name: string;
  title: string;
  path: string;
  component?: ElementType;
};

export type AuthType = {
  path: string;
  component?: ElementType;
};

export const routesAuthentication: Array<AuthType> = [
  {
    path: ROUTES.login,
    component: LoginScreen,
  },
];

export const routesPortal: Array<RouteType> = [
  {
    name: 'Claim gốc',
    title: 'Thông tin',
    path: ROUTES.originalAssessment,
    component: OriginalAssessmentScreen,
  },
  {
    name: 'Trình case',
    title: 'Trình case',
    path: ROUTES.notificationAssessment,
    component: PresentCaseScreen,
  },
  {
    name: 'Danh sách letter',
    title: 'Letters',
    path: ROUTES.letterListAssessment,
    component: LetterListScreen,
  },
  {
    name: 'Thẩm định Case',
    title: 'Thông tin',
    path: ROUTES.requestCaseAssessment,
    component: RequestCaseAssessmentScreen,
  },
  {
    name: 'Thẩm định',
    title: 'Thông tin',
    path: ROUTES.requestAssessment,
    component: RequestAssessmentScreen,
  },
  {
    name: 'Thanh toán',
    title: 'Thông tin',
    path: ROUTES.paymentAssessment,
    component: PaymentAssessmentScreen,
  },
  {
    name: 'Thẩm định bảo hiểm',
    title: 'Thông tin chi tiết',
    path: ROUTES.assessmentDetail,
  },
  {
    name: 'Thẩm định bảo hiểm',
    title: 'Danh sách yêu cầu bồi thường',
    path: ROUTES.assessment,
    component: AssessmentScreen,
  },
  {
    name: 'Thẩm định bảo hiểm',
    title: 'Danh sách yêu cầu bồi thường',
    path: ROUTES.assessmentList,
    component: AssessmentScreen,
  },
  {
    name: 'Tổng quát',
    title: 'Thông tin tổng quát',
    path: ROUTES.dashboard,
    component: DashboardScreen,
  },
  {
    name: 'Profile',
    title: 'Cài đặt',
    path: ROUTES.setting,
    component: SettingPages,
  },
  {
    name: '',
    title: '404 Page not found 😅',
    path: ROUTES.notfound,
    component: NotFoundScreen,
  },
];
