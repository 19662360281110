import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnimatedSwitch } from 'react-router-transition';
import { Modal } from 'papaya-ui';
import _ from 'lodash';
import u from 'underscore';
import { routesHistory, routesSubmit } from '../config/ClaimWebsite/routes';
import { Footer, Header, SubHeader } from './components/ClaimWebsite';
import { FooterObjectType, ModalState, ToastType } from '../config/interface';
import loadingImg from '../assets/images/loading.svg';
import pathSetting, { ROUTES } from '../config/ClaimWebsite/pathSetting';
import setFooter from '../store/ClaimWebsite/commonActions/footer';
import ClaimService from '../config/ClaimWebsite/modelService';
import KEYS from '../config/key';
import {
  clearData,
  setData,
  setLoading,
} from '../store/ClaimWebsite/commonActions';
import utils from '../config/utils';

interface IProps extends RouteComponentProps<string> {
  footer: FooterObjectType;
  loading: boolean;
  modal: ModalState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  toast: ToastType;
  dispatch: Function;
}

interface IState {
  path: string;
  current?: number;
  refreshScreen: boolean;
}

const mapStateToProp = (state) => ({
  footer: state.footer,
  loading: state.loading,
  modal: state.modal,
  data: state.data,
  toast: state.toast,
});

class ClaimWebsiteApp extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      path: this.props.history.location.pathname || '/',
      refreshScreen: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.location.pathname !== prevState.path) {
      return { path: nextProps.history.location.pathname };
    }
    return null;
  }

  componentDidMount(): void {
    const { path }: Readonly<IState> = this.state;
    this.props.dispatch(setLoading(true));
    setTimeout(() => {
      this.props.dispatch(setLoading(false));
    }, 200);
    this.showFooterAndSubHeader(path);
    this.getInitialState().then();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.path !== this.state.path) {
      const { path }: Readonly<IState> = this.state;
      this.showFooterAndSubHeader(path);
    }
  }

  showFooterAndSubHeader = (path) => {
    if (pathSetting()[path]) {
      if (pathSetting()[path].footer) {
        this.props.dispatch(setFooter(pathSetting()[path].footer));
      } else {
        this.props.dispatch(setFooter({ visible: false }));
      }
      if (pathSetting()[path].index) {
        this.setState({
          current: pathSetting()[path].index,
        });
      } else {
        this.setState({
          current: undefined,
        });
      }
    } else {
      this.props.history.push(ROUTES.home);
      this.setState({
        current: undefined,
      });
    }
  };

  getInitialState = async () => {
    const { mainData } = this.props.data;
    if (mainData && mainData[KEYS.FIELD.CLAIM_ID]) {
      const response = await ClaimService.getInitialState(
        mainData[KEYS.FIELD.CLAIM_ID]
      );
      if (response && response.error) {
        this.props.dispatch(clearData());
        this.props.history.push(ROUTES.policy);
      } else {
        await this.syncDataToRedux(response);
      }
    } else {
      this.props.dispatch(clearData());
    }
  };

  syncDataToRedux = async (state) => {
    let detailScreenData;
    const list = sessionStorage.getItem('list');
    const newList = (list && JSON.parse(list)) || {};
    if (state) {
      detailScreenData = {};
      detailScreenData[KEYS.FIELD.IS_ACCIDENT] =
        state[KEYS.FIELD.IS_ACCIDENT] || false;
      if (state[KEYS.FIELD.RIDERS] && state[KEYS.FIELD.RIDERS].length) {
        detailScreenData.riders = [];
        state[KEYS.FIELD.RIDERS].forEach((riderObj) => {
          if (
            riderObj.riderDetailCode === parseInt(KEYS.DAYS_IN_HOSPITAL, 5) ||
            (riderObj.riderDetailCode === parseInt(KEYS.DAYS_IN_ICU, 5) && riderObj.isSelected)
          ) {
            detailScreenData[
              riderObj.riderDetailCode
            ] = utils.formatDateRangeStringToDateRange(riderObj.value || '');
          }
          if(riderObj.isSelected){
            detailScreenData.riders.push(riderObj.riderDetailCode.toString());
          }
        });
      }
      if (state[KEYS.FIELD.DISEASES] && state[KEYS.FIELD.DISEASES].length) {
        detailScreenData[KEYS.FIELD.DISEASES] = state[KEYS.FIELD.DISEASES].map(
          (diseases) => {
            return diseases.value;
          }
        );
        newList[KEYS.LIST.DISEASES_VALUE] = state[KEYS.FIELD.DISEASES];
      }

      if (state[KEYS.FIELD.MEDICAL_PROVIDER]) {
        detailScreenData[KEYS.FIELD.MEDICAL_PROVIDER] =
          state[KEYS.FIELD.MEDICAL_PROVIDER].value;
        detailScreenData[KEYS.FIELD.PROVINCE] =
          state[KEYS.FIELD.MEDICAL_PROVIDER].extra;
      }

      const docs = state[KEYS.FIELD.DOCUMENTS];
      const paperworkScreen = {};
      if (docs) {
        docs.forEach((doc) => {
          if(!paperworkScreen[KEYS.IMAGES[doc.docType]]) {
            paperworkScreen[KEYS.IMAGES[doc.docType]] = [];
          }
          paperworkScreen[KEYS.IMAGES[doc.docType]].push(u.omit(doc, 'docType'));
        });
      }

      const paymentScreen = {};
      if (state[KEYS.FIELD.PAYMENT]) {
        paymentScreen[KEYS.FIELD.CHILD] = {};
        if (
          state[KEYS.FIELD.PAYMENT].type.toString() ===
          KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE
        ) {
          paymentScreen[KEYS.FIELD.CHILD][
            KEYS.PAYMENT_TYPE.TRANSFER_MONEY.KEY
          ] = {
            bankCode: state[KEYS.FIELD.PAYMENT].bankCode,
            bankBranch: state[KEYS.FIELD.PAYMENT].bankBranch,
            ownerName: state[KEYS.FIELD.PAYMENT].ownerName,
            ownerAccount: state[KEYS.FIELD.PAYMENT].ownerAccount,
            bankCity: state[KEYS.FIELD.PAYMENT].bankCity
          };
        }
        if (
          state[KEYS.FIELD.PAYMENT].type.toString() ===
          KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE
        ) {
          paymentScreen[KEYS.FIELD.CHILD][KEYS.PAYMENT_TYPE.BANK_MONEY.KEY] = {
            bankCode: state[KEYS.FIELD.PAYMENT].bankCode,
            bankBranch: state[KEYS.FIELD.PAYMENT].bankBranch,
            bankCity: state[KEYS.FIELD.PAYMENT].bankCity,
            receiverName: state[KEYS.FIELD.PAYMENT].receiverName,
            receiverId: state[KEYS.FIELD.PAYMENT].receiverId,
            receiverIdLocation: state[KEYS.FIELD.PAYMENT].receiverIdLocation,
            receiverIdDate: new Date(
              utils.formatDateString(state[KEYS.FIELD.PAYMENT].receiverIdDate)
            ),
          };
        }
        if (
          state[KEYS.FIELD.PAYMENT].type.toString() ===
          KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE
        ) {
          paymentScreen[KEYS.FIELD.CHILD][
            KEYS.PAYMENT_TYPE.FORWARD_MONEY.KEY
          ] = {
            policyNumber: state[KEYS.FIELD.PAYMENT].policyNumber,
          };
        }
        const input = _.clone(state[KEYS.FIELD.PAYMENT]);
        delete input.amount;
        delete input.type;
        paymentScreen[KEYS.FIELD.DATA] = {
          PaymentType: state[KEYS.FIELD.PAYMENT].type.toString(),
          ValidType: {
            [KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE]:
              state[KEYS.FIELD.PAYMENT].type.toString() ===
              KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE,
            [KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE]:
              state[KEYS.FIELD.PAYMENT].type.toString() ===
              KEYS.PAYMENT_TYPE.BANK_MONEY.VALUE,
            [KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE]:
              state[KEYS.FIELD.PAYMENT].type.toString() ===
              KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE,
          },
          input,
        };
      }
      const { data } = this.props;
      const newData = data;
      if (detailScreenData) {
        newData.postBack = {
          detailScreen: {
            data: detailScreenData,
            list: newList || {},
          },
          paperworkScreen,
          paymentScreen,
        };
        this.props.dispatch(setData(newData));
        const { refreshScreen }: Readonly<IState> = this.state;
        this.setState({
          refreshScreen: !refreshScreen,
        });
      }
    }
  };

  render() {
    const { loading, modal, toast } = this.props;
    const { current }: Readonly<IState> = this.state;
    return (
      <div className="claimWebsiteApp">
        {/* modal */}
        <Modal
          name="globalModal"
          className="globalModal"
          value={modal.isShow}
          closeButton={modal.closeButton}
          onClose={modal.onClose}
        >
          {modal.children}
        </Modal>

        {/* toast */}
        <div className={`globalToast ${toast.isShow && 'show'} ${toast.type}`}>
          <div className="line" />
          <span>{toast.text}</span>
        </div>

        {(loading && (
          <div
            className="globalLoading"
            style={{ backgroundImage: `url(${loadingImg})` }}
          />
        )) ||
          ''}
        <Route path="/" render={(props) => <Header {...props} />} />
        {(current && <SubHeader current={current} />) || (
          <div style={{ height: '3.5vh', display: 'none' }} />
        )}
        <AnimatedSwitch
          atLeave={
            {
              // blank
            }
          }
          atEnter={{ opacity: 0, marginLeft: -150 }}
          atActive={{ opacity: 1, marginLeft: 0 }}
        >
          {routesHistory.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              render={(props) => (
                <div className="historyScreenContainer">
                  <route.component {...props} />
                </div>
              )}
            />
          ))}
          {routesSubmit.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              render={(props) => (
                <div
                  className={`screenContainer ${current && 'progressScreen'}`}
                >
                  <route.component
                    {...props}
                    refresh={this.state.refreshScreen}
                  />
                </div>
              )}
            />
          ))}
        </AnimatedSwitch>
        <Route path="/" render={(props) => <Footer {...props} />} />
      </div>
    );
  }
}

export default connect(mapStateToProp)(ClaimWebsiteApp);
