/**
 ** @class TrackingProductScreen
 */

import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import './style.css';
import { ROUTES } from '../../../../config/ClaimWebsite/pathSetting';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

class TrackingProductScreen extends React.PureComponent<IProps> {
  render = () => {
    const { t } = this.props;
    return (
      <div className="ProductContainer">
        <div className="detail">
          <p>
            {t('emptyTrackingProduct.message')}
          </p>
          <p>
            Vui lòng&nbsp;
            <span
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              className="redirect"
              onClick={() => {
                this.props.history.push(ROUTES.policy);
              }}
            >
              NHẤP VÀO ĐÂY&nbsp;
            </span>
            để tạo yêu cầu.
          </p>
        </div>
      </div>
    );
  };
}

export default (withTranslation('common')(TrackingProductScreen));
