import { TOAST } from '../../ClaimWebsite/types';
import { ToastAction } from '../../../config/interface';
import { ClaimPortalStore, ClaimWebsiteStore } from '../../index';
import setToast from '../actions/toast';

let timeout;
export default function toast(
  state = { isShow: false, text: '' },
  action: ToastAction
) {
  let newState = state;
  if (action.type === TOAST) {
    newState = action.toast;
    clearTimeout(timeout);
    if (action.toast.isShow) {
      timeout = setTimeout(() => {
        if(action.toast.preventClose) {
          // nothing
        } else {
          ClaimWebsiteStore.dispatch(
            setToast({
              isShow: false,
              type: action.toast.type,
              text: action.toast.text,
            })
          );
          ClaimPortalStore.dispatch(
            setToast({
              isShow: false,
              type: action.toast.type,
              text: action.toast.text,
            })
          );
        }
      }, 2000);
    }
  }
  return newState;
}
