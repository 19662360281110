/**
 ** @class PolicyScreen
 */

import React from 'react';
import { Calendar, Selection, TextField } from 'papaya-ui';
import './style.css';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import ClaimService from '../../../../config/ClaimWebsite/modelService';
import utils from '../../../../config/utils';
import {
  clearData,
  setData,
} from '../../../../store/ClaimWebsite/commonActions';
import pathSetting, {
  ROUTES,
} from '../../../../config/ClaimWebsite/pathSetting';
import setFooter from '../../../../store/ClaimWebsite/commonActions/footer';
import { iconShowNationalIdModal } from '../../../../config/modal';
import KEYS from '../../../../config/key';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';
import setToast from '../../../../store/ShareStore/actions/toast';

interface IProps extends RouteComponentProps<string>, WithTranslation {
  dispatch: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface IState {
  data: inputData;
  error: errorField;
  lifeAssureds: Array<{ value: string; name: string }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  holder?: holderObject;
  showPolicyNumber: boolean;
}

type holderObject = {
  id: string;
  firstname: string;
  lastname: string;
  name: string;
  dob: string;
  phone: string;
  policyOwnerNationalId: string;
  policyNumber?: string;
};

type errorField = {
  nationalId?: string;
  birthday?: string;
  policyId?: string;
  insured?: string;
};

type inputData = {
  captcha: string;
  policyOwnerNationalId?: string;
  policyId?: string;
  dob?: Date;
  insured?: string;
};

const mapStateToProp = (state) => ({
  data: state.data,
});

class PolicyScreen extends React.Component<IProps, IState> {
  timeOut;

  constructor(props) {
    super(props);
    this.state = {
      data: {
        captcha: '',
      },
      lifeAssureds: [],
      error: {},
      showPolicyNumber: false,
    };
    props.dispatch(clearData());
  }

  handleChange = (name, value) => {
    const { data }: Readonly<IState> = this.state;
    const newData = (data && _.clone(data)) || {};
    newData[name] = value;
    clearTimeout(this.timeOut);
    if (name !== KEYS.FIELD.INSURED) {
      newData[KEYS.FIELD.INSURED] = '';
      this.setState({
        lifeAssureds: [],
      });
      this.hideFooter();
    }
    if (
      (name === KEYS.FIELD.POLICY_NUMBER ||
        name === KEYS.FIELD.POLICY_OWNER_ID) &&
      value &&
      !utils.validate(KEYS.VALIDATE.NONE_SPECIAL_CHAR, value)
    ) {
      // wrong validation
    } else {
      this.setState({ data: newData }, async () => {
        // validate frontend
        const flag = this.handleValidate(name, newData[name]) || false;
        if (!flag) {
          await this.handleLogicAll();
        }
      });
    }
  };

  handleValidate = (name, value) => {
    const { t } = this.props;
    const { error }: Readonly<IState> = this.state;
    const newError = error;
    newError[name] = '';
    switch (name) {
      case KEYS.FIELD.POLICY_OWNER_ID: {
        if ((value && value.length < 8) || (value && value.length > 12)) {
          newError[KEYS.FIELD.POLICY_OWNER_ID] = t('errors.INFO_INCORRECT');
        } else {
          newError[KEYS.FIELD.POLICY_OWNER_ID] = '';
        }
        break;
      }
      case KEYS.FIELD.POLICY_NUMBER: {
        // clearing the error from po
        newError[KEYS.FIELD.POLICY_OWNER_ID] = '';
        newError[KEYS.FIELD.DOB] = '';
        if (value && value.length !== 8) {
          newError[KEYS.FIELD.POLICY_NUMBER] = t('errors.INFO_INCORRECT');
        } else {
          newError[KEYS.FIELD.POLICY_NUMBER] = '';
        }
        break;
      }
      default:
        break;
    }

    this.setState({ error: newError });
    return !!newError[name];
  };

  handleLogicAll = async () => {
    const { error, data }: Readonly<IState> = this.state;
    const newError = error;
    if (
      data[KEYS.FIELD.INSURED] &&
      data[KEYS.FIELD.POLICY_OWNER_ID] &&
      data[KEYS.FIELD.DOB] &&
      data[KEYS.FIELD.CAPTCHA]
    ) {
      this.readyToNext();
    } else if (
      (data[KEYS.FIELD.POLICY_OWNER_ID] && data[KEYS.FIELD.DOB]) ||
      (data[KEYS.FIELD.POLICY_OWNER_ID] &&
        data[KEYS.FIELD.DOB] &&
        data[KEYS.FIELD.POLICY_NUMBER])
    ) {
      this.hideFooter();
      this.timeOut = await setTimeout(async () => {
        if (
          (data[KEYS.FIELD.POLICY_OWNER_ID] &&
            data[KEYS.FIELD.DOB] &&
            (data[KEYS.FIELD.POLICY_NUMBER] === '' ||
              !data[KEYS.FIELD.POLICY_NUMBER])) ||
          (data[KEYS.FIELD.POLICY_OWNER_ID] &&
            data[KEYS.FIELD.DOB] &&
            data[KEYS.FIELD.POLICY_NUMBER] &&
            newError[KEYS.FIELD.POLICY_NUMBER] === '')
        ) {
          await this.getInsuredList(data, newError);
        }
        this.setState({ error: newError });
      }, 500);
    } else if (data[KEYS.FIELD.POLICY_OWNER_ID]) {
      this.hideFooter();
      this.timeOut = await setTimeout(async () => {
        this.setState({ error: newError });
      }, 500);
    }
  };

  getInsuredList = async (data, err) => {
    const { t } = this.props;
    const newError = err;
    this.clearAllError(newError);
    this.props.dispatch(setLoading(true));
    const response = await ClaimService.getInsured(
      data[KEYS.FIELD.POLICY_OWNER_ID],
      utils.formatDateToStringDate(data[KEYS.FIELD.DOB]),
      data[KEYS.FIELD.POLICY_NUMBER]
    );
    if (response) {
      if (response.status) {
        if (response.status === 404) {
          if (data[KEYS.FIELD.POLICY_NUMBER]) {
            newError[KEYS.FIELD.POLICY_NUMBER] = t('errors.INFO_INCORRECT');
          } else {
            newError[KEYS.FIELD.POLICY_OWNER_ID] = t('errors.INFO_INCORRECT');
            newError[KEYS.FIELD.DOB] = t('errors.INFO_INCORRECT');
          }
        }
        if (response.status === 400) {
          if (response.data.Errors) {
            if (response.data.Errors[0].Code === KEYS.ERROR.NO_POLICY) {
              this.props.history.push(ROUTES.product);
            }
            if (
              response.data.Errors[0].Code === KEYS.ERROR.DUPLICATE_NATIONAL_ID
            ) {
              // newError[KEYS.FIELD.POLICY_OWNER_ID] =
              //   response.data.Errors[0].Message;
              this.setState({ showPolicyNumber: true });
            } else {
              newError[KEYS.FIELD.POLICY_OWNER_ID] =
                response.data.Errors[0].Message;
            }
          }
          if (response.data.errors) {
            this.clearAllError(newError);
            const validateErr = _.zipObject(
              _.keys(response.data.errors),
              _.values(response.data.errors)
            );
            Object.keys(validateErr).forEach((errKey) => {
              // eslint-disable-next-line prefer-destructuring
              newError[errKey] = validateErr[errKey][0];
            });
          }
        }
      } else {
        this.setState({
          lifeAssureds: response[KEYS.FIELD.INSURED],
        });
      }
    }
    this.props.dispatch(setLoading(false));
  };

  clearAllError = (err: { [x: string]: string }) => {
    const newErr = err;
    newErr[KEYS.FIELD.POLICY_OWNER_ID] = '';
    newErr[KEYS.FIELD.DOB] = '';
    newErr[KEYS.FIELD.POLICY_NUMBER] = '';
  };

  doSubmitClaim = async () => {
    // submit claim
    const { data }: Readonly<IState> = this.state;
    const newData = this.props.data;
    this.props.dispatch(setLoading(true));
    const response = await ClaimService.submitClaim({
      lifeAssuredId: data[KEYS.FIELD.INSURED],
      policyOwnerDob: utils.formatDateToStringDate(data[KEYS.FIELD.DOB]),
      policyNumber: data[KEYS.FIELD.POLICY_NUMBER],
      policyOwnerNationalId: data[KEYS.FIELD.POLICY_OWNER_ID],
      captcha: data[KEYS.FIELD.CAPTCHA],
      policyAndPlanList: [],
    }).then();
    this.props.dispatch(setLoading(false));
    if (response && response[KEYS.FIELD.CLAIM_ID]) {
      if (!newData.mainData) newData.mainData = {};
      newData.mainData[KEYS.FIELD.INSURED] =
        this.state.data[KEYS.FIELD.INSURED];
      newData.mainData[KEYS.FIELD.CLAIM_ID] = response[KEYS.FIELD.CLAIM_ID];
      this.props.dispatch(setData(newData));
      return true;
    }
    if (response && response.status) {
      this.props.dispatch(
        setToast({
          isShow: true,
          type: 'warning',
          text:
            response.data &&
            response.data.Errors &&
            response.data.Errors[0].Message,
        })
      );
    }
    this.props.history.push(ROUTES.policy);
    return false;
  };

  hideFooter = () => {
    const { footer } = pathSetting()[ROUTES.policy];
    footer.nextState.disabled = true;
    this.props.dispatch(setFooter(footer));
  };

  readyToNext = async () => {
    const { footer } = pathSetting()[ROUTES.policy];
    // remake footer
    footer.nextState.disabled = false;
    footer.nextState.callback = async () => this.doSubmitClaim();
    this.props.dispatch(setFooter(footer));
  };

  setCaptcha = (token) => {
    const { data }: Readonly<IState> = this.state;
    data.captcha = token;
    this.setState(
      {
        data,
      },
      () => this.handleLogicAll()
    );
  };

  render = () => {
    const { t } = this.props;
    const { data, error, lifeAssureds, showPolicyNumber }: Readonly<IState> =
      this.state;
    return (
      <div className="contractContainer">
        <div className="contractHeader">
          <p>{t('policy.pleaseInput')}</p>
        </div>
        <div className="contractContent">
          <TextField
            className="fieldStyle policyId"
            name={KEYS.FIELD.POLICY_OWNER_ID}
            label={t('policy.label.nationalId')}
            placeHolder={t('policy.placeHolder.nationalId')}
            type="text"
            errorMsg={error[KEYS.FIELD.POLICY_OWNER_ID] || ''}
            errorPosition="bottom"
            value={data[KEYS.FIELD.POLICY_OWNER_ID] || ''}
            endButton={iconShowNationalIdModal}
            onChange={(name, value) => this.handleChange(name, value)}
          />
          <Calendar
            className="fieldStyle dob"
            name={KEYS.FIELD.DOB}
            rightShow={window.screen.width > 1460}
            bottomShow={window.screen.width <= 1460}
            pastBack
            label={t('policy.label.dob')}
            placeHolder={t('policy.placeHolder.dob')}
            errorMsg={error[KEYS.FIELD.DOB]}
            errorPosition="bottom"
            onChange={(name, value) => this.handleChange(name, value)}
            value={data[KEYS.FIELD.DOB]}
          />
          {(showPolicyNumber && (
            <TextField
              className="fieldStyle policyNumberContract"
              name={KEYS.FIELD.POLICY_NUMBER}
              placeHolder={t('policy.placeHolder.policyNumber')}
              label={t('policy.label.policyNumber')}
              type="text"
              max={8}
              errorMsg={error[KEYS.FIELD.POLICY_NUMBER] || ''}
              errorPosition="bottom"
              value={data[KEYS.FIELD.POLICY_NUMBER] || ''}
              onChange={(name, value) => this.handleChange(name, value)}
            />
          )) ||
            ''}
          <Selection
            className="fieldStyle insured marginBottom20"
            name={KEYS.FIELD.INSURED}
            label={t('policy.label.insured')}
            placeHolder={t('policy.placeHolder.insured')}
            options={lifeAssureds}
            value={data[KEYS.FIELD.INSURED]}
            onChange={(name, value) => this.handleChange(name, value)}
            errorPosition="bottom"
          />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPCHA_SITE_KEY}
            hl="vi"
            onChange={(token) => this.setCaptcha(token)}
          />
        </div>
      </div>
    );
  };
}

export default connect(mapStateToProp)(withTranslation('common')(PolicyScreen));
