import React from 'react';
import './styles.css';
import { Autocomplete, TextField } from 'papaya-ui';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';
import ClaimService from '../../../../../config/ClaimWebsite/modelService';
import KEYS from '../../../../../config/key';
import utils from '../../../../../config/utils';

interface IProps extends WithTranslation {
  onChange: Function;
  onValid?: Function;
  onError?: Function;
  dispatch: Function;
  childValue?: TransferType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  error?: TransferType;
  banksData: Array<{ name: string; value: string }>;
  citiesData: Array<{ name: string; value: string }>;
}

export interface TransferType {
  bankCode?: string;
  bankCity?: string;
  bankBranch?: string;
  ownerName?: string;
  ownerAccount?: string;
  paymentType?: string;
}

interface IState {
  banks: Array<{ name: string; value: string }>;
  cities: Array<{ name: string; value: string }>;
  checkApi: boolean;
  checkOwnerName: boolean;
}

const mapStateToProp = (state) => ({
  data: state.data,
});

class Transfer extends React.Component<IProps, IState> {
  timeOut;

  constructor(props) {
    super(props);
    this.state = {
      banks: [],
      cities: [],
      checkApi: false,
      checkOwnerName: true,
    };
  }

  componentDidMount = (): void => {
    this.getBankList().then();
    this.getCityList().then();
    this.handleLogicAll();
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (prevProps.error !== this.props.error) {
      this.handleLogicAll();
    }

    if (prevProps.childValue !== this.props.childValue) {
      this.handleLogicAll();
    }

    if (prevProps.banksData !== this.props.banksData) {
      this.getBankList();
    }

    if (prevProps.citiesData !== this.props.citiesData) {
      this.getCityList();
    }

    if (prevState.checkOwnerName !== this.state.checkOwnerName) {
      this.handleLogicAll();
    }
  }

  getBankList = async () => {
    const { banksData } = this.props;
    this.setState({
      banks: banksData,
    });
  };

  getCityList = async () => {
    const { citiesData } = this.props;
    this.setState({
      cities: citiesData,
    });
  };

  handleChange = (name, value) => {
    const { onChange, childValue, error, onError, onValid } = this.props;
    const newError = error || {};
    const newData = _.clone(childValue) || {};
    if (name === KEYS.FIELD.OWNER_NAME && onValid) {
      onValid(KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE, false);
      newData[name] = value.toUpperCase();
    } else if (name === KEYS.FIELD.BANK_BRANCH) {
      newData[name] = value.toUpperCase();
    } else {
      newData[name] = value;
    }
    if (!value) {
      delete newData[name];
    }
    if (
      name === KEYS.FIELD.OWNER_ACCOUNT &&
      value &&
      !utils.validate(KEYS.VALIDATE.PHONE, value)
    ) {
      // wrong validate
    } else if (
      name === KEYS.FIELD.OWNER_NAME &&
      value &&
      !utils.validate(KEYS.VALIDATE.NAME, value)
    ) {
      // wrong validate
    } else if (
      name === KEYS.FIELD.BANK_BRANCH &&
      value &&
      !utils.validate(KEYS.VALIDATE.BANK_BRANCH, value)
    ) {
      // wrong validate
    } else {
      if (onChange) {
        onChange('transfer', newData);
      }
      if (onError) {
        onError('transfer', newError);
      }
      this.handleValidate(name, newData[name]);
    }
  };

  handleValidate = async (name, value) => {
    const { error, onError, t } = this.props;
    const newError = error || {};
    if (!value) {
      delete newError[name];
    }
    switch (name) {
      case KEYS.FIELD.BANKS_CODE: {
        if (!value) {
          newError[KEYS.FIELD.BANKS_CODE] = t('errors.INFO_INCORRECT');
        } else {
          delete newError[KEYS.FIELD.BANKS_CODE];
        }
        break;
      }
      case KEYS.FIELD.OWNER_ACCOUNT: {
        if ((value && value.length < 4) || (value && value.length > 20)) {
          newError[KEYS.FIELD.OWNER_ACCOUNT] = t('errors.INFO_INCORRECT');
        } else {
          delete newError[KEYS.FIELD.OWNER_ACCOUNT];
        }
        break;
      }
      case KEYS.FIELD.BANK_BRANCH: {
        if (value && value.length > 50) {
          newError[KEYS.FIELD.BANK_BRANCH] = t('errors.INFO_INCORRECT');
        } else {
          delete newError[KEYS.FIELD.BANK_BRANCH];
        }
        break;
      }
      case KEYS.FIELD.OWNER_NAME: {
        this.setState(
          {
            checkOwnerName: false,
          },
          () => {
            this.handleOwnerName(value);
          }
        );
        break;
      }
    }
    if (onError) {
      onError('transfer', newError);
    }
    return !!newError[name];
  };

  handleOwnerName = async (ownerName?: string) => {
    const { data, error, onError, t } = this.props;
    const newError = error || {};
    clearTimeout(this.timeOut);
    if (ownerName && ownerName.length > 4) {
      this.timeOut = setTimeout(async () => {
        const response = await ClaimService.verifyOwnerName(
          data.mainData && data.mainData[KEYS.FIELD.CLAIM_ID],
          utils.formatVnString(ownerName || '')
        );
        if (response && response.status) {
          if (response.status === 404) {
            newError[KEYS.FIELD.OWNER_NAME] = t('errors.WRONG_OWNER_NAME');
            if (onError) {
              onError('transfer', newError);
            }
          }
        } else {
          delete newError[KEYS.FIELD.OWNER_NAME];
        }
        this.setState(
          {
            checkApi: true,
          },
          async () => {
            await this.handleLogicAll();
          }
        );
      }, 500);
    }
  };

  handleLogicAll = async () => {
    const { childValue, onValid, error } = this.props;
    const { checkApi, checkOwnerName } = this.state;
    if (onValid && childValue && error) {
      delete childValue.paymentType;
      if (
        childValue.ownerName &&
        childValue.ownerName.length >= 4 &&
        checkApi &&
        !error.ownerName
      ) {
        this.setState({
          checkOwnerName: true,
        });
      }

      if (
        Object.keys(childValue).length === 5 &&
        Object.keys(error).length === 0
      ) {
        if (checkOwnerName) {
          onValid(KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE, true);
        }
      } else {
        onValid(KEYS.PAYMENT_TYPE.TRANSFER_MONEY.VALUE, false);
      }
      this.setState({
        checkApi: false,
      });
    }
  };

  render() {
    const { childValue, error, t } = this.props;
    const { banks, cities }: Readonly<IState> = this.state;
    return (
      <div className="transferContainer">
        <div className="firstRowTransfer">
          <Autocomplete
            className="bankPayment"
            name="bankCode"
            label={t('payment.label.bankName')}
            placeHolder={t('payment.placeHolder.bankName')}
            options={banks}
            errorPosition="bottom"
            onChange={(name, value) => this.handleChange(name, value)}
            value={(childValue && childValue.bankCode) || ''}
          />
        </div>

        <div className="secondRowTransfer">
          <Autocomplete
            className="listCity"
            name="bankCity"
            label={t('payment.label.bankCity')}
            placeHolder={t('payment.placeHolder.bankCity')}
            options={cities}
            errorPosition="bottom"
            onChange={(name, value) => this.handleChange(name, value)}
            value={(childValue && childValue.bankCity) || ''}
          />

          <TextField
            className="branchPayment"
            name={KEYS.FIELD.BANK_BRANCH}
            label={t('payment.label.bankBranch')}
            helperText={t('payment.helperText.bankBranch')}
            placeHolder={t('payment.placeHolder.bankBranch')}
            type="text"
            max={50}
            errorPosition="bottom"
            errorMsg={(error && error[KEYS.FIELD.BANK_BRANCH]) || ''}
            onChange={(name, value) => this.handleChange(name, value)}
            value={(childValue && childValue[KEYS.FIELD.BANK_BRANCH]) || ''}
          />
        </div>

        <div className="thirdRowTransfer">
          <TextField
            className="accountNumberPayment"
            name={KEYS.FIELD.OWNER_ACCOUNT}
            label={t('payment.label.accountNumber')}
            placeHolder={t('payment.placeHolder.accountNumber')}
            type="text"
            errorPosition="bottom"
            errorMsg={(error && error[KEYS.FIELD.OWNER_ACCOUNT]) || ''}
            onChange={(name, value) => this.handleChange(name, value)}
            value={(childValue && childValue[KEYS.FIELD.OWNER_ACCOUNT]) || ''}
          />
          <TextField
            className="holderPayment"
            name={KEYS.FIELD.OWNER_NAME}
            label={t('payment.label.ownerName')}
            helperText={t('payment.helperText.ownerName')}
            placeHolder={t('payment.placeHolder.ownerName')}
            type="text"
            errorPosition="bottom"
            errorMsg={(error && error[KEYS.FIELD.OWNER_NAME]) || ''}
            onChange={(name, value) => this.handleChange(name, value)}
            value={(childValue && childValue.ownerName) || ''}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProp)(withTranslation('common')(Transfer));
