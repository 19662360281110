import React from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Button } from 'papaya-ui';
import { clearToken } from '../../../../store/ClaimPortal/commonActions';
import { ROUTES } from '../../../../config/ClaimPortal/pathSetting';
import settingRoutes from './routes';

interface IProps extends RouteComponentProps<string> {
  dispatch: Function;
}

interface IState {
  currentItem?: {
    key: string,
    name: string,
    title: string,
    path: string,
    component: React.ReactNode,
  }
}


class SettingPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentItem: (settingRoutes &&
        settingRoutes.length &&
        settingRoutes[this.getCurrentItem(props)]) || undefined
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.history.location.pathname === ROUTES.setting) {
      this.props.history.push(settingRoutes[0].path);
    }
  }

  getCurrentItem = (props: IProps) => {
    if (props.history.location.pathname !== ROUTES.setting) {
      for (let i = 0; i < settingRoutes.length; i++) {
        if (props.history.location.pathname === settingRoutes[i].path) {
          return i;
        }
      }
    } else {
      this.props.history.push(settingRoutes[0].path);
      return 0;
    }
    return 0;
  };

  doLogout = () => {
    // eslint-disable-next-line no-alert
    const isLogout = window.confirm('Bạn có chắc là muốn đăng xuất ?');
    if (isLogout) {
      this.props.dispatch(clearToken());
      this.props.history.push(ROUTES.login);
    }
  };

  selectItem = (item) => {
    const { currentItem }: Readonly<IState> = this.state;
    if (currentItem && currentItem.key !== item.key) {
      this.props.history.push(item.path);
      this.setState({
        currentItem: item
      });
    }
  };

  render() {
    const { currentItem }: IState = this.state;
    return (
      <div className="settingPageContainer">
        <div className="menuList">
          {(settingRoutes &&
            settingRoutes.length &&
            settingRoutes.map(menuItem => (
              <div
                role="presentation"
                key={menuItem.key}
                className={`${(currentItem && currentItem.key === menuItem.key && 'active') || ''}`}
                onClick={() => this.selectItem(menuItem)}
              >
                {menuItem.name}
              </div>))) || ''}
          <Button primary primaryClassName="logout" onClick={() => this.doLogout()}>Đăng xuất
          </Button>
        </div>
        <div className="childScreen active">
          <div className="titleHeader">
            {currentItem && currentItem.title}
          </div>
          <Switch>
            {(settingRoutes &&
              settingRoutes.length &&
              settingRoutes.map(menuItem => (
                <Route
                  key={menuItem.key}
                  path={menuItem.path}
                  component={menuItem.component}
                />
              ))) || ''}
          </Switch>
        </div>
      </div>
    );
  }
}

export default connect()(SettingPage);
