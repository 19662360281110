import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CalendarRange, Table } from 'papaya-ui';
import './styles.css';
import { connect } from 'react-redux';
import _ from 'lodash';
import ClaimPortalService from '../../../../config/ClaimPortal/modelService';
import { ROUTES } from '../../../../config/ClaimPortal/pathSetting';
import { setLoading } from '../../../../store/ClaimPortal/commonActions';
import utils from '../../../../config/utils';
import { FilterAssessmentType } from '../../../../config/interface';
import KEYS from '../../../../config/key';

interface IProps
  extends RouteComponentProps<{ jetTab: string; status: string }> {
  dispatch: Function;
}

interface IState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Array<IAssessmentData>;
  total?: number;
  page: number;
  filterComps: Array<React.ReactNode>;
  filters: {
    ReceivedAt: {
      from?: Date;
      to?: Date;
    };
    JetType?: string;
  };
  sorts: string;
  isDeleted: boolean;
  isInProgress: boolean;
  filterType: string;
}

interface IAssessmentData {
  assign?: number;
  id?: string;
  claimNumber?: string;
  policyOwnerName?: string;
  laId?: string;
  lifeAssuredName?: string;
  receivedAt?: string;
  updatedAt?: string;
  status?: string;
}

const FilterType = {
  ReceivedAt: 0,
  JetType: 1,
};

const JetOptions = [
  {
    name: 'Chọn loại jet...',
    value: '',
  },
  {
    name: 'Không thỏa Jet Rules',
    value: 0,
  },
  {
    name: 'Thỏa Jet Rules Papaya',
    value: 1,
  },
  {
    name: 'Thỏa Jet Rules FWD',
    value: 2,
  },
  {
    name: 'Không đạt yêu cầu Jet sau thẩm định',
    value: 3,
  },
];

const mapStateToProp = (state) => ({
  auth: state.auth,
});

class AssessmentScreen extends React.Component<IProps, IState> {
  timeOut;

  search = '';

  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      filterComps: [null, null],
      total: 0,
      page: 1,
      filters: {
        ReceivedAt: {
          from: undefined,
          to: undefined,
        },
        JetType: '',
      },
      sorts: '',
      isInProgress: props.location.pathname.includes(
        KEYS.ASSESSMENT_INPROGRESS
      ),
      isDeleted: this.props.location.pathname.includes(KEYS.ASSESSMENT_ERROR),
      filterType:
        (props.location.pathname.includes(KEYS.ASSESSMENT_INPROGRESS_JET) &&
          'Jet') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_INPROGRESS_STP
        ) &&
          'Stp') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_INPROGRESS_NONE_JET
        ) &&
          'NoneJet') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_INPROGRESS_TAT
        ) &&
          'Tat') ||
        (this.props.location.pathname.includes(KEYS.ASSESSMENT_PENDING) &&
          'WaitingPending') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_DECISION_NOT_YET
        ) &&
          'NotYetDecision') ||
        (this.props.location.pathname.includes(KEYS.ASSESSMENT_DONE) &&
          'Done') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_DECISION_FAILED
        ) &&
          'DecisionFailed') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_WAITING_DECISION
        ) &&
          'WaitingDecision') ||
        (this.props.location.pathname.includes(
          KEYS.ASSESSMENT_WAITING_RESULT
        ) &&
          'WaitingResult') ||
        '',
    };
    if (props.match.params.jetTab === ':jetTab') {
      this.props.history.push(
        this.props.location.pathname.replace(':jetTab', 'jet')
      );
    }
    if (props.match.params.jetTab === ':jetDecision') {
      this.props.history.push(
        this.props.location.pathname.replace(':jetDecision', 'notYetDecision')
      );
    }
  }

  async componentDidMount() {
    await this.loadList(this.state.filterType === '', 1);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.jetTab === ':jetTab') {
        this.props.history.push(
          this.props.location.pathname.replace(':jetTab', 'jet')
        );
      } else if (this.props.match.params.jetTab === ':jetDecision') {
        this.props.history.push(
          this.props.location.pathname.replace(':jetDecision', 'notYetDecision')
        );
      } else {
        this.setState(
          {
            isInProgress: this.props.location.pathname.includes(
              KEYS.ASSESSMENT_INPROGRESS
            ),
            isDeleted: this.props.location.pathname.includes(
              KEYS.ASSESSMENT_ERROR
            ),
            filterType:
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_INPROGRESS_JET
              ) &&
                'Jet') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_INPROGRESS_NONE_JET
              ) &&
                'NoneJet') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_INPROGRESS_TAT
              ) &&
                'Tat') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_INPROGRESS_STP
              ) &&
                'Stp') ||
              (this.props.location.pathname.includes(KEYS.ASSESSMENT_PENDING) &&
                'WaitingPending') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_DECISION_NOT_YET
              ) &&
                'NotYetDecision') ||
              (this.props.location.pathname.includes(KEYS.ASSESSMENT_DONE) &&
                'Done') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_DECISION_FAILED
              ) &&
                'DecisionFailed') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_WAITING_DECISION
              ) &&
                'WaitingDecision') ||
              (this.props.location.pathname.includes(
                KEYS.ASSESSMENT_WAITING_RESULT
              ) &&
                'WaitingResult') ||
              (this.props.location.pathname.includes(KEYS.ASSESSMENT_FAIL) &&
                'Fail') ||
              '',
          },
          () => this.loadList(this.state.filterType === '', 1)
        );
      }
    }
  }

  loadList = async (
    isAll?: boolean,
    page: number = this.state.page,
    search: string = this.search,
    filter?: FilterAssessmentType,
    sort?: string
  ) => {
    const { isInProgress, isDeleted, filterType }: Readonly<IState> =
      this.state;

    this.props.dispatch(setLoading(true));
    if (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.props.location.state as any) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.props.location.state as any).sessionPage != undefined
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      page = (this.props.location.state as any).sessionPage; // eslint-disable-line no-param-reassign
      this.props.history.replace({
        ...this.props.location,
        state: undefined,
      });
    }
    const response = await ClaimPortalService.getClaimList(
      isAll,
      page,
      search,
      filter || this.state.filters || [],
      sort || this.state.sorts || '',
      isInProgress,
      isDeleted,
      filterType
    );
    if (response && response.data) {
      const data = response.data.map((item) => {
        const itemData = item;
        itemData.receivedAt = utils.formatDateToStringDateDMYHM(
          new Date(item.receivedAt)
        );
        itemData.updatedAt = utils.formatDateToStringDateDMYHM(
          new Date(item.updatedAt)
        );
        return itemData;
      });
      this.setState({
        page,
        data,
        total: response.totalRow,
      });
    } else {
      this.props.history.replace(ROUTES.login);
    }
    this.props.dispatch(setLoading(false));
  };

  rowClick = (id: string, e) => {
    if (e == true) {
      window.open(ROUTES.requestAssessment.replace(':id', id));
    } else {
      const { page } = this.state;
      const route = this.props.history.location.pathname;
      this.props.history.push({
        pathname: ROUTES.requestAssessment.replace(':id', id),
        state: {
          sessionPage: page,
          path: route,
        },
      });
    }
  };

  handleChangeFilter = async (filters) => {
    const { filterComps }: Readonly<IState> = this.state;
    const newFilterComps = _.clone(filterComps) || [];
    const filtersData = _.clone(this.state.filters);
    if (filters.includes('ReceivedAt')) {
      if (!filterComps[FilterType.ReceivedAt]) {
        newFilterComps[FilterType.ReceivedAt] = this.renderCreatedAtComponent();
      }
    } else if (filterComps[FilterType.ReceivedAt]) {
      filtersData.ReceivedAt = {
        from: undefined,
        to: undefined,
      };
      newFilterComps[FilterType.ReceivedAt] = null;
    }
    if (filters.includes('JetType')) {
      if (!filterComps[FilterType.JetType]) {
        newFilterComps[FilterType.JetType] = this.renderJetTypeComponent();
      }
    } else if (filterComps[FilterType.JetType]) {
      filtersData.JetType = '';
      newFilterComps[FilterType.JetType] = null;
    }
    await this.loadList(
      this.state.filterType === '',
      1,
      this.search,
      filtersData,
      this.state.sorts
    );
    this.setState({
      filterComps: newFilterComps,
    });
  };

  handleFilterValueChange = async (name, value) => {
    const { filters }: Readonly<IState> = this.state;
    const newFilters = _.clone(filters);
    newFilters[name] = value;
    await this.loadList(
      this.state.filterType === '',
      1,
      this.search,
      newFilters,
      ''
    );
    this.setState({
      filters: newFilters,
    });
  };

  renderCreatedAtComponent = () => {
    const { filters }: Readonly<IState> = this.state;
    return (
      <div>
        <CalendarRange
          className="CreatedCalendar"
          name="ReceivedAt"
          errorPosition="bottom"
          label="Ngày tạo"
          labelFrom="Từ"
          labelTo="Tới"
          placeHolderFrom="Từ ngày..."
          placeHolderTo="Tới ngày..."
          // @ts-ignore
          value={filters.ReceivedAt}
          onChange={(name, value) => this.handleFilterValueChange(name, value)}
        />
      </div>
    );
  };

  renderJetTypeComponent = () => {
    const { filters }: Readonly<IState> = this.state;
    return (
      <div className="SelectionContainer">
        <span>Loại Jet</span>
        <select
          className="Selection"
          defaultValue={filters.JetType}
          onChange={(e) => {
            this.handleFilterValueChange(
              'JetType',
              parseInt(e.target.value, 0)
            );
          }}
        >
          {JetOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  handleSearch = async (s) => {
    this.search = s;
    await this.loadList(
      this.state.filterType === '',
      undefined,
      s,
      this.state.filters,
      this.state.sorts
    );
  };

  handleChangeSort = async (sort, sorts) => {
    if (sort === sorts) {
      this.setState({
        sorts: '',
      });
      await this.loadList(
        this.state.filterType === '',
        1,
        this.search,
        this.state.filters,
        ''
      );
    } else {
      this.setState({
        sorts: (sort === sorts && '') || sort,
      });
      await this.loadList(
        this.state.filterType === '',
        1,
        this.search,
        this.state.filters,
        sort
      );
    }
  };

  render() {
    const { data, total, page, filterComps, sorts }: Readonly<IState> =
      this.state;
    return (
      <div
        className={`assessmentListScreenContainer
      ${(!this.props.location.pathname.includes('done') && 'topMargin') || ''}`}
      >
        <Table
          label="Danh sách yêu cầu"
          limit={10}
          count={total}
          page={page}
          onChangePage={(p) => this.loadList(this.state.filterType === '', p)}
          onRowClick={(id, e) => this.rowClick(id, e)}
          onChangeSearch={async (s) => {
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(async () => {
              await this.handleSearch(s);
            }, 400);
          }}
          clickTrigger="id"
          searchPlaceHolder="Tìm theo "
          filters={[{ ReceivedAt: 'Ngày tạo' }, { JetType: 'Loại Jet' }]}
          filterComps={filterComps}
          onChangeFilter={(filters) => this.handleChangeFilter(filters)}
          sorts={['policyNumbers', 'receivedAt', 'updatedAt']}
          onSortChange={(sort) => this.handleChangeSort(sort, sorts)}
          headers={[
            { assign: 'STT' },
            { policyNumbers: 'SỐ HỒ SƠ CLAIM' },
            { policyOwnerName: 'CHỦ HỢP ĐỒNG' },
            { lifeAssuredName: 'NGƯỜI ĐƯỢC BẢO HIỂM' },
            { laId: 'MÃ NĐBH' },
            { receivedAt: 'NGÀY GỬI YÊU CẦU' },
            { updatedAt: 'NGÀY UPDATE MỚI' },
            { status: 'STATUS' },
          ]}
          tooltip={['status', 'policyNumbers']}
          data={data || []}
          highlightAt="isJet"
          highlightColor="rgb(246, 217, 205)"
          highlightContent="🚀"
          botChecked="isBot"
          botContent="🤖"
        />
      </div>
    );
  }
}

export default connect(mapStateToProp)(AssessmentScreen);
