import React from 'react';
import KEYS from './key';

const utils = {
  formatDateToStringDate: (date: Date) => {
    const month = date.getMonth();
    const day = date.getDate();
    return `${date.getFullYear()}-${month + 1 < 10 ? '0' : ''}${month + 1}-${
      day < 10 ? '0' : ''
    }${day}`;
  },
  formatDateToStringDateDMY: (date: Date) => {
    const month = date.getMonth();
    const day = date.getDate();
    return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${
      month + 1
    }/${date.getFullYear()}`;
  },
  formatDateToStringDateDMYHM: (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours =
      (date.getHours() < 10 && `0${date.getHours()}`) || date.getHours();
    const minutes =
      (date.getMinutes() < 10 && `0${date.getMinutes()}`) || date.getMinutes();
    return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${
      month + 1
    }/${year} ${hours}:${minutes}`;
  },
  formatToLocaleDate: (date: Date) => {
    const month = date.getMonth();
    const day = date.getDate();
    return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${
      month + 1
    }/${date.getFullYear()}`;
  },
  formatDateRangeToStringDate: (date: { from: Date; to: Date }) => {
    return `${utils.formatDateToStringDate(
      date.from
    )}&${utils.formatDateToStringDate(date.to)}`;
  },
  formatDateRangeToStringDateDMY: (date: { from: Date; to: Date }) => {
    return `${utils.formatDateToStringDateDMY(
      date.from
    )} - ${utils.formatDateToStringDateDMY(date.to)}`;
  },
  formatStringDateRangeToLocaleString: (date: string) => {
    const dates = date.split('&');
    const from = dates[0];
    const to = dates[1];
    const newFrom = `${from.split('-')[2]}/${from.split('-')[1]}/${
      from.split('-')[0]
    }`;
    const newTo = `${to.split('-')[2]}/${to.split('-')[1]}/${to.split('-')[0]}`;
    return `${newFrom} - ${newTo}`;
  },
  formatArrayDateRangeToString: (
    arrayDate: Array<{ from: Date; to: Date }>
  ) => {
    if (arrayDate && arrayDate.length) {
      const newArrayString = arrayDate.map((dateRange) =>
        utils.formatDateRangeToStringDate(dateRange)
      );
      return utils.formatArrayToString(newArrayString);
    }
    return '';
  },
  formatArrayToString: (array: Array<string>) =>
    (Array.isArray(array) && array.length && array.join('|')) || '',
  formatArrayToExcludeString: (array: Array<string>) =>
    (Array.isArray(array) && array.length && array.join(',')) || '',
  validate: (type: string, text: string) => {
    switch (type) {
      case KEYS.VALIDATE.EMAIL: {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(text);
      }
      case KEYS.VALIDATE.PHONE: {
        return /^[0-9]*$/gm.test(text);
      }
      case KEYS.VALIDATE.NONE_SPECIAL_CHAR: {
        // underscore exception, because \w support _
        if (text && text.length && text.includes('_')) return false;
        return /^[\w]+$/.test(text);
      }
      case KEYS.VALIDATE.NAME: {
        return /^[A-Za-z_ ]*$/gm.test(text);
      }
      case KEYS.VALIDATE.BANK_BRANCH: {
        return /^[0-9a-zA-Z_ ]*$/gm.test(text);
      }
      default:
        break;
    }
    return false;
  },
  formatDateRangeStringToDateRange: (
    dateRangeString: string,
    isArray?: boolean
  ) => {
    if (dateRangeString.includes('|') || isArray) {
      // Array date range
      const ArrayDateRangeString = dateRangeString.split('|');
      return ArrayDateRangeString.map((dateString) => {
        return {
          from: new Date(dateString.split('&')[0]),
          to: new Date(dateString.split('&')[1]),
        };
      });
    }
    return {
      from: new Date(dateRangeString.split('&')[0]),
      to: new Date(dateRangeString.split('&')[1]),
    };
  },
  formatDateString: (dateString: string) => {
    const date = dateString.split('/');
    return `${date[2]}/${date[1]}/${date[0]}`;
  },

  formatVnString: (string: string) => {
    let str = string;
    str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
    str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
    str = str.replace(/[ìíịỉĩ]/g, 'i');
    str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
    str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
    str = str.replace(/[ỳýỵỷỹ]/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, 'A');
    str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, 'E');
    str = str.replace(/[ÌÍỊỈĨ]/g, 'I');
    str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, 'O');
    str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, 'U');
    str = str.replace(/[ỲÝỴỶỸ]/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str;
  },
  b64toBlob: (b64Data: string, contentType: string, sliceSize?: number) => {
    const newContentType = contentType || '';
    const newSliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (
      let offset = 0;
      offset < byteCharacters.length;
      offset += newSliceSize
    ) {
      const slice = byteCharacters.slice(offset, offset + newSliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      // @ts-ignore
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: newContentType });
  },
  base64ToFile: (base64: string, fileName: string): FormData => {
    const file = new FormData();
    const block = base64.split(';');
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    const blob = utils.b64toBlob(realData, contentType);
    file.append('file', blob, fileName);
    return file;
  },
  ArrayBase64ToFile: (
    files: { base64: string; fileName: string }[]
  ): FormData => {
    const formDataFiles = new FormData();
    for (let i = 0; i < files.length; i++) {
      const block = files[i].base64.split(';');
      const contentType = block[0].split(':')[1]; // In this case "image/gif"
      const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      const blob = utils.b64toBlob(realData, contentType);
      formDataFiles.append('files', blob, files[i].fileName);
    }
    return formDataFiles;
  },
  toTxtComp: (text: string, symbol: string, isBullet: boolean = true) => {
    const textArray = text.split(symbol);
    return textArray.map((txt, index) => {
      const color =
        (txt.split('$') && txt.split('$').length && txt.split('$')[1]) ||
        undefined;
      if (color) {
        return (
          <div key={`txt${txt}`}>
            <span
              style={{ color: `${color}`, width: 'auto', display: 'initial' }}
            >
              {(isBullet && '-') || ''} {txt.split('$')[0]}
            </span>
            <br />
          </div>
        );
      }
      return (
        <div key={`txt${txt}`}>
          <p
            className="detailContent"
            style={{ width: 'auto', display: 'initial' }}
          >
            {(isBullet && '-') || ''} {txt}
          </p>
        </div>
      );
    });
  },
  toUCFirst: (string) => string.charAt(0).toUpperCase() + string.slice(1),
  highLightNumberInString: (string: string) => {
    let results;
    if (string && string.length) {
      const words = string.split(' ');
      let newWords: Array<React.ReactNode> = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newWords = words.map((word: any) => {
        if (!isNaN(word)) {
          return (
            <span
              key={word + Math.random()}
              style={{ color: 'red', fontWeight: 'bold' }}
            >
              {word}{' '}
            </span>
          );
        }
        return <span key={word + Math.random()}>{word} </span>;
      });
      results = newWords;
    }
    return results;
  },
  blobToBase64: (blob: Blob) => {
    return new Promise<string>((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = () => resolve(fileReader.result?.toString() ?? '');
    });
  },
};

export default utils;
