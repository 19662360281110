import { NOTIFICATION } from '../types';
import { NotificationAction } from '../interface';

export default function notification(state = {
  decisionFail: 0,
  jet: 0,
  noDecision: 0,
  nonJet: 0
}, action: NotificationAction) {
  let newState = state;
  if (action.type === NOTIFICATION) {
    newState = action.notification;
  }
  return newState;
}
