import React from 'react';
import './styles.css';
import { TextField } from 'papaya-ui';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import KEYS from '../../../../../config/key';
import utils from '../../../../../config/utils';

interface IProps extends WithTranslation{
  onChange: Function;
  dispatch: Function;
  childValue?: forwardType;
  onValid?: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: string;
}

export interface forwardType {
  policyNumber?: string;
  paymentType?: string;
}

interface IState {
  error: errorField;
}

interface errorField {
  policyNumber?: string;
}

const mapStateToProp = (state) => ({
  data: state.data,
});

class Forward extends React.Component<IProps, IState> {
  timeOut;
  
  constructor(props) {
    super(props);
    this.state = {
      error: {},
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if(prevProps.childValue !== this.props.childValue) {
      this.handleValidation();
    }
  }

  handleChange = (name, value) => {
    const { onChange, childValue } = this.props;
    const newData = childValue || {};
    newData[name] = value.trim();
    if((name === KEYS.FIELD.POLICY_NUMBER_CAMEL) && value
      && !utils.validate(KEYS.VALIDATE.PHONE, value)){
      // wrong validate
    } else {
      if (onChange) {
        onChange('forward', newData);
      }
      this.handleValidation();
    }
  };
  
  handleValidation = () => {
    const { onValid, childValue } = this.props;
    if (childValue && onValid && childValue.policyNumber) {
      delete childValue.paymentType;
      if (Object.keys(childValue).length === 1
            && childValue.policyNumber.length === 8) {
        onValid(KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE, true);
      } else {
        onValid(KEYS.PAYMENT_TYPE.FORWARD_MONEY.VALUE, false);
      }
    }
  };
  
  render() {
    const { childValue, t } = this.props;
    const { error }: Readonly<IState> = this.state;
    return (
      <div className="forwardContainer">
        <div className="rowForward">
          <TextField
            className="policyNumberForward"
            name={KEYS.FIELD.POLICY_NUMBER_CAMEL}
            label={t('policy.label.policyNumber')}
            helperText={t('payment.helperText.policyNumber')}
            placeHolder={t('policy.placeHolder.policyNumber')}
            type="text"
            max={8}
            errorPosition="bottom"
            errorMsg={error[KEYS.FIELD.POLICY_NUMBER_CAMEL] || ''}
            onChange={(name, value) => this.handleChange(name, value)}
            value={(childValue && childValue[KEYS.FIELD.POLICY_NUMBER_CAMEL]) || ''}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProp)(withTranslation('common')(Forward));
