export const prefix = '/portal';

export const ROUTES = {
  notfound: `${prefix}/404`,
  dashboard: `${prefix}/dashboard`,
  documentDetailAssessment: `${prefix}/assessment/detail/:id/docs/:docId`,
  documentAssessment: `${prefix}/assessment/detail/:id/docs`,
  letterListAssessment: `${prefix}/assessment/detail/:id/letters`,
  notificationAssessment: `${prefix}/assessment/detail/:id/presentCase`,
  paymentAssessment: `${prefix}/assessment/detail/:id/payment`,
  requestCaseAssessment: `${prefix}/assessment/detail/:id/request/:caseId`,
  requestAssessment: `${prefix}/assessment/detail/:id/request`,
  originalAssessment: `${prefix}/assessment/detail/:id/original`,
  assessmentDetail: `${prefix}/assessment/detail`,
  // assessment status
  shortAssessmentInProgress: `${prefix}/assessment/inProgress`,
  assessmentError: `${prefix}/assessment/errors/list`,
  assessmentWaitingResult: `${prefix}/assessment/:status/waitingResult`,
  shortAssessmentNotDecision: `${prefix}/assessment/notDecision`,
  assessment: `${prefix}/assessment/:status/:jetTab`,
  assessmentInProgress: `${prefix}/assessment/inProgress/:jetTab`,
  assessmentDone: `${prefix}/assessment/done/list`,
  assessmentDecision: `${prefix}/assessment/notDecision/:jetDecision`,
  // assessment in progress jetType
  assessmentStp: `${prefix}/assessment/:status/stp`,
  assessmentJet: `${prefix}/assessment/:status/jet`,
  assessmentTat: `${prefix}/assessment/:status/tat`,
  assessmentNoneJet: `${prefix}/assessment/:status/noneJet`,
  assessmentPending: `${prefix}/assessment/:status/pending`,
  assessmentFail: `${prefix}/assessment/:status/fail`,
  // assessment not decision
  assessmentNotYetDecision: `${prefix}/assessment/:status/notYetDecision`,
  assessmentFailedDecision: `${prefix}/assessment/:status/failedDecision`,
  assessmentWaitingDecision: `${prefix}/assessment/:status/waitingDecision`,
  assessmentList: `${prefix}/assessment/all`,
  // auth
  login: `${prefix}/auth/login`,
  // setting
  setting: `${prefix}/settings`,
  changePassword: `${prefix}/settings/change-password`,
  editProfile: `${prefix}/settings/edit-profile`,
};
