import axios from 'axios';
import _ from 'underscore';
import KEYS from './key';

axios.defaults.timeout = (process.env.REACT_APP_SERVER_TIMEOUT
  && !isNaN(parseInt(process.env.REACT_APP_SERVER_TIMEOUT, 0))
  && parseInt(process.env.REACT_APP_SERVER_TIMEOUT, 0)) || KEYS.API.TIMEOUT;

const apiUrl = [
  process.env.REACT_APP_WEBSITE_API,
  process.env.REACT_APP_PORTAL_API,
];
const CLAIM_WEBSITE = 0;
const CLAIM_PORTAL = 1;

const headers = (token: string) => {
  if (token) {
    return {
      headers: {
        'Accept-Language': 'vi-VN',
        'Insurer':'FWD',
        Authorization: `Bearer ${getTokenFromRaw(token)}`,
      },
    };
  }
  return {
    headers: {
      'Accept-Language': 'vi-VN',
      'Insurer':'FWD'
    },
  };
};

const getTokenFromRaw = (token: string) => {
  return (token && token.split(KEYS.DELIMITER)[0]) || '';
};

const Service = (apiKey: number, token: string = '') => {
  return {
    get: (path, params = {}) => {
      let requestParam = (Object.keys(params).length && '?') || '';
      Object.keys(params).forEach((key) => {
        requestParam += `${key}=${params[key]}&&`;
      });
      requestParam = requestParam.substring(
        requestParam.length - 2,
        requestParam.length - 1
      );
      return axios
        .get(apiUrl[apiKey] + path + requestParam, headers(token))
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        });
    },
    post: (path, params = {}) => {
      return axios
        .post(apiUrl[apiKey] + path, params, headers(token))
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        });
    },
    patch: (path, params={}) => {
      return axios
        .patch(apiUrl[apiKey] + path, params, headers(token))
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        });
    },
    put: (path, params = {}) =>
      axios
        .put(apiUrl[apiKey] + path, params, headers(token))
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        }),
    delete: (path, id) =>
      axios
        .delete(`${apiUrl[apiKey] + path}/${id}`, headers(token))
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        }),
    upload: (path, file = new FormData(), callBack) => {
      const newHeader = _.clone(headers(token));
      newHeader['Content-Type'] = 'multipart/form-data';
      newHeader.onUploadProgress = (progressEvent) => callBack(progressEvent);
      return axios
        .post(apiUrl[apiKey] + path, file, newHeader)
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        });
    },
    uploadPut: (path, file = new FormData(), callBack) => {
      const newHeader = _.clone(headers(token));
      newHeader['Content-Type'] = 'multipart/form-data';
      newHeader.onUploadProgress = (progressEvent) => callBack(progressEvent);
      
      return axios
        .put(apiUrl[apiKey] + path, file, newHeader)
        .then((res) => {
          return {
            data: res,
            error: null,
          };
        })
        .catch((error) => {
          if(error.response){
            return ({
              data: null,
              error: error.response,
            });
          }
          return ({
            data: null,
            error,
          });
        });
    },
  };
};

export { CLAIM_WEBSITE, CLAIM_PORTAL, Service };
